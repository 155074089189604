import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { Sizes, SIZE_MAP } from '@/styles/spacing'

type Variant = 'gray30' | 'gray20'

type Props = {
  spacing?: Sizes
  variant?: Variant
}

const Line = styled.div<{ spacing: Sizes; variant: Variant }>`
  border-top: 1rem dashed ${({ variant }) => colors[variant]};
  margin-top: ${({ spacing }) => SIZE_MAP[spacing ?? 'none']}rem;
  margin-bottom: ${({ spacing }) => SIZE_MAP[spacing ?? 'none']}rem;
`

const Divider: FC<Props> = ({ spacing = 'sm', variant = 'gray30' }) => {
  return (
    <Line
      spacing={spacing}
      variant={variant}
    />
  )
}

export default Divider
