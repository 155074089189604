import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { fromBreakpoint } from '../../styles/breakpoints';
import ECWInterestsWidget from './ECWInterestsWidget';
import { colorPalette } from '../../styles/styleGuide';
import WidgetContainer from '../shared/designElements/WidgetContainer';
import DesignsWidget from './DesignsWidget';
var WishlistBlock = function WishlistBlock(_ref) {
  var wishlist = _ref.wishlist,
    experienceRating = _ref.experienceRating;
  return __jsx(Container, {
    "data-e2e-block-wishlist": true,
    "data-id": "dashboard_DesignsBlock_Container"
  }, __jsx(WidgetContainer, {
    label: "Ontwerpen",
    size: "normal",
    icon: "brush",
    "data-id": "dashboard_DesignsBlock_WidgetContainer"
  }, __jsx(BlocksContainer, {
    "data-id": "dashboard_DesignsBlock_BlocksContainer"
  }, __jsx(Block, {
    "data-id": "dashboard_DesignsBlock_Block"
  }, __jsx(DesignsWidget, {
    wishlist: wishlist,
    "data-id": "dashboard_DesignsBlock_DesignsWidget"
  })), __jsx(ECWInterestsWidget, {
    experienceRating: experienceRating,
    "data-id": "dashboard_DesignsBlock_ECWInterestsWidget"
  }))));
};
export default withMatch(WishlistBlock);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
var BlocksContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  ", ";\n"])), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    flex-direction: row;\n  "]))));
var Block = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  position: relative;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    width: calc(2/3 * 100%);\n    border-right: 1rem dashed ", ";\n    padding-right: 30rem;\n  "])), colorPalette.lightBeige), fromBreakpoint.xl(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    border-right: 1rem dashed ", ";\n    padding-right: 30rem;\n  "])), colorPalette.lightBeige));