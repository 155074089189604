import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { colorPalette, textStyles, fadeInLeft, CUBIC_TIMING } from '../../styles/styleGuide';
import { fromBreakpoint } from '../../styles/breakpoints';
import { openModal as _openModal } from '../../redux/modal';
import { getResultData } from '../../redux/apiData';
import ThemeLabel from '../shared/designElements/ThemeLabel';
import MailIcon from '../shared/icons/Message';
import AvatarHSIcon from '../shared/icons/AvatarHS';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import truncateText from '../../services/truncateText';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import { WidgetScroller } from '../shared/designElements/WidgetScroller';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import UserAvatar from '../UserAvatar';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    phase: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'phase', undefined)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal() {
      if (ownProps.dummyContent) {
        dispatch(_openModal(colorPalette.lightOrange, {
          type: 'profile',
          componentProps: {
            type: 'messages',
            content: ownProps.dummyContent.modal
          }
        }));
      }
    }
  };
};
var redirect = function redirect(link, router) {
  if (!link) return;
  router === null || router === void 0 || router.push('/' + getLinkFromPageObject(link));
};
var countMessages = function countMessages(unreadCount) {
  if (unreadCount === undefined) return '1 ongelezen bericht';
  if (unreadCount === 0) {
    return 'Geen ongelezen berichten';
  } else if (unreadCount === 1) {
    return '1 ongelezen bericht';
  } else {
    return "".concat(unreadCount, " ongelezen berichten");
  }
};
var MessagesWidget = function MessagesWidget(_ref) {
  var phase = _ref.phase,
    _ref$items = _ref.items,
    items = _ref$items === void 0 ? [] : _ref$items,
    link = _ref.link,
    unreadCount = _ref.unreadCount,
    openModal = _ref.openModal,
    router = _ref.router;
  var messages = phase === 'cj1' ? [{
    sender: {
      name: 'Team Homestudios',
      icon: __jsx(AvatarHSIcon, {
        "data-id": "dashboard_MessagesWidget_AvatarHSIcon"
      })
    },
    onClick: openModal,
    subject: 'Welkom bij Homestudios',
    content: 'Als koper kun je vanaf deze plaats straks direct communiceren met de woonadviseur en andere Homestudios specialisten.',
    type: 'defaultMessage'
  }] : items.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      onClick: link ? function () {
        return redirect(link, router);
      } : undefined
    });
  });
  return __jsx(Container, {
    "data-id": "dashboard_MessagesWidget_Container"
  }, __jsx(Label, {
    "data-id": "dashboard_MessagesWidget_Label"
  }, __jsx(ThemeLabel, {
    label: "Berichtencentrum",
    theme: "projectUpdate",
    "data-id": "dashboard_MessagesWidget_ThemeLabel"
  }), __jsx(MailIcon, {
    width: 20,
    height: 14,
    color: colorPalette.white,
    "data-id": "dashboard_MessagesWidget_MailIcon"
  })), __jsx(MessageCount, {
    "data-id": "dashboard_MessagesWidget_MessageCount"
  }, countMessages(unreadCount)), __jsx(WidgetScroller, {
    "data-id": "dashboard_MessagesWidget_WidgetScroller"
  }, messages.map(function (item, index) {
    return index < 2 && __jsx(Message, {
      key: index,
      border: index === 1,
      onClick: item.onClick,
      "data-id": "dashboard_MessagesWidget_Message"
    }, __jsx(Icon, {
      "data-id": "dashboard_MessagesWidget_Icon"
    }, item.type === 'defaultMessage' ? item.sender.icon : __jsx(UserAvatar, {
      size: "small",
      mode: "imageLoader",
      name: item.sender.name,
      imageUrl: item.sender.image,
      "data-id": "dashboard_MessagesWidget_UserAvatar"
    })), __jsx(TextBlock, {
      "data-id": "dashboard_MessagesWidget_TextBlock"
    }, __jsx(Author, {
      "data-id": "dashboard_MessagesWidget_Author"
    }, __jsx("span", {
      "data-id": "dashboard_MessagesWidget_span"
    }, item.sender.name)), __jsx(Title, {
      "data-id": "dashboard_MessagesWidget_Title"
    }, truncateText(item.subject, 30)), __jsx(MessageContent, {
      "data-id": "dashboard_MessagesWidget_MessageContent"
    }, truncateText(item.content, 85))));
  })), unreadCount === 0 && __jsx(MailIconContainer, {
    "data-id": "dashboard_MessagesWidget_MailIconContainer"
  }, __jsx(MailIcon, {
    width: 200,
    height: 140,
    color: colorPalette.lightGrey,
    "data-id": "dashboard_MessagesWidget_MailIcon"
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_MessagesWidget_WidgetFooter"
  }, (phase === 'cj2' || phase === 'cj3') && link && __jsx(ButtonSecondary, {
    label: "Bekijk alle berichten",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(link),
    "data-id": "dashboard_MessagesWidget_ButtonSecondary"
  })));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(MessagesWidget));
export var ActionsAndMessageWidgetStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  box-sizing: border-box;\n  height: 346rem;\n  position: relative;\n  box-sizing: border-box;\n  padding: 20rem;\n  background-color: white;\n  opacity: 0;\n  animation-delay: 0.25s;\n  animation-fill-mode: forwards;\n  animation-timing-function: ", ";\n  animation-duration: 0.5s;\n  animation-name: ", ";\n\n  @media all and (max-width: 969px) {\n    min-height: 346rem;\n    margin-right: 0;\n  }\n"])), CUBIC_TIMING, fadeInLeft);
var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), ActionsAndMessageWidgetStyles);
var Message = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  padding-top: ", "rem;\n  padding-bottom: 14rem;\n  margin-top: ", "rem;\n  margin-bottom: ", "rem;\n  border-top: ", ";\n  border-bottom: 1rem dashed #979797;\n\n  &:hover {\n    cursor: pointer;\n  }\n"])), function (props) {
  return props.border ? 0 : 12;
}, function (props) {
  return props.border ? 14 : 16;
}, function (props) {
  return props.border ? 49 : 0;
}, function (props) {
  return props.border ? 'none' : '1rem dashed #979797';
});
var MessageContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n"])), textStyles.caption, colorPalette.textGrey);
var Title = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-weight: bold;\n"])), textStyles.caption, colorPalette.textGrey);
var Author = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  ", ";\n  font-size: 12rem;\n  color: ", ";\n  justify-content: space-between;\n  width: 100%;\n"])), textStyles.caption, colorPalette.beige);
var TextBlock = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 82%;\n  ", ";\n"])), fromBreakpoint.xl(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: 85%\n  "]))));
var Icon = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-right: 12rem;\n  background-color: ", ";\n  border-radius: 50%;\n  width: 36rem;\n  height: 36rem;\n  justify-content: center;\n  align-items: center;\n"])), colorPalette.orange);
var MessageCount = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-weight: bold;\n"])), textStyles.standardText, colorPalette.textGrey);
var MailIconContainer = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-grow: 1;\n  padding: 10rem;\n  height: 100%;\n"])));
var Label = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: -40rem;\n  margin-left: -20rem;\n\n  > div {\n    > span {\n      padding-left: 50rem;\n      padding-right: 41rem;\n    }\n  }\n\n  > svg {\n    position: absolute;\n    top: 0;\n    margin-top: 14rem;\n    margin-left: 20rem;\n  }\n"])));