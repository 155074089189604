import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fromBreakpoint } from '../../../styles/breakpoints';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import CalendarDate from '../icons/CalendarDate';
import CalendarDateTime from '../icons/CalendarDateTime';
import { hex2rgb } from '../../../styles/utils';
import parseTimestamp from '../../../services/parseTimestamp';
import get from 'lodash.get';
var defaultProps = {
  textColor: colorPalette.green,
  bgColor: '#ffffff',
  size: 'medium'
};
var DateIcon = function DateIcon(_ref) {
  var date = _ref.date,
    size = _ref.size,
    bgColor = _ref.bgColor,
    textColor = _ref.textColor;
  if (date === '' || date === undefined) return __jsx("span", {
    "data-id": "designElements_DateIcon_span"
  });
  var _parseTimestamp = parseTimestamp(date),
    day = _parseTimestamp.day,
    month = _parseTimestamp.month,
    time = _parseTimestamp.time;
  return __jsx(DateIconWrapper, {
    size: size,
    "data-id": "designElements_DateIcon_DateIconWrapper"
  }, time && __jsx("div", {
    "data-id": "designElements_DateIcon_div"
  }, __jsx(CalendarDateTime, {
    color: bgColor,
    "data-id": "designElements_DateIcon_CalendarDateTime"
  }), __jsx(CalendarDateTime, {
    color: hex2rgb(bgColor || defaultProps.bgColor, 0.5),
    "data-id": "designElements_DateIcon_CalendarDateTime_4"
  })), !time && __jsx("div", {
    "data-id": "designElements_DateIcon_div"
  }, __jsx(CalendarDate, {
    color: bgColor,
    "data-id": "designElements_DateIcon_CalendarDate"
  }), __jsx(CalendarDate, {
    color: hex2rgb(bgColor || defaultProps.bgColor, 0.5),
    "data-id": "designElements_DateIcon_CalendarDate_7"
  })), __jsx(DateIconInnerWrapper, {
    color: textColor,
    "data-id": "designElements_DateIcon_DateIconInnerWrapper"
  }, __jsx(CalendarDay, {
    size: size,
    "data-id": "designElements_DateIcon_CalendarDay"
  }, day), __jsx(CalendarMonth, {
    size: size,
    "data-id": "designElements_DateIcon_CalendarMonth"
  }, get(month, 'shortName')), time && __jsx(CalendarTime, {
    "data-id": "designElements_DateIcon_CalendarTime"
  }, time)));
};
DateIcon.defaultProps = defaultProps;
export default DateIcon;
var DateIconWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  flex-basis: 50rem;\n  width: 50rem;\n  height: 77rem;\n  transform: ", ";\n  margin-right: ", ";\n  margin-top: ", ";\n  font-family: ", ";\n\n  svg {\n    position: absolute;\n    left: 0;\n    transform: rotate(-12deg);\n\n    &:first-child {\n      transform: rotate(0deg);\n    }\n  }\n"])), function (props) {
  switch (props.size) {
    case 'mini':
      return 'scale(0.66,0.66)';
    case 'small':
      return 'scale(0.85,0.85)';
    case 'medium':
      return 'scale(1,1)';
    case 'big':
      return 'scale(1.5,1.5)';
    case 'huge':
    default:
      return 'scale(2.3,2.3)';
  }
}, function (props) {
  return props.size === 'medium' ? '0' : '15rem';
}, function (props) {
  return props.size === 'medium' ? '0' : '15rem';
}, fonts.primary);
var DateIconInnerWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  line-height: 18rem;\n  padding-top: 14rem;\n  width: 100%;\n  text-align: center;\n  color: ", ";\n\n  span {\n    display: block;\n  }\n"])), function (props) {
  return props.color;
});
var CalendarDay = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 16rem;\n  font-weight: bold;\n  margin-bottom: 3rem;\n  position: relative;\n  transform: ", ";\n  top: ", ";\n\n  ", ";\n"])), function (props) {
  return props.size === 'huge' ? 'scale(0.8,0.8)' : 'none';
}, function (props) {
  return props.size === 'huge' ? '2rem' : 'auto';
}, fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))));
var CalendarMonth = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 16rem;\n  margin-bottom: 4rem;\n  position: relative;\n  top: ", ";\n  transform: ", ";\n\n  ", ";\n"])), function (props) {
  return props.size === 'huge' ? '-3rem' : 'auto';
}, function (props) {
  return props.size === 'huge' ? 'scale(0.7,0.7)' : 'none';
}, fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))));
var CalendarTime = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 14rem;\n  font-weight: bold;\n  border-top: solid 1rem ", ";\n  margin-top: 3rem;\n  padding-top: 1rem;\n"])), function (props) {
  return props.textColor;
});