var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 66,
  height: 65
};
var Key = function Key(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 66 65",
    "data-id": "icons_Key_svg"
  }, __jsx("g", {
    "data-id": "icons_Key_g"
  }, __jsx("g", {
    fill: color,
    transform: "translate(-40.000000, -612.000000)",
    "data-id": "icons_Key_g_2"
  }, __jsx("path", {
    d: "M56.5,668.9l-3.8,3.8c-2.3,2.3-6.1,2.3-8.5,0l-4.2-4.2l30.9-30.9c-1.1-2.3-1.7-4.9-1.7-7.6c0-9.9,8.1-18,18-18s18,8.1,18,18s-8.1,18-18,18c-2.8,0-5.5-0.7-7.9-1.8L65,660.4l3.9,3.9c2.3,2.3,2.3,6.1,0,8.5l-4.2,4.2L56.5,668.9z M87.2,641c6.1,0,11-4.9,11-11s-4.9-11-11-11c-6.1,0-11,4.9-11,11S81.1,641,87.2,641z",
    "data-id": "icons_Key_path"
  }))));
};
Key.defaultProps = defaultProps;
export default Key;