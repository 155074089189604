import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, fonts } from '../../styles/styleGuide';
import Triangle from '../shared/designElements/Triangle';
export var triggerLabels = {
  updated: 'Geüpdatet',
  "new": 'Nieuw',
  cancelled: 'Vervallen',
  processed: 'Actie vereist'
};
var TriggerLabel = function TriggerLabel(_ref) {
  var type = _ref.type;
  return type && __jsx(Label, {
    type: type,
    "data-id": "homestudio_TriggerLabel_Label"
  }, __jsx(Triangle, {
    width: 8,
    height: 8,
    direction: "bottom",
    color: type === 'cancelled' ? colorPalette.lightBeige : colorPalette.lightOrange,
    "data-id": "homestudio_TriggerLabel_Triangle"
  }), triggerLabels[type]);
};
export default TriggerLabel;
var Label = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-items: center;\n  height: 26rem;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  background: ", ";\n  font-family: ", ";\n  font-size: 12rem;\n  font-weight: bold;\n  text-transform: uppercase;\n  color: #fff;\n  position: relative;\n\n  > svg {\n    position: absolute;\n    bottom: -4rem;\n  }\n"])), function (props) {
  return props.type === 'cancelled' ? colorPalette.lightBeige : colorPalette.lightOrange;
}, fonts.primary);