import { closeModal, HouseDesignModalState } from '@/redux/houseDesign'
import { useSelector } from '@/redux/index'
import { colors, RGBAColors } from '@/styles/colors'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import ImageSlider from '@/atoms/ImageSlider'
import { AnimatePresence, motion } from 'framer-motion'
import CloseIcon from '@/svg/CloseIcon'
import UtilityButton from '@/atoms/UtilityButton'
import Button from '@/atoms/Button'
import { useScrollbarWidth } from '@/hooks/useScrollbarWidth'
import { z } from '@/styles/zIndexes'
import { CoveredImageProps } from '@/atoms/CoveredImage.props'
import CoveredImage from '@/atoms/CoveredImage'
import MaxWidth from '@/atoms/MaxWidth'
import Sticky from '@/atoms/Sticky'
import { resetHtmlStyleForModal, setHtmlStyleForModal } from '@/processes/modalHelpers'
import FloorPlanModal from '@/components/houseDesign/FloorPlanModal'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { Sizes, SIZE_MAP } from '@/styles/spacing'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'

const FadeInOut = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${z.modal};
`

const Overlay = styled.div<{ transparent: boolean; noScroll: boolean }>`
  background-color: ${({ transparent }) => (transparent ? RGBAColors.black70 : colors.gray20)};
  height: 100%;
  overflow-y: auto;

  ${({ noScroll }) =>
    noScroll &&
    css`
      overflow-y: hidden;
    `}
`

const TopButtonWrapper = styled.div<{ overlayed: boolean; spacing: Sizes }>`
  padding: ${({ spacing }) => `${SIZE_MAP[spacing]}rem ${SIZE_MAP[spacing]}rem 0 0`};
  display: flex;
  justify-content: flex-end;
  ${({ overlayed }) =>
    overlayed &&
    css`
      position: fixed;
      top: 0;
      right: 0;
    `}
  z-index: ${z.modalButton};
`

const ContentWrapper = styled.div<{ bottomButton: boolean }>`
  height: 100%;

  ${({ bottomButton }) =>
    bottomButton &&
    css`
      padding-bottom: 80rem;
    `}
`

const BottomButtonWrapper = styled(Sticky)`
  display: flex;
  justify-content: center;
  z-index: ${z.modalButton};
`

const getModalConfig = (modalState?: HouseDesignModalState) => {
  if (!modalState) {
    return undefined
  }

  const { type, props } = modalState

  switch (type) {
    case 'FloorPlan':
      return {
        Component: FloorPlanModal,
        props,
        topButtonOverlayed: true,
        transparent: false,
        buttonOnBottom: true,
        noScroll: true,
      }
    case 'Image':
      return {
        Component: (props: CoveredImageProps) => (
          <CoveredImage
            alt=""
            {...props}
          />
        ),
        props,
        topButtonOverlayed: true,
        noScroll: true,
      }
    case 'ImageSlider':
      return {
        Component: ImageSlider,
        props,
        topButtonOverlayed: true,
        noScroll: true,
      }
    default:
      return undefined
  }
}

const Modal = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const scrollbarWidth = useScrollbarWidth()
  const modalState = useSelector((state) => state.houseDesign.modal)
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()
  const spacing = useResponsiveSpacing()

  useEffect(() => {
    if (modalState) {
      setOpen(true)
    }
  }, [modalState])

  const modalConfig = getModalConfig(modalState)
  const validAndOpen = !!modalConfig

  const noScroll = modalConfig?.noScroll ?? false

  useEffect(
    () => () => {
      dispatch(closeModal())
    },
    [dispatch]
  )

  useEffect(() => {
    setHtmlStyleForModal(validAndOpen, scrollbarWidth)
    return resetHtmlStyleForModal
  }, [dispatch, noScroll, scrollbarWidth, validAndOpen])

  if (!modalConfig) {
    return null
  }

  const {
    topButtonOverlayed = false,
    buttonOnBottom = false,
    Component,
    props,
    transparent = true,
  } = modalConfig

  const bottomButton = buttonOnBottom && !md

  return (
    <AnimatePresence
      onExitComplete={() => {
        dispatch(closeModal())
      }}
    >
      {open && (
        <FadeInOut
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Overlay
            transparent={transparent}
            noScroll={noScroll}
          >
            {!bottomButton && (
              <TopButtonWrapper
                overlayed={topButtonOverlayed}
                spacing={spacing}
              >
                <UtilityButton
                  onClick={() => setOpen(false)}
                  icon={CloseIcon}
                />
              </TopButtonWrapper>
            )}
            <ContentWrapper bottomButton={bottomButton}>
              {/* @ts-ignore - getModalConfig ensures that this is correct */}
              <Component
                {...props}
                style={{ zIndex: z.modalContent }}
              />
            </ContentWrapper>
            {bottomButton && (
              <BottomButtonWrapper
                withPadding
                withOffset={false}
              >
                <MaxWidth>
                  <Button
                    shadow
                    text="Sluiten"
                    onClick={() => setOpen(false)}
                    icon={CloseIcon}
                    variant="secondary"
                  />
                </MaxWidth>
              </BottomButtonWrapper>
            )}
          </Overlay>
        </FadeInOut>
      )}
    </AnimatePresence>
  )
}

export default Modal
