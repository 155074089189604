import { ITotalsWishList, WishList, WishListSubcategory } from '@/entities/wishList'
import { getTotalAmountForOption } from '@/processes/houseDesignStatuses'

/**
 * The total price for a WishList subcategory
 */
export const getTotalPriceForSubcategory = (
  { options }: WishListSubcategory,
  wishList?: WishList
) =>
  options.reduce((totalPrice, option) => {
    const { price } = option
    return totalPrice + getTotalAmountForOption(option, wishList) * price
  }, 0)

/**
 * The total price for a WishList category
 */
export const getTotalPriceForCategory = (title: string, totals: ITotalsWishList) =>
  totals.categories.find((item) => item.title === title)?.total ?? 0

/**
 * The total price for the reference options in a WishList category
 */
export const getTotalReferencePriceForCategory = (
  subcategories: WishListSubcategory[],
  wishList?: WishList
) =>
  subcategories.reduce(
    (totalSubcategoryPrice, { options }) =>
      totalSubcategoryPrice +
      options.reduce((totalOptionsPrice, option) => {
        const { price, optionType } = option
        if (optionType !== 'reference') {
          return totalOptionsPrice
        }
        return totalOptionsPrice + getTotalAmountForOption(option, wishList) * price
      }, 0),
    0
  )

/**
 * The total price for the full WishList
 */
export const getTotalPrice = (
  { categories = [], userWishTotal = 0 }: ITotalsWishList,
  basePrice = 0
) =>
  userWishTotal +
  categories.reduce((cumulativeTotal, { total = 0 }) => cumulativeTotal + total, basePrice)
