import { useMemo } from 'react'
import useParams from '@/hooks/useParams'
import useHomestudiosPath from '@/hooks/useHomestudiosPath'
import useWishList from '@/hooks/useWishList'
import {
  getNextSection,
  getPreviousSection,
  getWishListSections,
} from '@/processes/houseDesignSections'

/**
 * Get the config for the sections/pages of a wish list, to build and handle navigation etc.
 */
const useSections = () => {
  const { wishListItem } = useParams()
  const wishList = useWishList()
  const baseUrl = useHomestudiosPath()
  const sections = useMemo(() => getWishListSections(baseUrl, wishList), [wishList, baseUrl])
  return {
    sections,
    currentSectionSlug: wishListItem,
    nextSection: getNextSection(sections, wishListItem),
    previousSection: getPreviousSection(sections, wishListItem),
  }
}

export default useSections
