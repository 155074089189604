var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseComfort = function HouseComfort(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseComfort_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseComfort_title"
  }, "Huis Comfort"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseComfort_g"
  }, __jsx("g", {
    transform: "translate(-270.000000, -767.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_HouseComfort_g_3"
  }, __jsx("g", {
    transform: "translate(40.000000, 761.000000)",
    "data-id": "icons_HouseComfort_g_4"
  }, __jsx("g", {
    transform: "translate(230.000000, 6.000000)",
    "data-id": "icons_HouseComfort_g_5"
  }, __jsx("path", {
    d: "M10,1.8125 L1.5625,6.84375 L1.59375,18.375 L17.46875,18.40625 C18,18.40625 18.40625,18 18.40625,17.46875 L18.40625,6.875 L10,1.8125 Z M10,0 L20,6 L19.96875,18.09375 C19.96875,19.125 19.125,19.96875 18.09375,19.96875 L0,19.96875 L0,5.96875 L10,0 Z",
    "data-id": "icons_HouseComfort_path"
  }), __jsx("path", {
    d: "M8.09375,14.21875 C7.96875,14.34375 7.75,14.34375 7.625,14.21875 C7.5625,14.15625 7.53125,14.0625 7.53125,13.96875 C7.53125,13.875 7.59375,13.78125 7.65625,13.71875 L12.75,9.5625 L8.09375,14.21875 Z M6.8125,14.59375 L5.90625,15.5625 L6.25,15.90625 L7.1875,15.03125 C12.3125,16.71875 13.71875,12.40625 13.71875,8.09375 C10.84375,10.5 5.625,9.125 6.8125,14.59375 Z",
    "data-id": "icons_HouseComfort_path_7"
  }))))));
};
HouseComfort.defaultProps = defaultProps;
export default HouseComfort;