import React, { ReactNode } from 'react'
import ContentLoader from '@/components/shared/ui/ContentLoader'
import useMatch from '@/hooks/useMatch'
import ErrorBlock from '@/components/ErrorBlock'
import { getErrorBlockProps } from '@/constants/errorMessages'
import FadeAnimationWrapper from '@/atoms/FadeAnimationWrapper'

type Props = {
  children: ReactNode
  error?: string
  fullScreen?: boolean
  isLoading?: boolean
  isError?: boolean
  statusCode?: number
}

const RequestHandler = ({
  children,
  error,
  isError,
  fullScreen = false,
  isLoading = false,
  statusCode,
}: Props) => {
  const { match } = useMatch()

  if (isError) {
    return (
      <ErrorBlock
        {...getErrorBlockProps(match, error, statusCode)}
        fullscreen={fullScreen}
      />
    )
  }

  return (
    <FadeAnimationWrapper animationKey={isLoading ? 1 : 0}>
      {isLoading ? <ContentLoader big={fullScreen} /> : children}
    </FadeAnimationWrapper>
  )
}

export default RequestHandler
