import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../styles/styleGuide';
import formatPrice from '../../services/formatPrice';
import { fromBreakpoint } from '../../styles/breakpoints';
var SummaryOptionsOption = function SummaryOptionsOption(_ref) {
  var noneFound = _ref.noneFound,
    title = _ref.title,
    price = _ref.price,
    amount = _ref.amount,
    hidePriceDelta = _ref.hidePriceDelta;
  return __jsx(Option, {
    "data-id": "homestudio_SummaryOptionsOption_Option"
  }, __jsx(OptionTitle, {
    noOption: noneFound,
    "data-id": "homestudio_SummaryOptionsOption_OptionTitle"
  }, title), !noneFound && __jsx(Fragment, null, __jsx(OptionEuro, {
    "data-id": "homestudio_SummaryOptionsOption_OptionEuro"
  }, __jsx(OptionEuroDifference, {
    "data-id": "homestudio_SummaryOptionsOption_OptionEuroDifference"
  }, hidePriceDelta ? '' : price > 0 ? '+' : price === 0 ? '' : '-'), "\u20AC"), __jsx(OptionPrice, {
    "data-id": "homestudio_SummaryOptionsOption_OptionPrice"
  }, formatPrice(price * amount, {
    showDecimal: true,
    showEuroSign: false,
    showNegative: false
  }))));
};
export default SummaryOptionsOption;
var Option = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  font-size: 16rem;\n  color: ", ";\n  margin-bottom: 8rem;\n\n  ", ";\n"])), colorPalette.textGrey, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: 18rem;\n  "]))));
var OptionTitle = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  font-style: ", ";\n  width: 100%;\n"])), function (props) {
  return props.noOption ? 'italic' : '';
});
var OptionEuro = styled.p(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: 0;\n  margin: 0 5rem 0 auto;\n  width: 50rem;\n  text-align: right;\n"])));
var OptionEuroDifference = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: inline-block;\n  margin-right: 5rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    margin-right: 10rem;\n  "]))));
var OptionPrice = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding: 0;\n  margin: 0 10rem 0 auto;\n  width: 105rem;\n  text-align: right;\n"])));