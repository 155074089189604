var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var AvatarHS = function AvatarHS(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "24px",
    height: "28px",
    viewBox: "0 0 24 28",
    version: "1.1",
    "data-id": "icons_AvatarHS_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_AvatarHS_g"
  }, __jsx("g", {
    transform: "translate(-159.000000, -870.000000)",
    fill: "#FEFEFE",
    "data-id": "icons_AvatarHS_g_2"
  }, __jsx("g", {
    transform: "translate(159.000000, 870.000000)",
    "data-id": "icons_AvatarHS_g_3"
  }, __jsx("path", {
    d: "M12.6456,26.1304465 L12.6456,24.5995628 L20.6408432,19.9606791 L21.9653838,20.7284 L12.6456,26.1304465 Z M12.6456,21.5137023 L17.9872216,18.4232837 L19.3325189,19.2020744 L12.6456,23.0817023 L12.6456,21.5137023 Z M12.6456,18.4486791 L15.3400865,16.888493 L16.6782486,17.6640279 L12.6456,19.9971442 L12.6456,18.4486791 Z M12.6456,15.3270047 L14.0304649,16.1292372 L12.6456,16.9308186 L12.6456,15.3270047 Z M23.9294919,20.7284 C23.9294919,20.4855163 23.7978162,20.2732372 23.602573,20.1592837 L12.6456,13.8091442 L12.6456,12.0568651 L22.3617081,17.6887721 C22.5134919,17.7760279 22.6840865,17.8222605 22.8579243,17.8222605 C23.2114378,17.8222605 23.5416,17.6314698 23.7180324,17.3241209 L23.8769514,17.0486791 L12.6456,10.5390047 L12.6456,8.78672558 L22.3617081,14.4186326 C22.5134919,14.5058884 22.6840865,14.5521209 22.8579243,14.5521209 C23.2114378,14.5521209 23.5416,14.3613302 23.7180324,14.0539814 L23.8769514,13.7778884 L12.6456,7.26886512 L12.6456,5.51658605 L22.3617081,11.148493 C22.5134919,11.2357488 22.6847351,11.2819814 22.8579243,11.2819814 C23.2120865,11.2819814 23.5416,11.0911907 23.7180324,10.7838419 L23.8769514,10.5084 L12.6456,3.99872558 L12.6456,2.24644651 L22.3617081,7.87835349 C22.5134919,7.9656093 22.6840865,8.01184186 22.8579243,8.01184186 C23.2114378,8.01184186 23.5416,7.82105116 23.7180324,7.51435349 L23.8769514,7.23891163 L12.3193297,0.539748837 L12.3173838,0.539748837 C12.2213838,0.483748837 12.1098162,0.451190698 11.9911135,0.451190698 C11.6304649,0.451190698 11.3359784,0.746167442 11.3359784,1.10821395 L11.3359784,27.2686791 C11.336627,27.6313767 11.6298162,27.9250512 11.9911135,27.9250512 C12.1072216,27.9250512 12.2161946,27.8944465 12.3108973,27.8410512 L12.3115459,27.8410512 C12.3134919,27.8397488 12.3160865,27.8390977 12.3180324,27.8377953 L23.602573,21.2975163 C23.6038703,21.2968651 23.6045189,21.296214 23.6051676,21.2955628 C23.7991135,21.1809581 23.9294919,20.9699814 23.9294919,20.7284 L23.9294919,20.7284 Z",
    "data-id": "icons_AvatarHS_path"
  }), __jsx("path", {
    d: "M0.0520864865,8.3268093 L0.0520864865,21.0466233 L1.36235676,21.0466233 L1.36235676,7.33053023 L1.04451892,7.33053023 C0.497708108,7.33053023 0.0520864865,7.77787907 0.0520864865,8.3268093",
    "data-id": "icons_AvatarHS_path_5"
  }), __jsx("path", {
    d: "M8.51487568,3.11972093 L8.51487568,25.9853023 L9.8244973,25.9853023 L9.8244973,2.12344186 L9.50730811,2.12344186 C8.9604973,2.12344186 8.51487568,2.57013953 8.51487568,3.11972093",
    "data-id": "icons_AvatarHS_path_6"
  }), __jsx("path", {
    d: "M5.69416216,4.83664186 L5.69416216,24.3168279 L7.00378378,24.3168279 L7.00378378,3.84036279 L6.68659459,3.84036279 C6.13978378,3.84036279 5.69416216,4.28771163 5.69416216,4.83664186",
    "data-id": "icons_AvatarHS_path_7"
  }), __jsx("path", {
    d: "M2.8728,6.59204651 L2.8728,22.6484186 L4.18242162,22.6484186 L4.18242162,5.59576744 L3.86523243,5.59576744 C3.31842162,5.59576744 2.8728,6.04246512 2.8728,6.59204651",
    "data-id": "icons_AvatarHS_path_8"
  })))));
};
AvatarHS.defaultProps = defaultProps;
export default AvatarHS;