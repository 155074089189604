import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const HomeReadyIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50693)">
        <path
          d="M9.31873 13.3427L7.80935 11.831C7.51404 11.5023 7.02185 11.5023 6.72654 11.831L5.54529 12.9812L9.28591 16.7277L15.6844 10.2535L14.5359 9.10329C14.2078 8.77465 13.7484 8.77465 13.4203 9.10329L9.31873 13.3427Z"
          fill={fillColor}
        />
        <path
          d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0813 21 18.9984 21H0V6.277L10.5 0Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50693">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default HomeReadyIcon
