import useBreakpointInfo, { BreakpointInfo } from '@/hooks/useBreakpointInfo'
import { useCallback, useEffect, useState } from 'react'

const MAX_SITE_WIDTH_IN_PX = 2800
const MAX_SIDEBAR_WIDTH = 500
const MIN_SIDEBAR_WIDTH = 350
const MIN_SIDEBAR_WIDTH_FOR_MD = 350

export type LayoutDimensions = {
  currentContentWidthInPx: number
  maxContentWidthInPx: number
  sideBarWidth: number
  spacingInPx: number
}

const getSidebarWidth = (currentContentWidthInPx: number, breakpointInfo: BreakpointInfo) => {
  const {
    isLargerThan: { md, slg },
  } = breakpointInfo
  if (!md) {
    return 0
  }
  if (md && !slg) {
    return Math.min(currentContentWidthInPx / 2, MIN_SIDEBAR_WIDTH_FOR_MD)
  }
  const sideBarWidth = Math.round(currentContentWidthInPx / 4)
  if (sideBarWidth > MAX_SIDEBAR_WIDTH) {
    return MAX_SIDEBAR_WIDTH
  }
  if (sideBarWidth < MIN_SIDEBAR_WIDTH) {
    return MIN_SIDEBAR_WIDTH
  }
  return sideBarWidth
}

const useLayoutDimensions = (): LayoutDimensions => {
  const breakpointInfo = useBreakpointInfo()
  const [spacing, setSpacing] = useState(0)
  const [width, setWidth] = useState(0)

  const updateSpacing = useCallback(() => {
    const html = document.getElementsByTagName('html')[0]
    const htmlComputedStyle = getComputedStyle(html)
    const htmlWidth =
      html.clientWidth -
      (parseFloat(htmlComputedStyle.paddingLeft) + parseFloat(htmlComputedStyle.paddingRight))
    setWidth(htmlWidth)
    const remainingSpace = htmlWidth - MAX_SITE_WIDTH_IN_PX
    if (remainingSpace < 0) {
      setSpacing(0)
      return
    }
    setSpacing(Math.round(remainingSpace / 2))
  }, [])

  useEffect(() => {
    updateSpacing()
    window.addEventListener('resize', updateSpacing)

    return () => window.removeEventListener('resize', updateSpacing)
  }, [updateSpacing])

  const currentContentWidthInPx = Math.min(MAX_SITE_WIDTH_IN_PX, width)

  return {
    currentContentWidthInPx,
    maxContentWidthInPx: MAX_SITE_WIDTH_IN_PX,
    sideBarWidth: getSidebarWidth(currentContentWidthInPx, breakpointInfo),
    spacingInPx: spacing,
  }
}

export default useLayoutDimensions
