import { WishList, WishListCategory } from '@/entities/types'
import ImageWithFullscreen from '@/molecules/ImageWithFullscreen'
import Subcategory from '@/components/houseDesign/Subcategory'
import CategoryIntro from '@/components/houseDesign/CategoryIntro'
import Container from '@/atoms/Container'
import Layer from '@/molecules/Layer'
import { getTotalPriceForCategory } from '@/processes/houseDesignPrices'
import { getTotalLivingSurfaceForCategory } from '@/processes/houseDesignLivingSpace'
import { getAllNewOptions } from '@/processes/houseDesignStatuses'
import NavButtons from '@/components/houseDesign/NavButtons'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'
import useSectionLoading from '@/hooks/useSectionLoading'
import { slugify } from '@/processes/houseDesignSlugs'

type Props = {
  category: WishListCategory
  wishList: WishList
}

const Category = ({
  category: { id, hasModal, image, title, icon, description, subcategories },
  wishList,
}: Props) => {
  const { isSectionLoading } = useSectionLoading()

  return (
    <ErrorBoundary>
      <ImageWithFullscreen image={image} />
      <CategoryIntro
        title={title}
        iconSlug={icon}
        description={description}
        categoryHasImage={!!image}
        newOptions={getAllNewOptions(subcategories)}
        modalId={hasModal ? id : undefined}
      >
        {subcategories.map((subcategory) => (
          <Subcategory
            key={subcategory.id}
            subcategory={subcategory}
          />
        ))}
        <Container
          paddingHorizontal="xl"
          paddingVertical="lg"
          marginBottom="xl"
          shadow
        >
          <Layer
            title={title}
            squareMeters={getTotalLivingSurfaceForCategory(subcategories, wishList)}
            price={getTotalPriceForCategory(title, wishList.totals)}
            extraSquareMeters
            iconSlug={icon}
            isSummary
            loading={isSectionLoading(slugify(title))}
          />
        </Container>
        <NavButtons />
      </CategoryIntro>
    </ErrorBoundary>
  )
}
export default Category
