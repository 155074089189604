import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
import { hex2rgb } from '../../../styles/utils';
import ThemeLabel from './ThemeLabel';
import MailIcon from '../icons/Message';
import ActionsIcon from '../icons/Actions';
import BrushIcon from '../icons/Brush';
import CommunityIcon from '../icons/Community';
import HouseSolid from '../icons/House';
var defaultProps = {
  color: colorPalette.blue,
  caretColor: '#ffffff',
  size: 'normal'
};
var ICONMAPPING = {
  mail: MailIcon,
  action: ActionsIcon,
  brush: BrushIcon,
  community: CommunityIcon,
  house: HouseSolid
};
var WidgetContainer = function WidgetContainer(_ref) {
  var icon = _ref.icon,
    size = _ref.size,
    label = _ref.label,
    children = _ref.children;
  var Icon = ICONMAPPING[icon] || ICONMAPPING['action'];
  return __jsx(Container, {
    size: size,
    "data-id": "designElements_WidgetContainer_Container"
  }, __jsx(Label, {
    icon: icon,
    "data-id": "designElements_WidgetContainer_Label"
  }, __jsx(ThemeLabel, {
    label: label,
    theme: "projectUpdate",
    "data-id": "designElements_WidgetContainer_ThemeLabel"
  }), __jsx(Icon, {
    color: colorPalette.white,
    width: 18,
    height: 18,
    "data-id": "designElements_WidgetContainer_Icon"
  })), __jsx(InnerContent, {
    "data-id": "designElements_WidgetContainer_InnerContent"
  }, children));
};
WidgetContainer.defaultProps = defaultProps;
export default WidgetContainer;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  ", ";\n"])), fromBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    ", "\n  "])), function (props) {
  return props.size === 'normal' ? "\n      &:after {\n        content: '';\n        display: block;\n        width: 0;\n        height: 0;\n        position: absolute;\n        z-index: -1;\n        bottom: -20rem;\n        right: -20rem;\n        border-bottom: 310rem solid ".concat(hex2rgb(colorPalette.lightGrey, 0.65), ";\n        border-left: 310rem solid transparent};\n      }\n    ") : "";
}));
var InnerContent = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  background-color: #ffffff;\n  box-sizing: border-box;\n  padding: 20rem;\n  min-height: 300rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    padding: 20rem 30rem;\n  "]))));
var Label = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: relative;\n\n  > div {\n    > span {\n      width: 240rem;\n      padding-left: 50rem;\n      padding-right: 41rem;\n    }\n  }\n\n  > svg {\n    position: absolute;\n    margin-top: 14rem;\n    margin-left: 20rem;\n\n    ", ";\n  }\n"])), function (props) {
  switch (props.icon) {
    case 'mail':
      return 'top: -1rem;';
    case 'community':
      return 'top: -4rem; left: -5rem;';
    case 'action':
    case 'brush':
    default:
      return 'top: -4rem;';
  }
});