import Box from '@/atoms/Box'
import LeftArrowIcon from '@/svg/LeftArrowIcon'
import TextButton from '@/atoms/TextButton'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import shadows from '@/styles/shadows'
import { z } from '@/styles/zIndexes'

type Props = {
  backButtonText: string
  onClick: () => void
}

const Container = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  z-index: ${z.mobileHeader};
  ${shadows.default}
`

const Header = ({ backButtonText, onClick }: Props) => (
  <Container padding="md">
    <TextButton
      iconBefore={LeftArrowIcon}
      text={backButtonText}
      onClick={onClick}
      variant="brand"
    />
  </Container>
)

export default Header
