import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import ProfileWidget from './ProfileWidget';
import ActionsWidget from './ActionsWidget';
import MessagesWidget from './MessagesWidget';
import styled from 'styled-components';
import { fromBreakpoint } from '../../styles/breakpoints';
var ProfileBlock = function ProfileBlock(_ref) {
  var messages = _ref.messages,
    actions = _ref.actions;
  return __jsx(Container, {
    "data-e2e-block-profileblock": true,
    "data-id": "dashboard_ProfileBlock_Container"
  }, __jsx(MessagesWidget, {
    dummyContent: messages.dummyContent,
    items: messages.items,
    link: messages.link,
    unreadCount: messages.unreadCount,
    "data-id": "dashboard_ProfileBlock_MessagesWidget"
  }), __jsx(ProfileWidget, {
    isSmall: false,
    "data-id": "dashboard_ProfileBlock_ProfileWidget"
  }), __jsx(ActionsWidget, {
    dummyContent: actions.dummyContent,
    items: actions.items,
    link: actions.link,
    "data-id": "dashboard_ProfileBlock_ActionsWidget"
  }));
};
export default ProfileBlock;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  flex-flow: row wrap;\n  z-index: 10;\n  align-items: flex-end;\n  margin-top: -20px;\n\n  ", ";\n\n  > *:nth-child(1),\n  > *:nth-child(3) {\n    margin-top: 60rem;\n\n    ", ";\n\n    ", ";\n  }\n\n  @media only all and (max-width: 969px) {\n    > *:nth-child(1) {\n      order: 2;\n    }\n    > *:nth-child(2) {\n      order: 1;\n    }\n    > *:nth-child(3) {\n      order: 3;\n    }\n  }\n\n  @media only all and (min-width: 970px) {\n    flex-direction: row;\n    flex-wrap: nowrap;\n\n    > *:nth-child(1),\n    > *:nth-child(2),\n    > *:nth-child(3) {\n      width: calc((100% / 3) - (40rem / 3));\n    }\n  }\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    justify-content: space-between;\n  "]))), fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      width: calc(50% - 10rem);\n    "]))), fromBreakpoint.lg(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      margin-top: 0;\n    "]))));