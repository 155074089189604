import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../styles/styleGuide';
import ButtonPrimary from './shared/buttons/ButtonPrimary';
import Download from './shared/icons/Download';
import { fromBreakpoint } from '../styles/breakpoints';
var ConfirmedOrdersDownload = function ConfirmedOrdersDownload(_ref) {
  var url = _ref.url;
  if (!url) {
    return null;
  }
  return __jsx(Container, {
    "data-id": "components_ConfirmedOrdersDownload_Container"
  }, __jsx(ButtonPrimary, {
    label: "Download afschrift",
    type: "externalLink",
    link: url,
    size: "small",
    "data-id": "components_ConfirmedOrdersDownload_ButtonPrimary"
  }, __jsx(Download, {
    color: colorPalette.white,
    "data-id": "components_ConfirmedOrdersDownload_Download"
  })));
};
export default ConfirmedOrdersDownload;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-top: 15rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: row-reverse;\n  "]))));