import useParams from '@/hooks/useParams'

const useHomestudiosPath = (slug?: string) => {
  const { projectSlug, wishListId } = useParams()

  if (!wishListId) {
    return `/${projectSlug}/closed/`
  }

  const baseUrl = `/${projectSlug}/closed/homestudio/${wishListId}`

  if (!slug) {
    return baseUrl
  }

  return `${baseUrl}/${slug}`
}

export default useHomestudiosPath
