var __jsx = React.createElement;
import React from 'react';
var defaultProps = {
  color: '#ffffff',
  width: 12,
  height: 12
};
var Plus = function Plus(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 12 12",
    "data-id": "icons_Plus_svg"
  }, __jsx("title", {
    "data-id": "icons_Plus_title"
  }, "Plus Icon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Plus_g"
  }, __jsx("g", {
    transform: "translate(-7.000000, -8.000000)",
    fill: color,
    "data-id": "icons_Plus_g_3"
  }, __jsx("g", {
    "data-id": "icons_Plus_g_4"
  }, __jsx("g", {
    transform: "translate(0.000000, 1.000000)",
    "data-id": "icons_Plus_g_5"
  }, __jsx("g", {
    transform: "translate(7.000000, 7.000000)",
    "data-id": "icons_Plus_g_6"
  }, __jsx("g", {
    "data-id": "icons_Plus_g_7"
  }, __jsx("path", {
    d: "M12,6.155625 L11.99475,5.7279375 C11.9415,5.15475 11.4615,4.7085 10.8765,4.7085 L7.7188125,4.7085 L7.7188125,0 L6.2206875,0 L5.72625,0.0061875 C5.1585,0.064875 4.7188125,0.542625 4.7188125,1.1235 L4.7188125,4.7086875 L0,4.7086875 L0,6.2068125 L0.0061875,6.70125 C0.064875,7.269 0.542625,7.7086875 1.1235,7.7086875 L4.7188125,7.7086875 L4.7188125,12 L6.2716875,12 L6.6991875,11.99475 C7.2725625,11.9415 7.7188125,11.4615 7.7188125,10.8765 L7.7188125,7.7085 L12,7.7085 L12,6.155625 Z",
    fillRule: "nonzero",
    "data-id": "icons_Plus_path"
  }))))))));
};
Plus.defaultProps = defaultProps;
export default Plus;