import { IconProps } from '@/types/svgTypes'
import { colors } from '@/styles/colors'
import styled from 'styled-components'
import { transitions } from '@/styles/shared'
import { FC } from 'react'

type Size = 'sm' | 'md'

type Props = {
  icon: FC<IconProps>
  onClick?: () => void
  size?: Size
}

const SIZE_MAP: Record<Size, number> = {
  sm: 14,
  md: 18,
}

const Container = styled.span<{ size: Size }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${transitions.hover}

  &:hover,
  &:focus,
  &:active {
    path {
      fill: ${colors.brand};
    }
  }
`

const IconButton = ({ size = 'md', icon: Icon, onClick }: Props) => (
  <Container
    size={size}
    onClick={onClick}
  >
    <Icon height={SIZE_MAP[size]} />
  </Container>
)

export default IconButton
