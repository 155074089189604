import { colors } from '@/styles/colors'
import styled from 'styled-components'
import { INPUT_SIZE_MAP, INPUT_UNDERLINE_MAP, InputVariant, InputSizes } from '@/constants/inputs'

type Props = {
  value: string
  inputSize?: InputSizes
  variant?: InputVariant
  disabled?: boolean
  maxLength?: number
  placeholder?: string
  onChange: (value: string) => void
}

type InputProps = { inputSize: InputSizes; variant: InputVariant }

const Input = styled.input<InputProps>`
  ${({ inputSize }) => INPUT_SIZE_MAP[inputSize]}
  width: 100%;
  border: none;
  color: ${({ variant }) => variant && colors[variant]};
  background-color: inherit;
  padding: 7rem 0 8rem;
  border-bottom: 1rem solid ${({ variant }) => variant && INPUT_UNDERLINE_MAP[variant]};
  resize: none;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${({ variant }) => variant && colors[variant]};
    font-style: italic;
  }
`

const InputText = ({
  onChange,
  value,
  inputSize = 'md',
  variant = 'gray30',
  maxLength = 150,
  placeholder = '',
  ...props
}: Props) => {
  return (
    <Input
      {...props}
      maxLength={maxLength}
      variant={variant}
      value={value}
      inputSize={inputSize}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      type="text"
    />
  )
}
export default InputText
