import { ComponentType } from 'react'
import { WishListRequestKey } from '@/types/endpoints'
import useParams from '@/hooks/useParams'
import { useQueryClient } from '@tanstack/react-query'

export type WithInvalidateWishListRequestProps = {
  invalidateWishListRequest: () => void
}

const withInvalidateWishListRequest =
  <P extends object>(WrappedComponent: ComponentType<P & WithInvalidateWishListRequestProps>) =>
  (props: P) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { wishListItem, ...otherParams } = useParams()

    const queryClient = useQueryClient()

    return (
      // @ts-ignore (ignore error due to to-be-removed hoist-non-react-statics)
      <WrappedComponent
        {...props}
        invalidateWishListRequest={() => {
          // Note: the params and key have to match the ones in the useWishListQuery in HouseDesign.tsx, to invalidate exactly that request
          queryClient.invalidateQueries({
            queryKey: ['getWishList', otherParams, WishListRequestKey.wishListLoading],
          })
        }}
      />
    )
  }

export default withInvalidateWishListRequest
