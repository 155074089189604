import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colorPalette, textStyles } from '../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../styles/breakpoints';
import { darken } from '../styles/utils';
import ImageLoader from './shared/ui/ImageLoader';
import UserAvatar from './UserAvatar';
import { getResultData } from '../redux/apiData';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var ContactBlock = function ContactBlock(_ref) {
  var title = _ref.title,
    description = _ref.description,
    companies = _ref.companies,
    styling = _ref.styling,
    professionals = _ref.professionals;
  return __jsx(Block, {
    "data-e2e-block-contactblock": true,
    "data-id": "components_ContactBlock_Block"
  }, __jsx(TextContainer, {
    "data-id": "components_ContactBlock_TextContainer"
  }, __jsx(Title, {
    "data-id": "components_ContactBlock_Title"
  }, title), __jsx(SubTitle, {
    "data-id": "components_ContactBlock_SubTitle"
  }, description)), companies && companies.map(function (company, index) {
    return __jsx(DetailsBlock, {
      key: index,
      "data-id": "components_ContactBlock_DetailsBlock"
    }, __jsx(Details, {
      "data-id": "components_ContactBlock_Details"
    }, __jsx(Name, {
      "data-id": "components_ContactBlock_Name"
    }, company.name), __jsx(Address, {
      "data-id": "components_ContactBlock_Address"
    }, __jsx("span", {
      "data-id": "components_ContactBlock_span"
    }, company.address), __jsx("span", {
      "data-id": "components_ContactBlock_span_9"
    }, company.zipcode, " ", company.city)), __jsx(Contact, {
      "data-id": "components_ContactBlock_Contact"
    }, company.phone1 && __jsx(NumberLink, {
      "aria-label": company.phone1,
      href: "tel:".concat(company.phone1),
      "data-id": "components_ContactBlock_NumberLink"
    }, company.phone1), company.phone2 && __jsx(NumberLink, {
      "aria-label": company.phone1,
      href: "tel:".concat(company.phone2),
      "data-id": "components_ContactBlock_NumberLink_12"
    }, company.phone2), __jsx(Link, {
      color: styling && styling.button,
      "aria-label": company.email,
      href: "mailto:".concat(company.email),
      "data-id": "components_ContactBlock_Link"
    }, company.email), __jsx(Link, {
      color: styling && styling.button,
      "aria-label": company.url,
      href: company.url,
      target: "_blank",
      rel: "noopener noreferrer",
      "data-id": "components_ContactBlock_Link_14"
    }, company.url)), company.image && __jsx(Logo, {
      "data-id": "components_ContactBlock_Logo"
    }, __jsx(ImageLoader, {
      src: company.image,
      alt: company.name,
      mode: "img",
      "data-id": "components_ContactBlock_ImageLoader"
    }))), __jsx(Photos, {
      "data-id": "components_ContactBlock_Photos"
    }, company.professionals && company.professionals.sort(function (a, b) {
      return a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0;
    }).map(function (professional, index1) {
      return __jsx(Person, {
        key: index1,
        "data-id": "components_ContactBlock_Person"
      }, __jsx(UserAvatar, {
        mode: "imageLoader",
        size: "large",
        name: professional.name,
        imageUrl: professional.image,
        "data-id": "components_ContactBlock_UserAvatar"
      }), __jsx(User, {
        "data-id": "components_ContactBlock_User"
      }, professional.name));
    })));
  }), professionals && professionals.sort(function (a, b) {
    return a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0;
  }).map(function (professional, index) {
    return __jsx(DetailsBlock, {
      key: index,
      "data-id": "components_ContactBlock_DetailsBlock"
    }, __jsx(Person, {
      "data-id": "components_ContactBlock_Person"
    }, __jsx(UserAvatar, {
      mode: "imageLoader",
      size: "large",
      name: professional.name,
      imageUrl: professional.image,
      "data-id": "components_ContactBlock_UserAvatar"
    }), __jsx(User, {
      "data-id": "components_ContactBlock_User"
    }, professional.name)));
  }));
};
export default withMatch(connect(mapStateToProps)(ContactBlock));
var Block = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: #fff;\n  padding: 40rem 18rem 0 15rem;\n  margin-bottom: 50rem;\n\n  ", "\n\n  ", "\n  ", "\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 60rem 73rem 0 73rem;\n    margin-bottom: 60rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 60rem 125rem 0 42rem;\n  "]))), fromBreakpoint.xl(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    padding: 60rem 150rem 0 129rem;\n  "]))));
var Title = styled.h3(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 8rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var SubTitle = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n"])), textStyles.bodyText, colorPalette.textGrey);
var TextContainer = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  max-width: 782rem;\n"])));
var DetailsBlock = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  border-top: 1px dashed ", ";\n  margin-top: 40rem;\n  padding-top: 20rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"])), colorPalette.lightBeige, toBreakpoint.sm(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    margin-top: 28rem;\n  "]))), toBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]))), fromBreakpoint.lg(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    margin-left: 82rem;\n  "]))), fromBreakpoint.xl(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    margin-left: 200rem;\n  "]))));
var Details = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), colorPalette.textGrey);
var Name = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  ", " margin-bottom: 8rem;\n"])), textStyles.title);
var Address = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20rem;\n  ", ";\n"])), textStyles.bodyText);
var Contact = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  ", " display: flex;\n  flex-direction: column;\n"])), textStyles.bodyText);
var Link = styled.a(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  align-self: flex-start;\n  margin-bottom: 5rem;\n  text-decoration: none;\n  color: ", ";\n  border-bottom: 1rem solid transparent;\n  transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;\n\n  &:hover {\n    color: ", ";\n    border-bottom: 1rem solid\n      ", ";\n  }\n"])), function (props) {
  return props.color ? props.color : colorPalette.blue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
});
var NumberLink = styled.a(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  color: ", ";\n  display: inline-block;\n  text-decoration: none;\n  margin-bottom: 5rem;\n"])), colorPalette.textGrey);
var Logo = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  max-width: 150rem;\n  max-height: 100rem;\n  margin-top: 40rem;\n  margin-bottom: 30rem;\n\n  ", ";\n\n  > div {\n    max-width: 150rem;\n    max-height: 100rem;\n  }\n"])), toBreakpoint.sm(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n    margin-top: 20rem;\n  "]))));
var Photos = styled.div(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 280rem;\n  padding: 0 20rem;\n  justify-content: space-between;\n\n  ", ";\n"])), toBreakpoint.md(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n    padding: 0;\n  "]))));
var Person = styled.div(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 30rem;\n  max-width: 110rem;\n"])));
var User = styled.div(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 4rem;\n  text-align: center;\n  ", ";\n"])), textStyles.caption, colorPalette.textGrey, toBreakpoint.sm(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n    font-size: 12rem;\n  "]))));