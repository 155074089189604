import InputCheckbox from '@/atoms/inputs/InputCheckbox'
import { IUserWishOption } from '@/entities/wishList'
import InlineRequestHandler from '@/components/organisms/InlineRequestHandler'
import { useMutationRequest } from '@/hooks/requestHooks'
import useParams from '@/hooks/useParams'
import { useWishListQuery, useWishListMutation } from '@/hooks/wishListRequestHooks'
import { WishListRequestKey } from '@/types/endpoints'
import { useState, useEffect } from 'react'
import OptionTitleLine from '@/components/molecules/OptionTitleLine'
import Container from '@/atoms/Container'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import useSectionLoading from '@/hooks/useSectionLoading'
import { Slug } from '@/constants/slugs'
import UserWishForm from '@/components/houseDesign/userWish/UserWishForm'

type Props = {
  userWish: IUserWishOption
  editing?: boolean
  onEdit: (id?: number) => void
}

const SlideInOut = styled(motion.div)`
  overflow: hidden;
`

const UserWishItem = ({ userWish, editing, onEdit }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wishListItem, ...params } = useParams()
  const [refetchWishListIsLoading, setRefetchWishListIsLoading] = useState(false)
  const { toggleSectionLoading } = useSectionLoading()

  const { id, amount, title, price } = userWish

  const paramsWithUserWishId = { ...params, userWishId: id.toString() }

  const toggleUserWishSectionLoadingOff = () => toggleSectionLoading(false, Slug.userWishes)

  const { mutate, isLoading: mutateUserWishIsLoading } = useWishListMutation(
    'patchUserWish',
    [id],
    paramsWithUserWishId,
    {
      onSettled: toggleUserWishSectionLoadingOff,
    }
  )

  const {
    mutate: deleteUserWish,
    isLoading: deleteUserWishIsLoading,
    isSuccess: deleteUserWishIsSuccess,
  } = useMutationRequest('deleteUserWish')

  // the key (wishListUpdating) is intentionally different from the initial request key, so the UI does not go into the initial (blocking) loading state
  const { refetch } = useWishListQuery(
    'getWishList',
    [WishListRequestKey.wishListUpdating],
    params,
    {
      enabled: false,
      onSettled: () => {
        toggleUserWishSectionLoadingOff()
      },
      onError: () => {
        setRefetchWishListIsLoading(false)
      },
    }
  )

  useEffect(() => {
    if (deleteUserWishIsSuccess) {
      refetch()
    }
  }, [deleteUserWishIsSuccess, refetch])

  const selected = amount > 0

  return (
    <div>
      <AnimatePresence>
        {!editing && (
          <SlideInOut
            key={id}
            animate={{ height: 'auto' }}
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            transition={{ duration: 0.8 }}
          >
            <InlineRequestHandler
              isLoading={
                refetchWishListIsLoading || mutateUserWishIsLoading || deleteUserWishIsLoading
              }
              transparent
            >
              <Container
                variant="gray10"
                padding="md"
              >
                <OptionTitleLine
                  title={title}
                  price={price}
                  small
                  onEdit={() => {
                    onEdit(id)
                  }}
                  onDelete={() => {
                    setRefetchWishListIsLoading(true)
                    // only go into loading state if the user wish is selected, otherwise it does not affect the totals anyway
                    if (amount > 0) {
                      toggleSectionLoading(true, Slug.userWishes)
                    }
                    deleteUserWish({
                      params: paramsWithUserWishId,
                    })
                  }}
                >
                  <InputCheckbox
                    onClick={() => {
                      toggleSectionLoading(true, Slug.userWishes)
                      mutate({
                        params: paramsWithUserWishId,
                        body: {
                          selected: !(amount > 0),
                          wishList: params.wishListId,
                        },
                      })
                    }}
                    checked={selected}
                    disabled={refetchWishListIsLoading}
                  />
                </OptionTitleLine>
              </Container>
            </InlineRequestHandler>
          </SlideInOut>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {editing && (
          <SlideInOut
            key={id}
            animate={{ height: 'auto' }}
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            transition={{ duration: 0.8 }}
          >
            <UserWishForm
              userWish={userWish}
              callback={() => {
                onEdit()
              }}
            />
          </SlideInOut>
        )}
      </AnimatePresence>
    </div>
  )
}

export default UserWishItem
