var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 62,
  height: 64
};
var Actions = function Actions(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 62 64",
    version: "1.1",
    "data-id": "icons_Actions_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Actions_g"
  }, __jsx("g", {
    transform: "translate(-1.000000, 0.000000)",
    fill: color,
    "data-id": "icons_Actions_g_2"
  }, __jsx("path", {
    d: "M51.4780381,48.7176286 C51.4780381,49.074581 51.3665722,49.363379 51.1438038,49.5859839 C50.9211989,49.8089157 50.6317471,49.9202182 50.2757753,49.9202182 L41.4593452,49.9202182 C41.10321,49.9202182 40.8139216,49.8089157 40.5911533,49.5859839 C40.3683849,49.363379 40.2572459,49.074581 40.2572459,48.7176286 L40.2572459,35.5602389 L30.1050227,35.5602389 L30.1050227,48.7181189 C30.1050227,49.074581 29.9938837,49.363379 29.7712788,49.5859839 C29.548347,49.8089157 29.2590586,49.9202182 28.9030869,49.9202182 L20.0864933,49.9202182 C19.7746503,49.9202182 19.5077532,49.8089157 19.2851482,49.5859839 C19.0623799,49.363379 18.9507505,49.074581 18.9507505,48.7181189 L18.9507505,27.2780933 C18.9507505,26.8775025 19.0847711,26.5656594 19.3516682,26.3435448 L34.1789218,14.4542841 C34.7135332,14.0984758 35.2033621,14.0984758 35.6487354,14.4542841 L51.0101101,26.3435448 C51.3217897,26.5656594 51.4780381,26.8775025 51.4780381,27.2780933 L51.4780381,48.7176286 Z M58.3737243,0 L12.0488536,0 C9.59022951,0 7.60280222,1.98742729 7.60280222,4.44556104 L7.60280222,12.4603192 L4.1903438,12.4603192 C2.42503113,12.4603192 1,13.8860041 1,15.6514802 L1,17.1938611 C1,18.9590103 2.42503113,20.3842049 4.1903438,20.3842049 L7.60280222,20.3842049 L7.60280222,28.0331849 L4.1903438,28.0331849 C2.42503113,28.0331849 1,29.4580526 1,31.2233653 L1,32.7663999 C1,34.531876 2.42503113,35.9569071 4.1903438,35.9569071 L7.60280222,35.9569071 L7.60280222,43.6060506 L4.1903438,43.6060506 C2.42503113,43.6060506 1,45.0309183 1,46.7960675 L1,48.3521773 C1,50.1173265 2.42503113,51.5421942 4.1903438,51.5421942 L7.60280222,51.5421942 L7.60280222,59.5571158 C7.60280222,62.0157399 9.59022951,64.0033306 12.0488536,64.0033306 L58.3737243,64.0033306 C60.8323484,64.0033306 62.8199391,62.0157399 62.8199391,59.5571158 L62.8199391,4.4589631 C62.8326874,2.00017559 60.8323484,0 58.3737243,0 Z",
    "data-id": "icons_Actions_path"
  }))));
};
Actions.defaultProps = defaultProps;
export default Actions;