import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { openModalWithLoader } from '../redux/modal';
import { fromBreakpoint, breakpointSizes } from '../styles/breakpoints';
import { colorPalette, textStyles } from '../styles/styleGuide';
import ButtonPrimary from './shared/buttons/ButtonPrimary';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import ImageLoader from './shared/ui/ImageLoader';
import { darken } from './../styles/utils';
import { getResultData } from '../redux/apiData';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    handleClick: function handleClick(evt, endpoint) {
      evt.preventDefault();
      dispatch(openModalWithLoader(colorPalette.beige, endpoint));
    }
  };
};
var CTASecondary = function CTASecondary(_ref) {
  var renderWidth = _ref.renderWidth,
    match = _ref.match,
    content = _ref.content,
    title = _ref.title,
    formOneLabel = _ref.formOneLabel,
    formOneId = _ref.formOneId,
    formTwoLabel = _ref.formTwoLabel,
    formTwoId = _ref.formTwoId,
    comboMode = _ref.comboMode,
    image = _ref.image,
    styling = _ref.styling,
    handleClick = _ref.handleClick;
  var twoButtons = typeof formTwoId === 'number';
  var _styling$formSecondar = styling.formSecondaryBackground,
    formSecondaryBackground = _styling$formSecondar === void 0 ? colorPalette.lightGreen : _styling$formSecondar,
    _styling$formSecondar2 = styling.formSecondaryText,
    formSecondaryText = _styling$formSecondar2 === void 0 ? colorPalette.headerGrey : _styling$formSecondar2;
  var projectSlug = match.params.projectSlug;
  return __jsx(OuterView, {
    renderWidth: renderWidth,
    comboMode: comboMode,
    "data-e2e-block-ctasecondary": true,
    "data-id": "components_CTASecondary_OuterView"
  }, __jsx(ImageView, {
    renderWidth: renderWidth,
    comboMode: comboMode,
    "data-id": "components_CTASecondary_ImageView"
  }, __jsx(ImageLoader, {
    width: 600,
    mode: "bg",
    src: image,
    "data-id": "components_CTASecondary_ImageLoader"
  })), __jsx(BalloonView, {
    renderWidth: renderWidth,
    comboMode: comboMode,
    "data-id": "components_CTASecondary_BalloonView"
  }, __jsx(Balloon, {
    renderWidth: renderWidth,
    bgColor: formSecondaryBackground,
    comboMode: comboMode,
    "data-id": "components_CTASecondary_Balloon"
  }, __jsx(Title, {
    textColor: formSecondaryText === 'default' ? colorPalette.headerGrey : 'white',
    "data-id": "components_CTASecondary_Title"
  }, title), __jsx(BodyText, {
    textColor: formSecondaryText,
    "data-id": "components_CTASecondary_BodyText"
  }, content), twoButtons ? __jsx(LinksView, {
    renderWidth: renderWidth,
    "data-id": "components_CTASecondary_LinksView"
  }, __jsx(ButtonSecondary, {
    label: formOneLabel,
    color: formSecondaryText === 'default' ? colorPalette.headerGrey : 'white',
    colorHover: darken(formSecondaryBackground, 20),
    type: "button",
    clickHandler: function clickHandler(evt) {
      return handleClick(evt, {
        type: 'form',
        componentProps: {
          projectSlug: String(projectSlug),
          formId: formOneId
        }
      });
    },
    "data-id": "components_CTASecondary_ButtonSecondary"
  }), __jsx(ButtonSecondary, {
    color: formSecondaryText === 'default' ? colorPalette.headerGrey : 'white',
    colorHover: darken(formSecondaryBackground, 20),
    type: "button",
    label: formTwoLabel || formOneLabel,
    clickHandler: function clickHandler(evt) {
      return handleClick(evt, {
        type: 'form',
        componentProps: {
          projectSlug: String(projectSlug),
          formId: Number(formTwoId)
        }
      });
    },
    "data-id": "components_CTASecondary_ButtonSecondary_9"
  })) : __jsx(ButtonPrimary, {
    type: "button",
    color: "white",
    labelColor: formSecondaryBackground,
    buttonHoverColor: darken(formSecondaryBackground, 20),
    labelHoverColor: "white",
    label: formOneLabel,
    clickHandler: function clickHandler(evt) {
      return handleClick(evt, {
        type: 'form',
        componentProps: {
          projectSlug: String(projectSlug),
          formId: formOneId
        }
      });
    },
    "data-id": "components_CTASecondary_ButtonPrimary"
  }))));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(CTASecondary));
var OuterView = styled.aside(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  flex-direction: ", ";\n  margin-bottom: 50rem;\n\n  ", ";\n"])), function (props) {
  return props.comboMode ? 'column' : props.renderWidth > breakpointSizes.md ? 'row' : 'column';
}, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-bottom: 60rem;\n  "]))));
var ImageView = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: ", ";\n\n  ", ";\n\n  ", ";\n\n  > div {\n    min-height: 200rem;\n  }\n"])), function (props) {
  return props.renderWidth < 800 && props.comboMode ? '200px' : 'unset';
}, function (props) {
  return props.renderWidth > breakpointSizes.md ? "\n    flex: 3;\n  " : "\n    height: 250rem;\n  ";
}, function (props) {
  return props.comboMode ? "\n    max-height: 250rem;\n    height: 100%;" : '';
});
var BalloonView = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  height: 100%;\n  z-index: 2;\n  margin-top: ", ";\n"])), function (props) {
  return props.renderWidth > breakpointSizes.md ? 'flex: 5;' : '';
}, function (props) {
  return props.comboMode ? '-16rem' : '0';
});
var Balloon = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  padding-bottom: 30rem;\n  position: relative;\n  height: 100%;\n  box-sizing: border-box;\n\n  &:after{\n    content: '';\n    display: block;\n    width: ", ";\n    height: ", ";\n    position: absolute;\n    z-index: 1;\n    top: ", ";\n    left: ", ";\n    border-radius: 10rem 0 0 0;\n    background: ", ";\n  }\n\n  &:before {\n    content: '';\n    display: block;\n    width: 0;\n    height: 0;\n    position: absolute;\n    z-index: 2;\n    border: 16rem solid transparent;\n    ", ";\n"])), function (props) {
  return props.bgColor;
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '0 0 10rem 0' : '0 0 10rem 0';
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '10rem 20rem 20rem 20rem' : '20rem';
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '100%' : '10rem';
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '10rem' : '100%';
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '-9rem' : '0';
}, function (props) {
  return props.renderWidth < breakpointSizes.md ? '0' : '-9rem';
}, function (props) {
  return props.bgColor;
}, function (props) {
  return props.comboMode || props.renderWidth < breakpointSizes.md ? "\n        border-bottom-color: ".concat(props.bgColor, ";\n        border-top: none;\n        top: -16rem;\n        right: 80rem;\n    ") : "\n      border-right-color: ".concat(props.bgColor, ";\n      border-left: none;\n      left: -25rem;\n      top: 55rem;\n    ");
});
var Title = styled.h3(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  margin-top: 0;\n  color: ", ";\n"])), textStyles.title, function (props) {
  return props.textColor;
});
var BodyText = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n"])), textStyles.bodyText, function (props) {
  return props.textColor;
});
var LinksView = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: flex-start;\n\n  > button {\n    &:nth-of-type(1) {\n      margin-right: 20px;\n    }\n  }\n"])), function (props) {
  return props.renderWidth > breakpointSizes.md ? 'row' : 'column';
});