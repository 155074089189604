import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import MyHouseBase from './MyHouseBase';
import MyHouseChoices from './MyHouseChoices';
var MyHouseBlock = function MyHouseBlock(_ref) {
  var house = _ref.house,
    houseType = _ref.houseType,
    categories = _ref.categories,
    renderWidth = _ref.renderWidth,
    houseConfirmationExport = _ref.houseConfirmationExport;
  if (!house || !houseType || !categories) {
    return null;
  }
  return __jsx(Container, {
    "data-e2e-block-myhouse": true,
    "data-id": "components_MyHouseBlock_Container"
  }, __jsx(MyHouseBase, {
    house: house,
    houseType: houseType,
    categories: categories,
    "data-id": "components_MyHouseBlock_MyHouseBase"
  }), __jsx(MyHouseChoices, {
    bnr: house.bnr,
    price: house.price,
    livingSpace: houseType.livingSpace,
    bedrooms: houseType.bedrooms,
    categories: categories,
    renderWidth: renderWidth,
    houseConfirmationExport: houseConfirmationExport,
    "data-id": "components_MyHouseBlock_MyHouseChoices"
  }));
};
export default MyHouseBlock;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"])));