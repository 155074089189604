import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import { GridItem } from '../styles/gridElements';
import { shadows } from '../styles/styleGuide';
var LoginBlock = function LoginBlock() {
  return __jsx(FormContainer, {
    "data-e2e-block-login": true,
    "data-id": "components_LoginBlock_FormContainer"
  }, __jsx(LoginForm, {
    "data-id": "components_LoginBlock_LoginForm"
  }));
};
export default LoginBlock;
var FormContainer = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: 1;\n  display: flex;\n  justify-content: center;\n  padding-left: 20rem;\n  padding-right: 20rem;\n  background-color: #fff;\n  ", ";\n\n  > div {\n    max-width: 778rem;\n  }\n"])), shadows.boxPrimary);