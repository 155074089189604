import { WishList } from '@/entities/wishList'
import UserWishList from '@/userwish/UserWishList'
import UserWishForm from '@/userwish/UserWishForm'
import Text from '@/atoms/Text'
import Container from '@/atoms/Container'
import CategoryIntro from '@/components/houseDesign/CategoryIntro'
import Banner from '@/components/molecules/Banner'
import Layer from '@/components/molecules/Layer'
import NavButtons from '@/components/houseDesign/NavButtons'
import useSections from '@/hooks/useSections'
import TextBox from '@/atoms/TextBox'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'
import { Slug } from '@/constants/slugs'
import useSectionLoading from '@/hooks/useSectionLoading'

type Props = {
  wishList: WishList
}

const UserWishes = ({ wishList }: Props) => {
  const { userWishes } = wishList
  const { sections } = useSections()
  const { isSectionLoading } = useSectionLoading()
  const config = sections?.find((section) => section.slug === Slug.userWishes)

  if (!config) {
    return null
  }

  const { title, price, iconSlug } = config

  return (
    <ErrorBoundary>
      <CategoryIntro
        title={title}
        iconSlug={iconSlug}
      >
        <Container
          shadow
          marginBottom="xl"
        >
          <TextBox padding="xl">
            <Text text="Heb je woonwensen die je niet kon terugvinden? Voeg ze hier toe, inclusief een omschrijving en de benodigde investering. Zo houd je jouw verlangontwerp compleet en heb je alle kosten en mogelijkheden overzichtelijk bij elkaar." />

            {!!userWishes && userWishes.length > 0 ? (
              <UserWishList userWishes={userWishes} />
            ) : (
              <Banner content="Voeg hieronder je eerste eigen woonwens toe!" />
            )}
          </TextBox>
          <UserWishForm />
        </Container>
        <Container
          paddingHorizontal="xl"
          paddingVertical="lg"
          marginBottom="xl"
          shadow
        >
          <Layer
            title={title}
            price={price}
            iconSlug={iconSlug}
            isSummary
            loading={isSectionLoading(Slug.userWishes)}
          />
        </Container>
        <NavButtons />
      </CategoryIntro>
    </ErrorBoundary>
  )
}
export default UserWishes
