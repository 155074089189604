import FloorIcon from '@/svg/FloorIcon'
import ComfortIcon from '@/svg/ComfortIcon'
import ElectronicsIcon from '@/svg/ElectronicsIcon'
import Floor0Icon from '@/svg/Floor0Icon'
import Floor1Icon from '@/svg/Floor1Icon'
import Floor2Icon from '@/svg/Floor2Icon'
import Floor3Icon from '@/svg/Floor3Icon'
import Floor4Icon from '@/svg/Floor4Icon'
import HomeReadyIcon from '@/svg/HomeReadyIcon'
import HomeReadyPlusIcon from '@/svg/HomeReadyPlusIcon'
import SummaryIcon from '@/svg/SummaryIcon'
import { IconSlug } from '@/entities/wishList'
import { IconProps } from '@/types/svgTypes'
import { FC } from 'react'
import FloorOptionsIcon from '@/svg/FloorOptionsIcon'
import OneLayerIcon from '@/svg/OneLayerIcon'

export const ICON_MAP: Record<IconSlug, FC<IconProps>> = {
  houseEmpty: FloorIcon,
  houseGroundFloor: Floor0Icon,
  houseFirstFloor: Floor1Icon,
  houseSecondFloor: Floor2Icon,
  houseThirdFloor: Floor3Icon,
  houseFourthFloor: Floor4Icon,
  houseEllipsis: FloorOptionsIcon,
  houseCheck: HomeReadyIcon,
  houseEuro: SummaryIcon,
  houseLeaf: ComfortIcon,
  houseGear: ElectronicsIcon,
  houseCheckPlus: HomeReadyPlusIcon,
  houseOneLayer: OneLayerIcon,
}
