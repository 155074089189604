export default (function (price, _ref) {
  var showDecimal = _ref.showDecimal,
    _ref$showEuroSign = _ref.showEuroSign,
    showEuroSign = _ref$showEuroSign === void 0 ? true : _ref$showEuroSign,
    _ref$showNegative = _ref.showNegative,
    showNegative = _ref$showNegative === void 0 ? true : _ref$showNegative;
  if (!price) return "".concat(showEuroSign ? '€' : '', " 0,-");
  var isNegativeNumber = price < 0;
  var priceToFixed = price.toFixed(2); // make sure the price only has 2 decimals

  var priceArray = Math.abs(Number(priceToFixed)).toString().split('.');

  // places a thousand separator (period) after every third instance of a digit,
  // from the right
  priceArray[0] = priceArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  var processedNumber = priceArray.reduce(function (acc, curr, index) {
    if (priceArray.length === 1 && !showDecimal) {
      return "".concat(curr);
    }
    if (priceArray.length === 1) {
      return "".concat(curr, ",-");
    }
    if (index === 1) {
      return "".concat(acc, ",").concat(curr.length === 1 ? curr + '0' : curr);
    }
    return curr;
  }, '€ ');
  return "".concat(isNegativeNumber && showNegative ? '-' : '', " ").concat(showEuroSign ? '€ ' : '').concat(processedNumber);
});