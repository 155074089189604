import Box from '@/atoms/Box'
import styled from 'styled-components'
// there is no typing for react-svgmt, but since the code is simple, we can ignore it
// @ts-ignore
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import { Sizes, SIZE_MAP } from '@/styles/spacing'
import { fromBreakpoint } from '@/styles/breakpoints'
import { FloorPlanProps } from './FloorPlan.props'
import FloorPlan from './FloorPlan'

const Container = styled(Box)<{ spacing: Sizes }>`
  overflow: hidden;
  width: 100%;
  height: 100%;

  ${({ spacing }) => fromBreakpoint.md`
    padding-left: ${SIZE_MAP[spacing] + 60}rem;
    padding-right: ${SIZE_MAP[spacing] + 60}rem;
  `}
`

const FloorPlanModal = (props: FloorPlanProps) => {
  const spacing = useResponsiveSpacing()

  return (
    <Container
      paddingVertical={spacing}
      paddingHorizontal={spacing}
      spacing={spacing}
    >
      <FloorPlan {...props} />
    </Container>
  )
}

export default FloorPlanModal
