import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const UnionIcon: FC<IconProps> = ({ width = 8, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 8 8"
    >
      <g clipPath="url(#clip0_820_50465)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.72714 0.268253C7.57092 0.103167 7.34831 0.00012207 7.0981 0.00012207H4.18327L4.16477 0.000313686H3.32127V0.83549H3.32201L3.32182 0.853934C3.32182 1.32548 3.70134 1.70775 4.18327 1.70774L6.27473 1.70774L6.27473 3.78061C6.27473 4.24933 6.64615 4.62031 7.10964 4.63401V4.63534H7.99993V3.18353H7.99768L7.99768 0.891706C7.99768 0.643716 7.89371 0.423085 7.72714 0.268253ZM0.272783 7.732C0.429004 7.89709 0.651615 8.00013 0.901831 8.00013L3.81666 8.00013L3.83515 7.99994H4.67865V7.16477H4.67791L4.67811 7.14632C4.67811 6.67478 4.29858 6.29251 3.81666 6.29251L1.72519 6.29251L1.72519 4.21965C1.72519 3.75093 1.35377 3.37995 0.890286 3.36625L0.890286 3.36491L-6.0948e-06 3.36491L-6.30647e-06 4.81672H0.00224598L0.00224573 7.10855C0.00224573 7.35654 0.106216 7.57717 0.272783 7.732ZM4.69568 3.9993C4.69568 4.40169 4.36656 4.72788 3.96056 4.72788C3.55456 4.72788 3.22544 4.40169 3.22544 3.9993C3.22544 3.59691 3.55456 3.27071 3.96056 3.27071C4.36656 3.27071 4.69568 3.59691 4.69568 3.9993Z"
          fill={ICON_COLORS[variant]}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50465">
          <rect
            width="8"
            height="8"
            fill={ICON_COLORS[variant]}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default UnionIcon
