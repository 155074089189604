import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
export default (function (notifications) {
  var total = 0;
  var keys = [];
  if (!notifications) return total;
  for (var _i = 0, _Object$entries = Object.entries(notifications); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    if (value.unreadCount) {
      total = total + value.unreadCount;
    }
    keys.push(key);
  }
  return total;
});