import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { openModal as _openModal } from '../../redux/modal';
import { getResultData } from '../../redux/apiData';
import ThemeLabel from '../shared/designElements/ThemeLabel';
import ActionsIcon from '../shared/icons/Actions';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import { WidgetScroller } from '../shared/designElements/WidgetScroller';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import { colorPalette, textStyles, fadeInRight } from '../../styles/styleGuide';
import { ActionsAndMessageWidgetStyles } from './MessagesWidget';
import getDate from '../../services/getDate';
import truncateText from '../../services/truncateText';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    phase: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'phase', undefined)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal() {
      if (ownProps.dummyContent) {
        dispatch(_openModal(colorPalette.lightOrange, {
          type: 'profile',
          componentProps: {
            type: 'actions',
            content: ownProps.dummyContent.modal
          }
        }));
      }
    }
  };
};
var redirect = function redirect(link, router) {
  if (!link) return;
  router === null || router === void 0 || router.push('/' + getLinkFromPageObject(link));
};
var calculateDeadline = function calculateDeadline(deadline) {
  if (!deadline) return {
    days: -2,
    label: ''
  };
  var deadlineTransformed = new Date(deadline);
  var today = new Date();
  var diff = deadlineTransformed.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0);
  if (diff > 0) {
    var _days = Math.ceil(Math.abs((today.getTime() - deadlineTransformed.getTime()) / (24 * 60 * 60 * 1000)));
    return _days === 1 ? {
      days: _days,
      label: "Actie over ".concat(_days, " dag")
    } : {
      days: _days,
      label: "Actie over ".concat(_days, " dagen")
    };
  } else if (diff === 0) {
    return {
      days: 0,
      label: 'Actie vandaag'
    };
  }
  return {
    days: -1,
    label: 'Actie verlopen'
  };
};
var getActionsString = function getActionsString(items) {
  if (!items) return '';
  if (items.length === 0) {
    return 'Geen openstaande acties';
  } else if (items.length === 1) {
    return '1 openstaande actie';
  } else {
    return "".concat(items.length, " openstaande acties");
  }
};
var ActionsWidget = function ActionsWidget(_ref) {
  var dummyContent = _ref.dummyContent,
    openModal = _ref.openModal,
    link = _ref.link,
    phase = _ref.phase,
    _ref$items = _ref.items,
    items = _ref$items === void 0 ? [] : _ref$items,
    router = _ref.router;
  var actionItems = phase === 'cj1' && dummyContent ? [{
    id: 0,
    title: dummyContent && dummyContent.label,
    deadline: '',
    type: '',
    onClick: openModal
  }] : items.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      onClick: link ? function () {
        return redirect(link, router);
      } : undefined
    });
  });
  return __jsx(Container, {
    "data-id": "dashboard_ActionsWidget_Container"
  }, __jsx(Label, {
    "data-id": "dashboard_ActionsWidget_Label"
  }, __jsx(ThemeLabel, {
    label: "ACTIES",
    theme: "projectUpdate",
    "data-id": "dashboard_ActionsWidget_ThemeLabel"
  }), __jsx(ActionsIcon, {
    width: 20,
    height: 20,
    color: colorPalette.white,
    "data-id": "dashboard_ActionsWidget_ActionsIcon"
  })), __jsx(ActionCount, {
    "data-id": "dashboard_ActionsWidget_ActionCount"
  }, getActionsString(items)), actionItems && actionItems.length > 0 && __jsx(WidgetScroller, {
    "data-id": "dashboard_ActionsWidget_WidgetScroller"
  }, actionItems.map(function (item, index) {
    var deadline = calculateDeadline(item.deadline);
    return __jsx(Action, {
      key: index,
      border: index > 0,
      cj2: phase !== 'cj1',
      onClick: item.onClick,
      "data-id": "dashboard_ActionsWidget_Action"
    }, (phase === 'cj2' || phase === 'cj3') && __jsx(Deadline, {
      days: deadline.days,
      "data-id": "dashboard_ActionsWidget_Deadline"
    }, __jsx("div", {
      "data-id": "dashboard_ActionsWidget_div"
    }, "Deadline: ", getDate(item.deadline)), __jsx("span", {
      "data-id": "dashboard_ActionsWidget_span"
    }, deadline.label)), __jsx(ActionContent, {
      "data-id": "dashboard_ActionsWidget_ActionContent"
    }, truncateText(item.title, 100)));
  })), actionItems && actionItems.length === 0 && __jsx(ActionContainer, {
    "data-id": "dashboard_ActionsWidget_ActionContainer"
  }, __jsx(ActionsIcon, {
    width: 144,
    height: 149,
    color: colorPalette.lightGrey,
    "data-id": "dashboard_ActionsWidget_ActionsIcon"
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_ActionsWidget_WidgetFooter"
  }, (phase === 'cj2' || phase === 'cj3') && link && __jsx(ButtonSecondary, {
    label: "Toon alle acties",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(link),
    "data-id": "dashboard_ActionsWidget_ButtonSecondary"
  })));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(ActionsWidget));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  animation-name: ", ";\n"])), ActionsAndMessageWidgetStyles, fadeInRight);
var Deadline = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", " display: flex;\n  font-size: 12rem;\n  color: ", ";\n  justify-content: space-between;\n  width: 100%;\n  margin-bottom: 2rem;\n\n  > span {\n    color: ", ";\n  }\n"])), textStyles.caption, colorPalette.headerGrey, function (props) {
  return props.days === -1 ? colorPalette.headerGrey : props.days > 3 ? colorPalette.green : colorPalette.red;
});
var ActionContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 68%;\n"])));
var ActionContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-weight: bold;\n"])), textStyles.caption, colorPalette.textGrey);
var Action = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-right: ", "rem;\n  padding-top: 10rem;\n  padding-bottom: 14rem;\n  border-top: ", ";\n  border-bottom: 1px dashed #979797;\n  cursor: pointer;\n"])), function (props) {
  return props.cj2 ? 12 : 0;
}, function (props) {
  return props.border ? 'none' : '1px dashed #979797';
});
var ActionCount = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-weight: bold;\n  margin-bottom: 14rem;\n"])), textStyles.standardText, colorPalette.textGrey);
var Label = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: -40rem;\n  margin-left: -20rem;\n\n  > div {\n    > span {\n      padding-left: 51rem;\n      padding-right: 140rem;\n    }\n  }\n\n  > svg {\n    position: absolute;\n    top: 0;\n    margin-top: 9rem;\n    margin-left: 20rem;\n  }\n"])));