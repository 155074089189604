import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import DocumentIcon from '../shared/icons/Document';
import DownloadButton from '../shared/buttons/ButtonDownload';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import { colorPalette, textStyles } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import { openModal as _openModal } from '../../redux/modal';
import { WidgetScroller } from '../shared/designElements/WidgetScroller';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import WidgetHeader from '../shared/designElements/WidgetHeader';
import withMatch from '@/hocs/withMatch';
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    openModal: function openModal(modal) {
      if (modal) {
        dispatch(_openModal(colorPalette.lightOrange, {
          type: 'profile',
          componentProps: {
            type: 'messages',
            content: modal
          }
        }));
      }
    }
  };
};
var DocumentsWidget = function DocumentsWidget(_ref) {
  var phase = _ref.phase,
    docItem = _ref.document,
    openModal = _ref.openModal;
  return __jsx(Container, {
    "data-id": "dashboard_DocumentsWidget_Container"
  }, __jsx(WidgetHeader, {
    title: phase === 'cj1' ? 'Documenten' : "Nieuwe documenten (".concat(docItem.documents && docItem.documents.length, ")"),
    subtitle: phase === 'cj1' ? 'Na aankoop zie je hier je documenten.' : (phase === 'cj2' || phase === 'cj3') && (docItem.documents && docItem.documents.length) === 0 ? 'Er zijn geen nieuwe documenten.' : undefined,
    "data-id": "dashboard_DocumentsWidget_WidgetHeader"
  }), __jsx(Fragment, null, __jsx(WidgetScroller, {
    "data-id": "dashboard_DocumentsWidget_WidgetScroller"
  }, (phase === 'cj1' || docItem.documents && docItem.documents.length === 0) && __jsx(IconContainer, {
    onClick: function onClick() {
      return openModal(docItem.modal);
    },
    "data-id": "dashboard_DocumentsWidget_IconContainer"
  }, __jsx(DocumentIcon, {
    width: 200,
    height: 140,
    color: colorPalette.background,
    "data-id": "dashboard_DocumentsWidget_DocumentIcon"
  })), (docItem.documents || []).map(function (doc, index) {
    return __jsx(Document, {
      key: index,
      isLast: index === docItem.documents.length - 1,
      "data-id": "dashboard_DocumentsWidget_Document"
    }, __jsx(DownloadButton, {
      content: doc.name,
      link: doc.url,
      "data-id": "dashboard_DocumentsWidget_DownloadButton"
    }), __jsx(DocumentInfo, {
      "data-id": "dashboard_DocumentsWidget_DocumentInfo"
    }, __jsx(Folder, {
      "data-id": "dashboard_DocumentsWidget_Folder"
    }, "in map ", doc.folder), __jsx(Added, {
      "data-id": "dashboard_DocumentsWidget_Added"
    }, "Toegevoegd door ", doc.author, " |", ' ', doc.created.substring(0, 10).split('-').reverse().join('-'), ' ', doc.created.substring(11, 16))));
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_DocumentsWidget_WidgetFooter"
  }, phase === 'cj1' && __jsx(ButtonSecondary, {
    label: "Lees meer",
    type: "button",
    clickHandler: function clickHandler() {
      return openModal(docItem.modal);
    },
    "data-id": "dashboard_DocumentsWidget_ButtonSecondary"
  }), docItem.link && __jsx(ButtonSecondary, {
    label: "Alle documenten",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(docItem.link),
    "data-id": "dashboard_DocumentsWidget_ButtonSecondary_12"
  }))));
};
export default withMatch(connect(null, mapDispatchToProps)(DocumentsWidget));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n\n  ", ";\n\n  > button {\n    position: absolute;\n    bottom: 0;\n  }\n  > div > a {\n    position: absolute;\n    bottom: 0;\n  }\n"])), toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-top: 30rem;\n    &:after {\n      content: '';\n      width: calc(100% + 40rem);\n      position: absolute;\n      height: 2rem;\n      top: 0;\n      left: -20rem;\n      background-color: ", "\n    }\n  "])), colorPalette.lightGrey));
var IconContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 30rem;\n  margin-bottom: 50rem;\n  &:hover {\n    cursor: pointer;\n  }\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-top: 80rem;\n    margin-bottom: 80rem;\n  "]))));
var borderStyle = "1rem dashed ".concat(colorPalette.lightBeige, ";");
var Document = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 20rem 0;\n  border-top: ", ";\n  border-bottom: ", ";\n\n  > span {\n    margin-bottom: 6rem;\n  }\n"])), borderStyle, function (props) {
  return props.isLast ? borderStyle : '';
});
var DocumentInfo = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-left: 32rem;\n"])));
var Folder = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin: 0;\n"])), textStyles.caption, colorPalette.textGrey);
var Added = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 12rem;\n  color: ", ";\n  margin: 0;\n"])), textStyles.caption, colorPalette.beige);