import useParams from '@/hooks/useParams'
import { useSelector } from '@/redux/index'

const useWishList = () => {
  const { wishListId } = useParams()

  return useSelector((state) => {
    if (!wishListId) {
      return undefined
    }
    return state.houseDesign.wishLists[wishListId]?.data
  })
}

export default useWishList
