import styled from 'styled-components'
import Title from '@/atoms/Title'
import Text from '@/atoms/Text'
import { shadows } from '@/styles/styleGuide'
import { fromBreakpoint, toBreakpoint } from '@/styles/breakpoints'
import TextBox from '@/atoms/TextBox'
import { colors } from '@/styles/colors'

const Wrapper = styled(TextBox)`
  margin-right: 20rem;
  margin-left: 20rem;
`

const Container = styled.div`
  ${shadows.boxPrimary};
  align-self: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60rem;
  padding-bottom: 60rem;
`

const StyledTitle = styled(Title)`
  margin-left: 20rem;
  margin-right: 20rem;

  ${toBreakpoint.md`
    margin-top: 40rem;
  `};

  ${fromBreakpoint.md`
    margin-top: 60rem;
  `};
`

const DeadlineModal = () => (
  <Container>
    <Content>
      <Wrapper>
        <StyledTitle text="Beslisperiode" />
        <Text
          text="We hebben even de tijd nodig om ervoor te zorgen dat jouw keuzes straks duidelijk zijn
            voor onze collega’s op de bouwplaats. Om die reden werken we met beslisperiodes; het
            tijdsbestek waarin je jouw keuzes uit de beschikbare meer- en minderwerkopties aan ons
            door kunt geven. Er gelden verschillende beslisperiodes voor verschillende typen opties.
            Zo is de beslisperiode voor opties zoals een uitbouw, elektra of loodgieterswerk anders
            dan de beslisperiode voor opties die te maken hebben met de afwerking van de woning."
        />
        <Text
          text="De datum die je boven een subcategorie ziet, is de uiterste datum waarop je een aanvraag
            kunt starten voor opties binnen deze subcategorie. Wanneer deze datum is gepasseerd, is
            het niet meer mogelijk om een nieuwe aanvraag te starten. Opties die binnen de
            beslisperiode zijn aangevraagd, worden vanzelfsprekend wel in behandeling genomen."
        />
        <Text
          text="
            Heb je vragen over de beslisperiode, of is er nog geen beslisperiode ingesteld voor
            opties in een bepaalde subcategorie? Neem dan contact op met de woonadviseur."
        />
      </Wrapper>
    </Content>
  </Container>
)

export default DeadlineModal
