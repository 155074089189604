import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const SaveIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 460.002 460.002"
    >
      <path
        d="M427.137,0H32.865C14.743,0,0,14.743,0,32.865v394.272c0,18.122,14.743,32.865,32.865,32.865c3.087,0,390.415,0,394.272,0
			c18.122,0,32.865-14.743,32.865-32.865V32.865C460.001,14.743,445.258,0,427.137,0z M382.993,430.001h-29.999V309.673H107.007
			v120.328h-30V294.673c0-8.284,6.716-15,15-15h275.986c8.284,0,15,6.716,15,15V430.001z M383.185,165.328c0,8.284-6.716,15-15,15
			H92.198c-8.284,0-15-6.716-15-15V30h138.615v0.691v75.435c0,8.284,6.716,15,15,15h80.995c8.284,0,15-6.716,15-15V30.691V30h56.377
			V165.328z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default SaveIcon
