import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, fonts } from '../styles/styleGuide';
var HeaderLink = function HeaderLink(_ref) {
  var color = _ref.color,
    headerLink = _ref.headerLink,
    caption = _ref.caption;
  return __jsx(HeaderLinkItem, {
    color: color,
    target: "_blank",
    href: headerLink,
    rel: "noopener noreferrer",
    "data-id": "components_HeaderLink_HeaderLinkItem"
  }, caption);
};
export default HeaderLink;
var HeaderLinkItem = styled.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-left: 30rem;\n  color: ", ";\n  text-decoration: none;\n  font-family: ", ";\n  font-weight: normal;\n  font-size: 16rem;\n  line-height: 24rem;\n\n  &:hover {\n    color: ", ";\n    > span > svg > g > g {\n      fill: ", ";\n    }\n  }\n"])), colorPalette.headerGrey, fonts.primary, function (props) {
  return props.color;
}, function (props) {
  return props.color;
});