import { WishList, WishListOption, WishListSubcategory } from '@/entities/wishList'
import { getTotalAmountForOption } from '@/processes/houseDesignStatuses'

/**
 * Get the living surface in square meters for an option and it's orders
 */
export const getLivingSurfaceForOption = (option: WishListOption, wishList?: WishList) =>
  getTotalAmountForOption(option, wishList) * option.livingSurface

/**
 * Get the cumulative living surface in square meters for the selected and/or ordered options of a subcategory
 */
export const getTotalLivingSurfaceForSubcategory = (
  { options }: WishListSubcategory,
  wishList: WishList
) =>
  options.reduce((optionsCumulative, option) => {
    return optionsCumulative + getLivingSurfaceForOption(option, wishList)
  }, 0)

/**
 * Get the cumulative living surface in square meters for the selected and/or ordered options of a category
 */
export const getTotalLivingSurfaceForCategory = (
  subCategories: WishListSubcategory[],
  wishList: WishList
) =>
  subCategories.reduce(
    (subcategoryCumulative, subcategory) =>
      subcategoryCumulative + getTotalLivingSurfaceForSubcategory(subcategory, wishList),
    0
  )

/**
 * Get the cumulative living surface in square meters for all categories, plus the house living space
 */
export const getTotalLivingSurface = (wishList: WishList) => {
  const { houseType, categories } = wishList
  const houseLivingSpace = houseType?.livingSpace ?? 0

  return (
    houseLivingSpace +
    categories.reduce(
      (categoryTotal, { subcategories }) =>
        categoryTotal +
        subcategories.reduce(
          (subCategoryTotal, subcategory) =>
            subCategoryTotal + getTotalLivingSurfaceForSubcategory(subcategory, wishList),
          0
        ),
      0
    )
  )
}
