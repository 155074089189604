import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Slider from './shared/ui/Slider';
var PictureCollageSlider = function PictureCollageSlider(_ref) {
  var currentIndex = _ref.currentIndex,
    images = _ref.images;
  return __jsx(Container, {
    "data-id": "components_PictureCollageSliderContainer_Container"
  }, __jsx(Slider, {
    currentIndex: currentIndex,
    heroes: images,
    "data-id": "components_PictureCollageSliderContainer_Slider"
  }));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 940rem;\n  box-sizing: border-box;\n  margin-left: auto;\n  margin-right: auto;\n"])));
export default PictureCollageSlider;