var __jsx = React.createElement;
import React from 'react';
import HouseBase from '../icons/HouseBase';
import HouseFloor0 from '../icons/HouseFloor0';
import HouseFloor1 from '../icons/HouseFloor1';
import HouseFloor2 from '../icons/HouseFloor2';
import HouseFloor3 from '../icons/HouseFloor3';
import HouseFloor4 from '../icons/HouseFloor4';
import HouseOptions from '../icons/HouseOptions';
import HouseReady from '../icons/HouseReady';
import HouseEuro from '../icons/HouseEuro';
import HouseComfort from '../icons/HouseComfort';
import HouseAdditionalOptions from '../icons/HouseAdditionalOptions';
import HouseLivingReady from '../icons/HouseLivingReady';
import HouseOneLayer from '../icons/HouseOneLayer';
var ICONMAPPING = {
  houseEmpty: HouseBase,
  houseGroundFloor: HouseFloor0,
  houseFirstFloor: HouseFloor1,
  houseSecondFloor: HouseFloor2,
  houseThirdFloor: HouseFloor3,
  houseFourthFloor: HouseFloor4,
  houseEllipsis: HouseOptions,
  houseCheck: HouseReady,
  houseEuro: HouseEuro,
  houseLeaf: HouseComfort,
  houseGear: HouseAdditionalOptions,
  houseCheckPlus: HouseLivingReady,
  houseOneLayer: HouseOneLayer
};
var HouseIconsMap = function HouseIconsMap(_ref) {
  var icon = _ref.icon,
    color = _ref.color;
  var Icon = ICONMAPPING[icon];
  return __jsx(Icon, {
    color: color,
    "data-id": "designElements_HouseIconsMap_Icon"
  });
};
export default HouseIconsMap;