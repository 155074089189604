import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { GridWrapper, GridItem } from '../../styles/gridElements';
import LayoutBase from '../shared/layout/LayoutBase';
import ForgotPasswordBlock from '../ForgotPasswordBlock';
import Hero from '../HeroHome';
import { colorPalette, shadows, textStyles } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import WithSettings from '../WithSettings';
var meta = {
  metaTitle: 'Wachtwoord vergeten'
};
var Password = function Password(_ref) {
  var settings = _ref.settings,
    renderWidth = _ref.renderWidth;
  return __jsx(LayoutBase, {
    settings: settings,
    layoutSettings: {
      hasMenu: false,
      hasHeader: true,
      hasFooter: true
    },
    renderWidth: renderWidth,
    title: "Password",
    meta: meta,
    "data-id": "contentTypes_Password_LayoutBase"
  }, __jsx(HeroContainer, {
    "data-id": "contentTypes_Password_HeroContainer"
  }, __jsx(Hero, {
    hero: {
      desktopImage: '/images/defaultHero.jpg'
    },
    heroTriangleSmallColor: colorPalette.beige,
    heroTriangleLargeColor: colorPalette.beige,
    renderWidth: renderWidth,
    "data-id": "contentTypes_Password_Hero"
  }), __jsx(TextContainer, {
    "data-id": "contentTypes_Password_TextContainer"
  }, __jsx(Title, {
    "data-id": "contentTypes_Password_Title"
  }, "Wachtwoord opnieuw instellen"), __jsx(SubTitle, {
    "data-id": "contentTypes_Password_SubTitle"
  }, "Kies hieronder een nieuw wachtwoord voor toegang tot Mijn Homestudios"))), __jsx(Wrapper, {
    "data-id": "contentTypes_Password_Wrapper"
  }, __jsx(CopyCell, {
    "data-id": "contentTypes_Password_CopyCell"
  }, __jsx(ForgotPasswordBlock, {
    "data-id": "contentTypes_Password_ForgotPasswordBlock"
  }))));
};
export default WithSettings(Password);
var HeroContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n"])));
var TextContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 10%;\n  left: 8%;\n  z-index: 5;\n  margin-right: 20rem;\n  margin-top: 60rem;\n  ", " ", ";\n"])), toBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    margin-top: 20rem;\n  "]))), toBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    left: 5%;\n  "]))));
var Title = styled.h1(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  color: #fff;\n"])), textStyles.pageTitle, shadows.textPrimary);
var SubTitle = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", " ", ";\n  color: #fff;\n"])), textStyles.title, shadows.textPrimary);
var Wrapper = styled(GridWrapper)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  z-index: 2;\n  margin-top: -100rem;\n  margin-bottom: 20rem;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    margin-top: 50rem;\n    margin-bottom: 40rem;\n  "]))), fromBreakpoint.lg(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    margin-top: 0rem;\n  "]))));
var CopyCell = styled(GridItem)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  padding-top: 20rem;\n  padding-bottom: 20rem;\n  margin-right: 10rem;\n  margin-left: 5rem;\n  padding-right: 10rem;\n  padding-left: 10rem;\n  max-width: 1180rem;\n\n  ", ";\n"])), fromBreakpoint.lg(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    padding-left: 100rem;\n    padding-right: 100rem;\n  "]))));