var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseFloor2 = function HouseFloor2(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseFloor2_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseFloor2_title"
  }, "Tweede Verdieping"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseFloor2_g"
  }, __jsx("g", {
    transform: "translate(-142.000000, -767.000000)",
    fill: color,
    "data-id": "icons_HouseFloor2_g_3"
  }, __jsx("g", {
    transform: "translate(40.000000, 761.000000)",
    "data-id": "icons_HouseFloor2_g_4"
  }, __jsx("g", {
    transform: "translate(102.000000, 6.000000)",
    "data-id": "icons_HouseFloor2_g_5"
  }, __jsx("g", {
    transform: "translate(7.187500, 8.125000)",
    fillRule: "nonzero",
    "data-id": "icons_HouseFloor2_g_6"
  }, __jsx("path", {
    d: "M0.125,8.125 L0.125,6.96875 C0.5,6.6875 0.875,6.3125 1.34375,5.875 C1.8125,5.4375 2.21875,5 2.59375,4.59375 C3.28125,3.84375 3.625,3.15625 3.625,2.53125 C3.625,2.15625 3.53125,1.875 3.34375,1.6875 C3.125,1.5 2.84375,1.40625 2.5,1.40625 C2.1875,1.40625 1.84375,1.5 1.53125,1.59375 C1.21875,1.75 0.875,1.96875 0.5625,2.28125 C0.21875,1.84375 0,1.53125 0,1.34375 C0,1.1875 0.09375,1.03125 0.28125,0.875 C0.96875,0.28125 1.78125,0 2.65625,0 C3.46875,0 4.09375,0.1875 4.59375,0.59375 C5.03125,1 5.28125,1.5625 5.28125,2.3125 C5.28125,3.28125 4.71875,4.3125 3.59375,5.46875 C3.125,5.9375 2.71875,6.34375 2.34375,6.6875 L4.625,6.6875 C4.9375,6.6875 5.1875,6.78125 5.34375,6.9375 C5.5,7.09375 5.59375,7.3125 5.59375,7.59375 C5.59375,7.6875 5.59375,7.75 5.59375,7.84375 C5.59375,7.9375 5.59375,7.96875 5.59375,8.03125 L0.125,8.03125 L0.125,8.125 Z",
    "data-id": "icons_HouseFloor2_path"
  })), __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseFloor2_path_8"
  }))))));
};
HouseFloor2.defaultProps = defaultProps;
export default HouseFloor2;