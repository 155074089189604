var __jsx = React.createElement;
import React from 'react';
var defaultProps = {
  color: '#ffffff',
  filled: false
};
var Favorite = function Favorite(_ref) {
  var filled = _ref.filled,
    color = _ref.color;
  return __jsx("svg", {
    width: "23px",
    height: "22px",
    viewBox: "0 0 23 22",
    version: "1.1",
    "data-id": "icons_Favorite_svg"
  }, __jsx("title", {
    "data-id": "icons_Favorite_title"
  }, "Favorite icon"), __jsx("defs", {
    "data-id": "icons_Favorite_defs"
  }), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Favorite_g"
  }, __jsx("g", {
    transform: "translate(-871.000000, -586.000000)",
    fill: color,
    "data-id": "icons_Favorite_g_4"
  }, __jsx("g", {
    transform: "translate(871.000000, 586.000000)",
    "data-id": "icons_Favorite_g_5"
  }, !filled ? __jsx("path", {
    d: "M19.8590674,7.24334286 C19.5641417,10.4380571 16.6338103,14.1073429 11.1485074,18.1710571 C5.66162743,14.1089143 2.73602743,10.4380571 2.43637029,7.24334286 L2.43637029,6.18105714 C2.676096,3.63848571 4.24535314,2.56834286 5.25472457,2.47091429 C5.52914743,2.44262857 5.78779886,2.43005714 6.02752457,2.43005714 C7.47534171,2.43005714 8.08254171,2.8732 9.28117029,4.29534286 L11.1485074,6.51262857 L13.0174217,4.29534286 C14.2160503,2.8732 14.820096,2.43005714 16.2710674,2.43005714 C16.5076389,2.43005714 16.7662903,2.44262857 17.0438674,2.47091429 C18.0516617,2.56834286 19.622496,3.63848571 19.8590674,6.18105714 L19.8590674,7.24334286 Z M17.2804389,0.0524857143 C16.9208503,0.0179142857 16.5849189,0.000628571404 16.2710674,0.000628571404 C13.7444846,0.000628571404 12.5332389,1.08648571 11.1485074,2.73334286 C9.76219886,1.08648571 8.55095314,0.000628571404 6.02752457,0.000628571404 C5.71051886,0.000628571404 5.37458743,0.0179142857 5.01815314,0.0524857143 C2.830656,0.264628571 0.305650286,2.2572 -0.000315428559,6.07734286 L-0.000315428559,7.34705714 C0.286724571,11.0037714 3.05618743,15.5122 11.1485074,21.1677714 C19.2424046,15.5122 22.0118674,11.0037714 22.2989074,7.34705714 L22.2989074,6.07734286 C21.9913646,2.2572 19.4647817,0.264628571 17.2804389,0.0524857143 L17.2804389,0.0524857143 Z",
    "data-id": "icons_Favorite_path"
  }) : __jsx("path", {
    d: "M17.2804389,0.0524857143 C19.4647817,0.264628571 21.9913646,2.2572 22.2989074,6.07734286 L22.2989074,7.34705714 C22.0118674,11.0037714 19.2424046,15.5122 11.1485074,21.1677714 C3.05618743,15.5122 0.286724571,11.0037714 -0.000315428559,7.34705714 L-0.000315428559,6.07734286 C0.305650286,2.2572 2.830656,0.264628571 5.01815314,0.0524857143 C5.37458743,0.0179142857 5.71051886,0.000628571404 6.02752457,0.000628571404 C8.55095314,0.000628571404 9.76219886,1.08648571 11.1485074,2.73334286 C12.5332389,1.08648571 13.7444846,0.000628571404 16.2710674,0.000628571404 C16.5849189,0.000628571404 16.9208503,0.0179142857 17.2804389,0.0524857143 Z",
    fill: color,
    "data-id": "icons_Favorite_path_7"
  })))));
};
Favorite.defaultProps = defaultProps;
export default Favorite;