import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const Floor4Icon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 20"
    >
      <title>Derde Verdieping</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-185.000000, -130.000000)"
          fill={fillColor}
        >
          <g transform="translate(185.000000, 130.000000)">
            <g strokeWidth="1">
              <path
                d="M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z"
                fillRule="nonzero"
              />
            </g>
            <path d="M6.875,14.3001527 L6.875,13.1851946 L9.98708094,8.87238952 C10.1831185,8.60283785 10.3791531,8.41088727 10.5751907,8.29653202 C10.7712283,8.18217677 10.9835992,8.125 11.2123097,8.125 C11.3184967,8.125 11.4410183,8.14337825 11.5798783,8.18013529 C11.7187382,8.21689234 11.8208396,8.25977491 11.8861855,8.30878431 L11.8861855,13.0871763 L12.2660064,13.0871763 C12.6907544,13.0871763 12.9031253,13.2832109 12.9031253,13.6752861 C12.9031253,13.7488002 12.8990412,13.8202711 12.890873,13.8897011 C12.8827048,13.9591311 12.8745367,14.026518 12.8663684,14.0918639 L12.8418639,14.3001527 L11.8861855,14.3001527 L11.8861855,16.2482664 L10.2198744,16.2482664 L10.2198744,14.3001527 L6.875,14.3001527 Z M10.2198744,13.0504194 L10.2198744,10.3304117 L8.19824701,13.0504194 L10.2198744,13.0504194 Z" />
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default Floor4Icon
