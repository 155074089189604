import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import styled from 'styled-components';
import { textStyles, shadows } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
import Link from '@/components/atoms/Link';
export var CardCollectionContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  width: 100%;\n  box-sizing: border-box;\n  padding-top: 30rem;\n  margin-bottom: 15rem;\n  position: relative;\n\n  > * {\n    width: 100%;\n    ", ";\n\n    ", ";\n  }\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      width: calc( ((1/2) * 100%) - 10rem );\n\n      &:nth-of-type(2n + 1) {\n        margin-right: 20rem;\n      }\n\n    "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      width: calc( ((1/3) * 100%) - 13.333rem );\n\n      &:nth-of-type(3n) {\n        margin-right: 0;\n      }\n\n      &:nth-of-type(3n + 1) {\n        margin-right: 20rem;\n      }\n\n      &:nth-of-type(3n + 2) {\n        margin-right: 20rem;\n      }\n    "]))));
export var CardTitle = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin: 0;\n  transition: color 0.25s ease-in-out;\n"])), textStyles.sectionTitle, function (props) {
  return props.color;
});
export var Card = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: relative;\n  box-sizing: border-box;\n  height: 516rem;\n  margin-bottom: 20rem;\n  transition: color 0.25s ease-in-out;\n  &:hover {\n    ", " {\n      color: ", ";\n    }\n  }\n"])), CardTitle, function (props) {
  return props.hoverColor;
});
export var CardLink = styled(Link)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  text-decoration: none;\n  height: 100%;\n  background-color: #ffffff;\n  ", ";\n  transition: box-shadow 0.25s ease;\n  position: relative;\n\n  &:hover {\n    box-shadow: 0rem 0rem 20rem 0rem rgba(0, 0, 0, 0.25);\n  }\n"])), shadows.boxPrimary);
export var CardTextContainer = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  z-index: 2;\n  padding: 24rem 24rem 24rem 24rem;\n  box-sizing: border-box;\n"])));