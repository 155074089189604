var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var PlanningUpdate = function PlanningUpdate(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "15px",
    height: "19px",
    viewBox: "0 0 15 19",
    version: "1.1",
    "data-id": "icons_PlanningUpdate_svg"
  }, __jsx("title", {
    "data-id": "icons_PlanningUpdate_title"
  }, "Planning Update"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlanningUpdate_g"
  }, __jsx("g", {
    transform: "translate(-278.000000, -289.000000)",
    fill: color,
    "data-id": "icons_PlanningUpdate_g_3"
  }, __jsx("g", {
    transform: "translate(244.000000, 289.000000)",
    "data-id": "icons_PlanningUpdate_g_4"
  }, __jsx("g", {
    transform: "translate(34.000000, 0.000000)",
    "data-id": "icons_PlanningUpdate_g_5"
  }, __jsx("polygon", {
    points: "3.70408034 8.63308668 10.4937844 8.63308668 10.4937844 7.21281184 3.70408034 7.21281184",
    "data-id": "icons_PlanningUpdate_polygon"
  }), __jsx("polygon", {
    points: "3.73737844 11.5087104 10.5270825 11.5087104 10.5270825 10.0884355 3.73737844 10.0884355",
    "data-id": "icons_PlanningUpdate_polygon_7"
  }), __jsx("polygon", {
    points: "3.77023256 14.3838901 6.52420719 14.3838901 6.52420719 12.9636152 3.77023256 12.9636152",
    "data-id": "icons_PlanningUpdate_polygon_8"
  }), __jsx("path", {
    d: "M1.77589852,16.8847992 L12.4312896,16.8847992 L12.4312896,5.3987315 L1.77589852,5.3987315 L1.77589852,16.8847992 Z M8.8794926,1.62139535 L8.8794926,0.711247357 C8.8794926,0.319217759 8.56027484,0 8.16824524,0 L6.03672304,0 C5.64469345,0 5.32769556,0.319217759 5.32769556,0.711247357 L5.32769556,1.62139535 L0,1.62139535 L0,4.0512685 L0,17.2404228 C0,18.024482 0.636215645,18.6606977 1.42027484,18.6606977 L12.7864693,18.6606977 C13.5705285,18.6606977 14.2071882,18.024482 14.2071882,17.2404228 L14.2071882,1.62139535 L8.8794926,1.62139535 Z",
    "data-id": "icons_PlanningUpdate_path"
  }))))));
};
PlanningUpdate.defaultProps = defaultProps;
export default PlanningUpdate;