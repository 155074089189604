import { WishList, WishListCategory } from '@/entities/wishList'

export const slugify = (s: string) =>
  s
    .toLowerCase()
    .trim()
    .replace(/[^\-a-zA-Z\d\s]/g, '')
    .replace(/[\s_-]+/g, '-')

export const getCategoryBySlug = (
  wishList: WishList,
  slug: string
): WishListCategory | undefined => {
  if (!wishList?.categories) {
    return undefined
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const category of wishList.categories) {
    if (slugify(category.title) === slug) {
      return category
    }
  }
  return undefined
}
