import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
var defaultProps = {};
var DaysCounter = function DaysCounter(_ref) {
  var deadline = _ref.deadline,
    fontSize = _ref.fontSize;
  var today = new Date();
  var deadlineTransformed = new Date(deadline);
  if (deadlineTransformed.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
    var _days = Math.ceil(Math.abs((today.getTime() - deadlineTransformed.getTime()) / (24 * 60 * 60 * 1000)));
    if (_days > 28) {
      return null;
    } else if (_days === 1) {
      return __jsx(Text, {
        fontSize: fontSize,
        color: colorPalette.red,
        "data-id": "designElements_DaysCounter_Text"
      }, "Actie over ", _days, " dag");
    } else {
      return __jsx(Text, {
        fontSize: fontSize,
        color: _days < 4 ? colorPalette.red : colorPalette.green,
        "data-id": "designElements_DaysCounter_Text_1"
      }, "Actie over ", _days, " dagen");
    }
  } else if (deadlineTransformed.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
    return __jsx(Text, {
      fontSize: fontSize,
      color: colorPalette.red,
      "data-id": "designElements_DaysCounter_Text_2"
    }, "Actie vandaag");
  } else {
    return __jsx(Text, {
      fontSize: fontSize,
      color: colorPalette.textGrey,
      "data-id": "designElements_DaysCounter_Text_3"
    }, "Actie verlopen");
  }
};
DaysCounter.defaultProps = defaultProps;
var Text = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-family: ", ";\n\n  ", ";\n\n  ", ";\n"])), function (props) {
  return props.color ? props.color : colorPalette.green;
}, fonts.primary, function (props) {
  return props.fontSize ? "\n    font-size: ".concat(props.fontSize, "rem;\n    ") : "\n    font-size: 16rem;\n  ";
}, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    ", "\n  "])), function (props) {
  return props.fontSize ? "\n    " : "\n      font-size: 18rem;\n    ";
}));
export default DaysCounter;