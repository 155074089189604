import useParams from '@/hooks/useParams'
import { setLayoutImage } from '@/redux/houseDesign'
import { useSelector } from '@/redux/index'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

/**
 * If a Wish List category has options with a layout image (as in: floorplan), this hook will return the current layout image for the category
 * plus a function to update the layout image for the current category.
 */
const useLayoutImages = () => {
  const { wishListId, wishListItem } = useParams()
  const dispatch = useDispatch()
  const layoutImages = useSelector((state) => {
    if (!wishListId) {
      return undefined
    }
    return state.houseDesign.wishLists[wishListId]?.layoutImages ?? {}
  })

  return useMemo(
    () => ({
      setLayoutImage: (url?: string) => {
        if (!wishListId || !wishListItem) {
          return
        }
        dispatch(setLayoutImage(wishListId, wishListItem, url))
      },
      currentLayoutImage: wishListItem ? layoutImages?.[wishListItem] : undefined,
    }),
    [dispatch, layoutImages, wishListId, wishListItem]
  )
}

export default useLayoutImages
