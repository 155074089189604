var __jsx = React.createElement;
import React from 'react';
var defaultProps = {
  color: '#ffffff'
};
var Play = function Play(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "80px",
    height: "80px",
    viewBox: "0 0 80 80",
    version: "1.1",
    "data-id": "icons_Play_svg"
  }, __jsx("title", {
    "data-id": "icons_Play_title"
  }, "Afspelen"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Play_g"
  }, __jsx("g", {
    transform: "translate(-280.000000, -2654.000000)",
    fill: color,
    "data-id": "icons_Play_g_3"
  }, __jsx("g", {
    transform: "translate(130.000000, 2507.000000)",
    "data-id": "icons_Play_g_4"
  }, __jsx("g", {
    transform: "translate(0.000000, 1.000000)",
    "data-id": "icons_Play_g_5"
  }, __jsx("path", {
    d: "M190,226 C167.90861,226 150,208.09139 150,186 C150,163.90861 167.90861,146 190,146 C212.09139,146 230,163.90861 230,186 C230,208.09139 212.09139,226 190,226 Z M207.934066,187.142857 L180.857143,171.142857 L180.857143,203.142857 L207.934066,187.142857 Z",
    "data-id": "icons_Play_path"
  }))))));
};
Play.defaultProps = defaultProps;
export default Play;