import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { connect } from 'react-redux';
import { GridWrapper, GridItem } from '../../../styles/gridElements';
import { getResultData } from '../../../redux/apiData';
import { colorPalette } from '../../../styles/styleGuide';
import { breakpointSizes, fromBreakpoint, toBreakpoint } from '../../../styles/breakpoints';
import HeroFlex from '../../HeroFlex';
import PageTextHero from '../../PageTextHero';
import Block from './../../Block';
import get from 'lodash.get';
import isLastItem from '../../../services/isLastItem';
// These blocks should have a white background on the flex template closed env
var WHITE_BACKGROUND_BLOCKS = {
  storyslider: true,
  richtext: true,
  forum: true,
  lijst: true,
  messageCenter: true,
  documentCenter: true
};
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var getEnv = function getEnv(params) {
  if (params.projectSlug === 'portal') {
    return 'closed';
  }
  return String(params.env);
};
var LayoutFlex = function LayoutFlex(_ref) {
  var styling = _ref.styling,
    renderWidth = _ref.renderWidth,
    page = _ref.page,
    match = _ref.match;
  var templateProps = page.templateProps;
  return __jsx("div", {
    "data-id": "layout_LayoutFlex_div"
  }, templateProps && templateProps.hero && styling && __jsx(HeroFlex, _extends({}, templateProps.hero, {
    heroTriangleSmallColor: getEnv(match.params) ? styling.heroTriangleSmall : colorPalette.beige,
    heroTriangleLargeColor: getEnv(match.params) ? styling.heroTriangleLarge : colorPalette.beige,
    renderWidth: renderWidth,
    "data-id": "layout_LayoutFlex_HeroFlex"
  })), __jsx(GridWrapper, {
    "data-id": "layout_LayoutFlex_GridWrapper"
  }, templateProps && __jsx(PageTextHero, {
    env: getEnv(match.params),
    heroText: templateProps,
    renderWidth: renderWidth,
    "data-id": "layout_LayoutFlex_PageTextHero"
  }), (page.blocks || []).map(function (block, index) {
    return __jsx(BlockCell, {
      key: index,
      whiteBackground: get(WHITE_BACKGROUND_BLOCKS, block.type),
      noGutterUntilBreakpoint: block.type === 'storyslider' ? breakpointSizes.md : 0,
      "data-id": "layout_LayoutFlex_BlockCell"
    }, __jsx(Block, {
      block: block,
      renderWidth: renderWidth,
      isLastItem: isLastItem(page.blocks, index),
      "data-id": "layout_LayoutFlex_Block"
    }));
  })));
};
export default withMatch(connect(mapStateToProps)(LayoutFlex));
var BlockCell = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 0rem;\n  padding-bottom: 40rem;\n\n  ", " &:last-child {\n    ", ";\n\n    ", ";\n  }\n"])), toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-right: 10rem;\n    padding-left: 10rem;\n  "]))), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      padding-bottom: 0;\n    "]))), fromBreakpoint.lg(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      padding-bottom: 30rem;\n    "]))));