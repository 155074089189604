import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import MenuHamburger from './MenuHamburger';
import Link from '@/components/atoms/Link';
import getLinkFromPageObject from '../services/getLinkFromPageObject';
import withMatch from '@/hocs/withMatch';
import { WidthLimit } from '../styles/gridElements';
import { gridGutter, maxContentWidth, colorPalette } from '../styles/styleGuide';
import LoginBar from './LoginBar';
import { HSSiteLogo } from '../constants/logos';
import { isSubmenuActive } from '../redux/menu';
export var whichLayout = function whichLayout(renderWidth) {
  return renderWidth > 1040 ? 'large' : 'small';
};
var Header = function Header(_ref) {
  var match = _ref.match,
    settings = _ref.settings,
    renderWidth = _ref.renderWidth,
    hasMenu = _ref.hasMenu;
  var _match$params = match.params,
    projectSlug = _match$params.projectSlug,
    env = _match$params.env;
  var layout = whichLayout(renderWidth);
  var logo = match.params.env === 'closed' ? layout === 'large' ? HSSiteLogo.large : HSSiteLogo.small : settings && settings.logoUrl;
  var submenuIsActive = match && settings && settings.menu ? isSubmenuActive(match, settings.menu) : false;
  return __jsx(Container, {
    layout: layout,
    submenuIsActive: submenuIsActive,
    "data-id": "components_Header_Container"
  }, __jsx(MaxWidth, {
    "data-id": "components_Header_MaxWidth"
  }, __jsx(GridCell, {
    layout: layout,
    "data-id": "components_Header_GridCell"
  }, __jsx(Link, {
    href: '/' + getLinkFromPageObject({
      projectSlug: projectSlug,
      env: env
    }),
    "data-id": "components_Header_Link"
  }, logo ? __jsx(Logo, {
    src: logo,
    layout: layout,
    alt: "Homestudios",
    name: "Homestudios",
    "data-id": "components_Header_Logo"
  }) : ''), hasMenu ? __jsx(Fragment, null, __jsx(MenuLoginWrapper, {
    "data-id": "components_Header_MenuLoginWrapper"
  }, __jsx(LoginBarWrapper, {
    "data-id": "components_Header_LoginBarWrapper"
  }, settings && __jsx(LoginBar, {
    settings: settings,
    "data-id": "components_Header_LoginBar"
  })), settings && settings.menu && __jsx(Menu, {
    menuItems: settings && settings.menu,
    "data-id": "components_Header_Menu"
  })), __jsx(MobileMenuButton, {
    "data-id": "components_Header_MobileMenuButton"
  }, __jsx(MenuHamburger, {
    "data-id": "components_Header_MenuHamburger"
  }))) : null)));
};
export default withMatch(Header);
var Container = styled.header(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.32);\n  height: 42rem;\n  background: white;\n  position: relative;\n  z-index: 10;\n  color: ", ";\n  margin-bottom: ", ";\n\n  @media only all and (min-width: 1040px) {\n    height: 100rem;\n  }\n"])), colorPalette.textGrey, function (props) {
  return props.submenuIsActive && props.hasMenu && props.layout === 'large' ? '35rem' : '0';
});
var MenuLoginWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: none;\n\n  @media only all and (min-width: 1040px) {\n    display: flex;\n    flex-direction: column;\n    align-self: flex-end;\n    width: 100%;\n  }\n"])));
var LoginBarWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: ", "rem;\n  width: 100%;\n  top: 10rem;\n  text-align: right;\n  box-sizing: border-box;\n  padding-left: ", "rem;\n"])), maxContentWidth + gridGutter, gridGutter / 2);
var MaxWidth = styled(WidthLimit)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: 100%;\n"])));
var GridCell = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  height: 100%;\n  padding-left: 10rem;\n  padding-right: 10rem;\n\n  @media only all and (min-width: 1040px) {\n    padding-left: ", "rem;\n    padding-right: ", "rem;\n  }\n"])), gridGutter / 2, gridGutter / 2);
var MobileMenuButton = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  position: absolute;\n  right: 0;\n  height: 100%;\n  align-items: center;\n\n  @media only all and (min-width: 1040px) {\n    display: none;\n  }\n"])));
var Logo = styled.img(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  max-width: 140rem;\n  max-height: 32rem;\n  margin-top: 5rem;\n\n  @media only all and (min-width: 1040px) {\n    max-width: 280rem;\n    max-height: 80rem;\n    margin-top: 10rem;\n  }\n"])));