import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import ActivationModal from './dashboard/ActivationModal';
import Article from './Article';
import CategoryDescriptionModal from './homestudio/CategoryDescriptionModal';
import CategorySubDescriptionModal from './homestudio/CategorySubDescriptionModal';
import DeadlineModal from './homestudio/DeadlineModal';
import DeleteWishListModal from './DeleteWishListModal';
import ECWInterestModal from './dashboard/ECWInterestModal';
import EditProfileModal from './dashboard/EditProfileModal';
import FeedbackModal from './FeedbackModal';
import FirstWelcomeWishlistModal from './FirstWelcomeWishlistModal';
import FirstWelcomeCJ2Modal from './FirstWelcomeCJ2Modal';
import FirstWelcomeModal from './FirstWelcomeModal';
import FormContainer from './FormContainer';
import ForgotPasswordModal from './ForgotPasswordModal';
import Handshake from './Handshake';
import ImageContainer from './ImageContainer';
import PictureCollageSliderContainer from './PictureCollageSliderContainer';
import PlanningStepModal from './dashboard/PlanningStepModal';
import PlanningActionModal from './dashboard/PlanningActionModal';
import PricelistModal from './PricelistModal';
import ProductOptionDescriptionModal from './homestudio/ProductOptionDescriptionModal';
import ProductOptionStatusModal from './homestudio/ProductOptionStatusModal';
import ProductOptionRequestModal from './homestudio/ProductOptionRequestModal';
import ProductOptionRequestResultModal from './homestudio/ProductOptionRequestResultModal';
import ProductOptionConfirmModal from './homestudio/ProductOptionConfirmModal';
import ProductOptionVerificationModal from './homestudio/ProductOptionVerificationModal';
import ProductOptionFinalConfirmationModal from './homestudio/ProductOptionFinalConfirmationModal';
import ProfileModal from './dashboard/ProfileModal';
import ProjectModal from './ProjectModal';
import GenericModal from './GenericModal';

/*
 * Note: 32 different modal types is too many, let's use GenericModal for any new functionality
 */

var ModalComponentLoader = function ModalComponentLoader(_ref) {
  var type = _ref.type,
    modalConfig = _ref.modalConfig,
    renderWidth = _ref.renderWidth;
  switch (type) {
    case 'genericModal':
      return __jsx(GenericModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_GenericModal"
      }));
    case 'article':
      return __jsx(Article, _extends({
        modal: true
      }, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_Article"
      }));
    case 'form':
      return __jsx(FormContainer, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_FormContainer"
      }));
    case 'image':
      return __jsx(ImageContainer, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ImageContainer"
      }));
    case 'slider':
      return __jsx(PictureCollageSliderContainer, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_PictureCollageSliderContainer"
      }));
    case 'handshake':
      return __jsx(Handshake, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_Handshake"
      }));
    case 'categoryDescription':
      return __jsx(CategoryDescriptionModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_CategoryDescriptionModal"
      }));
    case 'subCategoryDescription':
      return __jsx(CategorySubDescriptionModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_CategorySubDescriptionModal"
      }));
    case 'fullDescription':
      return __jsx(ProductOptionDescriptionModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionDescriptionModal"
      }));
    case 'firstWelcomeCJ1':
      return __jsx(FirstWelcomeModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        type: type,
        "data-id": "components_ModalComponentLoader_FirstWelcomeModal"
      }));
    case 'firstWelcomeCJ2':
      return __jsx(FirstWelcomeCJ2Modal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_FirstWelcomeCJ2Modal"
      }));
    case 'firstWelcomeCJ3':
      return __jsx(FirstWelcomeModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        type: type,
        "data-id": "components_ModalComponentLoader_FirstWelcomeModal"
      }));
    case 'firstWelcomeWishlist':
      return __jsx(FirstWelcomeWishlistModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_FirstWelcomeWishlistModal"
      }));
    case 'password':
      return __jsx(ForgotPasswordModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_ForgotPasswordModal"
      }));
    case 'pricelist':
      return __jsx(PricelistModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_PricelistModal"
      }));
    case 'profile':
      return __jsx(ProfileModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_ProfileModal"
      }));
    case 'feedback':
      return __jsx(FeedbackModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_FeedbackModal"
      }));
    case 'editProfile':
      return __jsx(EditProfileModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_EditProfileModal"
      }));
    case 'deleteWishList':
      return __jsx(DeleteWishListModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_DeleteWishListModal"
      }));
    case 'planningStep':
      return __jsx(PlanningStepModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_PlanningStepModal"
      }));
    case 'planningAction':
      return __jsx(PlanningActionModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_PlanningActionModal"
      }));
    case 'activation':
      return __jsx(ActivationModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ActivationModal"
      }));
    case 'interests':
      return __jsx(ECWInterestModal, _extends({}, modalConfig.componentProps, {
        "data-id": "components_ModalComponentLoader_ECWInterestModal"
      }));
    case 'project':
      return __jsx(ProjectModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProjectModal"
      }));
    case 'deadlinesDescription':
      return __jsx(DeadlineModal, {
        "data-id": "components_ModalComponentLoader_DeadlineModal"
      });
    case 'orderRequest':
      return __jsx(ProductOptionRequestModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionRequestModal"
      }));
    case 'orderRequestResult':
      return __jsx(ProductOptionRequestResultModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionRequestResultModal"
      }));
    case 'orderConfirm':
      return __jsx(ProductOptionConfirmModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionConfirmModal"
      }));
    case 'orderVerification':
      return __jsx(ProductOptionVerificationModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionVerificationModal"
      }));
    case 'orderConfirmation':
      return __jsx(ProductOptionFinalConfirmationModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionFinalConfirmationModal"
      }));
    case 'optionOrderStatus':
      return __jsx(ProductOptionStatusModal, _extends({}, modalConfig.componentProps, {
        renderWidth: renderWidth,
        "data-id": "components_ModalComponentLoader_ProductOptionStatusModal"
      }));
    default:
      return null;
  }
};
export default ModalComponentLoader;