import Title from '@/atoms/Title'
import React, { useEffect, useState } from 'react'
import { useWishListMutation } from '@/hooks/wishListRequestHooks'
import { WishListRequestKey } from '@/types/endpoints'
import useParams from '@/hooks/useParams'
import styled, { css } from 'styled-components'
import { colors } from '@/styles/colors'
import IconButton from '@/atoms/IconButton'
import EditIcon from '@/svg/EditIcon'
import SaveIcon from '@/svg/SaveIcon'
import InlineRequestHandler from '@/components/organisms/InlineRequestHandler'
import CloseIcon from '@/svg/CloseCircleIcon'
import { fontStyles } from '@/styles/fonts'

type Props = {
  title?: string
}

const Container = styled.div<{ editing: boolean }>`
  padding-right: 32rem;
  position: relative;
  transition: padding-right 300ms ease-in-out, border-color 300ms ease-in-out;
  border-bottom: 1rem solid transparent;

  ${({ editing }) =>
    editing &&
    css`
      padding-right: 64rem;
      border-bottom: 1rem solid ${colors.gray30};
    `}
`

const Input = styled.input`
  ${fontStyles.title.lg}
  width: 100%;
  border: none;
  color: ${colors.brand};
  background-color: inherit;
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`

const IconButtons = styled.div<{ isLoading: boolean }>`
  display: flex;
  gap: 8rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 56rem;
  justify-content: flex-end;
  align-items: center;
  opacity: 1;
  transition: opacity 300ms ease-in-out;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`

const EditableTitle = ({ title = '' }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wishListItem, ...params } = useParams()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(title)
  const [initialValue, setInitalValue] = useState(title)

  useEffect(() => {
    setValue(title)
    setInitalValue(title)
  }, [title])

  const { mutate, isLoading } = useWishListMutation(
    'patchWishList',
    [WishListRequestKey.wishListTitle],
    params
  )

  const submit = () => {
    setEditing(false)
    if (value === initialValue) {
      return
    }
    mutate({ params, body: { title: value } })
    setInitalValue(value)
  }

  return (
    <InlineRequestHandler
      isLoading={isLoading}
      transparent
      alignRight
    >
      <Container editing={editing}>
        {editing || isLoading ? (
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setEditing(false)
                return
              }
              if (e.key === 'Enter') {
                submit()
              }
            }}
            maxLength={50}
            type="text"
            autoFocus={!isLoading}
          />
        ) : (
          <Title
            text={value}
            variant="brand"
            hideOverflow
            onClick={() => {
              setEditing(true)
            }}
          />
        )}
        <IconButtons isLoading={isLoading}>
          {editing ? (
            <>
              <IconButton
                icon={SaveIcon}
                onClick={submit}
              />
              <IconButton
                icon={CloseIcon}
                onClick={() => {
                  setValue(initialValue)
                  setEditing(false)
                }}
              />
            </>
          ) : (
            <IconButton
              icon={EditIcon}
              onClick={() => setEditing(true)}
            />
          )}
        </IconButtons>
      </Container>
    </InlineRequestHandler>
  )
}

export default EditableTitle
