import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const FloorOptionsIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 20"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-242.000000, -130.000000)"
          fill={fillColor}
        >
          <g transform="translate(0.000000, 124.000000)">
            <g transform="translate(242.000000, 6.000000)">
              <g>
                <g
                  transform="translate(4.062500, 13.125000)"
                  fillRule="nonzero"
                >
                  <g>
                    <circle
                      cx="1.40625"
                      cy="1.40625"
                      r="1.40625"
                    />
                    <circle
                      cx="5.90625"
                      cy="1.40625"
                      r="1.40625"
                    />
                    <circle
                      cx="10.375"
                      cy="1.40625"
                      r="1.40625"
                    />
                  </g>
                </g>
                <path
                  d="M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default FloorOptionsIcon
