var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Facebook = function Facebook(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "13px",
    height: "26px",
    viewBox: "0 0 13 26",
    version: "1.1",
    "data-id": "icons_Facebook_svg"
  }, __jsx("title", {
    "data-id": "icons_Facebook_title"
  }, "Facebook"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Facebook_g"
  }, __jsx("g", {
    transform: "translate(-345.000000, -1181.000000)",
    fill: color,
    "data-id": "icons_Facebook_g_3"
  }, __jsx("g", {
    transform: "translate(331.000000, 1173.000000)",
    "data-id": "icons_Facebook_g_4"
  }, __jsx("path", {
    d: "M17.2261667,34 L17.2261667,21.7995 L14,21.7995 L14,17.4076667 L17.2261667,17.4076667 L17.2261667,13.6560833 C17.2261667,10.70725 19.13175,8 23.5225,8 C25.30025,8 26.6154167,8.17008333 26.6154167,8.17008333 L26.5114167,12.2715833 C26.5114167,12.2715833 25.17025,12.2596667 23.70775,12.2596667 C22.1239167,12.2596667 21.8704167,12.98875 21.8704167,14.1999167 L21.8704167,17.4076667 L26.6370833,17.4076667 L26.4301667,21.7995 L21.8704167,21.7995 L21.8704167,34 L17.2261667,34 Z",
    "data-id": "icons_Facebook_path"
  })))));
};
Facebook.defaultProps = defaultProps;
export default Facebook;