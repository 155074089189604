import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { shadows, textStyles, colorPalette } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import BBCode from '../shared/richText/BBCode';
import HeroArticle from '../HeroArticle';
import withApiData from '../../services/withApiData';
var connectApiData = withApiData({
  wishListModalSubCategory: 'getWishListModalSubCategory'
}, function (ownProps) {
  return {
    wishListModalSubCategory: {
      projectSlug: ownProps.match.params.projectSlug,
      modalId: ownProps.modalId
    }
  };
});
var CategorySubDescriptionModal = function CategorySubDescriptionModal(_ref) {
  var wishListModalSubCategory = _ref.wishListModalSubCategory,
    color = _ref.color,
    renderWidth = _ref.renderWidth;
  var data = wishListModalSubCategory.data;
  return data ? __jsx(Container, {
    "data-id": "homestudio_CategorySubDescriptionModal_Container"
  }, data.type === 'slider' && __jsx(HeroArticle, {
    type: data.type,
    media: data.media,
    renderWidth: renderWidth,
    theme: "woonStyling",
    "data-id": "homestudio_CategorySubDescriptionModal_HeroArticle"
  }), data.type === 'image' && __jsx(HeroArticle, {
    type: data.type,
    image: data.image,
    theme: data.type === 'category' ? 'projectUpdate' : 'woonStyling',
    renderWidth: renderWidth,
    "data-id": "homestudio_CategorySubDescriptionModal_HeroArticle_2"
  }), data.type === 'video' && __jsx(HeroArticle, {
    type: data.type,
    youtubeId: data.youtubeId,
    renderWidth: renderWidth,
    theme: "woonStyling",
    "data-id": "homestudio_CategorySubDescriptionModal_HeroArticle_3"
  }), __jsx(Content, {
    "data-id": "homestudio_CategorySubDescriptionModal_Content"
  }, __jsx("div", {
    "data-id": "homestudio_CategorySubDescriptionModal_div"
  }, __jsx(Title, {
    color: color,
    "data-id": "homestudio_CategorySubDescriptionModal_Title"
  }, data.title)), __jsx(Wrapper, {
    "data-id": "homestudio_CategorySubDescriptionModal_Wrapper"
  }, __jsx(BBCode, {
    content: data.text,
    "data-id": "homestudio_CategorySubDescriptionModal_BBCode"
  })))) : null;
};
export default withMatch(connectApiData(CategorySubDescriptionModal));
var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-right: 20rem;\n  margin-left: 20rem;\n"])));
var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n  align-self: center;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n"])), shadows.boxPrimary);
var Content = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  padding-bottom: 60rem;\n"])));
var Title = styled.h1(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-left: 20rem;\n  margin-right: 20rem;\n  ", ";\n\n  ", ";\n"])), textStyles.pageTitle, function (props) {
  return props.color ? props.color : colorPalette.headerGrey;
}, toBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-top: 40rem;\n  "]))), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    margin-top: 60rem;\n  "]))));