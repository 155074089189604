import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Link from '@/components/atoms/Link';
import { breakpointSizes } from '../../../styles/breakpoints';
import { colorPalette, shadows, textStyles } from '../../../styles/styleGuide';
import getLinkFromPageObject from '../../../services/getLinkFromPageObject';
import ImageLoader from './ImageLoader';
var SliderSlide = function SliderSlide(_ref) {
  var slide = _ref.slide,
    sliderWidth = _ref.sliderWidth,
    isCurrent = _ref.isCurrent,
    fit = _ref.fit;
  var titleProps = {
    sliderWidth: sliderWidth,
    isCurrent: isCurrent
  };
  return slide.internalLink && slide.internalLink.projectSlug && slide.internalLink.env ? __jsx(SlideInternalLink, {
    href: '/' + getLinkFromPageObject(slide.internalLink),
    "data-id": "ui_SliderSlide_SlideInternalLink"
  }, fit === 'contain' && slide.image && __jsx(ImageBlurContainer, {
    "data-id": "ui_SliderSlide_ImageBlurContainer"
  }, __jsx(ImageLoader, {
    src: slide.image.src,
    mode: "bg",
    width: 100,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader"
  })), __jsx(ImageLoader, {
    src: slide.image && slide.image.src,
    mode: "bg",
    fit: fit,
    width: sliderWidth,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader_3"
  }), slide.text && __jsx(Title, _extends({}, titleProps, {
    "data-id": "ui_SliderSlide_Title"
  }), slide.text)) : slide.externalLink ? __jsx(SlideExternalLink, {
    href: slide.externalLink,
    target: "_blank",
    "data-id": "ui_SliderSlide_SlideExternalLink"
  }, fit === 'contain' && slide.image && __jsx(ImageBlurContainer, {
    "data-id": "ui_SliderSlide_ImageBlurContainer"
  }, __jsx(ImageLoader, {
    src: slide.image.src,
    mode: "bg",
    width: 100,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader"
  })), __jsx(ImageLoader, {
    src: slide.image && slide.image.src,
    mode: "bg",
    fit: fit,
    width: sliderWidth,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader_8"
  }), slide.text && __jsx(Title, _extends({}, titleProps, {
    "data-id": "ui_SliderSlide_Title"
  }), slide.text)) : __jsx(SlideNoLink, {
    "data-id": "ui_SliderSlide_SlideNoLink"
  }, fit === 'contain' && slide.image && __jsx(ImageBlurContainer, {
    "data-id": "ui_SliderSlide_ImageBlurContainer"
  }, __jsx(ImageLoader, {
    src: slide.image.src,
    mode: "bg",
    width: 100,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader"
  })), __jsx(ImageLoader, {
    src: slide.image && slide.image.src,
    mode: "bg",
    fit: fit,
    width: sliderWidth,
    height: 0,
    "data-id": "ui_SliderSlide_ImageLoader_13"
  }), slide.text && __jsx(Title, _extends({}, titleProps, {
    "data-id": "ui_SliderSlide_Title"
  }), slide.text));
};
var ImageBlurContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-color: #000000;\n\n  > div {\n    backface-visibility: hidden;\n    opacity: 0.85;\n    transform: scale(1.3);\n    filter: blur(8px);\n  }\n"])));
var Slide = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  height: 100%;\n"])));
var LinkSlide = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: block;\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n\n  > div {\n    transform: scale(1) translateZ(0);\n    transition: transform 1s ease;\n    will-change: transform;\n    backface-visibility: visible;\n\n    &:hover {\n      transform: scale(1.03) translateZ(0);\n    }\n  }\n"])));
var SlideNoLink = Slide;
var SlideInternalLink = LinkSlide.withComponent(Link);
var SlideExternalLink = LinkSlide.withComponent('a');
var Title = styled.h2(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  pointer-events: none;\n  margin-top: 0;\n  margin-bottom: 0;\n  top: 20rem;\n  left: 20rem;\n  width: calc(100% - 40rem);\n  max-width: 530rem;\n  z-index: 2;\n  color: ", ";\n  ", ";\n  ", "\n  opacity: ", ";\n  transition: opacity 0.3s 0.5s ease-in-out;\n\n  ", "\n\n  ", "\n"])), colorPalette.white, shadows.textPrimary, textStyles.pageTitle, function (props) {
  return props.isCurrent ? 1 : 0;
}, function (props) {
  return props.sliderWidth >= breakpointSizes.md ? "\n    width: calc((7/12) * 100%);\n  " : '';
}, function (props) {
  return props.sliderWidth >= breakpointSizes.lg ? "\n    top: 30rem;\n    left: 30rem;\n  " : '';
});
export default SliderSlide;