var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 64,
  height: 62
};
var Message = function Message(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 64 62",
    "data-id": "icons_Profile_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Profile_g"
  }, __jsx("g", {
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_Profile_g_2"
  }, __jsx("g", {
    "data-id": "icons_Profile_g_3"
  }, __jsx("path", {
    d: "M24.122,33.791 C24.042,34.182 24,34.586 24,35 C24,38.307 26.688,41 30.003,41 L33.996,41 C37.322,41 40,38.314 40,35 C40,34.586 39.958,34.182 39.878,33.791 C44.728,30.688 48,24.808 48,18.064 C48,8.088 40.836,0 32,0 C23.164,0 16,8.088 16,18.064 C16,24.808 19.272,30.688 24.122,33.791 Z",
    "data-id": "icons_Profile_path"
  }), __jsx("path", {
    d: "M50.506,35 L45.559,35 C44.005,41.9 38.523,47 32,47 C25.477,47 19.995,41.9 18.441,35 L13.495,35 C6.05,35 0,41.044 0,48.5 L0,62 L64,62 L64,48.5 C64,41.043 57.959,35 50.506,35 Z",
    "data-id": "icons_Profile_path_5"
  })))));
};
Message.defaultProps = defaultProps;
export default Message;