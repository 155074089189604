var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.background
};
var Forum = function Forum(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "130px",
    height: "111px",
    viewBox: "0 0 130 111",
    version: "1.1",
    "data-id": "icons_Forum_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Forum_g"
  }, __jsx("g", {
    transform: "translate(-669.000000, -208.000000)",
    fill: color,
    "data-id": "icons_Forum_g_2"
  }, __jsx("g", {
    "data-id": "icons_Forum_g_3"
  }, __jsx("g", {
    transform: "translate(0.000000, 40.000000)",
    "data-id": "icons_Forum_g_4"
  }, __jsx("g", {
    transform: "translate(669.000000, 168.000000)",
    "data-id": "icons_Forum_g_5"
  }, __jsx("path", {
    d: "M122.961071,11.9498692 L122.961071,59.484837 C122.961071,62.4990642 120.503358,64.9584304 117.4888,64.9584304 L110.8536,64.9584304 C109.055507,64.9584304 107.601764,66.4118424 107.601764,68.2102664 L107.601764,81.2430564 L94.4890004,66.0942597 C93.8796116,65.380442 92.9810606,64.9842072 92.0296342,64.9842072 L37.0128,64.9842072 C33.9985728,64.9842072 31.5405285,62.5261629 31.5405285,59.5119357 L31.5405285,11.9498692 C31.5405285,8.9369639 33.9985728,6.4779282 37.0128,6.4779282 L117.4888,6.4779282 C120.503358,6.4779282 122.961071,8.9369639 122.961071,11.9498692 L122.961071,11.9498692 Z M37.0128,3.30471133e-05 C30.4291541,3.30471133e-05 25.0629638,5.36754519 25.0629638,11.9498692 L25.0629638,59.484837 C25.0629638,66.0684829 30.4291541,71.4346732 37.0128,71.4346732 L90.5749003,71.4346732 L105.672144,88.9109781 C106.596141,89.9681553 107.892249,90.5762222 109.293116,90.5762222 C111.936885,90.5762222 114.105436,88.4347692 114.105436,85.7910002 L114.105436,71.4617718 L117.515898,71.4617718 C124.098223,71.4617718 129.466065,66.0942597 129.466065,59.5119357 L129.466065,11.9498692 C129.466065,5.36754519 124.098223,3.30471133e-05 117.515898,3.30471133e-05 L37.0128,3.30471133e-05 Z",
    "data-id": "icons_Forum_path"
  }), __jsx("path", {
    d: "M18.4805737,109.822431 C19.0357652,110.033933 19.6167334,110.140014 20.1715945,110.140014 C21.5205776,110.140014 22.8424622,109.559046 23.7681118,108.47444 L38.8640332,90.999787 L86.0282123,90.999787 C87.8266363,90.999787 89.2800483,89.5447226 89.2800483,87.7479511 C89.2800483,85.9495272 87.8266363,84.4961151 86.0282123,84.4961151 L37.383192,84.4961151 C36.4304437,84.4961151 35.5593218,84.89235 34.9241563,85.6061677 L21.8368385,100.80784 L21.8368385,87.7737278 C21.8368385,85.9753039 20.3834264,84.5218919 18.5850025,84.5218919 L11.9498031,84.5218919 C8.93557592,84.5218919 6.47786211,82.0628562 6.47786211,79.0496204 L6.47786211,31.5149831 C6.47786211,28.5004254 8.93557592,26.0413897 11.9498031,26.0413897 C13.748227,26.0413897 15.2016391,24.5879776 15.2016391,22.7895537 C15.2016391,20.9927822 13.748227,19.5648164 11.9498031,19.5648164 C5.36615721,19.5648164 -3.30471133e-05,24.9313371 -3.30471133e-05,31.5149831 L-3.30471133e-05,79.0496204 C-3.30471133e-05,85.6319444 5.36615721,90.999787 11.9498031,90.999787 L15.3605957,90.999787 L15.3605957,105.328685 C15.3605957,107.364387 16.576399,109.135382 18.4805737,109.822431",
    "data-id": "icons_Forum_path_7"
  }), __jsx("path", {
    d: "M106.32294,21.8169111 L48.2066173,21.8169111 C46.4432234,21.8169111 45.0136052,23.2458683 45.0136052,25.0105841 C45.0136052,26.7739781 46.4432234,28.2029353 48.2066173,28.2029353 L106.32294,28.2029353 C108.086334,28.2029353 109.514961,26.7739781 109.514961,25.0105841 C109.514961,23.2458683 108.086334,21.8169111 106.32294,21.8169111",
    "data-id": "icons_Forum_path_8"
  }), __jsx("path", {
    d: "M70.2628887,42.9284976 L48.2065843,42.9284976 C46.4431903,42.9284976 45.0139027,44.3587767 45.0139027,46.1221707 C45.0139027,47.8855646 46.4431903,49.3145218 48.2065843,49.3145218 L70.2628887,49.3145218 C72.0262826,49.3145218 73.4552398,47.8855646 73.4552398,46.1221707 C73.4552398,44.3587767 72.0262826,42.9284976 70.2628887,42.9284976",
    "data-id": "icons_Forum_path_9"
  })))))));
};
Forum.defaultProps = defaultProps;
export default Forum;