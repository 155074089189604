var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseReady = function HouseReady(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseReady_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseReady_title"
  }, "Huis Woonklaar"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseReady_g"
  }, __jsx("g", {
    transform: "translate(-196.000000, -130.000000)",
    fill: color,
    "data-id": "icons_HouseReady_g_3"
  }, __jsx("g", {
    transform: "translate(0.000000, 124.000000)",
    "data-id": "icons_HouseReady_g_4"
  }, __jsx("g", {
    transform: "translate(196.000000, 6.000000)",
    "data-id": "icons_HouseReady_g_5"
  }, __jsx("g", {
    "data-id": "icons_HouseReady_g_6"
  }, __jsx("g", {
    transform: "translate(5.312500, 8.437500)",
    fillRule: "nonzero",
    "data-id": "icons_HouseReady_g_7"
  }, __jsx("g", {
    "data-id": "icons_HouseReady_g_8"
  }, __jsx("g", {
    "data-id": "icons_HouseReady_g_9"
  }, __jsx("path", {
    d: "M3.59375,4.28125 L2.15625,2.84375 C1.875,2.53125 1.40625,2.53125 1.125,2.84375 L0,3.9375 L3.5625,7.5 L9.65625,1.34375 L8.5625,0.25 C8.25,-0.0625 7.8125,-0.0625 7.5,0.25 L3.59375,4.28125 Z",
    "data-id": "icons_HouseReady_path"
  })))), __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseReady_path_11"
  })))))));
};
HouseReady.defaultProps = defaultProps;
export default HouseReady;