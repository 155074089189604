import InputControl, { InputControlProps } from '@/atoms/inputs/InputControl'

const InputCheckbox = (props: Omit<InputControlProps, 'isRadio'>) => (
  <InputControl
    isRadio
    {...props}
  />
)

export default InputCheckbox
