import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const RightArrowIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 10 16"
    >
      <path
        d="M1.0646 1.52234L1.06935 1.52735C0.300795 2.34347 0.305802 3.61873 1.11441 4.42734L4.69058 8.0035L1.11416 11.5799C0.29003 12.4043 0.300545 13.7129 1.11416 14.5265C1.12493 14.5372 1.13569 14.5478 1.14671 14.5583L1.14596 14.559L2.58694 16L9.04432 9.54187C9.47216 9.11403 9.67544 8.55576 9.65741 8.0035C9.67468 7.4515 9.47216 6.89298 9.04432 6.46514L2.58669 0L1.0646 1.52234Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default RightArrowIcon
