import useParams from '@/hooks/useParams'
import { useWishListMutation } from '@/hooks/wishListRequestHooks'
import { WishListRequestKey } from '@/types/endpoints'
import React from 'react'
import BoxIcon from '@/atoms/BoxIcon'
import FavoriteIcon from '@/svg/FavoriteIcon'
import FavoriteFilledIcon from '@/svg/FavoriteFilledIcon'
import InlineRequestHandler from '@/organisms/InlineRequestHandler'

type Props = {
  isFavorite?: boolean
}

const FavoriteButton = ({ isFavorite = false }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wishListItem, ...params } = useParams()
  const { mutate, isLoading } = useWishListMutation(
    'patchWishList',
    [WishListRequestKey.wishListFavorite],
    params
  )

  return (
    <InlineRequestHandler isLoading={isLoading}>
      <BoxIcon
        variant="transparent"
        size="lg"
        icon={isFavorite ? FavoriteFilledIcon : FavoriteIcon}
        onClick={() => {
          mutate({ params, body: { isFavorite: !isFavorite } })
        }}
      />
    </InlineRequestHandler>
  )
}

export default FavoriteButton
