import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import RichTextBlock from './RichTextBlock';
import StorySlider from './StorySlider';
import ArticleCardCollection from './ArticleCardCollection';
import HouseTypeCollection from './HouseTypeCollection';
import ArticleCollection from './ArticleCollection';
import HouseTypeCarousel from './HouseTypeCarousel';
import PricelistBlock from './PricelistBlock';
import CTAPrimary from './CTAPrimary';
import CTASecondary from './CTASecondary';
import ComboBlock from './ComboBlock';
import LoginBlock from './LoginBlock';
import BannerBlock from './BannerBlock';
import ContactBlock from './ContactBlock';
import ContactExpert from './ContactExpert';
import ExpandableListBlock from './ExpandableListBlock';
import IFrameBlock from './IFrameBlock';
import WishListCollection from './WishListCollection';
import FeedbackBlock from './FeedbackBlock';
import MyHouseBlock from './MyHouseBlock';
import ECWInterestsBlock from './dashboard/ECWInterestsBlock';
import ProfileBlock from './dashboard/ProfileBlock';
import PlanningSummaryBlock from './dashboard/PlanningSummaryBlock';
import PlanningBlock from './dashboard/PlanningBlock';
import CommunityBlock from './dashboard/CommunityBlock';
import DesignsBlock from './dashboard/DesignsBlock';
import NeighboursBlock from './dashboard/NeighboursBlock';
import ProfileOverviewBlock from './dashboard/ProfileOverviewBlock';
import MyHouseAndDocsBlock from './dashboard/MyHouseAndDocsBlock';
import ProjectMapBlock from './ProjectMapBlock';
var Block = function Block(_ref) {
  var renderWidth = _ref.renderWidth,
    block = _ref.block,
    isLastItem = _ref.isLastItem;
  if (!block.type) return null;
  switch (block.type) {
    case 'richtext':
      return __jsx(RichTextBlock, _extends({}, block, {
        "data-id": "components_Block_RichTextBlock"
      }));
    case 'ctaform':
      return __jsx(CTAPrimary, _extends({}, block, {
        "data-id": "components_Block_CTAPrimary"
      }));
    case 'ctaformSecond':
      return __jsx(CTASecondary, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_CTASecondary"
      }));
    case 'storyslider':
      return __jsx(StorySlider, _extends({}, block, {
        "data-id": "components_Block_StorySlider"
      }));
    case 'recentarticles':
      return __jsx(ArticleCardCollection, _extends({}, block, {
        renderWidth: renderWidth || 0,
        "data-id": "components_Block_ArticleCardCollection"
      }));
    case 'pricelist':
      return __jsx(PricelistBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_PricelistBlock"
      }));
    case 'housetypeCollection':
      return __jsx(HouseTypeCollection, _extends({}, block, {
        "data-id": "components_Block_HouseTypeCollection"
      }));
    case 'articlecollection':
      return __jsx(ArticleCollection, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_ArticleCollection"
      }));
    case 'housetypeCarousel':
      return __jsx(HouseTypeCarousel, _extends({}, block, {
        "data-id": "components_Block_HouseTypeCarousel"
      }));
    case 'combo':
      return __jsx(ComboBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_ComboBlock"
      }));
    case 'bannerCollection':
      return __jsx(BannerBlock, _extends({
        renderWidth: renderWidth
      }, block, {
        "data-id": "components_Block_BannerBlock"
      }));
    case 'login':
      return __jsx(LoginBlock, _extends({}, block, {
        "data-id": "components_Block_LoginBlock"
      }));
    case 'contact':
      return __jsx(ContactBlock, _extends({}, block, {
        "data-id": "components_Block_ContactBlock"
      }));
    case 'contactExpert':
      return __jsx(ContactExpert, _extends({}, block, {
        isLastItem: isLastItem,
        "data-id": "components_Block_ContactExpert"
      }));
    case 'wishlistOverview':
      return __jsx(WishListCollection, _extends({}, block, {
        "data-id": "components_Block_WishListCollection"
      }));
    case 'profile':
      return __jsx(ProfileBlock, _extends({}, block, {
        "data-id": "components_Block_ProfileBlock"
      }));
    case 'widgetFeedback':
      return __jsx(FeedbackBlock, _extends({}, block, {
        "data-id": "components_Block_FeedbackBlock"
      }));
    case 'widgetPlanning':
      return __jsx(PlanningSummaryBlock, _extends({
        renderWidth: renderWidth
      }, block, {
        "data-id": "components_Block_PlanningSummaryBlock"
      }));
    case 'planning':
      return __jsx(PlanningBlock, _extends({
        renderWidth: renderWidth
      }, block, {
        "data-id": "components_Block_PlanningBlock"
      }));
    case 'widgetCommunity':
      return __jsx(CommunityBlock, _extends({
        renderWidth: renderWidth
      }, block, {
        "data-id": "components_Block_CommunityBlock"
      }));
    case 'wishlistMoodboard':
      return __jsx(DesignsBlock, _extends({}, block, {
        "data-id": "components_Block_DesignsBlock"
      }));
    case 'houseOverview':
      return __jsx(MyHouseBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_MyHouseBlock"
      }));
    case 'neighbour':
      return __jsx(NeighboursBlock, _extends({}, block, {
        "data-id": "components_Block_NeighboursBlock"
      }));
    case 'experienceRatingOverview':
      return __jsx(ECWInterestsBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_ECWInterestsBlock"
      }));
    case 'widgetMyhouseDocument':
      return __jsx(MyHouseAndDocsBlock, _extends({}, block, {
        "data-id": "components_Block_MyHouseAndDocsBlock"
      }));
    case 'widgetProjectMap':
      return __jsx(ProjectMapBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_ProjectMapBlock"
      }));
    case 'profileOverview':
      return __jsx(ProfileOverviewBlock, _extends({}, block, {
        "data-id": "components_Block_ProfileOverviewBlock"
      }));
    case 'lijst':
      return __jsx(ExpandableListBlock, _extends({}, block, {
        "data-id": "components_Block_ExpandableListBlock"
      }));
    case 'forum':
    case 'messageCenter':
    case 'documentCenter':
      return __jsx(IFrameBlock, _extends({}, block, {
        renderWidth: renderWidth,
        "data-id": "components_Block_IFrameBlock"
      }));
    default:
      return null;
  }
};
export default Block;