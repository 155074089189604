import styled from 'styled-components'
import { colors } from '@/styles/colors'
import shadows from '@/styles/shadows'
import Box, { BoxProps } from '@/atoms/Box'

type Variant = 'white' | 'gray10' | 'gray40'

export type ContainerProps = {
  variant?: Variant
  shadow?: boolean
} & BoxProps

const Wrapper = styled(Box)<{ variant: Variant; shadow: boolean }>`
  ${({ shadow }) => shadow && shadows.default}
  border-radius: 8rem 0;
  background-color: ${({ variant }) => colors[variant]};
`

const Container = ({ variant = 'white', shadow = false, ...boxProps }: ContainerProps) => (
  <Wrapper
    {...boxProps}
    variant={variant}
    shadow={shadow}
  />
)

export default Container
