import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { fontStyles, fontWeight } from '@/styles/fonts'
import { OptionStatus } from '@/processes/houseDesignStatuses'

type Props = {
  optionStatus: OptionStatus
}

const OPTIONS_STATUS_LABEL_MAP: Record<OptionStatus, string> = {
  [OptionStatus.disabled]: 'Vervallen',
  [OptionStatus.excluded]: 'Niet selecteerbaar',
  [OptionStatus.final]: 'Definitief',
  [OptionStatus.new]: 'Nieuw',
  [OptionStatus.pending]: 'In behandeling',
  [OptionStatus.actionRequired]: 'Actie vereist',
}

const OPTIONS_STATUS_COLOR_MAP: Record<OptionStatus, string> = {
  [OptionStatus.disabled]: colors.gray20,
  [OptionStatus.excluded]: colors.gray20,
  [OptionStatus.final]: colors.green,
  [OptionStatus.new]: colors.brand,
  [OptionStatus.pending]: colors.blue,
  [OptionStatus.actionRequired]: colors.brand,
}

const Pill = styled.span<Props>`
  ${fontStyles.body.sm}
  font-weight: ${fontWeight.bold};
  background-color: ${({ optionStatus }) => OPTIONS_STATUS_COLOR_MAP[optionStatus]};
  color: ${colors.white};
  padding: 4rem 8rem;
  border-radius: 4rem;
  position: relative;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -6rem;
    border-left: 6rem solid transparent;
    border-right: 6rem solid transparent;
    border-top: 6rem solid ${({ optionStatus }) => OPTIONS_STATUS_COLOR_MAP[optionStatus]};
  }
`

const StatusLabel = ({ optionStatus }: Props) => (
  <Pill optionStatus={optionStatus}>{OPTIONS_STATUS_LABEL_MAP[optionStatus]}</Pill>
)

export default StatusLabel
