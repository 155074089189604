import { colors } from '@/styles/colors'
import { FC } from 'react'
import styled from 'styled-components'
import AnimatedBox from './AnimatedBox'

type Variant = 'gray40' | 'gray30' | 'gray25'

type Props = {
  squareMeters?: number
  withPlus?: boolean
  variant?: Variant
  displayZero?: boolean
  animation?: boolean
}

const Meters = styled.span<{ variant: Variant }>`
  color: ${({ variant }) => colors[variant]};
  min-width: max-content;
  white-space: nowrap;
`

const Sup = styled.sup`
  top: -0.25em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
`

const SquareMeters: FC<Props> = ({
  squareMeters,
  withPlus = false,
  variant = 'gray40',
  displayZero = false,
  animation = false,
}) => {
  if (squareMeters === undefined || (squareMeters === 0 && !displayZero)) {
    return null
  }

  const content = (
    <>
      {withPlus && '+ '}
      {squareMeters}m<Sup>2</Sup>
    </>
  )

  return (
    <Meters variant={variant}>
      {animation ? (
        <AnimatedBox
          animationKey={squareMeters}
          fromTextColor={colors[variant]}
        >
          {content}
        </AnimatedBox>
      ) : (
        content
      )}
    </Meters>
  )
}

export default SquareMeters
