import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const FavoriteIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8493 0.0342984C10.6235 0.0114328 10.4126 0 10.2156 0C8.62932 0 7.86887 0.718188 6.9995 1.80742C6.12915 0.718188 5.3687 0 3.78443 0C3.5854 0 3.3745 0.0114328 3.15072 0.0342984C1.77735 0.17461 0.192093 1.4925 0 4.01915V4.85895C0.180211 7.27751 1.91895 10.2594 6.9995 14C12.0811 10.2594 13.8198 7.27751 14 4.85895V4.01915C13.8069 1.4925 12.2207 0.17461 10.8493 0.0342984Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default FavoriteIcon
