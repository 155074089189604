import { months } from '../constants/months';
export default (function (timestamp) {
  if (!timestamp) return {
    error: 'no timestamp provided'
  };
  var date = timestamp.split(/-|\s|:/);
  if (date.length < 3) return {
    error: 'invalid date'
  };
  return {
    day: Number(date[2]),
    month: months[Number(date[1])],
    year: Number(date[0]),
    time: date.length > 3 ? "".concat(date[3], ":").concat(date[4]) : ''
  };
});