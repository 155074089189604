import { FC } from 'react'
import styled from 'styled-components'
import Dot, { DotVariant } from '@/atoms/Dot'
import Text, { TextVariant } from '@/atoms/Text'

type Variant = 'primary' | 'disabled' | 'inverted'

type Props = {
  values: string[]
  variant?: Variant
}

const DOT_VARIANT_MAP: Record<Variant, DotVariant> = {
  primary: 'brand',
  disabled: 'disabled',
  inverted: 'inverted',
}

const TEXT_VARIANT_MAP: Record<Variant, TextVariant> = {
  primary: 'gray40',
  disabled: 'gray25',
  inverted: 'white',
}

const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
`

const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 12rem;
  list-style: none;
  margin-bottom: 4rem;
`

const List: FC<Props> = ({ values, variant = 'primary' }) => (
  <ListContainer>
    {values.map((value) => (
      <ListItem key={value}>
        <Dot variant={DOT_VARIANT_MAP[variant]} />
        <Text
          text={value}
          variant={TEXT_VARIANT_MAP[variant]}
        />
      </ListItem>
    ))}
  </ListContainer>
)

export default List
