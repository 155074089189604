import { fontStyles } from '@/styles/fonts'
import { colors } from '@/styles/colors'
import { CSSProp } from 'styled-components'

export type InputSizes = 'sm' | 'md' | 'lg'
export type InputVariant = 'brand' | 'gray30' | 'white'

export const INPUT_SIZE_MAP: Record<InputSizes, CSSProp> = {
  sm: fontStyles.body.sm,
  md: fontStyles.body.md,
  lg: fontStyles.title.lg,
}

export const INPUT_UNDERLINE_MAP: Record<InputVariant, string> = {
  brand: colors.gray30,
  gray30: colors.gray30,
  white: colors.white,
}
