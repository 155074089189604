import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import NeighbourWidget from './NeighbourWidget';
import ForumWidget from './ForumWidget';
import { toBreakpoint, fromBreakpoint } from '../../styles/breakpoints';
import WidgetContainer from '../shared/designElements/WidgetContainer';
var CommunityBlock = function CommunityBlock(_ref) {
  var neighbours = _ref.neighbours,
    topics = _ref.topics,
    renderWidth = _ref.renderWidth;
  return __jsx(Container, {
    "data-e2e-block-community": true,
    "data-id": "dashboard_CommunityBlock_Container"
  }, __jsx(WidgetContainer, {
    label: "Community",
    size: "normal",
    icon: "community",
    "data-id": "dashboard_CommunityBlock_WidgetContainer"
  }, __jsx(Blocks, {
    "data-id": "dashboard_CommunityBlock_Blocks"
  }, __jsx(NeighbourWidget, {
    link: neighbours.link,
    neighbours: neighbours.neighbours,
    dummyContent: neighbours.dummyContent,
    "data-id": "dashboard_CommunityBlock_NeighbourWidget"
  }), __jsx(ForumWidget, {
    link: topics.link,
    topics: topics.topics,
    dummyContent: topics.dummyContent,
    renderWidth: renderWidth,
    "data-id": "dashboard_CommunityBlock_ForumWidget"
  }))));
};
export default CommunityBlock;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 40rem;\n"])));
var Blocks = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-start;\n\n  ", ";\n\n  ", ";\n"])), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    flex-direction: column;\n    align-items: center;\n  "]))), fromBreakpoint.xl(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    > *:nth-child(1) {\n      width: 32%;\n    }\n    > *:nth-child(2) {\n      width: 68%;\n    }\n  "]))));