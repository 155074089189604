import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Triangle from './shared/designElements/Triangle';
import { colorPalette, textStyles } from '../styles/styleGuide';
import { hex2rgb } from '../styles/utils';
import ImageLoader from './shared/ui/ImageLoader';
var BannerBlock = function BannerBlock(_ref) {
  var _ref$banners = _ref.banners,
    banners = _ref$banners === void 0 ? [] : _ref$banners,
    title = _ref.title,
    renderWidth = _ref.renderWidth;
  return __jsx(Container, {
    renderWidth: renderWidth,
    "data-e2e-block-bannerblock": true,
    "data-id": "components_BannerBlock_Container"
  }, renderWidth && __jsx(Triangle, {
    direction: "bottomright",
    color: hex2rgb(colorPalette.orange, 0.25),
    width: renderWidth > 1100 ? 360 : 280,
    height: renderWidth > 1100 ? 360 : 280,
    "data-id": "components_BannerBlock_Triangle"
  }), __jsx(InnerContainer, {
    "data-id": "components_BannerBlock_InnerContainer"
  }, __jsx(Title, {
    "data-id": "components_BannerBlock_Title"
  }, title), __jsx(BannersContainer, {
    renderWidth: renderWidth,
    "data-id": "components_BannerBlock_BannersContainer"
  }, banners.map(function (banner, index) {
    return __jsx(BannerContainer, {
      key: index,
      renderWidth: renderWidth,
      "data-id": "components_BannerBlock_BannerContainer"
    }, __jsx(Banner, {
      renderWidth: renderWidth,
      "data-id": "components_BannerBlock_Banner"
    }, __jsx(ImageLoader, {
      mode: "img",
      src: banner.media,
      width: renderWidth,
      "data-id": "components_BannerBlock_ImageLoader"
    }), __jsx(Text, {
      renderWidth: renderWidth,
      "data-id": "components_BannerBlock_Text"
    }, banner.leader)));
  }))));
};
export default BannerBlock;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  position: relative;\n  padding: 40rem;\n  box-sizing: border-box;\n  background-color: ", ";\n  ", ";\n  color: #fff;\n\n  > svg {\n    position: absolute;\n    bottom: ", "rem;\n    right: ", "rem;\n  }\n"])), colorPalette.lightOrange, textStyles.sectionTitle, function (props) {
  return props.renderWidth > 1100 ? '-20' : '-10';
}, function (props) {
  return props.renderWidth > 1100 ? '-20' : '-10';
});
var InnerContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  max-width: 780rem;\n"])));
var Title = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", " color: #fff;\n  margin: 0 0 30rem 0;\n  padding: 0 15rem;\n"])), textStyles.sectionTitle);
var BannersContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: ", ";\n  margin-top: -10rem;\n  justify-content: center;\n"])), function (props) {
  return props.renderWidth > 872 ? 'row' : 'column';
});
var BannerContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 10rem;\n  box-sizing: border-box;\n  width: ", ";\n  flex: 0 0 auto;\n  flex-direction: column;\n  max-width: ", "rem;\n  max-height: ", "rem;\n"])), function (props) {
  return props.renderWidth > 872 ? '33%' : '100%';
}, function (props) {
  return props.renderWidth > 872 ? '246' : '245';
}, function (props) {
  return props.renderWidth > 872 ? '246' : '270';
});
var Banner = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n  z-index: 5;\n  background-color: #fff;\n  margin-bottom: ", "rem;\n"])), function (props) {
  return props.renderWidth > 872 ? '0' : '20';
});
var Text = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  padding: 6rem 12rem;\n  margin: 5rem;\n  flex-grow: 1;\n"])), textStyles.caption, colorPalette.textGrey);