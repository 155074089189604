var __jsx = React.createElement;
import React from 'react';
var DottedVerticalLine = function DottedVerticalLine(_ref) {
  var height = _ref.height;
  return __jsx("svg", {
    width: "5",
    height: "".concat(height),
    viewBox: "0 0 3 ".concat(height),
    version: "1.1",
    "data-id": "designElements_DottedVerticalLine_svg"
  }, __jsx("line", {
    strokeDasharray: "5, 4",
    y1: "0",
    x1: "0",
    y2: "".concat(height),
    x2: "0",
    style: {
      stroke: 'white',
      strokeWidth: 2
    },
    "data-id": "designElements_DottedVerticalLine_line"
  }));
};
export default DottedVerticalLine;