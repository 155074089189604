import useParams from '@/hooks/useParams'
import { toggleSectionLoading } from '@/redux/houseDesign'
import { useSelector } from '@/redux/index'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

const useSectionLoading = () => {
  const dispatch = useDispatch()
  const { wishListId } = useParams()
  const sectionLoadingStates = useSelector((state) => {
    if (!wishListId) {
      return {}
    }
    return state.houseDesign.wishLists[wishListId].sectionLoadingStates
  })

  return useMemo(
    () => ({
      isSectionLoading: (slug?: string) => {
        if (!slug) {
          return false
        }
        return sectionLoadingStates[slug] === true
      },
      toggleSectionLoading: (loading: boolean, slug?: string) => {
        if (!wishListId || !slug) {
          return
        }
        dispatch(toggleSectionLoading(wishListId, slug, loading))
      },
      isAnySectionLoading: Object.values(sectionLoadingStates).some((isLoading) => isLoading),
    }),
    [dispatch, sectionLoadingStates, wishListId]
  )
}

export default useSectionLoading
