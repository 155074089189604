var __jsx = React.createElement;
import React from 'react';
var defaultProps = {
  color: '#ffffff',
  width: 12,
  height: 3.5
};
var Minus = function Minus(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 7 2",
    "data-id": "icons_Minus_svg"
  }, __jsx("title", {
    "data-id": "icons_Minus_title"
  }, "Minus"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-1126.000000, -14144.000000)",
    "data-id": "icons_Minus_g"
  }, __jsx("g", {
    transform: "translate(1120.000000, 14136.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_Minus_g_3"
  }, __jsx("g", {
    transform: "translate(6.000000, 8.000000)",
    "data-id": "icons_Minus_g_4"
  }, __jsx("path", {
    d: "M6.9969375,0.594671875 C6.965875,0.2603125 6.685875,0 6.344625,0 L0,0 L0,0.87390625 L0.003609375,1.16232813 C0.03784375,1.49351563 0.31653125,1.75 0.655375,1.75 L7,1.75 L7,0.84415625 L6.9969375,0.594671875 Z",
    "data-id": "icons_Minus_path"
  })))));
};
Minus.defaultProps = defaultProps;
export default Minus;