var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.background
};
var Neighbour = function Neighbour(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "130px",
    height: "128px",
    viewBox: "0 0 130 128",
    version: "1.1",
    "data-id": "icons_Neighbours_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Neighbours_g"
  }, __jsx("g", {
    transform: "translate(-259.000000, -258.000000)",
    fill: color,
    "data-id": "icons_Neighbours_g_2"
  }, __jsx("g", {
    transform: "translate(130.000000, 50.000000)",
    "data-id": "icons_Neighbours_g_3"
  }, __jsx("g", {
    transform: "translate(0.000000, 40.000000)",
    "data-id": "icons_Neighbours_g_4"
  }, __jsx("g", {
    transform: "translate(129.000000, 168.000000)",
    "data-id": "icons_Neighbours_g_5"
  }, __jsx("path", {
    d: "M27.1497237,26.4435763 C21.6197432,26.4435763 17.138495,21.9618549 17.138495,16.4323476 C17.138495,10.9028404 21.6197432,6.42159222 27.1497237,6.42159222 C32.6792309,6.42159222 37.1604791,10.9028404 37.1604791,16.4323476 C37.1604791,21.9618549 32.6792309,26.4435763 27.1497237,26.4435763 M27.1497237,0 C18.0816915,0 10.7169028,7.36431547 10.7169028,16.4323476 C10.7169028,25.5003798 18.0816915,32.8651685 27.1497237,32.8651685 C36.2172826,32.8651685 43.5820713,25.5003798 43.5820713,16.4323476 C43.5820713,7.36431547 36.2172826,0 27.1497237,0",
    "data-id": "icons_Neighbours_path"
  }), __jsx("path", {
    d: "M65.0158769,6.42159222 C70.5453045,6.42159222 75.0279079,10.9028404 75.0279079,16.4323476 C75.0279079,21.9618549 70.5453045,26.4435763 65.0158769,26.4435763 C59.485976,26.4435763 55.0052656,21.9618549 55.0052656,16.4323476 C55.0052656,10.9028404 59.5115314,6.42159222 65.0158769,6.42159222 M65.0158769,32.8651685 C74.0837785,32.8651685 81.4484612,25.5003798 81.4484612,16.4323476 C81.4484612,7.36431547 74.0837785,0 65.0158769,0 C55.9484485,0 48.5832926,7.36431547 48.5832926,16.4323476 C48.5832926,25.5003798 55.9484485,32.8651685 65.0158769,32.8651685",
    "data-id": "icons_Neighbours_path_7"
  }), __jsx("path", {
    d: "M0,51.9591531 L0,72.7301225 C0,78.7252598 3.35262914,84.1984874 8.52376864,86.9868687 L8.52376864,114.273944 C8.52376864,121.388669 14.293726,127.173913 21.3889668,127.173913 L36.0989556,127.173913 C37.8653745,127.173913 39.2953102,125.740897 39.2953102,123.968569 C39.2953102,122.19577 37.8653745,120.762754 36.0989556,120.762754 L21.3889668,120.762754 C17.8026297,120.762754 14.8916053,117.843542 14.8916053,114.247119 L14.8916053,84.9542917 C14.8916053,83.6243397 14.0600181,82.4256888 12.8126373,81.9560171 C8.96678122,80.5484141 6.39317845,76.8484557 6.39317845,72.7564769 L6.39317845,51.9591531 C6.39317845,46.5381635 10.7848223,42.1341683 16.1910776,42.1341683 L30.0952904,42.1341683 C31.8617092,42.1341683 33.2916449,40.7011522 33.2916449,38.9283535 C33.2916449,37.1555548 31.8617092,35.7230093 30.0952904,35.7230093 L16.1910776,35.7230093 C7.25104604,35.7752473 0,43.0466867 0,51.9591531",
    "data-id": "icons_Neighbours_path_8"
  }), __jsx("path", {
    d: "M42.1318919,51.938246 C42.1318919,46.5157496 46.5542383,42.1105303 51.9951274,42.1105303 L77.3207489,42.1105303 C82.7625829,42.1105303 87.1839844,46.5157496 87.1839844,51.938246 L87.1839844,72.7149891 C87.1839844,76.8085761 84.5927474,80.5100336 80.7207731,81.918028 C79.4655468,82.3878302 78.6284142,83.5868143 78.6284142,84.9161945 L78.6284142,114.270358 C78.6284142,117.867781 75.6979779,120.787804 72.0881981,120.787804 L57.2801171,120.787804 C53.6698649,120.787804 50.7394286,117.867781 50.7394286,114.270358 L50.7394286,84.9425561 C50.7394286,83.6122345 49.9027685,82.4132503 48.6470697,81.9434481 C44.774623,80.5354538 42.1843308,76.834467 42.1843308,72.7413507 L42.1843308,51.938246 L42.1318919,51.938246 Z M35.7230093,51.938246 L35.7230093,72.7149891 C35.7230093,78.7117928 39.0979951,84.1865418 44.3036178,86.9756981 L44.3036178,114.270358 C44.3036178,121.387061 50.1120516,127.173913 57.2546063,127.173913 L72.0626873,127.173913 C79.2047696,127.173913 85.0122585,121.387061 85.0122585,114.270358 L85.0122585,86.9756981 C90.2192985,84.1865418 93.5942843,78.7117928 93.5942843,72.7149891 L93.5942843,51.938246 C93.5942843,42.9959991 86.294413,35.7230093 77.3207489,35.7230093 L51.9951274,35.7230093 C42.9954801,35.7498417 35.7230093,43.0233023 35.7230093,51.938246 L35.7230093,51.938246 Z",
    "data-id": "icons_Neighbours_path_9"
  }), __jsx("path", {
    d: "M102.167807,26.4435763 C96.6383789,26.4435763 92.1571953,21.9618549 92.1571953,16.4323476 C92.1571953,10.9028404 96.6383789,6.42159222 102.167807,6.42159222 C107.697234,6.42159222 112.178418,10.9028404 112.178418,16.4323476 C112.178418,21.9618549 107.697234,26.4435763 102.167807,26.4435763 M102.167807,0 C93.0999049,0 85.7352223,7.36431547 85.7352223,16.4323476 C85.7352223,25.5003798 93.0999049,32.8651685 102.167807,32.8651685 C111.235708,32.8651685 118.600391,25.5003798 118.600391,16.4323476 C118.600391,7.36431547 111.235708,0 102.167807,0",
    "data-id": "icons_Neighbours_path_10"
  }), __jsx("path", {
    d: "M129.317294,51.9591531 C129.317294,43.0466867 122.066248,35.7752473 113.126216,35.7230093 L99.2215339,35.7230093 C97.4551151,35.7230093 96.0256487,37.1555548 96.0256487,38.9283535 C96.0256487,40.7011522 97.4551151,42.1341683 99.2215339,42.1341683 L113.126216,42.1341683 C118.532471,42.1341683 122.924115,46.5381635 122.924115,51.9591531 L122.924115,72.7564769 C122.924115,76.8484557 120.350512,80.5484141 116.504187,81.9560171 C115.256806,82.4256888 114.425688,83.6243397 114.425688,84.9542917 L114.425688,114.247119 C114.425688,117.843542 111.514195,120.762754 107.928327,120.762754 L93.218338,120.762754 C91.4514498,120.762754 90.0219834,122.19577 90.0219834,123.968569 C90.0219834,125.740897 91.4514498,127.173913 93.218338,127.173913 L107.928327,127.173913 C115.023098,127.173913 120.793525,121.388669 120.793525,114.273944 L120.793525,86.9868687 C125.964195,84.1984874 129.317294,78.7252598 129.317294,72.7301225 L129.317294,51.9591531 Z",
    "data-id": "icons_Neighbours_path_11"
  })))))));
};
Neighbour.defaultProps = defaultProps;
export default Neighbour;