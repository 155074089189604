import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import StorySlider from './StorySlider';
import CTASecondary from './CTASecondary';
var ComboBlock = function ComboBlock(_ref) {
  var blocks = _ref.blocks,
    renderWidth = _ref.renderWidth;
  var mediumView = renderWidth >= 800 && renderWidth < 1080;
  var largeView = renderWidth >= 1080;
  return __jsx(ComboWrapper, {
    largeView: largeView,
    "data-e2e-block-comboblock": true,
    "data-id": "components_ComboBlock_ComboWrapper"
  }, __jsx(SizeTwoColumn, {
    mediumView: mediumView,
    largeView: largeView,
    "data-id": "components_ComboBlock_SizeTwoColumn"
  }, __jsx(StorySlider, _extends({}, blocks[0], {
    "data-id": "components_ComboBlock_StorySlider"
  }))), __jsx(SizeOneColumn, {
    mediumView: mediumView,
    largeView: largeView,
    "data-id": "components_ComboBlock_SizeOneColumn"
  }, __jsx(CTASecondary, _extends({
    comboMode: true,
    renderWidth: renderWidth
  }, blocks[1], {
    "data-id": "components_ComboBlock_CTASecondary"
  }))));
};
export default ComboBlock;
var ComboWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 50rem;\n  justify-content: space-between;\n  display: flex;\n  ", ";\n"])), function (props) {
  return !props.largeView ? "flex-wrap: wrap;" : '';
});
var SizeTwoColumn = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 60rem;\n\n  > div {\n    padding: 0;\n  }\n\n  ", " ", ";\n"])), function (props) {
  return props.mediumView ? "width: calc((1/2 * 100%) - 10rem);\n      margin-bottom: 0rem;\n    " : '';
}, function (props) {
  return props.largeView ? "width: calc((2/3 * 100%) - 10rem);\n    margin-bottom: 0rem;\n    " : '';
});
var SizeOneColumn = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n\n  ", " ", "\n\n  > *, > * > * {\n    height: 100%;\n  }\n"])), function (props) {
  return props.mediumView ? "width: calc((1/2 * 100%) - 10rem);" : '';
}, function (props) {
  return props.largeView ? "width: calc((1/3 * 100%) - 10rem);" : '';
});