import React, { FC } from 'react'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import { ICON_COLORS } from '@/constants/colors'
import Svg from './Svg'

const CloseIcon: FC<IconProps> = ({ width = 16, height, variant = 'brand' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 16 16"
    >
      <path
        fill={ICON_COLORS[variant]}
        fillRule="evenodd"
        d="M11.2,8l3.669,3.669a1.6,1.6,0,0,1,0,2.263L12.8,16,8,11.2,4.331,14.869a1.6,1.6,0,0,1-2.263,0L0,12.8,4.8,8,1.13,4.331a1.6,1.6,0,0,1,0-2.263L3.2,0,8,4.8,11.669,1.13a1.6,1.6,0,0,1,2.263,0L16,3.2Z"
      />
    </Svg>
  )
}

export default CloseIcon
