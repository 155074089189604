var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange,
  width: 64,
  height: 64
};
var MyHouse = function MyHouse(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 64 64",
    "data-id": "icons_MyHouse_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_MyHouse_g"
  }, __jsx("g", {
    transform: "translate(-488.000000, -480.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_MyHouse_g_2"
  }, __jsx("g", {
    transform: "translate(488.000000, 480.000000)",
    "data-id": "icons_MyHouse_g_3"
  }, __jsx("path", {
    d: "M13.6,58.9 L13.6,19.6 L32,6.4 L50.4,19.5 L50.4,55.7 C50.4,57.5 49.1,58.8 47.3,58.8 L13.6,58.8 L13.6,58.9 Z M62.9,22.2 L33.5,1.2 C33.5,1.2 33.5,1.2 33.5,1.2 L32,0.1 L30.5,1.2 L19.3,9.1 L19.3,0 L14.2,0 L14.2,12.8 L8.4,16.9 L8.4,17 L1.1,22.2 C-0.1,23 -0.3,24.6 0.5,25.8 C1,26.5 1.8,26.9 2.6,26.9 C3.1,26.9 3.6,26.7 4.1,26.4 L8.4,23.3 L8.4,64 L46.7,64 L49.1,64 L49.4,64 C52.2,64 54.6,62.1 55.4,59.5 C55.5,59.1 55.6,58.6 55.7,58.2 L55.7,35.4 L55.7,23.3 L60,26.4 C60.5,26.7 61,26.9 61.5,26.9 C62.3,26.9 63.1,26.5 63.6,25.8 C64.3,24.6 64.1,23 62.9,22.2 Z",
    "data-id": "icons_MyHouse_path"
  })))));
};
MyHouse.defaultProps = defaultProps;
export default MyHouse;