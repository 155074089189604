import styled, { css } from 'styled-components'
import Title from '@/atoms/Title'
import Price from '@/atoms/Price'
import Box, { BoxProps } from '@/atoms/Box'
import SquareMeters from '@/atoms/SquareMeters'
import TrashcanIcon from '@/atoms/svg/TrashcanIcon'
import IconButton from '@/atoms/IconButton'
import { fromBreakpoint } from '@/styles/breakpoints'
import EditIcon from '@/atoms/svg/EditIcon'

type Props = {
  title: string
  price: number
  livingSurface?: number
  disabled?: boolean
  postDeadlineNotSelected?: boolean
  onEdit?: () => void
  onDelete?: () => void
  small?: boolean
  selected?: boolean
} & BoxProps

const OptionTitle = styled(Box)`
  ${fromBreakpoint.lg`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16rem;
  `}
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 16rem;
`

const Input = styled.div`
  flex-shrink: 0;
  width: 24rem;
`

const Properties = styled.div<{ small: boolean }>`
  display: flex;
  align-items: center;
  gap: 16rem;
  padding-left: 40rem;
  margin-top: 8rem;
  justify-content: flex-end;
  flex-flow: row-reverse nowrap;
  ${({ small }) =>
    small &&
    css`
      flex-direction: row;
      justify-content: flex-start;
    `}
  ${fromBreakpoint.lg`
    padding-left: 0;
    margin-top: 0;
    flex-direction: row;
  `}
`

const Buttons = styled.div`
  display: flex;
  gap: 8rem;
`

const getColor = (postDeadlineNotSelected: boolean, disabled: boolean) => {
  if (postDeadlineNotSelected) {
    return 'gray30'
  }
  if (disabled) {
    return 'gray25'
  }
  return undefined
}

const OptionTitleLine = ({
  children,
  title,
  price,
  livingSurface = 0,
  disabled = false,
  postDeadlineNotSelected = false,
  small = false,
  onEdit,
  onDelete,
  selected = false,
  ...boxProps
}: Props) => {
  return (
    <OptionTitle {...boxProps}>
      <Item>
        {!!children && <Input>{children}</Input>}
        <Title
          level={3}
          size={small ? 'xs' : 'sm'}
          text={title}
          variant={getColor(postDeadlineNotSelected, disabled)}
        />
      </Item>
      <Properties small={small}>
        {livingSurface > 0 && (
          <SquareMeters
            squareMeters={livingSurface}
            withPlus
            variant={getColor(postDeadlineNotSelected, disabled)}
          />
        )}
        <Price
          price={price}
          sign="plus"
          variant={getColor(postDeadlineNotSelected, disabled)}
          displayZero
          strikeThrough={selected && disabled}
        />
        {(!!onEdit || !!onDelete) && (
          <Buttons>
            {!!onEdit && (
              <IconButton
                icon={EditIcon}
                onClick={onEdit}
                size="sm"
              />
            )}
            {!!onDelete && (
              <IconButton
                icon={TrashcanIcon}
                onClick={onDelete}
                size="sm"
              />
            )}
          </Buttons>
        )}
      </Properties>
    </OptionTitle>
  )
}

export default OptionTitleLine
