var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var PlanningEvent = function PlanningEvent(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "18px",
    height: "18px",
    viewBox: "0 0 18 18",
    version: "1.1",
    "data-id": "icons_PlanningEvent_svg"
  }, __jsx("title", {
    "data-id": "icons_PlanningEvent_title"
  }, "Planning Event"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlanningEvent_g"
  }, __jsx("g", {
    transform: "translate(-543.000000, -363.000000)",
    fill: color,
    "data-id": "icons_PlanningEvent_g_3"
  }, __jsx("g", {
    transform: "translate(534.000000, 354.000000)",
    "data-id": "icons_PlanningEvent_g_4"
  }, __jsx("g", {
    transform: "translate(9.000000, 9.000000)",
    "data-id": "icons_PlanningEvent_g_5"
  }, __jsx("path", {
    d: "M6.5946825,11.249945 L6.3267575,12.84192 C6.2905325,13.060145 6.3792575,13.278895 6.5577575,13.409445 C6.7360825,13.538595 6.9733825,13.55627 7.1681575,13.453895 L8.6003575,12.70647 L10.0318575,13.453895 C10.1165575,13.49817 10.2094825,13.520045 10.3011825,13.520045 C10.4214075,13.520045 10.5411075,13.482595 10.6422575,13.409445 C10.8205825,13.27942 10.9101825,13.060145 10.8732575,12.84192 L10.6060325,11.249945 L11.7585825,10.11927 C11.9164325,9.96457 11.9724325,9.734445 11.9048825,9.524445 C11.8371575,9.314445 11.6553325,9.16132 11.4372825,9.12947 L9.8397075,8.891995 L9.1199325,7.44632 C9.0228075,7.248745 8.8205075,7.12362 8.5996575,7.12362 C8.3795075,7.12362 8.1779075,7.248745 8.0793825,7.44632 L7.3603075,8.891995 L5.7634325,9.12947 C5.5446825,9.16132 5.3635575,9.314445 5.2958325,9.524445 C5.2274075,9.734445 5.2835825,9.96457 5.4414325,10.11927 L6.5946825,11.249945 Z",
    "data-id": "icons_PlanningEvent_path"
  }), __jsx("path", {
    d: "M1.961925,15.512 L15.23865,15.512 L15.23865,6.057275 L1.961925,6.057275 L1.961925,15.512 Z M15.465625,1.857275 L13.63635,1.857275 L13.63635,0.8428 C13.63635,0.3773 13.25905,0 12.79285,0 L12.6595,0 C12.194,0 11.8167,0.3773 11.8167,0.8428 L11.8167,1.857275 L5.362875,1.857275 L5.362875,0.8428 C5.362875,0.3773 4.985575,0 4.5199,0 L4.386025,0 C3.920525,0 3.54305,0.3773 3.54305,0.8428 L3.54305,1.857275 L1.73495,1.857275 C0.778575,1.857275 0,2.63585 0,3.592925 L0,15.738975 C0,16.69535 0.778575,17.473925 1.73495,17.473925 L15.465625,17.473925 C16.422,17.473925 17.200575,16.69535 17.200575,15.738975 L17.200575,3.592925 C17.200575,2.63585 16.422,1.857275 15.465625,1.857275 L15.465625,1.857275 Z",
    "data-id": "icons_PlanningEvent_path_7"
  }))))));
};
PlanningEvent.defaultProps = defaultProps;
export default PlanningEvent;