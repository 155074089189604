import { FC } from 'react'
import { colors } from '@/styles/colors'
import styled, { css } from 'styled-components'
import { fontStyles } from '@/styles/fonts'
import { IconProps } from '@/types/svgTypes'
import { transitions } from '@/styles/shared'
import shadows from '@/styles/shadows'
import Link from '@/atoms/Link'

type Variant = 'primary' | 'secondary' | 'white' | 'outlined' | 'outlined-inverted'
type Size = 'sm' | 'md'

type Props = {
  onClick?: () => void
  href?: string
  text: string
  icon?: FC<IconProps>
  iconBefore?: FC<IconProps>
  disabled?: boolean
  variant?: Variant
  shadow?: boolean
  fullWidth?: boolean
  centerText?: boolean
  size?: Size
}

type ButtonContainerProps = {
  disabled: boolean
  variant: Variant
  shadow: boolean
  fullWidth: boolean
  centerText: boolean
  size: Size
}

const getBackgroundColor = (
  { disabled, variant }: { disabled: boolean; variant: Variant },
  hover = false
) => {
  if (variant === 'outlined' || variant === 'outlined-inverted') {
    return 'transparent'
  }
  if (disabled) {
    return colors.gray20
  }
  if (variant === 'secondary') {
    return hover ? colors.brand : colors.gray40
  }
  if (variant === 'white') {
    return hover ? colors.brand : colors.white
  }
  return hover ? colors.white : colors.brand
}

const getColor = (
  { disabled, variant }: { disabled: boolean; variant: Variant },
  hover = false
) => {
  if (disabled || variant === 'secondary') {
    return colors.white
  }
  if (variant === 'white') {
    return hover ? colors.white : colors.brand
  }
  if (variant === 'outlined') {
    return hover ? colors.gray40 : colors.brand
  }
  if (variant === 'outlined-inverted') {
    return hover ? colors.brand : colors.gray10
  }
  return hover ? colors.brand : colors.white
}

const getBorderColor = (
  { disabled, variant }: { disabled: boolean; variant: Variant },
  hover = false
) => {
  if (variant === 'outlined' || variant === 'outlined-inverted') {
    return getColor({ disabled, variant }, hover)
  }
  if (variant === 'primary' && hover && !disabled) {
    return colors.brand
  }
  return getBackgroundColor({ disabled, variant }, hover)
}

const styles = ({ centerText, fullWidth, shadow, size, ...props }: ButtonContainerProps) => css`
  box-sizing: border-box;
  ${fontStyles.button.md}
  background-color: ${getBackgroundColor(props)};
  color: ${getColor(props)};
  border: 1rem solid ${getBorderColor(props)};
  border-radius: 8rem 0;
  ${size === 'sm' ? `padding: 6.5rem 11rem 7.5rem;` : `padding: 10.5rem 15rem 11.5rem;`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
  text-decoration: none;
  ${fullWidth ? `width: 100%;` : ``}
  ${centerText ? `text-align: center; display:block;` : ``}
  ${props.disabled ? `cursor: default;` : `cursor: pointer;`}
  ${transitions.hover}
  ${shadow ? shadows.lg : ``}

  path {
    fill: ${getColor(props)};
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${getBackgroundColor(props, true)};
    color: ${getColor(props, true)};
    border-color: ${getBorderColor(props, true)};

    path {
      fill: ${getColor(props, true)};
    }
  }
`
const ButtonAsButton = styled.button`
  ${styles}
`
const ButtonAsDiv = styled.div`
  ${styles}
`
const ButtonAsLink = styled(Link)`
  ${styles}
`

const Button: FC<Props> = ({
  onClick,
  href,
  text,
  disabled = false,
  variant = 'primary',
  icon: Icon,
  iconBefore: IconBefore,
  shadow = false,
  fullWidth = true,
  centerText = false,
  size = 'md',
}) => {
  const iconHeight = size === 'sm' ? 10 : 12
  const icon =
    Icon && !centerText ? (
      <Icon
        height={iconHeight}
        variant="white"
      />
    ) : null
  const iconBefore =
    IconBefore && !centerText ? (
      <IconBefore
        height={iconHeight}
        variant="white"
      />
    ) : null

  const props = {
    disabled,
    variant,
    shadow,
    fullWidth,
    centerText,
    size,
  }

  if (href) {
    if (disabled) {
      return (
        <ButtonAsDiv {...props}>
          {iconBefore}
          {text}
          {icon}
        </ButtonAsDiv>
      )
    }

    return (
      <ButtonAsLink
        {...props}
        href={href}
      >
        {iconBefore}
        {text}
        {icon}
      </ButtonAsLink>
    )
  }

  return (
    <ButtonAsButton
      {...props}
      onClick={!disabled ? onClick : undefined}
    >
      {text}
      {icon}
    </ButtonAsButton>
  )
}

export default Button
