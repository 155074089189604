import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';

// Services
import formatCategoriesToOptionNotifications from '../services/formatOptionsToNotifications';
import OptionNotifications from './homestudio/OptionNotifications';
import ImageLoader from './shared/ui/ImageLoader';

// Styles
import { fromBreakpoint, toBreakpoint } from '../styles/breakpoints';
import { colorPalette, fonts, textStyles } from '../styles/styleGuide';
var getNotificationsFromCategories = function getNotificationsFromCategories(categories) {
  return formatCategoriesToOptionNotifications(categories);
};
var MyHouseBase = function MyHouseBase(_ref) {
  var house = _ref.house,
    houseType = _ref.houseType,
    categories = _ref.categories;
  var notifications = getNotificationsFromCategories(categories);
  var hasNotifications = notifications.newOpts.length > 0 || notifications.ordersToConfirm.length > 0;
  return house && houseType ? __jsx(MyHouseBaseContainer, {
    "data-id": "components_MyHouseBase_MyHouseBaseContainer"
  }, __jsx(Block, {
    hasNotifications: hasNotifications,
    "data-id": "components_MyHouseBase_Block"
  }, __jsx(Address, {
    "data-id": "components_MyHouseBase_Address"
  }, house.address ? __jsx(Fragment, null, house.address.street, " ", house.address.houseNumber, " ", house.address.houseNumberAddition, __jsx("br", {
    "data-id": "components_MyHouseBase_br"
  }), house.address.zipcode, " ", house.address.city) : 'Adres nog onbekend')), hasNotifications && __jsx(OptionNotifications, {
    notifications: notifications,
    "data-id": "components_MyHouseBase_OptionNotifications"
  }), __jsx(Info, {
    "data-id": "components_MyHouseBase_Info"
  }, __jsx(HouseInfo, {
    "data-id": "components_MyHouseBase_HouseInfo"
  }, house.address ? __jsx("div", {
    "data-id": "components_MyHouseBase_div"
  }, __jsx("p", {
    "data-id": "components_MyHouseBase_p"
  }, house.address.street, " ", house.address.houseNumber, ' ', house.address.houseNumberAddition), __jsx("p", {
    "data-id": "components_MyHouseBase_p_9"
  }, house.address.zipcode, " ", house.address.city)) : __jsx("div", {
    "data-id": "components_MyHouseBase_div"
  }, __jsx("p", {
    "data-id": "components_MyHouseBase_p"
  }, "Adres nog onbekend")), __jsx("p", {
    "data-id": "components_MyHouseBase_p_12"
  }, "Bouwnummer: ".concat(house.bnr)), __jsx("p", {
    "data-id": "components_MyHouseBase_p_13"
  }, "Variant: ".concat(houseType.typeName, " - ").concat(houseType.variantName, " ").concat(house.variantCode)), house.surface && __jsx("p", {
    "data-id": "components_MyHouseBase_p_14"
  }, "Kaveloppervlak: ".concat(house.surface, " m\xB2")), houseType.energyLabel && __jsx("p", {
    "data-id": "components_MyHouseBase_p_15"
  }, "Energielabel: ".concat(houseType.energyLabel)), houseType.bedrooms && __jsx("p", {
    "data-id": "components_MyHouseBase_p_16"
  }, "Slaapkamers: ".concat(houseType.bedrooms))), __jsx(ImageContainer, {
    "data-id": "components_MyHouseBase_ImageContainer"
  }, __jsx(ImageLoader, {
    src: houseType.media[0].image.src,
    mode: "bg",
    alt: "jouw woning",
    "data-id": "components_MyHouseBase_ImageLoader"
  })))) : null;
};
export default MyHouseBase;
var MyHouseBaseContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 980rem;\n  width: 100%;\n  position: relative;\n  background-color: white;\n  margin: 0 auto;\n\n  ", ";\n"])), fromBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    width: calc(100% - 98rem);\n  "]))));
var Block = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 20rem 20rem ", ";\n\n  box-sizing: border-box;\n\n  ", ";\n\n  ", ";\n"])), function (props) {
  return props.hasNotifications ? "8rem" : "20rem";
}, fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    padding: 30rem 40rem ", ";\n  "])), function (props) {
  return props.hasNotifications ? "8rem" : "30rem";
}), fromBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    padding: 60rem 120rem ", " 100rem;\n  "])), function (props) {
  return props.hasNotifications ? "8rem" : "40rem";
}));
var Address = styled.h2(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 32rem;\n  line-height: 38rem;\n  color: ", ";\n  margin: 0;\n\n  ", ";\n\n  > p {\n    margin: 0;\n  }\n"])), fonts.primary, colorPalette.orange, fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    font-size: 48rem;\n    line-height: 56rem;\n  "]))));
var Info = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 90%;\n  display: flex;\n  flex-direction: column;\n  margin: 0 20rem 20rem;\n  box-sizing: border-box;\n  border-top: 1rem dashed ", ";\n\n  ", ";\n\n  ", ";\n"])), colorPalette.lightBeige, fromBreakpoint.md(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    width: 100%;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 40rem;\n  "]))), fromBreakpoint.lg(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    width: 100%;\n    transform: translateX(98rem);\n  "]))));
var HouseInfo = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  display: flex;\n  order: 1;\n  margin-top: 20rem;\n  flex-direction: column;\n\n  > div {\n    margin-bottom: 12rem;\n\n    > p {\n      font-size: 16rem;\n      margin-bottom: 0;\n      margin-top: 5rem;\n    }\n  }\n\n  > p {\n    margin-bottom: 0;\n    margin-top: 8rem;\n  }\n\n  ", ";\n"])), textStyles.caption, colorPalette.textGrey, toBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    max-width: 250rem;\n  "]))));
var ImageContainer = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  width: 100%;\n  overflow: hidden;\n  margin-top: 20rem;\n  order: 0;\n  height: 160rem;\n\n  ", ";\n\n  ", ";\n\n  > div {\n    transition: transform 0.45s ease-in-out;\n    transform: scale(1);\n\n    &:hover {\n      transform: scale(1.04);\n    }\n  }\n"])), fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    width: 50%;\n    order: 2;\n  "]))), fromBreakpoint.lg(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    height: 230rem;\n  "]))));