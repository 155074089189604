import styled from 'styled-components'
import { SIZE_MAP, Sizes } from '@/styles/spacing'
import Box, { BoxProps } from '@/atoms/Box'

type Props = BoxProps & { spacing?: Sizes }

const Container = styled(Box)<{ spacing: Sizes }>`
  display: flex;
  flex-direction: column;
  gap: ${({ spacing }) => SIZE_MAP[spacing]}rem;
`

const TextBox = ({ spacing = 'lg', ...props }: Props) => (
  <Container
    spacing={spacing}
    {...props}
  />
)

export default TextBox
