import { captureException } from '@sentry/nextjs'

const LOCALE = 'nl-NL'

/**
 * Takes ISO 8601 datestring and considers it expired if it is after "now"
 */
export const isDateExpired = (isoString?: string) => {
  try {
    if (!isoString) {
      return true
    }
    const date = new Date(isoString)

    return date.getTime() < Date.now()
  } catch (error) {
    captureException('isDateExpired failed', { extra: { isoString, error } })

    return false
  }
}

/**
 * Takes ISO 8601 datestring and formats as date, no time, with full month, e.g. 1 januari 1970
 */
export const formatDateWithFullMonth = (isoString: string) => {
  try {
    const date = new Date(isoString)

    return new Intl.DateTimeFormat(LOCALE, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date)
  } catch (error) {
    captureException('formatDateWithFullMonth failed', { extra: { isoString, error } })

    return isoString
  }
}

/**
 * Takes ISO 8601 datestring and formats as date, no time, with full month, e.g. 1 januari 1970
 */
export const formatShortDate = (isoString: string) => {
  try {
    const date = new Date(isoString.replace(' ', 'T'))

    return new Intl.DateTimeFormat(LOCALE, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }).format(date)
  } catch (error) {
    captureException('formatShortDate failed', { extra: { isoString, error } })

    return ''
  }
}
