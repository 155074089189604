import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const ElectronicsIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50722)">
        <path
          d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0812 21 18.9984 21H0V6.277L10.5 0Z"
          fill={fillColor}
        />
        <path
          d="M9.02347 10.2205C9.25316 10.0891 9.51566 9.99049 9.77816 9.92476V8.51161H10.7297C11.0907 8.51161 11.386 8.80739 11.386 9.16889V9.99049C11.5828 10.0562 11.7797 10.1548 11.9766 10.2534L13.0594 9.16889L13.7157 9.82617C13.9782 10.0891 13.9782 10.4834 13.7157 10.7464L13.0594 11.4036C13.1578 11.568 13.2235 11.7651 13.2891 11.9623H14.8641V12.9154C14.8641 13.2769 14.5688 13.5726 14.2078 13.5726H13.2891C13.2235 13.7698 13.1578 13.9341 13.0594 14.1313L14.0438 15.1172L13.3875 15.7745C13.125 16.0374 12.7313 16.0374 12.4688 15.7745L11.9766 15.2816C11.7797 15.3802 11.6157 15.4787 11.386 15.5445V17.0562H10.4672C10.1063 17.0562 9.81097 16.7604 9.81097 16.3989V15.6431C9.58129 15.5773 9.3516 15.5116 9.12191 15.3802L8.07191 16.4318L7.41566 15.7745C7.15316 15.5116 7.15316 15.1172 7.41566 14.8543L7.97347 14.2957C7.84222 14.0656 7.74379 13.8356 7.67816 13.5726H6.2016V12.6196C6.2016 12.2581 6.49691 11.9623 6.85785 11.9623H7.64535C7.71097 11.7323 7.80941 11.5351 7.90785 11.3379L6.9891 10.4177L7.64535 9.76044C7.90785 9.49753 8.3016 9.49753 8.5641 9.76044L9.02347 10.2205ZM10.4672 14.1313C11.1891 14.1313 11.8125 13.5398 11.8125 12.7839C11.8125 12.028 11.2219 11.4365 10.4672 11.4365C9.71254 11.4365 9.12191 12.028 9.12191 12.7839C9.12191 13.5398 9.74535 14.1313 10.4672 14.1313Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50722">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default ElectronicsIcon
