import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
/* eslint-disable no-useless-computed-key */
/*
  PLEASE NOTE:
  The ProfileForm.js component styles certain form elements in this definition based on their class name.
  So if you decide to make changes to this form definition file, please check the ProfileForm component,
  and the styling of the profile form in the frontend to make sure everything still looks okay.

*/
import formatPhoneNumbersToString from '../../../services/formatPhoneNumbersToBBCodeString';
import formatAddressElementsToString from '../../../services/formatAddressElementsToBBCodeString';
export default (function (link, _ref) {
  var address = _ref.address,
    addressHousenumber = _ref.addressHousenumber,
    addressHousenumberAddition = _ref.addressHousenumberAddition,
    postalCode = _ref.postalCode,
    city = _ref.city,
    phone = _ref.phone,
    mobile = _ref.mobile,
    users = _ref.users;
  var phoneString = formatPhoneNumbersToString(phone, mobile);
  var addressString = formatAddressElementsToString(address, addressHousenumber, addressHousenumberAddition, postalCode, city);
  return [{
    categoryTitle: 'Huishouden',
    categoryDescription: "Met je huishouden communiceer je direct met de homestudios Woonadviseur, kun je jezelf alvast voorstellen aan je toekomstige buren en kun je meepraten op het forum. Blijf je liever nog even anoniem? Vink dan de optie 'Houd mijn huishouden anoniem' aan. Je bouwnummer is dan het enige dat zichtbaar is voor anderen.",
    options: [{
      id: 'customerName',
      name: 'customerName',
      label: 'Naam van het huishouden',
      required: true,
      maxLength: 60,
      title: 'Naam van het huishouden',
      type: 'text'
    }, {
      id: 'customerImage',
      name: 'customerImage',
      label: 'Foto van het huishouden',
      title: 'Foto van het huishouden',
      type: 'imageUpload',
      required: false,
      excludeFromSubmit: true,
      uploadType: 'customer'
    }, {
      id: 'bio',
      name: 'bio',
      label: 'Introductie van het huishouden',
      required: false,
      title: 'Introductie van het huishouden',
      type: 'textarea'
    }, {
      id: 'anonymous',
      name: 'anonymous',
      label: 'Houd mijn huishouden anoniem',
      required: false,
      title: '',
      type: 'checkbox',
      choices: [{
        key: 'anonymous',
        value: 'Houd mijn huishouden anoniem'
      }]
    }, {
      id: 'familyMakeup',
      type: 'readOnly',
      name: 'familyMakeup',
      title: '',
      label: "[h4]Het huishouden van...[/h4][p]Hieronder tonen we alle homestudios accounts die toegang hebben tot het dashboard. Zie je iets dat niet klopt? Neem dan contact op met je woonadviseur.[/p]",
      excludeFromSubmit: true
    }, {
      id: 'users',
      type: 'users',
      name: 'users',
      title: '',
      excludeFromSubmit: true,
      link: {
        link: link,
        label: 'Huishouden wijzigen'
      }
    }]
  }, {
    categoryTitle: 'Communicatie met Homestudios',
    categoryDescription: "",
    options: [{
      id: 'contactInformation',
      name: 'contactInformation',
      type: 'readOnly',
      title: '',
      label: "\n            [p]Je woonadviseur van Homestudios neemt op verschillende momenten tijdens het proces contact met je op. Hiervoor gebruiken ze onderstaande gegevens. Zijn je contactgegevens gewijzigd of wil je ze graag aanpassen? [pagenode=\"0\" type=\"link\"]Stuur dan een bericht[/pagenode] of neem contact op met je woonadviseur.[/p]\n            [p][strong]Adresgegevens[/strong][br][/br]".concat(addressString, "[/p]\n            [p][strong]E-mailadressen[/strong][br][/br]").concat((users || []).map(function (user) {
        return "".concat(user.email);
      }).join('[br][/br]'), "[/p]\n            [p][strong]Telefoongegevens[/strong][br][/br]").concat(phoneString, "[/p]\n            [p][strong][pagenode=\"0\" type=\"link\"]Contactgegevens wijzigen[/pagenode][/strong][/p]\n            "),
      pages: _defineProperty({}, 0, link || {}),
      excludeFromSubmit: true
    }]
  }];
});