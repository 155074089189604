import InputNumber from '@/inputs/InputNumber'
import styled from 'styled-components'
import CounterButton from '@/atoms/CounterButton'
import MinusIcon from '@/svg/MinusIcon'
import PlusIcon from '@/svg/PlusIcon'

type Props = {
  value: number
  max?: number
  min?: number
  disabled?: boolean
  onChange: (n: number) => void
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8rem;
`

const InputContainer = styled.div`
  width: 40rem;
`

const InputCounter = ({ value, min = 0, max, disabled = false, onChange }: Props) => {
  return (
    <Wrapper>
      <CounterButton
        disabled={value <= min || disabled}
        onClick={() => onChange(value - 1)}
        icon={MinusIcon}
      />
      <InputContainer>
        <InputNumber
          value={value}
          min={min}
          onBlur={onChange}
          disabled={disabled}
          centered
        />
      </InputContainer>
      <CounterButton
        disabled={(!!max && value >= max) || disabled}
        onClick={() => onChange(value + 1)}
        icon={PlusIcon}
      />
    </Wrapper>
  )
}

export default InputCounter
