import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { GridWrapper, GridItem } from '../../../styles/gridElements';
import { colorPalette, shadows, textStyles } from '../../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../../styles/breakpoints';
import Hero from '../../HeroHome';
import Block from '../../Block';
import isLastItem from '../../../services/isLastItem';
var LayoutLogin = function LayoutLogin(_ref) {
  var page = _ref.page,
    renderWidth = _ref.renderWidth;
  var templateProps = page.templateProps;
  return __jsx("div", {
    "data-e2e-page-layout": "login",
    "data-id": "layout_LayoutLogin_div"
  }, __jsx(HeroContainer, {
    "data-id": "layout_LayoutLogin_HeroContainer"
  }, templateProps && __jsx(Hero, {
    hero: templateProps.hero,
    heroTriangleSmallColor: colorPalette.lightBeige,
    heroTriangleLargeColor: colorPalette.beige,
    renderWidth: renderWidth,
    "data-id": "layout_LayoutLogin_Hero"
  }), __jsx(TextContainer, {
    "data-id": "layout_LayoutLogin_TextContainer"
  }, templateProps && templateProps.title && __jsx(Title, {
    "data-id": "layout_LayoutLogin_Title"
  }, templateProps.title), templateProps && templateProps.subtitle && __jsx(SubTitle, {
    "data-id": "layout_LayoutLogin_SubTitle"
  }, templateProps.subtitle))), __jsx(LoginGridWrapper, {
    "data-id": "layout_LayoutLogin_LoginGridWrapper"
  }, (page.blocks || []).map(function (block, index) {
    return __jsx(CopyCell, {
      key: index,
      "data-id": "layout_LayoutLogin_CopyCell"
    }, __jsx(Block, {
      block: block,
      renderWidth: renderWidth,
      isLastItem: isLastItem(page.blocks, index),
      "data-id": "layout_LayoutLogin_Block"
    }));
  })));
};
var HeroContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n"])));
var LoginGridWrapper = styled(GridWrapper)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  z-index: 2;\n  margin-top: -60rem;\n  margin-bottom: 20rem;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    margin-top: 80rem;\n  "]))), fromBreakpoint.lg(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-top: 50rem;\n  "]))), fromBreakpoint.xl(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-top: 0;\n  "]))));
var TextContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 10%;\n  left: 8%;\n  z-index: 5;\n  margin-right: 20rem;\n  max-width: 780rem;\n  margin-top: 60rem;\n\n  ", " ", ";\n"])), toBreakpoint.lg(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    margin-top: 20rem;\n  "]))), toBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    left: 5%;\n  "]))));
var Title = styled.h1(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  color: #fff;\n"])), textStyles.pageTitle, shadows.textPrimary);
var SubTitle = styled.p(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", " ", ";\n  color: #fff;\n"])), textStyles.title, shadows.textPrimary);
var CopyCell = styled(GridItem)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  margin-bottom: 20rem;\n\n  ", "\n\n  ", "\n\n  ", "\n\n\n"])), toBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    margin-top: 40rem;\n  "]))), fromBreakpoint.lg(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    padding-left: 100rem;\n    padding-right: 100rem;\n  "]))), fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    margin-top: -190rem;\n    margin-left: 20rem;\n    margin-right: 20rem;\n  "]))));
export default LayoutLogin;