import { useCallback, useEffect, useRef, useState } from 'react'
import { breakpointSizesInEm, BreakpointSize } from '@/styles/breakpoints'

export type BreakpointIsLargerThan = Record<BreakpointSize, boolean>

export type BreakpointInfo = {
  isLargerThan: BreakpointIsLargerThan
  isHighResolution: boolean
}

/**
 * Whether a the min-width requirement for a BreakpointSize is met
 */
const useBreakpointInfo = (): BreakpointInfo => {
  const isHighResolutionRef = useRef(false)
  const [breakpointInfo, setBreakpointInfo] = useState<BreakpointIsLargerThan>({
    [BreakpointSize.xxl]: false,
    [BreakpointSize.xl]: false,
    [BreakpointSize.slg]: false,
    [BreakpointSize.lg]: false,
    [BreakpointSize.md]: false,
    [BreakpointSize.sm]: true,
  })

  const updateBreakpoint = useCallback(() => {
    const result: Partial<BreakpointIsLargerThan> = {}
    const entries = Object.entries(breakpointSizesInEm) as [BreakpointSize, number][]
    entries.forEach(([key, value]) => {
      result[key] = window.matchMedia(`(min-width: ${value}em)`).matches
    })
    setBreakpointInfo(result as BreakpointIsLargerThan)
  }, [])

  useEffect(() => {
    isHighResolutionRef.current = window.matchMedia(
      '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
    ).matches
    updateBreakpoint()
    window.addEventListener('resize', updateBreakpoint)

    return () => window.removeEventListener('resize', updateBreakpoint)
  }, [updateBreakpoint])

  return { isLargerThan: breakpointInfo, isHighResolution: isHighResolutionRef.current }
}

export default useBreakpointInfo
