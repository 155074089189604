import BoxIcon from '@/atoms/BoxIcon'
import { FC } from 'react'
import styled from 'styled-components'
import UnionIcon from '@/svg/UnionIcon'
import CircularImage from '@/atoms/CircularImage'
import Container from '@/atoms/Container'
import Text from '@/atoms/Text'
import useOpenLegacyModal from '@/hooks/useOpenLegacyModal'

type Props = {
  imageUrl?: string
  text: string
  modalId?: number
}

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16rem;
  width: 100%;
`

const Wrapper = styled.div`
  margin-left: 40rem;
`

const CircularImageBox = styled.div`
  margin-left: -56rem;
  flex: 0 0 64rem;
`

const CTA: FC<Props> = ({ imageUrl, text, modalId }) => {
  const { openSubcategoryDescriptionModal } = useOpenLegacyModal()
  return (
    <Wrapper>
      <Container
        variant="gray10"
        padding="md"
      >
        <Content>
          <CircularImageBox>{!!imageUrl && <CircularImage url={imageUrl} />}</CircularImageBox>
          <Text text={text}>
            {!!modalId && (
              <BoxIcon
                variant="white"
                icon={UnionIcon}
                onClick={() => {
                  openSubcategoryDescriptionModal(modalId)
                }}
              />
            )}
          </Text>
        </Content>
      </Container>
    </Wrapper>
  )
}
export default CTA
