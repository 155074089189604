var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Twitter = function Twitter(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "20px",
    height: "17px",
    viewBox: "0 0 20 17",
    version: "1.1",
    "data-id": "icons_Twitter_svg"
  }, __jsx("title", {
    "data-id": "icons_Twitter_title"
  }, "Twitter"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Twitter_g"
  }, __jsx("g", {
    transform: "translate(-390.000000, -1185.000000)",
    fill: color,
    "data-id": "icons_Twitter_g_3"
  }, __jsx("g", {
    transform: "translate(331.000000, 1173.000000)",
    "data-id": "icons_Twitter_g_4"
  }, __jsx("g", {
    transform: "translate(50.000000, 0.000000)",
    "data-id": "icons_Twitter_g_5"
  }, __jsx("g", {
    transform: "translate(9.000000, 12.000000)",
    "data-id": "icons_Twitter_g_6"
  }, __jsx("path", {
    d: "M20,1.9238 C19.264,2.2498 18.473,2.4708 17.644,2.5698 C18.49,2.0618 19.141,1.2578 19.447,0.2998 C18.654,0.7698 17.776,1.1118 16.842,1.2958 C16.093,0.4978 15.027,-0.0002 13.847,-0.0002 C11.581,-0.0002 9.744,1.8368 9.744,4.1028 C9.744,4.4238 9.78,4.7368 9.85,5.0378 C6.439,4.8668 3.416,3.2328 1.393,0.7508 C1.039,1.3568 0.837,2.0608 0.837,2.8138 C0.837,4.2368 1.562,5.4928 2.662,6.2288 C1.99,6.2078 1.357,6.0228 0.804,5.7158 C0.803,5.7328 0.803,5.7498 0.803,5.7668 C0.803,7.7558 2.218,9.4138 4.095,9.7908 C3.75,9.8838 3.388,9.9348 3.014,9.9348 C2.75,9.9348 2.492,9.9088 2.242,9.8608 C2.764,11.4908 4.279,12.6778 6.075,12.7108 C4.67,13.8108 2.901,14.4668 0.979,14.4668 C0.647,14.4668 0.321,14.4478 0,14.4098 C1.816,15.5738 3.973,16.2528 6.29,16.2528 C13.837,16.2528 17.964,10.0008 17.964,4.5778 C17.964,4.4008 17.96,4.2238 17.953,4.0478 C18.754,3.4688 19.45,2.7468 20,1.9238",
    "data-id": "icons_Twitter_path"
  })))))));
};
Twitter.defaultProps = defaultProps;
export default Twitter;