import { colors } from '@/styles/colors'
import { fontStyles } from '@/styles/fonts'
import { FC, useState, useEffect } from 'react'
import styled from 'styled-components'

type Props = {
  value: number
  min?: number
  max?: number
  disabled?: boolean
  centered?: boolean
  onBlur: (n: number) => void
}

const Input = styled.input.attrs(({ min, max, value, disabled }: Props) => ({
  type: 'number',
  max,
  min,
  value,
  disabled,
}))<Props>`
  ${fontStyles.body.md}
  ${({ centered }) => centered && 'text-align: center'};
  padding: 0;
  margin: 0;
  width: 100%;
  border: 1rem solid ${({ disabled }) => (disabled ? colors.gray20 : colors.gray30)};
  border-radius: 2rem;
  color: ${({ disabled }) => (disabled ? colors.gray20 : colors.gray40)};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const InputNumber: FC<Props> = ({ onBlur, value, ...props }) => {
  const [newValue, setNewValue] = useState(value)
  useEffect(() => {
    setNewValue(value)
  }, [value])
  return (
    <Input
      {...props}
      value={newValue}
      onChange={(e) => setNewValue(Number(e.target.value))}
      onBlur={() => {
        if (newValue !== value) {
          onBlur(newValue)
        }
      }}
    />
  )
}
export default InputNumber
