import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { shadows } from '../../styles/styleGuide';
import { toBreakpoint } from '../../styles/breakpoints';
import RichText from '../RichTextBlock';
var ProfileModal = function ProfileModal(_ref) {
  var content = _ref.content;
  return __jsx(Container, {
    "data-id": "dashboard_ProfileModal_Container"
  }, __jsx(RichText, {
    bbcode: content,
    "data-id": "dashboard_ProfileModal_RichText"
  }));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  background-color: #fff;\n  padding: 80rem 200rem 80rem 200rem;\n\n  ", " ", ";\n"])), shadows.boxPrimary, toBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 80rem 70rem 60rem 70rem;\n  "]))), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 40rem 30rem 40rem 30rem;\n  "]))));
export default ProfileModal;