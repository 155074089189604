import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
export default (function (data) {
  return data.reduce(function (result, category) {
    var next = {
      newOpts: _toConsumableArray(result.newOpts),
      ordersToConfirm: _toConsumableArray(result.ordersToConfirm)
    };
    // WishList notifications
    if (category.subcategories) {
      category.subcategories.forEach(function (subcategory) {
        return subcategory.options.forEach(function (option) {
          var added = false;
          option.orders.forEach(function (order) {
            // Add processed orders
            if (order.place === 'processed') {
              next.ordersToConfirm.push(option);
              added = true;
            }
          });
          // Add new options to result
          if (!added && option && option.flag && option.flag === 'new') {
            next.newOpts.push(option);
          }
        });
      });
    } else {
      // MyHouse notifications
      category.orders.forEach(function (order) {
        // Add processed orders
        if (order.place === 'processed') {
          next.ordersToConfirm.push(order.option);
        }
      });
    }
    return next;
  }, {
    newOpts: [],
    ordersToConfirm: []
  });
});