import { FC } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'

export type DotVariant = 'brand' | 'disabled' | 'inverted'

type Size = 'sm' | 'lg'

type Props = {
  size?: Size
  variant?: DotVariant
}

const COLOR_MAP = {
  brand: colors.brand,
  disabled: colors.gray20,
  inverted: colors.white,
}

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24rem;
`

const CustomDot = styled.div<{ variant: DotVariant; size: Size }>`
  flex: 0 0 ${({ size }) => (size === 'lg' ? '6' : '4')}rem;
  height: ${({ size }) => (size === 'lg' ? '6' : '4')}rem;
  width: ${({ size }) => (size === 'lg' ? '6' : '4')}rem;
  border-radius: 50%;
  background-color: ${({ variant }) => COLOR_MAP[variant]};
`
const Dot: FC<Props> = ({ size = 'lg', variant = 'brand' }) => (
  <DotContainer>
    <CustomDot
      variant={variant}
      size={size}
    />
  </DotContainer>
)

export default Dot
