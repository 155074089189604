import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, textStyles } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
import ButtonPrimary from '../../shared/buttons/ButtonPrimary';
var DeleteConfirmation = function DeleteConfirmation(_ref) {
  var title = _ref.title,
    description = _ref.description,
    submitButtonLabel = _ref.submitButtonLabel,
    loading = _ref.loading,
    loadingLabel = _ref.loadingLabel,
    submitFn = _ref.submitFn,
    cancelFn = _ref.cancelFn,
    cancelButtonLabel = _ref.cancelButtonLabel,
    lastOne = _ref.lastOne,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  return __jsx(Container, {
    "data-e2e-modal-confirmation": true,
    "data-id": "designElements_DeleteConfirmation_Container"
  }, __jsx(Title, {
    dangerouslySetInnerHTML: {
      __html: title
    },
    "data-id": "designElements_DeleteConfirmation_Title"
  }), __jsx(Description, {
    "data-id": "designElements_DeleteConfirmation_Description"
  }, description), submitButtonLabel && submitFn && __jsx(ButtonPrimary, {
    label: submitButtonLabel,
    type: "button",
    clickHandler: submitFn,
    loadingLabel: loadingLabel,
    loading: loading,
    disabled: disabled && !loading,
    "data-id": "designElements_DeleteConfirmation_ButtonPrimary"
  }), cancelButtonLabel && cancelFn && __jsx(Cancel, {
    lastOne: lastOne,
    onClick: disabled ? undefined : cancelFn,
    "data-id": "designElements_DeleteConfirmation_Cancel"
  }, cancelButtonLabel));
};
var Title = styled.h2(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-bottom: 10rem;\n\n  > span {\n    font-weight: normal;\n    font-style: italic;\n  }\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var Description = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-bottom: 40rem;\n  font-weight: normal;\n"])), textStyles.title, colorPalette.textGrey);
var Container = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  > button {\n    margin-bottom: 20rem;\n  }\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    > button {\n      margin-bottom: 0;\n    }\n  "]))));
var Cancel = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", " font-weight: bold;\n  color: ", ";\n  cursor: pointer;\n  margin-left: ", "rem;\n  font-size: 18rem;\n  transition: color 0.2s ease-in-out;\n\n  &:hover {\n    color: ", ";\n  }\n\n  ", ";\n"])), textStyles.bodyText, colorPalette.blue, function (props) {
  return props.lastOne ? 0 : 20;
}, colorPalette.darkBlue, fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    font-size: 20rem;\n  "]))));
export default DeleteConfirmation;