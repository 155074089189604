import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Alert from './shared/icons/Notification';
import { colorPalette } from '../styles/styleGuide';
import NotificationsNumber from './NotificationNumber';
var NotificationsCounter = function NotificationsCounter(_ref) {
  var notificationsCount = _ref.notificationsCount;
  return __jsx(Container, {
    "data-id": "components_NotificationsCounter_Container"
  }, __jsx(Alert, {
    color: colorPalette.lightBeige,
    width: 21,
    height: 21,
    "data-id": "components_NotificationsCounter_Alert"
  }), __jsx(NotificationsNumber, {
    number: notificationsCount,
    "data-id": "components_NotificationsCounter_NotificationsNumber"
  }));
};
export default NotificationsCounter;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n"])));