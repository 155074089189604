import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { WishListOption, WishListSubcategory } from '@/entities/wishList'
import Box from '@/atoms/Box'
import TextBox from '@/atoms/TextBox'
import Text from '@/atoms/Text'
import { colors } from '@/styles/colors'
import InlineRequestHandler from '@/organisms/InlineRequestHandler'
import OptionTitleLine from '@/molecules/OptionTitleLine'
import Orders from '@/molecules/Orders'
import StatusLabel from '@/atoms/StatusLabel'
import { OptionStatusInfo } from '@/processes/houseDesignStatuses'
import Button from '@/atoms/Button'
// eslint-disable-next-line import/no-cycle
import useOpenLegacyModal from '@/hooks/useOpenLegacyModal'
import TextButton from '@/atoms/TextButton'
import UnionIcon from '@/atoms/svg/UnionIcon'
import { fromBreakpoint } from '@/styles/breakpoints'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import Pill from '@/atoms/Pill'
import ApproximateIcon from '@/atoms/svg/ApproximateIcon'
import Banner from '@/molecules/Banner'
import OptionIncludedMessage from '@/molecules/OptionIncludedMessage'
import ExternalSeller from '../molecules/ExternalSeller'

type Props = {
  option: WishListOption
  children?: ReactNode
  input?: ReactNode
  isLoading?: boolean
  subcategory: WishListSubcategory
  selected: boolean
} & OptionStatusInfo

const Container = styled.div`
  border-top: 1rem dashed ${colors.gray20};
`

const OptionSubtitle = styled.div`
  display: flex;
  gap: 16rem;
`

const OptionContent = styled(Box)<{ hasInput: boolean }>`
  ${({ hasInput }) => hasInput && `padding-left: 40rem;`}
`

const Columns = styled(Box)<{ hasChildren: boolean }>`
  ${({ hasChildren }) =>
    hasChildren &&
    fromBreakpoint.lg`
      display: flex;
      gap: 16rem;
      flex-direction: row-reverse;
    `}
`

const ColumnTextBox = styled(TextBox)`
  flex-grow: 10;
`

export const OptionSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const getTextColor = (isPostDeadlineNotSelected: boolean, disabled: boolean) => {
  if (isPostDeadlineNotSelected) {
    return 'gray30'
  }
  if (disabled) {
    return 'gray25'
  }
  return undefined
}

const getDescriptionModalTextButtonColor = (
  isPostDeadlineNotSelected: boolean,
  disabled: boolean
) => {
  if (isPostDeadlineNotSelected) {
    return 'gray30'
  }
  if (disabled) {
    return 'gray25'
  }
  return 'brand2'
}

const Option: FC<Props> = ({
  input,
  children,
  option,
  isLoading = false,
  isExcluded,
  isDisabled,
  isFinal,
  isPending,
  isPostDeadlineSelected,
  isPostDeadlineNotSelected,
  optionStatus,
  subcategory,
  selected,
}) => {
  const {
    id,
    amount,
    title,
    price,
    optionCode,
    description,
    livingSurface,
    isRequestable,
    hasModal,
    optionType,
    included,
    // eslint-disable-next-line camelcase
    external_seller,
  } = option
  const { options } = subcategory

  const disabled = (isDisabled || isExcluded || isPostDeadlineNotSelected) && !isFinal

  const {
    isLargerThan: { lg },
  } = useBreakpointInfo()
  const { openOrderRequestModal, openFullDescriptionModal } = useOpenLegacyModal()

  const showDescriptionModalButton = !!hasModal && !isDisabled
  const showRequestButton =
    isRequestable &&
    amount > 0 &&
    !isFinal &&
    !isPending &&
    !isDisabled &&
    !isExcluded &&
    !isPostDeadlineSelected &&
    !isPostDeadlineNotSelected

  const showOrders = !isDisabled && !isPostDeadlineNotSelected

  return (
    <Container id={`optie-${option.id}-${option.uuid}`}>
      <InlineRequestHandler
        isLoading={isLoading}
        transparent
      >
        <Box
          marginBottom="lg"
          paddingTop="lg"
        >
          {!!optionStatus && !isPostDeadlineNotSelected && (
            <OptionContent
              marginBottom="md"
              hasInput={!!input}
            >
              <StatusLabel optionStatus={optionStatus} />
            </OptionContent>
          )}
          <OptionTitleLine
            title={title}
            price={price}
            livingSurface={livingSurface}
            disabled={disabled}
            postDeadlineNotSelected={isPostDeadlineNotSelected}
            selected={selected}
          >
            {input}
          </OptionTitleLine>
          <OptionContent
            marginTop="sm"
            hasInput={!!input}
          >
            <OptionSubtitleContainer>
              {!!optionCode && (
                <OptionSubtitle>
                  <Text
                    text={optionCode}
                    variant={isPostDeadlineNotSelected || disabled ? 'gray20' : 'gray25'}
                  />
                  {optionType === 'reference' && (
                    <Pill
                      icon={ApproximateIcon}
                      text="Voorbeeldoptie"
                      disabled={disabled}
                    />
                  )}
                </OptionSubtitle>
              )}
              {/* eslint-disable-next-line camelcase */}
              {!!external_seller && (
                <Box marginLeft="sm">
                  {/* eslint-disable-next-line camelcase */}
                  <ExternalSeller text={external_seller} />
                </Box>
              )}
            </OptionSubtitleContainer>
            {isExcluded && (
              <Box
                marginTop="sm"
                marginBottom="md"
              >
                <Banner
                  content="Deze optie is niet meer aan te vragen, in verband met een andere aanvraag."
                  shadow={false}
                />
              </Box>
            )}
            <Columns
              hasChildren={!!children}
              marginTop="sm"
            >
              {children}
              <ColumnTextBox
                spacing="sm"
                marginTop={!!children && !lg ? 'sm' : undefined}
              >
                {!!description && (
                  <Text
                    text={description}
                    variant={getTextColor(isPostDeadlineNotSelected, disabled)}
                  />
                )}
                <OptionIncludedMessage included={included} />
                {showDescriptionModalButton && (
                  <TextButton
                    onClick={() => {
                      openFullDescriptionModal(id, options)
                    }}
                    text="Volledige omschrijving"
                    iconAfter={UnionIcon}
                    variant={getDescriptionModalTextButtonColor(
                      isPostDeadlineNotSelected,
                      disabled
                    )}
                  />
                )}
              </ColumnTextBox>
            </Columns>
            {showRequestButton && (
              <Box marginTop="md">
                <Button
                  size="sm"
                  text="Optie aanvragen"
                  onClick={() => {
                    openOrderRequestModal(id, amount, options)
                  }}
                  disabled={disabled || amount === 0}
                  fullWidth={false}
                />
              </Box>
            )}
          </OptionContent>
          {showOrders && (
            <OptionContent hasInput={!!input}>
              <Orders
                option={option}
                disabled={disabled}
              />
            </OptionContent>
          )}
        </Box>
      </InlineRequestHandler>
    </Container>
  )
}

export default Option
