import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const HomeReadyPlusIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50703)">
        <path
          d="M7.71091 12.9155L6.46404 11.6667C6.20154 11.4038 5.80779 11.4038 5.54529 11.6667L4.56091 12.6197L7.6781 15.7418L13.0265 10.3521L12.075 9.39906C11.8125 9.13615 11.4187 9.13615 11.1562 9.39906L7.71091 12.9155Z"
          fill={fillColor}
        />
        <path
          d="M16.7015 14.8216V14.6573C16.6687 14.4272 16.5047 14.2629 16.275 14.2629H15.0609V12.4554H14.5031H14.3062C14.0765 12.4883 13.9125 12.6526 13.9125 12.8826V14.2629H12.1078V14.8216V15.0188C12.1406 15.2488 12.3047 15.4131 12.5344 15.4131H13.9125V17.0563H14.5031H14.6672C14.8969 17.0235 15.0609 16.8592 15.0609 16.6291V15.4131H16.7015V14.8216Z"
          fill={fillColor}
        />
        <path
          d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0812 21 18.9984 21H0V6.277L10.5 0Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50703">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default HomeReadyPlusIcon
