var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 21,
  height: 22
};
var Alert = function Alert(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 21 22",
    version: "1.1",
    "data-id": "icons_Alert_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Alert_g"
  }, __jsx("g", {
    transform: "translate(-1177.000000, -16.000000)",
    fill: color,
    "data-id": "icons_Alert_g_2"
  }, __jsx("g", {
    transform: "translate(1177.000000, 16.000000)",
    "data-id": "icons_Alert_g_3"
  }, __jsx("g", {
    "data-id": "icons_Alert_g_4"
  }, __jsx("path", {
    d: "M16.8645196,11.8461538 C16.8645196,8.76953846 16.8459686,2.90569231 11.7697482,1.86830769 C11.776494,1.80738462 11.8051637,1.75492308 11.8051637,1.69230769 C11.8051637,0.758153846 11.0496332,0 10.1187117,0 C9.18779026,0 8.43225978,0.758153846 8.43225978,1.69230769 C8.43225978,1.75492308 8.46092946,1.80738462 8.46767527,1.86830769 C3.39145488,2.90569231 3.37290391,8.76953846 3.37290391,11.8461538 C3.37290391,15.2307692 0,16.9230769 0,16.9230769 L0,18.6153846 L20.2374235,18.6153846 L20.2374235,16.9230769 C20.2374235,16.9230769 16.8645196,15.2307692 16.8645196,11.8461538",
    "data-id": "icons_Alert_path"
  }), __jsx("path", {
    d: "M8.43225978,22 L11.8051637,22 C12.7327123,22 13.4916157,21.2401538 13.4916157,20.3076923 L6.74580783,20.3076923 C6.74580783,21.2401538 7.50471121,22 8.43225978,22",
    "data-id": "icons_Alert_path_6"
  }))))));
};
Alert.defaultProps = defaultProps;
export default Alert;