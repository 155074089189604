import LayoutFlex from './LayoutFlex';
import LayoutDashboard from './LayoutDashboard';
import LayoutHome from './LayoutHome';
import LayoutLogin from './LayoutLogin';
import LayoutHouseType from './LayoutHouseType';
import LayoutFooterPage from './LayoutFooterPage';
export var TEMPLATE_LAYOUT_MAP = {
  home: LayoutHome,
  flex: LayoutFlex,
  flex_closed: LayoutFlex,
  dashboard: LayoutDashboard,
  houseType: LayoutHouseType,
  footerPage: LayoutFooterPage,
  notLoggedIn: LayoutLogin
};