import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, fonts } from '../styles/styleGuide';
import Link from '@/components/atoms/Link';
import LoginIcon from './shared/icons/Login';
var LoginBarOpenEnv = function LoginBarOpenEnv(_ref) {
  var projectSlug = _ref.projectSlug,
    color = _ref.color;
  return __jsx(LoginItemlink, {
    href: "/".concat(String(projectSlug), "/closed"),
    color: color,
    "data-id": "components_LoginBarOpenEnv_LoginItemlink"
  }, __jsx(LoginIconWrapper, {
    "data-id": "components_LoginBarOpenEnv_LoginIconWrapper"
  }, __jsx(LoginIcon, {
    color: colorPalette.beige,
    "data-id": "components_LoginBarOpenEnv_LoginIcon"
  })), "Naar Mijn Homestudios");
};
export default LoginBarOpenEnv;
var LoginItemlink = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-left: 30rem;\n  color: ", ";\n  text-decoration: none;\n  font-family: ", ";\n  font-weight: normal;\n  font-size: 16rem;\n  line-height: 24rem;\n\n  &:hover {\n    color: ", ";\n    > span > svg > g > g {\n      fill: ", ";\n    }\n  }\n"])), colorPalette.headerGrey, fonts.primary, function (props) {
  return props.color;
}, function (props) {
  return props.color;
});
var LoginIconWrapper = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-right: 8rem;\n"])));