import React, { useMemo } from 'react'
import { Storey, WishList } from '@/entities/types'
import FloorPlan from '@/components/houseDesign/FloorPlan'
import styled, { css } from 'styled-components'
import { z } from '@/styles/zIndexes'
import useLayoutDimensions, { LayoutDimensions } from '@/hooks/useLayoutDimensions'
import { colors } from '@/styles/colors'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import SidebarImage from '@/components/houseDesign/SidebarImage'
import UtilityButton from '@/atoms/UtilityButton'
import UnionIcon from '@/svg/UnionIcon'
import Box from '@/atoms/Box'
import { fromBreakpoint } from '@/styles/breakpoints'
import shadows from '@/styles/shadows'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'
import useLayoutImages from '@/hooks/useLayoutImages'
import { slugify } from '@/processes/houseDesignSlugs'

type Props = {
  wishList: WishList
  wishListItem?: string
  disableFloorPlan?: boolean
  storeys?: Storey[]
  openModal: () => void
}

const SideBar = styled.div<LayoutDimensions>`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: ${z.sideBar};
  background: ${colors.gray20};

  ${({ currentContentWidthInPx, sideBarWidth, spacingInPx }) =>
    css`
      padding-right: ${spacingInPx}px;
      width: ${spacingInPx + sideBarWidth}px;
      left: ${Math.round(spacingInPx + currentContentWidthInPx - sideBarWidth)}px;
    `}

  ${fromBreakpoint.slg`
    ${shadows.defaultInset}
  `};
`

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16rem;
`

const Buttons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 12rem;
`

/**
 * Get an image from houseType based on the index of the current category
 */
const getImageSrc = ({ houseType, categories }: WishList, wishListItem?: string) => {
  if (!houseType?.media || houseType.media.length === 0 || !wishListItem) {
    return undefined
  }
  let index = 0

  categories.forEach(({ title }, i) => {
    if (wishListItem === slugify(title)) {
      index = i
    }
  })

  while (index >= houseType.media.length) {
    index -= houseType.media.length
  }

  return houseType.media[index]?.image.src
}

const LayoutSidebar = ({
  wishList,
  wishListItem,
  disableFloorPlan = false,
  storeys,
  openModal,
}: Props) => {
  const layoutDimensions = useLayoutDimensions()
  const {
    isLargerThan: { slg },
  } = useBreakpointInfo()
  const spacing = useResponsiveSpacing()
  const { currentLayoutImage } = useLayoutImages()

  // has a storeys array of which the first item has a floorPlan svg
  const hasStoreys = !!storeys?.[0]?.floorPlan

  const content = useMemo(() => {
    if (!slg) {
      return null
    }

    if (!disableFloorPlan && hasStoreys) {
      return (
        <Container padding={spacing}>
          <FloorPlan
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            storeys={storeys!}
            wishList={wishList}
          />
          <Buttons>
            <UtilityButton
              onClick={openModal}
              icon={UnionIcon}
            />
          </Buttons>
        </Container>
      )
    }

    if (currentLayoutImage) {
      return (
        <SidebarImage
          src={currentLayoutImage}
          withFullscreen
          withPadding
        />
      )
    }

    return <SidebarImage src={getImageSrc(wishList, wishListItem)} />
  }, [
    currentLayoutImage,
    disableFloorPlan,
    hasStoreys,
    openModal,
    slg,
    spacing,
    storeys,
    wishList,
    wishListItem,
  ])

  if (!slg) {
    return null
  }

  return (
    <SideBar {...layoutDimensions}>
      <ErrorBoundary>{content}</ErrorBoundary>
    </SideBar>
  )
}

export default LayoutSidebar
