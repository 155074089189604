import { colors } from '@/styles/colors'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  url: string
}

const CircleWithImage = styled.div<{ url: string }>`
  background: ${({ url }) => `url(${url}) center center/cover no-repeat;`};
  border: 2rem solid ${colors.white};
  border-radius: 50%;
  width: 60rem;
  height: 60rem;
`
const CircularImage: FC<Props> = ({ url }) => <CircleWithImage url={url} />

export default CircularImage
