import formatCmsImageUrl from '@/services/formatCmsImageUrl'
import { colors } from '@/styles/colors'
import shadows from '@/styles/shadows'
import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { motion } from 'framer-motion'
import UtilityButton from '@/atoms/UtilityButton'
import UnionIcon from '@/atoms/svg/UnionIcon'
import { Sizes, SIZE_MAP } from '@/styles/spacing'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import { useDispatch } from 'react-redux'
import { openModal } from '@/redux/houseDesign'
import { z } from '@/styles/zIndexes'

type Props = {
  src?: string
  withFullscreen?: boolean
  withPadding?: boolean
}

const Container = styled(motion.div)`
  background-color: ${colors.gray20};
  height: 100%;
  position: relative;
`

const StyledImage = styled(Image)<{ covered: boolean }>`
  object-fit: ${({ covered }) => (covered ? 'cover' : 'contain')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`

const Shadow = styled.div`
  ${shadows.insetDarker}
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
`

const ButtonContainer = styled.div<{ spacing: Sizes }>`
  position: absolute;
  z-index: ${z.sidebarContent};
  ${({ spacing }) => `bottom: ${SIZE_MAP[spacing]}rem; right: ${SIZE_MAP[spacing]}rem`}
`

const SidebarImage = ({ src, withFullscreen, withPadding = false }: Props) => {
  const { isHighResolution } = useBreakpointInfo()
  const spacing = useResponsiveSpacing()
  const dispatch = useDispatch()

  if (!src) {
    return null
  }

  let width = isHighResolution ? 800 : 400

  if (withPadding) {
    // in this case, we show the full image, so set the width to 0 to keep the original aspect ratio while resizing
    width = 0
  }

  const processedSrc = formatCmsImageUrl(width, isHighResolution ? 1600 : 800, src)

  const processedSrcFull = formatCmsImageUrl(0, 0, src)

  if (!processedSrc) {
    return null
  }

  return (
    <Container
      key={processedSrc}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ImageContainer>
        <StyledImage
          priority
          src={processedSrc}
          layout="fill"
          covered={!withPadding}
        />
        {withFullscreen && !!processedSrcFull && (
          <ButtonContainer spacing={spacing}>
            <UtilityButton
              icon={UnionIcon}
              onClick={() => {
                dispatch(
                  openModal({
                    type: 'Image',
                    props: { src: processedSrcFull, layout: 'fill', withPadding: true },
                  })
                )
              }}
            />
          </ButtonContainer>
        )}
      </ImageContainer>
      <Shadow />
    </Container>
  )
}

export default SidebarImage
