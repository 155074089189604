import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { textStyles, themes } from '../../../styles/styleGuide';
import Triangle from './Triangle';
var defaultProps = {};
var ThemeLabel = function ThemeLabel(_ref) {
  var twoTriangles = _ref.twoTriangles,
    theme = _ref.theme,
    label = _ref.label;
  return __jsx(Container, {
    "data-id": "designElements_ThemeLabel_Container"
  }, twoTriangles && __jsx(Triangle, {
    direction: "bottomright",
    color: themes[theme].primary,
    height: 40,
    width: 40,
    "data-id": "designElements_ThemeLabel_Triangle"
  }), __jsx(Label, {
    color: themes[theme].secondary,
    "data-id": "designElements_ThemeLabel_Label"
  }, label), __jsx(Triangle, {
    direction: "bottomleft",
    color: themes[theme].primary,
    height: 40,
    width: 40,
    "data-id": "designElements_ThemeLabel_Triangle"
  }));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: flex-end;\n  > svg {\n    width: 40px;\n    height: 40px;\n    margin-top: auto;\n  }\n"])));
var Label = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  height: 40rem;\n  box-sizing: border-box;\n  width: max-content;\n  min-width: 210rem;\n  padding: 0 25rem;\n  background-color: ", ";\n  ", ";\n  font-weight: bold;\n  text-transform: uppercase;\n  color: #ffffff;\n"])), function (props) {
  return props.color;
}, textStyles.standardText);
ThemeLabel.defaultProps = defaultProps;
export default ThemeLabel;