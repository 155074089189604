import Modal from '@/components/Modal'
import useMatch from '@/hooks/useMatch'
import useRenderWidth from '@/hooks/useRenderWidth'
import { useRouter } from 'next/router'

const LegacyModal = () => {
  const { match } = useMatch()
  const router = useRouter()

  const renderWidth = useRenderWidth()

  if (!match || !router) {
    return null
  }

  return (
    <Modal
      match={match}
      renderWidth={renderWidth}
      router={router}
    />
  )
}

export default LegacyModal
