import useParams from '@/hooks/useParams'
import React from 'react'
import RequestHandler from '@/organisms/RequestHandler'
import Chrome from '@/components/houseDesign/Chrome'
import Menu from '@/components/houseDesign/Menu'
import { useWishListQuery } from '@/hooks/wishListRequestHooks'
import { WishListRequestKey } from '@/types/endpoints'
import { GlobalStyleHouseDesign } from '@/styles/globalStyles'
import Layout from '@/components/houseDesign/Layout'
import getLinkFromPageObject from '@/services/getLinkFromPageObject'
import Modal from '@/molecules/Modal'
import LegacyModal from '@/components/LegacyModal'
import { useSetPhase } from '@/hooks/useSetPhase'

const HouseDesign = () => {
  const { wishListItem, ...params } = useParams()

  const {
    error: errorWishlist,
    data,
    isLoading: isLoadingWishlist,
    isError: isErrorWishlist,
    isRefetching: isRefetchingWishlist,
  } = useWishListQuery('getWishList', [WishListRequestKey.wishListLoading], params)

  const {
    error: errorSettings,
    isLoading: isLoadingSettings,
    isError: isErrorSettings,
    isRefetching: isRefetchingSettings,
  } = useSetPhase()

  const backLink = getLinkFromPageObject(data?.link ?? params)

  const isLoading =
    isLoadingWishlist || isLoadingSettings || isRefetchingWishlist || isRefetchingSettings
  const isError = isErrorWishlist || isErrorSettings
  const error = errorWishlist ?? errorSettings

  return (
    <>
      <GlobalStyleHouseDesign />
      <RequestHandler
        fullScreen
        error={error?.humanReadableMessage}
        isError={isError || (!isLoading && !data)}
        isLoading={isLoading}
        statusCode={error?.statusCode}
      >
        {!!data && (
          <Chrome
            menu={
              <Menu
                wishList={data}
                backLink={backLink}
              />
            }
            backLink={backLink}
          >
            <Layout
              wishList={data}
              wishListItem={wishListItem}
            />
          </Chrome>
        )}
      </RequestHandler>
      <Modal />
      <LegacyModal />
    </>
  )
}

export default HouseDesign
