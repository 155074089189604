var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var PlanningAction = function PlanningAction(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "17px",
    height: "18px",
    viewBox: "0 0 17 18",
    version: "1.1",
    "data-id": "icons_PlanningAction_svg"
  }, __jsx("title", {
    "data-id": "icons_PlanningAction_title"
  }, "Planning Action"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlanningAction_g"
  }, __jsx("g", {
    transform: "translate(-681.000000, -328.000000)",
    fill: color,
    "data-id": "icons_PlanningAction_g_3"
  }, __jsx("g", {
    transform: "translate(681.000000, 328.000000)",
    "data-id": "icons_PlanningAction_g_4"
  }, __jsx("path", {
    d: "M13.8018703,13.3205333 C13.8018703,13.4181324 13.7713929,13.4970965 13.7104828,13.5579619 C13.6496175,13.6189167 13.5704746,13.6493493 13.4731437,13.6493493 L11.0625265,13.6493493 C10.9651508,13.6493493 10.8860527,13.6189167 10.8251426,13.5579619 C10.7642326,13.4970965 10.7338446,13.4181324 10.7338446,13.3205333 L10.7338446,9.72299682 L7.95799051,9.72299682 L7.95799051,13.3206674 C7.95799051,13.4181324 7.92760251,13.4970965 7.86673715,13.5579619 C7.8057824,13.6189167 7.72668424,13.6493493 7.62935327,13.6493493 L5.21869143,13.6493493 C5.13342628,13.6493493 5.06045041,13.6189167 4.99958504,13.5579619 C4.93867498,13.4970965 4.90815292,13.4181324 4.90815292,13.3206674 L4.90815292,7.45846548 C4.90815292,7.34893463 4.94479727,7.26366949 5.01777315,7.20293819 L9.07188932,3.95213763 C9.21806451,3.85485135 9.35199514,3.85485135 9.47377056,3.95213763 L13.6739279,7.20293819 C13.7591483,7.26366949 13.8018703,7.34893463 13.8018703,7.45846548 L13.8018703,13.3205333 Z M15.6873114,0 L3.02101368,0 C2.34876865,0 1.80535978,0.543408869 1.80535978,1.21551984 L1.80535978,3.40694123 L0.872314237,3.40694123 C0.389636672,3.40694123 0,3.79675666 0,4.27947891 L0,4.70120173 C0,5.18383461 0.389636672,5.57351597 0.872314237,5.57351597 L1.80535978,5.57351597 L1.80535978,7.6649251 L0.872314237,7.6649251 C0.389636672,7.6649251 0,8.05451709 0,8.53719465 L0,8.95909623 C0,9.44181848 0.389636672,9.83145515 0.872314237,9.83145515 L1.80535978,9.83145515 L1.80535978,11.922909 L0.872314237,11.922909 C0.389636672,11.922909 0,12.312501 0,12.7951338 L0,13.2206105 C0,13.7032433 0.389636672,14.0928353 0.872314237,14.0928353 L1.80535978,14.0928353 L1.80535978,16.2843014 C1.80535978,16.9565464 2.34876865,17.5 3.02101368,17.5 L15.6873114,17.5 C16.3595564,17.5 16.9030099,16.9565464 16.9030099,16.2843014 L16.9030099,1.21918427 C16.9064956,0.546894551 16.3595564,0 15.6873114,0 L15.6873114,0 Z",
    "data-id": "icons_PlanningAction_path"
  })))));
};
PlanningAction.defaultProps = defaultProps;
export default PlanningAction;