import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Plus from '../icons/Plus';
import Minus from '../icons/Minus';
import { colorPalette } from '../../../styles/styleGuide';
var ButtonPlusMinus = function ButtonPlusMinus(_ref) {
  var type = _ref.type,
    disabled = _ref.disabled,
    clickHandler = _ref.clickHandler,
    width = _ref.width,
    height = _ref.height;
  return __jsx(Button, {
    onClick: !disabled ? clickHandler : undefined,
    disabled: disabled,
    "data-id": "buttons_ButtonPlusMinus_Button"
  }, type === 'add' && __jsx(Plus, {
    width: width,
    height: height,
    "data-id": "buttons_ButtonPlusMinus_Plus"
  }), type === 'subtract' && __jsx(Minus, {
    width: width,
    height: height,
    "data-id": "buttons_ButtonPlusMinus_Minus"
  }));
};
export default ButtonPlusMinus;
var Button = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 100%;\n  height: 18rem;\n  width: 18rem;\n  cursor: ", ";\n  background: ", ";\n  transition: background 0.15s ease-in-out;\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) {
  return props.disabled ? 'default' : 'pointer';
}, function (props) {
  return !props.disabled ? colorPalette.blue : colorPalette.lightGrey;
}, function (props) {
  return !props.disabled ? colorPalette.darkBlue : colorPalette.lightGrey;
});