import React, { ReactNode } from 'react'
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars'

type Props = {
  children: ReactNode
} & Omit<ScrollbarProps, 'autoHide' | 'autoHideTimeout' | 'autoHideDuration'>

const CustomScrollbars = ({ children, ...props }: Props) => (
  <Scrollbars
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    {...props}
  >
    {children}
  </Scrollbars>
)

export default CustomScrollbars
