var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 64,
  height: 64
};
var House = function House(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 64 64",
    "data-id": "icons_House_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_House_g"
  }, __jsx("g", {
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_House_g_2"
  }, __jsx("path", {
    d: "M41,64 L41,38 L23,38 L23,64 L0,64 L0,19.1298905 L32,0 L64,19.2300469 L63.9,57.9906103 C63.9,61.2957746 61.2,64 57.9,64 L41,64 Z",
    "data-id": "icons_House_path"
  }))));
};
House.defaultProps = defaultProps;
export default House;