import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { fontStyles, fontWeight } from '@/styles/fonts'
import { IconProps } from '@/types/svgTypes'
import { FC } from 'react'

type Props = {
  text: string
  disabled?: boolean
  icon?: FC<IconProps>
}

const Container = styled.div<{ disabled: boolean }>`
  ${fontStyles.body.sm}
  font-weight: ${fontWeight.bold};
  padding: 1rem 6rem;
  background-color: ${({ disabled }) => (disabled ? colors.gray10 : colors.gray25)};
  color: ${colors.white};
  border-radius: 4rem 0;
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 4rem;
`

const Pill = ({ text, disabled = false, icon: Icon }: Props) => {
  return (
    <Container disabled={disabled}>
      {!!Icon && (
        <Icon
          variant="white"
          height={12}
        />
      )}
      {text}
    </Container>
  )
}

export default Pill
