import { ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import Spinner from '@/components/shared/ui/Spinner'
import { colors } from '@/styles/colors'

type Props = {
  children: ReactNode
  loading: boolean
  backgroundColor?: string
}

const Container = styled.div`
  position: relative;
  min-height: 22rem;
  min-width: 22rem;
`

const Loading = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const LoadingInner = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const UpdateAnimator = ({ children, loading, backgroundColor = colors.white }: Props) => {
  return (
    <Container>
      {children}
      <AnimatePresence>
        {loading && (
          <Loading
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <LoadingInner backgroundColor={backgroundColor}>
              <Spinner
                monochrome
                size="small"
                color={colors.brand}
              />
            </LoadingInner>
          </Loading>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default UpdateAnimator
