import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openModal as _openModal } from '../../redux/modal';
import { colorPalette } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import ImageLoader from '../shared/ui/ImageLoader';
import WidgetHeader from '../shared/designElements/WidgetHeader';
import { useRouter } from 'next/router';
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal() {
      dispatch(_openModal(colorPalette.lightOrange, {
        type: 'profile',
        componentProps: {
          type: 'interests',
          content: ownProps.experienceRating.modal
        }
      }));
    }
  };
};
var ECWInterestsWidget = function ECWInterestsWidget(_ref) {
  var experienceRating = _ref.experienceRating,
    openModal = _ref.openModal;
  var router = useRouter();
  var link = experienceRating && experienceRating.link ? getLinkFromPageObject(experienceRating.link) : undefined;
  var buttonType = experienceRating.modal ? 'button' : 'internalLink';
  var buttonRoute = experienceRating.modal ? undefined : "/".concat(String(link));
  var buttonClickHandler = experienceRating.modal ? openModal : undefined;
  return __jsx(Container, {
    "data-id": "dashboard_ECWInterestsWidget_Container"
  }, __jsx(WidgetHeader, {
    title: "Jouw interesses",
    subtitle: "Bekijk hier wat je interessant vond tijdens je bezoek(en) aan het Homestudios experience center.",
    "data-id": "dashboard_ECWInterestsWidget_WidgetHeader"
  }), __jsx(Image, {
    onClick: experienceRating.modal ? openModal : function () {
      return router === null || router === void 0 ? void 0 : router.push('/' + String(link));
    },
    "data-id": "dashboard_ECWInterestsWidget_Image"
  }, __jsx(ImageLoader, {
    src: "/images/interestscollage.jpg",
    mode: "img",
    alt: "BAM Experience Center",
    "data-id": "dashboard_ECWInterestsWidget_ImageLoader"
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_ECWInterestsWidget_WidgetFooter"
  }, __jsx(ButtonSecondary, {
    label: "Mijn interesses bekijken",
    type: buttonType,
    route: buttonRoute,
    clickHandler: buttonClickHandler,
    "data-id": "dashboard_ECWInterestsWidget_ButtonSecondary"
  })));
};
export default connect(null, mapDispatchToProps)(ECWInterestsWidget);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  margin-top: 30rem;\n  padding-top: 30rem;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), toBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    &:after {\n      content: '';\n      width: calc(100% + 40rem);\n      position: absolute;\n      height: 2rem;\n      top: 0;\n      left: -20rem;\n      background-color: ", "\n    }\n"])), colorPalette.lightGrey), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    width: calc(1/3 * 100%);\n    padding-left: 30rem;\n    margin-top: 0;\n    padding-top: 0;\n  "]))), fromBreakpoint.xl(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: calc(1/3 * 100%);\n    padding-left: 30rem;\n  "]))));
var Image = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  align-self: center;\n  width: 260rem;\n  height: auto;\n  margin-bottom: 60rem;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    width: 338rem;\n  "]))));