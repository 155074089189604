var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Delete = function Delete(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "16px",
    height: "19px",
    viewBox: "0 0 16 19",
    version: "1.1",
    "data-id": "icons_Delete_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Delete_g"
  }, __jsx("g", {
    transform: "translate(-875.000000, -519.000000)",
    fillRule: "nonzero",
    fill: color,
    "data-id": "icons_Delete_g_2"
  }, __jsx("path", {
    d: "M879.15625,520.78125 L879.15625,519.59375 L879.15625,519.59375 C879.15625,519.265831 879.422081,519 879.75,519 L885.984375,519 C886.312294,519 886.578125,519.265831 886.578125,519.59375 L886.578125,520.78125 L889.546875,520.78125 C890.202713,520.78125 890.734375,521.312912 890.734375,521.96875 L890.734375,524.34375 L889.546875,524.34375 L889.546875,536.21875 C889.546875,537.202507 888.749382,538 887.765625,538 L877.96875,538 C876.984993,538 876.1875,537.202507 876.1875,536.21875 L876.1875,524.34375 L875,524.34375 L875,521.96875 C875,521.312912 875.531662,520.78125 876.1875,520.78125 L876.1875,520.78125 L879.15625,520.78125 Z M887.765625,524.34375 L877.96875,524.34375 L877.96875,535.328125 C877.96875,535.983963 878.500412,536.515625 879.15625,536.515625 L886.578125,536.515625 C887.233963,536.515625 887.765625,535.983963 887.765625,535.328125 L887.765625,524.34375 Z M880.34375,519.890625 L880.34375,520.78125 L885.390625,520.78125 L885.390625,519.890625 L880.34375,519.890625 Z M880.9375,526.71875 L881.53125,526.71875 C881.859169,526.71875 882.125,526.984581 882.125,527.3125 L882.125,533.25 C882.125,533.577919 881.859169,533.84375 881.53125,533.84375 L880.9375,533.84375 C880.609581,533.84375 880.34375,533.577919 880.34375,533.25 L880.34375,527.3125 C880.34375,526.984581 880.609581,526.71875 880.9375,526.71875 Z M884.5,526.71875 L885.09375,526.71875 C885.421669,526.71875 885.6875,526.984581 885.6875,527.3125 L885.6875,533.25 C885.6875,533.577919 885.421669,533.84375 885.09375,533.84375 L884.5,533.84375 C884.172081,533.84375 883.90625,533.577919 883.90625,533.25 L883.90625,527.3125 C883.90625,526.984581 884.172081,526.71875 884.5,526.71875 Z",
    "data-id": "icons_Delete_path"
  }))));
};
Delete.defaultProps = defaultProps;
export default Delete;