import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const CheckmarkIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 11 9"
    >
      <path
        d="M4.1642 4.63755L2.60115 3.04632C2.2807 2.72455 1.75963 2.72455 1.43802 3.04747L0.25 4.24094L4.14821 8.09249L10.75 1.43564L9.56181 0.24234C9.24103 -0.0800924 8.71979 -0.0810814 8.39868 0.241186L4.1642 4.63755Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default CheckmarkIcon
