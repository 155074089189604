import { endpointConfig } from '@/constants/endpointConfig'
import useHandleLogout from '@/hooks/useHandleLogout'
import { getBaseUrl, getPathWithParams } from '@/processes/endpointHelpers'
import { devError } from '@/services/devLog'
import {
  MutationOptions,
  RequestParams,
  RequestBody,
  RequestOptions,
  RequestError,
  Variables,
  Endpoint,
  MutationEndpoint,
  QueryEndpoint,
  Method,
} from '@/types/endpoints'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

/*
 * These hooks are an interface to the regular useQuery and useMutation hooks with additional typing and features,
 * like automatically building the queryKey/mutationKey array and handling an optional beforeRequest function.
 */

const getHumanReadableMessage = (method: Method) => {
  switch (method) {
    case Method.delete:
    case Method.patch:
    case Method.post:
      return 'Aanpassing niet geslaagd'
    default:
      return 'Ophalen van data niet gelukt'
  }
}

export const request = async <T>(
  endpoint: Endpoint,
  params?: RequestParams,
  body?: RequestBody
): Promise<T> => {
  const { path, method, apiVersion } = endpointConfig[endpoint]

  try {
    const url = `${getBaseUrl(apiVersion)}${getPathWithParams(path, params)}`
    const { data } = await axios<T>({
      method,
      url,
      responseType: 'json',
      withCredentials: true,
      data: body,
    })

    return data
  } catch (e) {
    const error: RequestError = {
      message: `Request failed: ${method} ${path}`,
    }
    if ((e as AxiosError).isAxiosError) {
      error.statusCode = (e as AxiosError)?.response?.status
      error.data = (e as AxiosError)?.response?.data
      error.humanReadableMessage = getHumanReadableMessage(method)
      devError(error)
    } else {
      devError(e)
    }

    throw error
  }
}

export const useQueryRequest = <T>(
  endpoint: QueryEndpoint,
  queryKey?: unknown[],
  params?: RequestParams,
  options?: RequestOptions<T>
) => {
  const handleLogout = useHandleLogout()
  return useQuery<T, RequestError>({
    ...options,
    queryKey: [endpoint, params, ...(queryKey ?? [])],
    queryFn: () => request<T>(endpoint, params),
    onError: (error) => {
      handleLogout(error)
      options?.onError?.(error)
    },
  })
}

export const useMutationRequest = <T>(
  endpoint: MutationEndpoint,
  mutationKey?: unknown[],
  options?: MutationOptions<T>
) => {
  const handleLogout = useHandleLogout()
  return useMutation<T, RequestError, Variables>({
    ...options,
    mutationKey: [endpoint, ...(mutationKey ?? [])],
    mutationFn: ({ params, body }) => request<T>(endpoint, params, body),
    onError: (error, ...rest) => {
      handleLogout(error)
      options?.onError?.(error, ...rest)
    },
  })
}
