var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  direction: 'right',
  color: colorPalette.textGrey,
  width: 150,
  height: 150
};
var DIRECTION = {
  top: 45,
  topright: 90,
  right: 135,
  bottomright: 180,
  bottom: 225,
  bottomleft: 270,
  left: 315,
  topleft: 0
};
var Triangle = function Triangle(_ref) {
  var width = _ref.width,
    height = _ref.height,
    direction = _ref.direction,
    color = _ref.color;
  var style = {
    width: width || 0,
    height: height || 0,
    transform: "rotateZ(".concat(DIRECTION[direction], "deg)")
  };
  return __jsx("svg", {
    viewBox: "0 0 150 150",
    style: style,
    preserveAspectRatio: "xMinYMin",
    "data-id": "designElements_Triangle_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "designElements_Triangle_g"
  }, __jsx("g", {
    fill: color,
    "data-id": "designElements_Triangle_g_2"
  }, __jsx("polygon", {
    points: "0 150 0 0 150 0",
    "data-id": "designElements_Triangle_polygon"
  }))));
};
Triangle.defaultProps = defaultProps;
export default Triangle;