import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { fontStyles, fontWeight } from '@/styles/fonts'

type Props = {
  text: string
  labelColor?: string
  disabled?: boolean
}

const ExternalSellerContainer = styled.span<{
  labelColor?: string
  disabled: boolean
}>`
  ${fontStyles.body.sm}
  font-weight: ${fontWeight.bold};
  padding: 1rem 6rem;
  background-color: ${({ disabled }) => (disabled ? colors.gray10 : colors.gray25)};
  color: ${(props) => (props.labelColor ? props.labelColor : colors.white)};
  border-radius: 4rem 0;
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 4rem;
`

const ExternalSeller = ({ text, labelColor, disabled = false }: Props) => {
  if (!text) {
    return null
  }

  return (
    <ExternalSellerContainer
      labelColor={labelColor}
      disabled={disabled}
    >
      Verkoop door {text}
    </ExternalSellerContainer>
  )
}

export default ExternalSeller
