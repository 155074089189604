var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Clipboard = function Clipboard(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "20px",
    height: "20px",
    viewBox: "0 0 20 20",
    version: "1.1",
    "data-id": "icons_Clipboard_svg"
  }, __jsx("title", {
    "data-id": "icons_Clipboard_title"
  }, "Clipboard"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Clipboard_g"
  }, __jsx("g", {
    transform: "translate(-490.000000, -1183.000000)",
    fill: color,
    "data-id": "icons_Clipboard_g_3"
  }, __jsx("g", {
    transform: "translate(331.000000, 1173.000000)",
    "data-id": "icons_Clipboard_g_4"
  }, __jsx("g", {
    transform: "translate(150.000000, 0.000000)",
    "data-id": "icons_Clipboard_g_5"
  }, __jsx("g", {
    transform: "translate(9.000000, 10.000000)",
    "data-id": "icons_Clipboard_g_6"
  }, __jsx("path", {
    d: "M18.8184,2.18093333 L17.8757333,1.23826667 C16.3130667,-0.323066667 13.7810667,-0.323066667 12.2184,1.23693333 L9.42106667,4.0356 C10.5637333,3.57026667 11.8677333,3.6596 12.9584,4.26893333 L14.1037333,3.12493333 C14.6237333,2.60226667 15.4690667,2.60226667 15.9904,3.12493333 L16.9330667,4.06626667 C17.4530667,4.58626667 17.4530667,5.4316 16.9317333,5.95293333 L11.2757333,11.6089333 C10.7557333,12.1289333 9.9104,12.1289333 9.3904,11.6089333 L8.60773333,10.8249333 L6.6664,12.6556 L7.50506667,13.4929333 C9.0664,15.0569333 11.5997333,15.0569333 13.1610667,13.4929333 L18.8184,7.83693333 C20.3797333,6.2756 20.3797333,3.74226667 18.8184,2.18093333",
    "data-id": "icons_Clipboard_path"
  }), __jsx("path", {
    d: "M7.03053333,15.7974667 L5.88653333,16.9414667 C5.3652,17.4628 4.51986667,17.4628 3.99986667,16.9414667 L3.0572,15.9988 C2.53586667,15.4788 2.53586667,14.6348 3.0572,14.1134667 L8.7132,8.45746667 C9.23453333,7.93613333 10.0785333,7.93613333 10.5985333,8.45746667 L11.3812,9.24013333 L13.3238667,7.4108 L12.4852,6.57213333 C10.9225333,5.00946667 8.39053333,5.00946667 6.82786667,6.57213333 L1.17053333,12.2281333 C-0.3908,13.7908 -0.3908,16.3228 1.17053333,17.8841333 L2.1132,18.8268 C3.67586667,20.3894667 6.2092,20.3894667 7.77053333,18.8281333 L10.5692,16.0294667 C9.4252,16.4948 8.12253333,16.4054667 7.03053333,15.7974667",
    "data-id": "icons_Clipboard_path_8"
  })))))));
};
Clipboard.defaultProps = defaultProps;
export default Clipboard;