import React, { FC } from 'react'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { ICON_COLORS } from '@/constants/colors'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const PlusIcon: FC<IconProps> = ({ width = 21, height, variant = 'white' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 8 9"
    >
      <path
        d="M8 4.92211L7.9965 4.63698C7.961 4.25486 7.641 3.95736 7.251 3.95736H5.14587V0.818359H4.14712L3.8175 0.822484C3.439 0.861609 3.14587 1.18011 3.14587 1.56736V3.95748H0V4.95623L0.004125 5.28586C0.04325 5.66436 0.36175 5.95748 0.749 5.95748H3.14587V8.81836H4.18113L4.46613 8.81486C4.84837 8.77936 5.14587 8.45936 5.14587 8.06936V5.95736H8V4.92211Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default PlusIcon
