import Box from '@/atoms/Box'
import TextBox from '@/atoms/TextBox'
import List from '@/atoms/List'
import MaxWidth from '@/atoms/MaxWidth'
import FloorIcon from '@/atoms/svg/FloorIcon'
import Title from '@/atoms/Title'
import { WishList } from '@/entities/wishList'
import { colors } from '@/styles/colors'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import NavButtons from '@/components/houseDesign/NavButtons'
import BBCodeHandler from '@/organisms/BBCodeHandler'
import Divider from '@/atoms/Divider'
import { listFormat } from '@/processes/stringFormatters'

type Props = {
  wishList: WishList
}

const Main = styled(Box)`
  background: ${colors.gray40} url('/images/homestudios.svg') no-repeat 24rem 24rem;
  background-size: min(80%, 320rem);
  padding-bottom: 112rem;
  position: relative;
  color: ${colors.white};
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 2;
`

const TextContainer = styled.div`
  & * {
    color: ${colors.white};
  }
`

const ButtonContainer = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
`

const getList = (wishList: WishList) => {
  let houseType
  if (wishList.houseType?.typeName) {
    houseType = `Type: ${wishList.houseType.typeName}, ${wishList.houseType.variantName}`
    if (wishList.houseType.variantCodes) {
      houseType = `${houseType} (${listFormat(wishList.houseType?.variantCodes)})`
    }
  }

  const livingSpace = wishList.houseType?.livingSpace
    ? `Woonoppervlak: ${wishList.houseType?.livingSpace}`
    : undefined

  return [
    ...(houseType ? [houseType] : []),
    `Bouwnummer: ${wishList.house.bnr}`,
    ...(livingSpace ? [livingSpace] : []),
    `Slaapkamers: ${wishList.bedrooms}`,
    `Energielabel: ${wishList.energyLabel}`,
  ]
}

const HouseDescription = ({ wishList }: Props) => {
  const list = useMemo(() => getList(wishList), [wishList])
  const { houseType } = wishList

  return (
    <Main padding="xl">
      <MaxWidth>
        <TextContainer>
          <TextBox>
            <Title
              icon={FloorIcon}
              text="De basis"
              variant="white"
            />
            {!!houseType?.typeUsp && (
              <Title
                text={houseType.typeUsp}
                level={2}
                size="md"
                variant="white"
              />
            )}
            {!!houseType?.description && <BBCodeHandler content={houseType.description} />}
          </TextBox>
          <Divider spacing="xl" />
          <TextBox marginBottom="sm">
            <Title
              text="De basis"
              variant="white"
              size="md"
              level={2}
            />
            <List
              values={list}
              variant="inverted"
            />
          </TextBox>
        </TextContainer>
        <ButtonContainer padding="xl">
          <MaxWidth>
            <NavButtons isInitial />
          </MaxWidth>
        </ButtonContainer>
      </MaxWidth>
    </Main>
  )
}

export default HouseDescription
