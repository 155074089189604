import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import ModalInnerWrapper from '../shared/designElements/ModalInnerWrapper';
import { fonts, colorPalette, textStyles } from '../../styles/styleGuide';
import { fromBreakpoint } from '../../styles/breakpoints';
import getDate from '../../services/getDate';
var PlanningStepModal = function PlanningStepModal(_ref) {
  var stepNumber = _ref.stepNumber,
    title = _ref.title,
    date = _ref.date,
    showDate = _ref.showDate,
    text = _ref.text;
  return __jsx(Container, {
    "data-id": "dashboard_PlanningStepModal_Container"
  }, __jsx(ModalInnerWrapper, {
    "data-id": "dashboard_PlanningStepModal_ModalInnerWrapper"
  }, __jsx(Content, {
    "data-id": "dashboard_PlanningStepModal_Content"
  }, __jsx(TitleContainer, {
    "data-id": "dashboard_PlanningStepModal_TitleContainer"
  }, __jsx(DateIconWrapper, {
    "data-id": "dashboard_PlanningStepModal_DateIconWrapper"
  }, __jsx(StepIcon, {
    "data-id": "dashboard_PlanningStepModal_StepIcon"
  }, __jsx("span", {
    "data-id": "dashboard_PlanningStepModal_span"
  }, "stap"), __jsx("span", {
    "data-id": "dashboard_PlanningStepModal_span_7"
  }, stepNumber))), __jsx(Title, {
    style: {
      color: colorPalette.lightOrange
    },
    "data-id": "dashboard_PlanningStepModal_Title"
  }, title), showDate && __jsx(PublishedDate, {
    "data-id": "dashboard_PlanningStepModal_PublishedDate"
  }, getDate(date, false))), __jsx(IntroText, {
    "data-id": "dashboard_PlanningStepModal_IntroText"
  }, text))));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
var StepIcon = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 80rem;\n  height: 80rem;\n  background-color: ", ";\n  border-radius: 6rem;\n  color: #ffffff;\n  text-align: center;\n  font-family: ", ";\n  padding-top: 10rem;\n  box-sizing: border-box;\n  font-weight: bold;\n\n  ", " span:nth-of-type(2) {\n    position: relative;\n    font-size: 24rem;\n    line-height: 28rem;\n\n    ", ";\n  }\n\n  span:nth-of-type(1) {\n    position: relative;\n    display: block;\n    font-size: 20rem;\n\n    ", ";\n  }\n\n  &:before {\n    content: '';\n    border-radius: 6rem;\n    width: 100%;\n    height: 100%;\n    background-color: ", ";\n    opacity: 0.4;\n    position: absolute;\n    top: 5rem;\n    left: 5rem;\n\n    ", ";\n  }\n"])), colorPalette.lightOrange, fonts.primary, fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding-top: 15rem;\n    width: 110rem;\n    height: 110rem;\n  "]))), fromBreakpoint.lg(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      font-size: 32rem;\n      line-height: 38rem;\n    "]))), fromBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      font-size: 28rem;\n    "]))), colorPalette.lightOrange, fromBreakpoint.lg(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      top: 6rem;\n      left: 6rem;\n    "]))));
var Title = styled.h1(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  margin-top: 0;\n  word-break: break-all;\n  word-break: break-word;\n  hyphens: auto;\n\n  ", ";\n\n  ", ";\n"])), textStyles.pageTitle, fromBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: calc(100% - 50rem);\n  "]))), fromBreakpoint.lg(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
var Content = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 0;\n  width: 100%;\n  box-sizing: border-box;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    padding-left: 20rem;\n    padding-right: 20rem;\n  "]))));
var TitleContainer = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  position: relative;\n  padding-right: 90rem;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    padding-right: 60rem;\n  "]))), fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    padding-right: 0;\n  "]))));
var DateIconWrapper = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 0rem;\n  top: 0;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    right: -40rem;\n  "]))), fromBreakpoint.lg(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    right: -120rem;\n  "]))));
var PublishedDate = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 10rem;\n  margin-bottom: 45rem;\n"])), textStyles.bodyText, colorPalette.textGrey);
var IntroText = styled.p(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 40rem;\n"])), textStyles.introText, colorPalette.textGrey);
export default PlanningStepModal;