import InformationIcon from '@/svg/InformationIcon'
import TextButton from '@/atoms/TextButton'
import { getDeadlineInfo } from '@/processes/houseDesignDeadline'
import { useSelector } from '@/redux/index'

type Props = {
  deadline?: string
  passed?: boolean
  onClick: () => void
}

const Deadline = ({ deadline, passed, onClick }: Props) => {
  const userIsInCj1 = useSelector((state) => state.houseDesign.phase) === 'cj1'

  const { content, variant } = getDeadlineInfo(userIsInCj1, deadline, passed)

  return (
    <TextButton
      size="sm"
      onClick={onClick}
      text={content}
      iconAfter={InformationIcon}
      variant={variant}
    />
  )
}

export default Deadline
