import { WishListOption, WishListSubcategory } from '@/entities/wishList'
import useWishList from '@/hooks/useWishList'
import { isExpired } from '@/processes/houseDesignDeadline'
import {
  isOptionDisabled,
  isOptionExcluded,
  isOptionFinal,
  isOptionNew,
  isOptionPending,
  OptionStatusInfo,
  getOptionStatus,
  isOptionFinalPostDeadline,
  isOptionActionRequired,
} from '@/processes/houseDesignStatuses'
import { useMemo } from 'react'

const useOptionStatus = (
  option: WishListOption,
  subcategory?: WishListSubcategory
): OptionStatusInfo => {
  const wishList = useWishList()

  return useMemo(() => {
    const deadlineExpired = subcategory ? isExpired(subcategory) : false
    const statuses = {
      isExcluded: wishList ? isOptionExcluded(option, wishList) : false,
      isFinal: isOptionFinal(option),
      isDisabled: isOptionDisabled(option),
      isNew: isOptionNew(option),
      isPending: isOptionPending(option),
      isActionRequired: isOptionActionRequired(option),
      isPostDeadlineSelected: deadlineExpired && isOptionFinalPostDeadline(option),
      isPostDeadlineNotSelected: deadlineExpired && !isOptionFinalPostDeadline(option),
    }

    return {
      ...statuses,
      optionStatus: getOptionStatus(statuses),
    }
  }, [option, subcategory, wishList])
}

export default useOptionStatus
