import React from 'react'
import Head from 'next/head'

type Props = {
  title: string
  metaTitle?: string
  metaKeywords?: string
  metaDescription?: string
}

const HeadComponent = ({ title, metaTitle, metaKeywords, metaDescription }: Props) => (
  <Head>
    <title key="title">{title ?? 'Homestudios'}</title>
    {metaTitle && (
      <meta
        name="title"
        content={metaTitle}
      />
    )}
    {metaKeywords && (
      <meta
        name="keywords"
        content={metaKeywords}
      />
    )}
    {metaDescription && (
      <meta
        name="description"
        content={metaDescription}
      />
    )}
  </Head>
)

export default HeadComponent
