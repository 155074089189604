export default (function (date) {
  if (!date) return '';
  var today = new Date();
  var dateTransformed = new Date(date.replace(' ', 'T')); // for Safari as it only accepts formats like this '2018-05-02T14:00:00'
  var differenceInHours = Math.floor(Math.abs((today.getTime() - dateTransformed.getTime()) / (60 * 60 * 1000)));
  if (differenceInHours < 24) {
    return "".concat(differenceInHours, " uur geleden");
  } else if (differenceInHours >= 24 && differenceInHours < 48) {
    return "".concat(Math.floor(Math.abs((today.getTime() - dateTransformed.getTime()) / (24 * 60 * 60 * 1000))), " dag geleden");
  } else {
    return "".concat(Math.floor(Math.abs((today.getTime() - dateTransformed.getTime()) / (24 * 60 * 60 * 1000))), " dagen geleden");
  }
});