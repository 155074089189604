import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import ImageLoader from '../shared/ui/ImageLoader';
import House from '../shared/icons/MyHouse';
import { colorPalette, textStyles } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import { openModal as _openModal } from '../../redux/modal';
import WidgetHeader from '../shared/designElements/WidgetHeader';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal() {
      dispatch(_openModal(colorPalette.lightOrange, {
        type: 'profile',
        componentProps: {
          type: 'messages',
          content: ownProps.house.modal
        }
      }));
    }
  };
};
var redirect = function redirect(link, router) {
  if (link === undefined || !link) return;
  router === null || router === void 0 || router.push('/' + getLinkFromPageObject(link));
};
var HouseWidget = function HouseWidget(_ref) {
  var phase = _ref.phase,
    openModal = _ref.openModal,
    house = _ref.house,
    router = _ref.router;
  var houseImage = get(house, 'houseType.media[0].image.src');
  return __jsx(Container, {
    "data-id": "dashboard_HouseWidget_Container"
  }, __jsx(WidgetHeader, {
    title: "Jouw woning",
    subtitle: phase === 'cj1' ? 'Als koper heb je vanaf hier straks toegang tot meer informatie over de woning die je hebt gekocht en de keuzes die je hebt gemaakt om van je huis een thuis te maken.' : undefined,
    "data-id": "dashboard_HouseWidget_WidgetHeader"
  }), phase === 'cj1' && __jsx(IconContainer, {
    onClick: openModal,
    "data-id": "dashboard_HouseWidget_IconContainer"
  }, __jsx(House, {
    width: 200,
    height: 140,
    color: colorPalette.background,
    "data-id": "dashboard_HouseWidget_House"
  })), (phase === 'cj2' || phase === 'cj3') && __jsx(Fragment, null, houseImage && __jsx(ImageContainer, {
    onClick: function onClick() {
      return redirect(house.link, router);
    },
    "data-id": "dashboard_HouseWidget_ImageContainer"
  }, __jsx(ImageLoader, {
    src: houseImage,
    mode: "bg",
    alt: house.houseType.slug,
    "data-id": "dashboard_HouseWidget_ImageLoader"
  })), __jsx(HouseInfo, {
    "data-id": "dashboard_HouseWidget_HouseInfo"
  }, house.house && house.house.address ? __jsx(Address, {
    "data-id": "dashboard_HouseWidget_Address"
  }, __jsx("p", {
    "data-id": "dashboard_HouseWidget_p"
  }, house.house.address.street, " ", house.house.address.houseNumber, ' ', house.house.address.houseNumberAddition), __jsx("p", {
    "data-id": "dashboard_HouseWidget_p_9"
  }, house.house.address.zipcode, " ", house.house.address.city)) : __jsx(Address, {
    "data-id": "dashboard_HouseWidget_Address"
  }, __jsx("p", {
    "data-id": "dashboard_HouseWidget_p"
  }, "Adres nog onbekend")), house.house && house.house.bnr && __jsx("p", {
    "data-id": "dashboard_HouseWidget_p_12"
  }, "Bouwnummer: ", house.house.bnr), house.houseType && house.house && __jsx("p", {
    "data-id": "dashboard_HouseWidget_p_13"
  }, "Variant: ", house.houseType.typeName, " - ", house.houseType.variantName, ' ', house.house.variantCode), house.house && house.house.surface && __jsx("p", {
    "data-id": "dashboard_HouseWidget_p_14"
  }, "Kaveloppervlak: ", house.house.surface, " m\xB2"), house.houseType && house.houseType.energyLabel && __jsx("p", {
    "data-id": "dashboard_HouseWidget_p_15"
  }, "Energielabel: ", house.houseType.energyLabel))), __jsx(WidgetFooter, {
    "data-id": "dashboard_HouseWidget_WidgetFooter"
  }, phase === 'cj1' && __jsx(ButtonSecondary, {
    label: "Lees meer",
    type: "button",
    clickHandler: openModal,
    "data-id": "dashboard_HouseWidget_ButtonSecondary"
  }), house.link && __jsx(ButtonSecondary, {
    label: "Woning en gemaakte keuzes bekijken",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(house.link),
    "data-id": "dashboard_HouseWidget_ButtonSecondary_18"
  })));
};
export default withMatch(connect(null, mapDispatchToProps)(HouseWidget));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  position: relative;\n  margin-bottom: 20rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-right: 30rem;\n    border-right: 1rem dashed ", ";\n    margin-bottom: 0;\n    min-height: 480rem;\n  "])), colorPalette.lightBeige));
var IconContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 30rem;\n  margin-bottom: 50rem;\n  &:hover {\n    cursor: pointer;\n  }\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-top: 20rem;\n    margin-bottom: 80rem;\n  "]))));
var ImageContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 218rem;\n  overflow: hidden;\n  cursor: pointer;\n\n  > div {\n    transition: transform 0.45s ease-in-out;\n    transform: scale(1);\n    &:hover {\n      transform: scale(1.04);\n    }\n  }\n"])));
var HouseInfo = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n\n  > p {\n    margin-bottom: 0;\n    margin-top: 8rem;\n  }\n\n  + div {\n    ", ";\n  }\n"])), textStyles.caption, colorPalette.textGrey, toBreakpoint.lg(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n      padding-bottom: 10rem;\n    "]))));
var Address = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin-bottom: 12rem;\n  margin-top: 10rem;\n  > p {\n    margin-bottom: 8rem;\n    margin-top: 0;\n  }\n"])));