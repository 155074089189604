import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const MapIcon: FC<IconProps> = ({ width = 22, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 14 14"
    >
      <path
        d="M9.33333 14L4.66667 12.3667L1.05 13.7667C0.790741 13.8704 0.550926 13.8411 0.330556 13.6788C0.110185 13.517 0 13.3 0 13.0278V2.13889C0 1.97037 0.0487407 1.8213 0.146222 1.69167C0.243185 1.56204 0.375926 1.46482 0.544445 1.4L4.66667 0L9.33333 1.63333L12.95 0.233333C13.2093 0.12963 13.4491 0.158667 13.6694 0.320444C13.8898 0.482741 14 0.7 14 0.972222V11.8611C14 12.0296 13.9513 12.1787 13.8538 12.3083C13.7568 12.438 13.6241 12.5352 13.4556 12.6L9.33333 14ZM8.55556 12.0944V2.99444L5.44444 1.90556V11.0056L8.55556 12.0944ZM10.1111 12.0944L12.4444 11.3167V2.1L10.1111 2.99444V12.0944ZM1.55556 11.9L3.88889 11.0056V1.90556L1.55556 2.68333V11.9Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default MapIcon
