import React from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { shadows, textStyles, colorPalette } from '../../styles/styleGuide'
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints'
import BBCode from '../shared/richText/BBCode'
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import HeroArticle from '../HeroArticle'
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import withApiData, { ApiDataBinding, GetParams } from '../../services/withApiData'
import { IAuthor, IImage, IProductOptionModal, ISlideNode } from '../../entities/types'

const TypedHeroArticle = (props: {
  renderWidth: number
  showDownloadButton?: boolean
  type: 'slider' | 'image' | 'video'
  title?: string
  author?: IAuthor
  credits?: string
  image?: IImage
  theme: string
  media?: Array<ISlideNode>
  youtubeId?: string
}) => {
  // @ts-ignore
  return <HeroArticle {...props} />
}

export type CategoryDescriptionModalProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  projectSlug: string
  // eslint-disable-next-line react/no-unused-prop-types
  modalId: number
  renderWidth: number
}

type CategoryDescriptionModalTotalProps = CategoryDescriptionModalProps & {
  wishListModalCategory: ApiDataBinding<IProductOptionModal>
}

const getApiDataParams = ({ projectSlug, modalId }: CategoryDescriptionModalProps) => ({
  wishListModalCategory: {
    projectSlug,
    modalId,
  },
})

const connectApiData = withApiData(
  {
    wishListModalCategory: 'getWishListModalCategory',
  },
  getApiDataParams as unknown as GetParams
)

const Wrapper = styled.div`
  margin-right: 20rem;
  margin-left: 20rem;
`

const Container = styled.div`
  ${shadows.boxPrimary};
  align-self: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60rem;
`

const Title = styled.h1`
  ${textStyles.pageTitle};
  color: ${colorPalette.headerGrey};
  margin-left: 20rem;
  margin-right: 20rem;
  ${toBreakpoint.md`
    margin-top: 40rem;
  `};

  ${fromBreakpoint.md`
    margin-top: 60rem;
  `};
`

const CategoryDescriptionModal = ({
  wishListModalCategory,
  renderWidth,
}: CategoryDescriptionModalTotalProps) => {
  const { data } = wishListModalCategory
  if (!data) {
    return null
  }
  const heroArticleProps = {
    theme: 'woonStyling',
    type: data.type as 'slider' | 'image' | 'video',
    renderWidth,
  }
  return (
    <Container>
      {data.type === 'slider' && (
        <TypedHeroArticle
          {...heroArticleProps}
          media={data.media}
        />
      )}
      {data.type === 'image' && (
        <TypedHeroArticle
          {...heroArticleProps}
          image={data.image}
        />
      )}
      {data.type === 'video' && (
        <TypedHeroArticle
          {...heroArticleProps}
          youtubeId={data.youtubeId}
        />
      )}
      <Content>
        <div>
          <Title>{data.title}</Title>
        </div>
        <Wrapper>
          {/* @ts-ignore */}
          <BBCode content={data.text} />
        </Wrapper>
      </Content>
    </Container>
  )
}

export default connectApiData(CategoryDescriptionModal)
