import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import PriceTag from './PriceTag';
import formatPrice from '../../../services/formatPrice';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../../styles/breakpoints';
var WishlistFooter = function WishlistFooter(_ref) {
  var smallColumn = _ref.smallColumn,
    bedrooms = _ref.bedrooms,
    notAvailable = _ref.notAvailable,
    small = _ref.small,
    price = _ref.price,
    livingSpace = _ref.livingSpace,
    userWishTotal = _ref.userWishTotal;
  return __jsx(Container, {
    smallColumn: smallColumn,
    "data-id": "designElements_WishlistFooter_Container"
  }, __jsx(UserOptionsMessage, {
    smallColumn: smallColumn,
    small: small,
    "data-id": "designElements_WishlistFooter_UserOptionsMessage"
  }, userWishTotal ? "Dit bedrag is inclusief ".concat(formatPrice(userWishTotal, {
    showDecimal: true,
    showEuroSign: true
  }), " aan eigen woonwensen") : '', livingSpace && bedrooms ? "".concat(livingSpace, " m\xB2 woonoppervlak, ").concat(bedrooms, " slaapkamer").concat(bedrooms > 1 ? 's' : '') : ''), __jsx(PriceTagContainer, {
    small: small,
    "data-id": "designElements_WishlistFooter_PriceTagContainer"
  }, __jsx(PriceTag, {
    price: price,
    small: small,
    notAvailable: notAvailable,
    "data-id": "designElements_WishlistFooter_PriceTag"
  })));
};
export default WishlistFooter;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  font-family: ", ";\n  ", ";\n"])), fonts.primary, toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: ", ";\n    align-items: ", ";\n  "])), function (props) {
  return props.smallColumn ? 'column' : 'row';
}, function (props) {
  return props.smallColumn ? 'flex-start' : 'center';
}));
var UserOptionsMessage = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-style: italic;\n  font-size: ", ";\n  color: ", ";\n  padding-right: 25rem;\n\n  ", ";\n"])), function (props) {
  return props.small ? '12rem' : '14rem';
}, colorPalette.textGrey, toBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-bottom: ", "rem;\n  "])), function (props) {
  return props.smallColumn ? 10 : 0;
}));
var PriceTagContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n\n  > div > div {\n    width: 100%;\n    >span: first-of-type {\n      margin-left: 5rem;\n    }\n    > span:last-of-type {\n      margin-left: auto;\n    }\n  }\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    margin-top: 0;\n  "]))));