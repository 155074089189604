import React from 'react'
import ImageSlider from '@/atoms/ImageSlider'
import { WishList } from '@/entities/wishList'
import styled from 'styled-components'
import HouseDescription from '@/components/houseDesign/HouseDescription'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { fromBreakpoint } from '@/styles/breakpoints'
import { colors } from '@/styles/colors'
import CustomScrollbars from '@/atoms/CustomScrollbars'

type Props = {
  wishList: WishList
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${colors.gray40};
  min-height: 100vh;
  padding-top: 56rem;

  ${fromBreakpoint.md`
    padding-top: 0;
  `}
  ${fromBreakpoint.slg`
    align-items: stretch;
    height: 100vh;
    flex-direction: row-reverse;
  `}
`

const HouseDescriptionWrapper = styled.div`
  flex-grow: 10;
  display: flex;
  flex-direction: column;

  ${fromBreakpoint.slg`
    min-height: unset;
  `}
`

const ImageSliderWrapper = styled.div`
  height: 40vh;

  ${fromBreakpoint.md`
    height: 50vh;
  `}
  ${fromBreakpoint.lg`
    height: 60vh;
  `}
  ${fromBreakpoint.slg`
    width: 66.66667%;
    height: 100vh;
  `}
`

const HouseLayout = ({ wishList }: Props) => {
  const {
    isLargerThan: { slg },
  } = useBreakpointInfo()
  const { houseType } = wishList

  const houseDescription = <HouseDescription wishList={wishList} />

  return (
    <Container>
      <ImageSliderWrapper>
        <ImageSlider
          images={houseType?.media}
          title={houseType?.variantName}
          withFullscreenButton
          squareAspect
        />
      </ImageSliderWrapper>
      <HouseDescriptionWrapper>
        {slg ? (
          <CustomScrollbars height="100vh">{houseDescription}</CustomScrollbars>
        ) : (
          houseDescription
        )}
      </HouseDescriptionWrapper>
    </Container>
  )
}

export default HouseLayout
