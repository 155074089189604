var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseOptions = function HouseOptions(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseOptions_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseOptions_title"
  }, "Huis Opties"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseOptions_g"
  }, __jsx("g", {
    transform: "translate(-242.000000, -130.000000)",
    fill: color,
    "data-id": "icons_HouseOptions_g_3"
  }, __jsx("g", {
    transform: "translate(0.000000, 124.000000)",
    "data-id": "icons_HouseOptions_g_4"
  }, __jsx("g", {
    transform: "translate(242.000000, 6.000000)",
    "data-id": "icons_HouseOptions_g_5"
  }, __jsx("g", {
    "data-id": "icons_HouseOptions_g_6"
  }, __jsx("g", {
    transform: "translate(4.062500, 13.125000)",
    fillRule: "nonzero",
    "data-id": "icons_HouseOptions_g_7"
  }, __jsx("g", {
    "data-id": "icons_HouseOptions_g_8"
  }, __jsx("circle", {
    cx: "1.40625",
    cy: "1.40625",
    r: "1.40625",
    "data-id": "icons_HouseOptions_circle"
  }), __jsx("circle", {
    cx: "5.90625",
    cy: "1.40625",
    r: "1.40625",
    "data-id": "icons_HouseOptions_circle_10"
  }), __jsx("circle", {
    cx: "10.375",
    cy: "1.40625",
    r: "1.40625",
    "data-id": "icons_HouseOptions_circle_11"
  }))), __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseOptions_path"
  })))))));
};
HouseOptions.defaultProps = defaultProps;
export default HouseOptions;