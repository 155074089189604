import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const ComfortIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50712)">
        <path
          d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0812 21 18.9984 21H0V6.27699L10.5 0Z"
          fill={fillColor}
        />
        <path
          d="M8.49841 14.9528C8.36716 15.0843 8.13748 15.0843 8.00623 14.9528C7.9406 14.8871 7.90779 14.7885 7.90779 14.6899C7.90779 14.5913 7.97341 14.4927 8.03904 14.427L13.3875 10.0561L8.49841 14.9528ZM7.1531 15.3472L6.20154 16.366L6.56248 16.7275L7.54685 15.8073C12.9281 17.5819 14.4047 13.0467 14.4047 8.51151C11.3859 11.042 5.90623 9.59601 7.1531 15.3472Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50712">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default ComfortIcon
