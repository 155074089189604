import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getResultData } from '../../redux/apiData';
import { openModal } from '../../redux/modal';
import { colorPalette, textStyles } from '../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../styles/breakpoints';
import NeighboursIcon from '../shared/icons/Neighbours';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import UserAvatar from '../UserAvatar';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import WidgetHeader from '../shared/designElements/WidgetHeader';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import { WidgetScroller } from '../shared/designElements/WidgetScroller';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    phase: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'phase', undefined)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openNeighbourModal: function openNeighbourModal() {
      if (ownProps.dummyContent) {
        dispatch(openModal(colorPalette.lightOrange, {
          type: 'profile',
          componentProps: {
            type: 'neighbours',
            content: ownProps.dummyContent.modal
          }
        }));
      }
    }
  };
};
var NeighbourWidget = function NeighbourWidget(_ref) {
  var phase = _ref.phase,
    openNeighbourModal = _ref.openNeighbourModal,
    _ref$neighbours = _ref.neighbours,
    neighbours = _ref$neighbours === void 0 ? [] : _ref$neighbours,
    link = _ref.link;
  return __jsx(Container, {
    "data-id": "dashboard_NeighbourWidget_Container"
  }, __jsx(Header, {
    "data-id": "dashboard_NeighbourWidget_Header"
  }, __jsx(WidgetHeader, {
    title: "Ontmoet de nieuwe buren",
    subtitle: phase === 'cj1' ? 'Op deze plaats maak je straks kennis met alle nieuwe buurtbewoners.' : undefined,
    "data-id": "dashboard_NeighbourWidget_WidgetHeader"
  })), __jsx(WidgetScroller, {
    "data-id": "dashboard_NeighbourWidget_WidgetScroller"
  }, phase === 'cj1' ? __jsx(IconWrapper, {
    onClick: openNeighbourModal,
    "data-id": "dashboard_NeighbourWidget_IconWrapper"
  }, __jsx(NeighboursIcon, {
    "data-id": "dashboard_NeighbourWidget_NeighboursIcon"
  })) : (neighbours || []).map(function (neighbour, index) {
    return __jsx(Neighbour, {
      key: index,
      "data-id": "dashboard_NeighbourWidget_Neighbour"
    }, __jsx(UserAvatar, {
      size: "normal",
      mode: "imageLoader",
      name: neighbour.customerName,
      imageUrl: neighbour.customerImage,
      phase: phase,
      "data-id": "dashboard_NeighbourWidget_UserAvatar"
    }), __jsx(Info, {
      "data-id": "dashboard_NeighbourWidget_Info"
    }, __jsx(Name, {
      "data-id": "dashboard_NeighbourWidget_Name"
    }, neighbour.customerName ? neighbour.customerName : 'Toekomstige bewoner(s)'), __jsx(BNR, {
      "data-id": "dashboard_NeighbourWidget_BNR"
    }, "Bouwnummer ", neighbour.bnr)));
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_NeighbourWidget_WidgetFooter"
  }, phase === 'cj1' && __jsx(ButtonSecondary, {
    label: "Lees meer",
    type: "button",
    clickHandler: openNeighbourModal,
    "data-id": "dashboard_NeighbourWidget_ButtonSecondary"
  }), (phase === 'cj2' || phase === 'cj3') && link && __jsx(ButtonSecondary, {
    label: "Bekijk alle buren",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(link),
    "data-id": "dashboard_NeighbourWidget_ButtonSecondary_13"
  })));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(NeighbourWidget));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  min-height: 400rem;\n  max-height: 470rem;\n  position: relative;\n  background-color: white;\n  padding: 0 24rem 0 0;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  > .scrollHeight {\n    height: 100%;\n\n    > svg {\n      position: absolute;\n      right: 0;\n      top: 0;\n    }\n  }\n\n  ", ";\n"])), toBreakpoint.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    max-height: 705rem;\n  "]))), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 0 0 24rem 0;\n    max-height: 470rem;\n    &:after {\n      content: '';\n      width: calc(100% + 40rem);\n      position: absolute;\n      height: 2rem;\n      bottom: 0;\n      left: -20rem;\n      background-color: ", "\n    }\n  "])), colorPalette.lightGrey), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    min-height: 480rem;\n  "]))), fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    min-height: 480rem;\n    margin-bottom: 0;\n    border-bottom: 0;\n    border-right: dashed 0.5rem #979797;\n  "]))));
var Header = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: 10rem;\n"])));
var IconWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  &:hover {\n    cursor: pointer;\n  }\n\n  > svg {\n    align-self: center;\n    transform: scale(1.4);\n    position: absolute;\n    top: 40%;\n\n    ", ";\n  }\n"])), toBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      top: 37%;\n    "]))));
var Info = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-left: 16rem;\n"])));
var Name = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: bold;\n"])), textStyles.caption, colorPalette.textGrey);
var BNR = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n"])), textStyles.caption, colorPalette.textGrey);
var Neighbour = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-bottom: 20rem;\n"])));