export default (function (phone, mobile) {
  var phoneString = phone ? "Tel. ".concat(phone) : undefined;
  var mobileString = mobile ? "Mob. ".concat(mobile) : undefined;
  if (phoneString && mobileString) {
    return "".concat(phoneString, "[br][/br]").concat(mobileString);
  }
  if (phoneString) {
    return phoneString;
  }
  if (mobileString) {
    return mobileString;
  }
  return '[i]Geen telefoongegevens bekend[/i]';
});