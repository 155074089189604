import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import Slider from './shared/ui/Slider';
import { connect } from 'react-redux';
import { getResultData } from '../redux/apiData';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }).styling
  };
};
var StorySlider = function StorySlider(_ref) {
  var heroes = _ref.heroes,
    styling = _ref.styling;
  return __jsx(Container, {
    "data-e2e-block-storyslider": true,
    "data-id": "components_StorySlider_Container"
  }, __jsx(Slider, {
    heroes: heroes,
    paginationColor: styling.button,
    currentIndex: 0,
    "data-id": "components_StorySlider_Slider"
  }));
};
export default withMatch(connect(mapStateToProps)(StorySlider));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 30rem 0rem;\n"])));