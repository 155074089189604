import InputText from '@/inputs/InputText'
import InputPrice from '@/inputs/InputPrice'
import styled from 'styled-components'
import { useState } from 'react'
import Title from '@/atoms/Title'
import useParams from '@/hooks/useParams'
import { colors } from '@/styles/colors'
import { useWishListMutation } from '@/hooks/wishListRequestHooks'
import Button from '@/atoms/Button'
import InlineRequestHandler from '@/organisms/InlineRequestHandler'
import Container from '@/atoms/Container'
import Box from '@/atoms/Box'
import { IUserWishOption } from '@/entities/wishList'
import useSectionLoading from '@/hooks/useSectionLoading'
import { Slug } from '@/constants/slugs'
import { WishListRequestKey } from '@/types/endpoints'

type Props = {
  callback?: () => void
  userWish?: IUserWishOption
}

const Wrapper = styled(Container)<{ isEditing: boolean }>`
  background-color: ${colors.gray40};

  ${({ isEditing }) => !isEditing && `border-top-left-radius: 0;`}
`

const Form = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20rem;
`

const ButtonWidth = styled.div`
  min-width: 96rem;
`

const InputTextField = styled(InputText)`
  flex: 1 0 75%;
`

const UserWishForm = ({ callback, userWish }: Props) => {
  const params = useParams()
  const { toggleSectionLoading } = useSectionLoading()

  const originalTitle = userWish?.title
  const originalPrice = userWish?.price.toString()

  const [userWishDescription, setUserWishDescription] = useState(originalTitle ?? '')
  const [userWishPrice, setUserWishPrice] = useState(originalPrice ?? '')

  const onSuccess = () => {
    setUserWishDescription('')
    setUserWishPrice('')
  }

  const onSettled = () => {
    callback?.()
    toggleSectionLoading(false, Slug.userWishes)
  }

  const {
    mutate: postUserWish,
    isLoading: postUserWishIsLoading,
    isError: postUserWishIsError,
    error: postUserWishError,
  } = useWishListMutation('postUserWish', [WishListRequestKey.wishListUserWish], params, {
    onSuccess,
    onSettled,
  })

  const {
    mutate: patchUserWish,
    isLoading: patchUserWishIsLoading,
    isError: patchUserWishIsError,
    error: patchUserWishError,
  } = useWishListMutation(
    'patchUserWish',
    [WishListRequestKey.wishListUserWish, userWish?.id],
    { ...params, userWishId: userWish?.id.toString() ?? '' },
    {
      onSuccess,
      onSettled,
    }
  )

  const isLoading = postUserWishIsLoading || patchUserWishIsLoading
  const isError = postUserWishIsError || patchUserWishIsError
  const error = postUserWishError ?? patchUserWishError

  const isEditing = !!userWish

  return (
    <InlineRequestHandler
      isError={isError}
      error={error?.humanReadableMessage}
      isLoading={isLoading}
      statusCode={error?.statusCode}
      transparent
    >
      <Wrapper
        padding="xl"
        isEditing={isEditing}
      >
        <Title
          variant="white"
          level={3}
          size="md"
          text={isEditing ? 'Woonwens aanpassen' : 'Voeg een woonwens toe'}
        />
        <Form
          paddingTop="md"
          paddingBottom="lg"
        >
          <InputTextField
            variant="white"
            placeholder="Beschrijving woonwens"
            onChange={setUserWishDescription}
            value={userWishDescription}
          />
          <InputPrice
            variant="white"
            placeholder="Prijs"
            onChange={setUserWishPrice}
            value={userWishPrice}
          />
        </Form>
        <ButtonContainer>
          {isEditing && (
            <ButtonWidth>
              <Button
                text="Annuleren"
                size="sm"
                centerText
                variant="outlined-inverted"
                onClick={() => {
                  callback?.()
                }}
              />
            </ButtonWidth>
          )}
          <ButtonWidth>
            <Button
              text={isEditing ? 'Wijziging opslaan' : 'Opslaan'}
              size="sm"
              centerText
              disabled={userWishDescription.length === 0 || userWishPrice.length === 0}
              onClick={() => {
                if (userWishDescription === originalTitle && userWishPrice === originalPrice) {
                  callback?.()
                  return
                }
                toggleSectionLoading(true, Slug.userWishes)
                const body = {
                  price: userWishPrice,
                  selected: true,
                  title: userWishDescription,
                  wishList: params.wishListId,
                }
                if (isEditing) {
                  patchUserWish({
                    params: { ...params, userWishId: userWish?.id.toString() ?? '' },
                    body,
                  })
                  return
                }
                postUserWish({
                  params,
                  body,
                })
              }}
            />
          </ButtonWidth>
        </ButtonContainer>
      </Wrapper>
    </InlineRequestHandler>
  )
}

export default UserWishForm
