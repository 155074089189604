import UtilityButton from '@/atoms/UtilityButton'
import UnionIcon from '@/svg/UnionIcon'
import { openModal } from '@/redux/houseDesign'
import Image from 'next/image'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import formatCmsImageUrl from '@/services/formatCmsImageUrl'
import { IImage } from '@/entities/shared'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'

type Props = {
  image?: IImage
}

const Container = styled.figure`
  padding: 0;
  margin: 0;
  min-height: 200rem;
  height: 40vh;
  max-height: 344rem;
  position: relative;
`

const StyledImage = styled(Image)`
  object-fit: cover;
`

const ButtonContainer = styled.div`
  position: absolute;
  z-index: 11;
  top: 32rem;
  right: 32rem;
`

const ImageWithFullscreen = ({ image }: Props) => {
  const dispatch = useDispatch()
  const { isHighResolution } = useBreakpointInfo()

  const src = formatCmsImageUrl(
    isHighResolution ? 1600 : 800,
    isHighResolution ? 900 : 450,
    image?.src
  )

  if (!image || !src) {
    return null
  }

  const { width, height } = image

  return (
    <ErrorBoundary silent>
      <Container>
        <StyledImage
          priority
          src={src}
          layout="fill"
        />
        {width !== undefined && height !== undefined && (
          <ButtonContainer>
            <UtilityButton
              icon={UnionIcon}
              onClick={() => {
                dispatch(
                  openModal({
                    type: 'Image',
                    props: {
                      src: image.src,
                      width,
                      height,
                      withPadding: true,
                    },
                  })
                )
              }}
            />
          </ButtonContainer>
        )}
      </Container>
    </ErrorBoundary>
  )
}

export default ImageWithFullscreen
