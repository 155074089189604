import Title from '@/atoms/Title'
import styled from 'styled-components'
import { ICON_MAP } from '@/constants/icons'
import { IconSlug, WishListOption } from '@/entities/wishList'
import { colors } from '@/styles/colors'
import Box from '@/atoms/Box'
import Text from '@/atoms/Text'
import MaxWidth from '@/atoms/MaxWidth'
import { ReactNode } from 'react'
import Banner from '@/molecules/Banner'
import TextBox from '@/atoms/TextBox'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import TextButton from '@/atoms/TextButton'
import UnionIcon from '@/svg/UnionIcon'
import useOpenLegacyModal from '@/hooks/useOpenLegacyModal'

type Props = {
  title: string
  description?: string
  iconSlug?: IconSlug
  children?: ReactNode
  categoryHasImage?: boolean
  newOptions?: WishListOption[]
  additionalContent?: ReactNode
  modalId?: number
}

const IntroContainer = styled(Box)`
  background: ${colors.gray40} url('/images/homestudios.svg') no-repeat 24rem 24rem;
  background-size: min(80%, 300rem);
  padding-bottom: 104rem;
`

const ContentContainer = styled(Box)`
  margin-top: -104rem;
`

const NewOptionLink = styled.a`
  color: ${colors.white};
  font-style: italic;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`

const getNewOptionsText = (newOptions?: WishListOption[]) => {
  if (!newOptions || newOptions.length === 0) {
    return undefined
  }

  if (newOptions.length === 1) {
    return (
      <>
        Er is een nieuwe optie toegevoegd:{' '}
        <NewOptionLink href={`#optie${newOptions[0].id}`}>{newOptions[0].title}</NewOptionLink>
      </>
    )
  }

  return (
    <>
      Er zijn nieuwe opties toegevoegd:{' '}
      {newOptions.map((option, i) => (
        <>
          {i !== 0 ? ', ' : ''}
          <NewOptionLink
            key={option.id}
            href={`#optie${option.id}`}
          >
            {option.title}
          </NewOptionLink>
        </>
      ))}
    </>
  )
}

const CategoryIntro = ({
  children,
  title,
  description,
  additionalContent,
  iconSlug,
  categoryHasImage = false,
  newOptions,
  modalId,
}: Props) => {
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()

  const { openCategoryDescriptionModal } = useOpenLegacyModal()

  const newOptionsText = getNewOptionsText(newOptions)

  return (
    <Box>
      <IntroContainer
        paddingHorizontal={md ? 'xl' : 'none'}
        paddingTop={!categoryHasImage ? 'md' : undefined}
      >
        <MaxWidth>
          <TextBox
            paddingHorizontal={md ? 'none' : 'xl'}
            paddingBottom="xl"
            paddingTop="xl"
          >
            <Title
              text={title}
              size="lg"
              variant="white"
              icon={iconSlug ? ICON_MAP[iconSlug] : undefined}
            />
            {!!description && (
              <Text
                text={description}
                variant="white"
              />
            )}
            {!!modalId && (
              <TextButton
                variant="white"
                text="Lees meer"
                iconAfter={UnionIcon}
                onClick={() => {
                  openCategoryDescriptionModal(modalId)
                }}
              />
            )}
            {!!newOptionsText && <Banner>{newOptionsText}</Banner>}
            {additionalContent}
          </TextBox>
        </MaxWidth>
      </IntroContainer>
      <ContentContainer paddingHorizontal={md ? 'xl' : 'sm'}>
        <MaxWidth>{children}</MaxWidth>
      </ContentContainer>
    </Box>
  )
}

export default CategoryIntro
