import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fonts, colorPalette } from './../../../styles/styleGuide';
import { darken } from '../../../styles/utils';
import Caret from '../icons/Caret';
var defaultProps = {
  color: colorPalette.blue,
  direction: 'down'
};
var ButtonShowMoreLess = function ButtonShowMoreLess(_ref) {
  var clickHandler = _ref.clickHandler,
    color = _ref.color,
    label = _ref.label,
    direction = _ref.direction;
  return __jsx(Button, {
    onClick: clickHandler,
    "data-id": "buttons_ButtonShowMoreLess_Button"
  }, __jsx(Label, {
    color: color,
    "data-id": "buttons_ButtonShowMoreLess_Label"
  }, label), __jsx(Container, {
    color: color,
    "data-id": "buttons_ButtonShowMoreLess_Container"
  }, __jsx(Caret, {
    color: "white",
    direction: direction,
    width: 12,
    height: 7,
    "data-id": "buttons_ButtonShowMoreLess_Caret"
  })));
};
ButtonShowMoreLess.defaultProps = defaultProps;
export default ButtonShowMoreLess;
var Button = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  font-family: ", ";\n  padding: 10rem 0;\n\n  &:hover {\n    > span {\n      color: ", ";\n    }\n    > div {\n      background-color: ", ";\n    }\n  }\n"])), fonts.primary, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
});
var Container = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  align-self: center;\n  height: 20rem;\n  width: 20rem;\n  background: ", ";\n  border-radius: 4rem 0 4rem 0;\n  margin-left: 10rem;\n  margin-top: 2rem;\n  transition: background 0.2s ease-in-out;\n"])), function (props) {
  return props.color ? props.color : colorPalette.blue;
});
var Label = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: bold;\n  font-size: 18rem;\n  transition: color 0.2s ease-in-out;\n"])), function (props) {
  return props.color;
});