import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../styles/styleGuide';
import withMatch from '@/hocs/withMatch';
import LoginBarOpenEnv from './LoginBarOpenEnv';
import LoginBarClosedEnv from './LoginBarClosedEnv';
import HeaderLink from './HeaderLink';
var LoginBar = function LoginBar(_ref) {
  var match = _ref.match,
    settings = _ref.settings;
  var _match$params = match.params,
    projectSlug = _match$params.projectSlug,
    env = _match$params.env;
  var _settings$styling$nav = settings.styling.navActiveLabel,
    color = _settings$styling$nav === void 0 ? colorPalette.blue : _settings$styling$nav;
  var loggedIn = settings && settings.user;
  var phase = settings && settings.phase;
  var showLogin = settings && settings.showLogin;
  var showb2bLink = window.location.pathname.startsWith('/portal/open');
  return __jsx(Bar, {
    "data-id": "components_LoginBar_Bar"
  }, showb2bLink && __jsx(HeaderLink, {
    color: color,
    headerLink: 'https://www2.homestudios.nl/',
    caption: 'Over Homestudios',
    "data-id": "components_LoginBar_HeaderLink"
  }), showLogin && env === 'open' && __jsx(LoginBarOpenEnv, {
    projectSlug: String(projectSlug),
    color: color,
    "data-id": "components_LoginBar_LoginBarOpenEnv"
  }), loggedIn && env === 'closed' && __jsx(LoginBarClosedEnv, {
    projectSlug: String(projectSlug),
    phase: phase,
    "data-id": "components_LoginBar_LoginBarClosedEnv"
  }));
};
export default withMatch(LoginBar);
var Bar = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-end;\n"])));