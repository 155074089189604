import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import getLinkFromPageObject from '../services/getLinkFromPageObject';
import { colorPalette, textStyles } from '../styles/styleGuide';
import { fromBreakpoint } from '../styles/breakpoints';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import UserAvatar from './UserAvatar';
var ContactExpert = function ContactExpert(_ref) {
  var isLastItem = _ref.isLastItem,
    title = _ref.title,
    description = _ref.description,
    _ref$professionals = _ref.professionals,
    professionals = _ref$professionals === void 0 ? [] : _ref$professionals;
  return __jsx(Block, {
    isLastItem: isLastItem,
    "data-e2e-block-contactexpert": true,
    "data-id": "components_ContactExpert_Block"
  }, __jsx(TextContainer, {
    isLastItem: isLastItem,
    "data-id": "components_ContactExpert_TextContainer"
  }, __jsx(Title, {
    "data-id": "components_ContactExpert_Title"
  }, title), __jsx(SubTitle, {
    "data-id": "components_ContactExpert_SubTitle"
  }, description)), __jsx(DetailsBlock, {
    "data-id": "components_ContactExpert_DetailsBlock"
  }, professionals.map(function (expert, index) {
    return __jsx(Expert, {
      key: index,
      "data-id": "components_ContactExpert_Expert"
    }, __jsx(UserAvatar, {
      mode: "imageLoader",
      size: "large",
      name: expert.name,
      imageUrl: expert.image,
      "data-id": "components_ContactExpert_UserAvatar"
    }), __jsx(ExpertInfo, {
      "data-id": "components_ContactExpert_ExpertInfo"
    }, expert.functionTitle && __jsx(ExpertTitle, {
      "data-id": "components_ContactExpert_ExpertTitle"
    }, expert.functionTitle), expert.name && __jsx(ExpertName, {
      "data-id": "components_ContactExpert_ExpertName"
    }, expert.name), expert.mobile && __jsx(ExpertPhone, {
      href: "tel:".concat(expert.mobile),
      "data-id": "components_ContactExpert_ExpertPhone"
    }, expert.mobile), expert.phone && __jsx(ExpertPhone, {
      href: "tel:".concat(expert.phone),
      "data-id": "components_ContactExpert_ExpertPhone_11"
    }, expert.phone), expert.link && __jsx(ButtonWrapper, {
      "data-id": "components_ContactExpert_ButtonWrapper"
    }, __jsx(ButtonSecondary, {
      type: "internalLink",
      route: '/' + getLinkFromPageObject(expert.link),
      color: colorPalette.blue,
      label: "Stuur bericht",
      "data-id": "components_ContactExpert_ButtonSecondary"
    }))));
  })));
};
var DetailsBlock = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  border-top: 1rem dashed ", ";\n  margin-top: 40rem;\n  padding-top: 20rem;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), colorPalette.lightBeige, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-top: 40rem;\n    flex-wrap: wrap;\n    flex-direction: row;\n    justify-content: space-between;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    margin: 0 80rem;\n    width: calc(100% - 160rem);\n  "]))), fromBreakpoint.xl(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin: 0 200rem;\n    width: calc(100% - 400rem);\n  "]))));
var ExpertInfo = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-left: 20rem;\n\n  ", "\n"])), textStyles.bodyText, colorPalette.textGrey, fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n  "]))));
var ExpertPhone = styled.a(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  text-decoration: none;\n  display: block;\n"])), colorPalette.textGrey);
var ExpertTitle = styled.h3(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", " font-weight: bold;\n  margin-top: 0;\n  margin-bottom: 12rem;\n"])), textStyles.bodyText);
var Expert = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: no-wrap;\n  justify-content: flex-start;\n  margin-bottom: 40rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    padding: 0;\n    width: calc(50% - 40rem);\n  "]))));
var ExpertName = styled.span(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: block;\n"])));
var Block = styled.section(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  background-color: #ffffff;\n  padding: 40rem 20rem 30rem 20rem;\n  position: relative;\n  box-sizing: border-box;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), function (props) {
  return props.isLastItem ? "\n    &:before {\n      content: '';\n      z-index: 0;\n      position: absolute;\n      top: 0;\n      background-color: #FFFFFF;\n      width: 100vw;\n      height: calc(100% + 30rem);\n      left: calc((-50vw + 50%));\n    }\n  " : "\n    ''\n\n    ".concat(fromBreakpoint.md(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n      margin-bottom: 60rem;\n    "]))), ";\n  ");
}, fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    ", "\n  "])), function (props) {
  return props.isLastItem ? "\n      padding: 60rem 0 40rem 0;\n    " : "\n      padding: 60rem 73rem 40rem 73rem;\n    ";
}), fromBreakpoint.lg(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    ", "\n  "])), function (props) {
  return props.isLastItem ? "\n    &:before {\n      height: calc(100% + 30rem);\n    }\n    " : "\n      padding: 60rem 20rem 40rem 20rem;\n    ";
}));
var Title = styled.h3(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: bold;\n  margin-top: 0;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var SubTitle = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n"])), textStyles.bodyText, colorPalette.textGrey);
var TextContainer = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  margin-bottom: 40rem;\n  position: relative;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), fromBreakpoint.md(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    margin-bottom: 50rem;\n    max-width: 70%;\n  "]))), fromBreakpoint.lg(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n    max-width: 50%;\n    ", "\n  "])), function (props) {
  return props.isLastItem ? "\n    " : "\n      margin-left: 22rem;\n      margin-right: 22rem;\n    ";
}), fromBreakpoint.xl(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n    ", "\n  "])), function (props) {
  return props.isLastItem ? "\n    " : "\n      max-width: 570rem;\n      margin-left: 110rem;\n      margin-right: 110rem;\n    ";
}));
var ButtonWrapper = styled.div(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  padding-top: 12rem;\n  margin-top: auto;\n"])));
export default ContactExpert;