import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import styled from 'styled-components';
import { updateMobileMenuState } from '../redux/menu';
import { getResultData } from '../redux/apiData';
import Hamburger from './shared/icons/Hamburger';
import { fonts, colorPalette } from '../styles/styleGuide';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {}),
    mobileMenuIsOpen: state.menu.mobileMenuIsOpen,
    env: get(ownProps, 'match.params.env')
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onPress: function onPress(mobileMenuIsOpen) {
      if (!mobileMenuIsOpen) {
        dispatch(updateMobileMenuState(true));
      }
    }
  };
};
var mergeProps = function mergeProps(stateProps, dispatchProps) {
  return {
    onPress: function onPress() {
      return dispatchProps.onPress(stateProps.mobileMenuIsOpen);
    },
    menuColor: stateProps.env !== 'closed' ? get(stateProps, 'styling.navActiveLabel') : colorPalette.blue
  };
};
var MenuHamburger = function MenuHamburger(_ref) {
  var onPress = _ref.onPress,
    menuColor = _ref.menuColor;
  return __jsx(MenuButton, {
    onClick: onPress,
    "data-id": "components_MenuHamburger_MenuButton"
  }, __jsx(ButtonLabel, {
    color: menuColor,
    "data-id": "components_MenuHamburger_ButtonLabel"
  }, "Menu"), __jsx(Hamburger, {
    color: menuColor,
    "data-id": "components_MenuHamburger_Hamburger"
  }));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps, mergeProps)(MenuHamburger));
var MenuButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 10rem;\n  align-items: center;\n  -webkit-tap-highlight-color: transparent;\n  &:active {\n    opacity: 0.5;\n  }\n"])));
var ButtonLabel = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 14rem;\n  text-transform: uppercase;\n  color: ", ";\n  letter-spacing: 0.58rem;\n  margin-right: 8rem;\n  text-transform: uppercase;\n"])), fonts.primary, function (props) {
  return props.color;
});