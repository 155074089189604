import { useState } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '@/styles/colors'
import LayerName from '@/atoms/LayerName'
import Price from '@/atoms/Price'
import SquareMeters from '@/atoms/SquareMeters'
import { ICON_MAP } from '@/constants/icons'
import { IconSlug } from '@/entities/wishList'
import { fontStyles, fontWeight } from '@/styles/fonts'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { fromBreakpoint } from '@/styles/breakpoints'
import { transitions } from '@/styles/shared'
import UpdateAnimator from '@/atoms/UpdateAnimator'

type Props = {
  title: string
  squareMeters?: number
  price?: number
  active?: boolean
  hasNew?: boolean
  extraSquareMeters?: boolean
  sign?: 'minus' | 'plus' | 'none'
  isFirst?: boolean
  isSummary?: boolean
  onClick?: () => void
  iconSlug?: IconSlug
  loading?: boolean
}

const ContentWrapper = styled.div<{ isLast: boolean }>`
  display: flex;
  align-items: center;
  gap: 8rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.gray40};
  transition: color 200ms ease-in-out;
  ${({ isLast }) => isLast && fontStyles.body.lg}

  ${fromBreakpoint.slg`
    gap: 12rem;
  `}
`

const Outer = styled.div<{
  isFirst: boolean
  active: boolean
  isMd: boolean
  interactive: boolean
}>`
  ${({ isFirst, isMd, interactive }) =>
    isFirst &&
    isMd &&
    interactive &&
    css`
      border-top: 1rem dashed ${colors.gray20};
    `}
  ${({ isMd, interactive }) =>
    isMd &&
    interactive &&
    css`
      border-bottom: 1rem dashed ${colors.gray20};
    `}
  padding-top: 0;
  padding-bottom: 0;
  transition: padding-top 300ms ease-in-out, padding-bottom 300ms ease-in-out;
  ${({ active }) =>
    active &&
    css`
      padding-top: 12rem;
      padding-bottom: 12rem;
    `}
  ${({ isMd }) =>
    !isMd &&
    css`
      padding-top: 4rem;
      padding-bottom: 4rem;
    `}
`

const Inner = styled.div<{ active: boolean; isMd: boolean; interactive: boolean }>`
  ${transitions.hover}
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ interactive }) =>
    interactive &&
    css`
      padding: 16rem;
      margin: 0 -16rem;
    `}
  gap: 8rem;
  border-radius: 8rem 0;
  font-size: 16rem;
  line-height: 20rem;

  ${({ interactive }) => interactive && `cursor: pointer;`}

  ${({ active, isMd, interactive }) =>
    (active || !isMd) &&
    interactive &&
    css`
      background-color: ${colors.gray10};
    `}

  &:hover,
  &:focus,
  &:active {
    ${ContentWrapper} {
      ${({ interactive }) => interactive && `color: ${colors.brand};`}
    }
  }
`

const IconWrapper = styled.div`
  width: 16rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  svg {
    width: 100%;
  }

  ${fromBreakpoint.slg`
    width: 24rem;
  `}
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8rem;

  ${fromBreakpoint.slg`
    gap: 16rem;
  `}
`

const Layer = ({
  active = false,
  hasNew = false,
  title,
  squareMeters,
  extraSquareMeters = false,
  price,
  sign = 'none',
  isFirst = false,
  isSummary = false,
  onClick,
  iconSlug = 'houseEmpty',
  loading = false,
}: Props) => {
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()
  const [hovered, setHovered] = useState(false)
  const Icon = ICON_MAP[iconSlug]

  return (
    <Outer
      isFirst={isFirst}
      active={active}
      isMd={md}
      interactive={!!onClick}
    >
      <Inner
        active={active}
        interactive={!!onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        isMd={md}
      >
        <ContentWrapper isLast={isSummary}>
          {!!Icon && (
            <IconWrapper>
              <Icon
                variant={active || hovered || !onClick || !md ? 'brand' : undefined}
                width={22}
              />
            </IconWrapper>
          )}
          <LayerName
            title={title}
            hasNew={hasNew}
          />
        </ContentWrapper>
        <UpdateAnimator
          loading={loading}
          backgroundColor={active ? colors.gray10 : undefined}
        >
          <InfoWrapper>
            <SquareMeters
              squareMeters={squareMeters}
              withPlus={extraSquareMeters}
              animation
            />
            <Price
              price={price}
              sign={sign}
              variant={isSummary ? 'brand' : 'transparent'}
              displayZero
              animation
            />
          </InfoWrapper>
        </UpdateAnimator>
      </Inner>
    </Outer>
  )
}

export default Layer
