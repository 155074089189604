var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange,
  width: 21,
  height: 20
};
var HouseBase = function HouseBase(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseBase_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseBase_title"
  }, "Huis Basis"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseBase_g"
  }, __jsx("g", {
    transform: "translate(-46.000000, -767.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_HouseBase_g_3"
  }, __jsx("g", {
    transform: "translate(40.000000, 761.000000)",
    "data-id": "icons_HouseBase_g_4"
  }, __jsx("g", {
    transform: "translate(6.000000, 6.000000)",
    "data-id": "icons_HouseBase_g_5"
  }, __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    "data-id": "icons_HouseBase_path"
  }))))));
};
HouseBase.defaultProps = defaultProps;
export default HouseBase;