import { colors } from '@/styles/colors'
import styled from 'styled-components'
import Text from '@/atoms/Text'
import { INPUT_SIZE_MAP, INPUT_UNDERLINE_MAP, InputVariant, InputSizes } from '@/constants/inputs'

type Props = {
  onChange: (n: string) => void
  value: string
  size?: InputSizes
  variant?: InputVariant
  placeholder?: string
}

type InputProps = { inputSize: InputSizes; variant: InputVariant }

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8rem;
`

const Input = styled.input<InputProps>`
  ${({ inputSize }) => inputSize && INPUT_SIZE_MAP[inputSize]}
  width: 100%;
  border: none;
  color: ${({ variant }) => variant && colors[variant]};
  background-color: inherit;
  padding: 7rem 0 8rem;
  border-bottom: 1rem solid ${({ variant }) => variant && INPUT_UNDERLINE_MAP[variant]};
  &:focus-visible {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: ${({ variant }) => variant && colors[variant]};
    font-style: italic;
  }
  &::before {
    content: '€ ';
  }
`
const InputPrice = ({
  onChange,
  value,
  size = 'md',
  placeholder = '',
  variant = 'gray30',
}: Props) => {
  return (
    <Wrapper>
      <Text
        text="€"
        variant="white"
        size={size}
        italic
      />
      <Input
        value={value}
        variant={variant}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        inputSize={size}
        type="number"
        step={0.01}
        min={0}
      />
    </Wrapper>
  )
}

export default InputPrice
