import React from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import Svg from './Svg'

type Props = {
  width?: number
  height?: number
}

const HomestudiosLogo = ({ width = 21, height }: Props) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 430 248"
    >
      <g>
        <g>
          <g>
            <path
              fill={colors.gray30}
              d="M24.52,90.19h38.39V48.9c3.05-0.51,5.58-0.76,7.62-0.76c4.16,0,7.41,0.99,9.75,2.97
				c2.34,1.98,3.5,4.95,3.5,8.91v90.8H62.91v-44.18H24.52v44.18H3.65V48.9c3.25-0.51,5.69-0.76,7.31-0.76
				c9.04,0,13.56,3.96,13.56,11.88V90.19z"
            />
            <path
              fill={colors.gray30}
              d="M189.28,99.48c0,16.86-4.04,30.06-12.11,39.61c-8.07,9.55-19.58,14.32-34.51,14.32
				c-9.85,0-18.18-2.19-24.98-6.55c-6.81-4.37-11.96-10.61-15.46-18.74c-3.5-8.12-5.26-17.62-5.26-28.49
				c0-10.76,1.93-20.19,5.79-28.26c3.86-8.07,9.29-14.19,16.3-18.36c7.01-4.16,15.29-6.25,24.83-6.25
				c14.52,0,25.72,4.62,33.59,13.86C185.34,69.87,189.28,82.82,189.28,99.48z M167.8,99.63c0-11.58-2.06-20.49-6.17-26.74
				c-4.11-6.25-10.08-9.37-17.9-9.37c-8.23,0-14.47,3.12-18.74,9.37c-4.27,6.25-6.4,15.16-6.4,26.74c0,11.68,2.13,20.79,6.4,27.35
				c4.26,6.55,10.36,9.83,18.28,9.83c7.92,0,13.99-3.25,18.21-9.75C165.69,120.56,167.8,111.42,167.8,99.63z"
            />
            <path
              fill={colors.gray30}
              d="M217.36,48.14c2.84,0,5.26,0.66,7.24,1.98c1.98,1.32,3.63,3.51,4.95,6.55l23.16,54.54l23.31-53.93
				c2.74-6.09,6.96-9.14,12.64-9.14c4.67,0,8.18,0.46,10.51,1.37l7.31,101.31h-19.81l-4.72-67.64l-20.72,46.31h-17.52l-20.11-44.33
				l-4.42,65.66h-19.96l7.47-101.31C209.64,48.6,213.19,48.14,217.36,48.14z"
            />
            <path
              fill={colors.gray30}
              d="M374.55,134.67c4.06,0,7.18,0.94,9.37,2.82c2.18,1.88,3.28,4.55,3.28,8c0,1.12-0.03,2.06-0.07,2.82
				c-0.05,0.76-0.13,1.6-0.23,2.51h-65.97V48.75h63.53c0.3,1.52,0.46,3.4,0.46,5.64c0,3.86-0.99,6.6-2.97,8.23
				c-1.98,1.63-5.31,2.44-9.98,2.44h-30.01v25.14h32.3v16.3h-32.3v28.18H374.55z"
            />
          </g>
          <g>
            <path
              fill={colors.gray30}
              d="M8.11,228.22c3.11,2.42,6.02,4.15,8.72,5.19c2.7,1.04,5.5,1.56,8.4,1.56c3.53,0,6.35-0.81,8.46-2.44
				c2.11-1.63,3.16-3.93,3.16-6.9c-0.07-2.42-0.73-4.34-1.97-5.76c-1.25-1.42-2.89-2.59-4.93-3.53c-2.04-0.93-4.27-1.78-6.69-2.54
				c-2.22-0.69-4.43-1.5-6.64-2.44c-2.22-0.93-4.24-2.13-6.07-3.58c-1.83-1.45-3.32-3.32-4.46-5.6c-1.14-2.28-1.71-5.12-1.71-8.51
				c0-4.22,1.07-7.85,3.22-10.89c2.14-3.04,5.1-5.38,8.87-7c3.77-1.62,7.97-2.44,12.61-2.44c3.6,0,7.09,0.47,10.48,1.4
				c3.39,0.93,5.91,2.02,7.57,3.27c1.94,1.11,2.91,2.66,2.91,4.67c0,0.9-0.38,2.06-1.14,3.48c-0.76,1.42-1.76,2.79-3.01,4.1
				c-2.14-1.73-4.6-3.13-7.37-4.2c-2.77-1.07-5.47-1.61-8.09-1.61c-3.39,0-6.09,0.67-8.09,2.02c-2.01,1.35-3.01,3.3-3.01,5.86
				c0,1.39,0.33,2.63,0.99,3.74c0.66,1.11,1.75,2.14,3.27,3.11c1.52,0.97,4.36,2.22,8.51,3.74c2.21,0.83,4.46,1.73,6.74,2.7
				c2.28,0.97,4.36,2.2,6.23,3.68c1.87,1.49,3.39,3.36,4.57,5.6c1.18,2.25,1.76,5.07,1.76,8.46c0,7.06-2.33,12.59-7,16.6
				c-4.67,4.01-11.02,6.02-19.04,6.02c-3.73,0-7.23-0.38-10.48-1.14c-3.25-0.76-6.02-1.8-8.3-3.11c-1.94-1.31-2.91-2.84-2.91-4.57
				C3.65,235.14,5.14,232.16,8.11,228.22z"
            />
            <path
              fill={colors.gray30}
              d="M76.64,186.3H54.96c-0.21-1.25-0.31-2.63-0.31-4.15c0-2.28,0.79-4.1,2.39-5.45
				c1.59-1.35,3.73-2.02,6.43-2.02h48.77c0.21,1.45,0.31,2.94,0.31,4.46c0,2.21-0.74,3.96-2.23,5.24c-1.49,1.28-3.58,1.92-6.28,1.92
				H90.96v57.9H76.64V186.3z"
            />
            <path
              fill={colors.gray30}
              d="M171.81,216.19c0,3.05-0.14,5.85-0.42,8.4c-0.41,4.5-1.75,8.34-3.99,11.52c-2.25,3.18-5.29,5.59-9.13,7.21
				c-3.84,1.63-8.39,2.44-13.64,2.44c-8.16,0-14.35-1.71-18.57-5.14c-4.22-3.42-6.78-8.91-7.68-16.45
				c-0.28-2.21-0.41-4.88-0.41-7.99v-41.4c2.21-0.34,3.87-0.52,4.98-0.52c6.16,0,9.23,2.7,9.23,8.09v34.76
				c0,4.15,0.42,7.37,1.25,9.65c0.83,2.28,2.18,3.98,4.05,5.08c1.87,1.11,4.36,1.66,7.47,1.66c4.36,0,7.56-1.25,9.6-3.74
				c2.04-2.49,3.06-6.43,3.06-11.83v-43.16c2.21-0.34,3.87-0.52,4.98-0.52c6.16,0,9.24,2.7,9.24,8.09V216.19z"
            />
            <path
              fill={colors.gray30}
              d="M237.79,208.3c0,7.33-1.35,13.73-4.05,19.19c-2.7,5.47-6.52,9.61-11.46,12.45
				c-4.95,2.84-10.81,4.25-17.59,4.25h-22.1v-68.9c6.43-0.83,12.94-1.24,19.51-1.24c11.83,0,20.73,2.87,26.72,8.61
				C234.8,188.41,237.79,196.96,237.79,208.3z M223.27,208.51c0-7.89-1.66-13.75-4.98-17.59c-3.32-3.84-8.37-5.76-15.15-5.76
				c-1.94,0-4.01,0.1-6.22,0.31v47.83h5.71c7.12,0,12.35-1.97,15.67-5.92C221.61,223.45,223.27,217.15,223.27,208.51z"
            />
            <path
              fill={colors.gray30}
              d="M247.93,174.79c2.21-0.34,3.87-0.52,4.98-0.52c6.16,0,9.23,2.7,9.23,8.09v61.84h-14.21V174.79z"
            />
            <path
              fill={colors.gray30}
              d="M334.4,209.23c0,11.48-2.75,20.48-8.25,26.98c-5.5,6.5-13.33,9.75-23.5,9.75c-6.71,0-12.38-1.49-17.02-4.46
				c-4.63-2.97-8.14-7.23-10.53-12.76c-2.39-5.53-3.58-12-3.58-19.4c0-7.33,1.31-13.75,3.94-19.25c2.63-5.5,6.33-9.67,11.1-12.5
				c4.77-2.84,10.41-4.25,16.91-4.25c9.89,0,17.52,3.15,22.88,9.44C331.72,189.07,334.4,197.89,334.4,209.23z M319.77,209.34
				c0-7.88-1.4-13.95-4.2-18.21c-2.8-4.25-6.87-6.38-12.19-6.38c-5.6,0-9.86,2.13-12.76,6.38c-2.9,4.25-4.36,10.32-4.36,18.21
				c0,7.96,1.45,14.16,4.36,18.63c2.91,4.46,7.06,6.69,12.45,6.69c5.4,0,9.53-2.21,12.4-6.64
				C318.33,223.59,319.77,217.36,319.77,209.34z"
            />
            <path
              fill={colors.gray30}
              d="M343.62,228.22c3.11,2.42,6.02,4.15,8.72,5.19c2.7,1.04,5.5,1.56,8.4,1.56c3.53,0,6.35-0.81,8.46-2.44
				c2.11-1.63,3.16-3.93,3.16-6.9c-0.07-2.42-0.73-4.34-1.97-5.76c-1.25-1.42-2.89-2.59-4.93-3.53c-2.04-0.93-4.27-1.78-6.69-2.54
				c-2.22-0.69-4.43-1.5-6.64-2.44c-2.22-0.93-4.24-2.13-6.07-3.58c-1.83-1.45-3.32-3.32-4.46-5.6c-1.14-2.28-1.71-5.12-1.71-8.51
				c0-4.22,1.07-7.85,3.22-10.89c2.14-3.04,5.1-5.38,8.87-7c3.77-1.62,7.97-2.44,12.61-2.44c3.6,0,7.09,0.47,10.48,1.4
				c3.39,0.93,5.91,2.02,7.57,3.27c1.94,1.11,2.91,2.66,2.91,4.67c0,0.9-0.38,2.06-1.14,3.48c-0.76,1.42-1.76,2.79-3.01,4.1
				c-2.14-1.73-4.6-3.13-7.37-4.2c-2.77-1.07-5.47-1.61-8.09-1.61c-3.39,0-6.09,0.67-8.09,2.02c-2.01,1.35-3.01,3.3-3.01,5.86
				c0,1.39,0.33,2.63,0.99,3.74c0.66,1.11,1.75,2.14,3.27,3.11c1.52,0.97,4.36,2.22,8.51,3.74c2.21,0.83,4.46,1.73,6.74,2.7
				c2.28,0.97,4.36,2.2,6.23,3.68c1.87,1.49,3.39,3.36,4.57,5.6c1.18,2.25,1.76,5.07,1.76,8.46c0,7.06-2.33,12.59-7,16.6
				c-4.67,4.01-11.02,6.02-19.04,6.02c-3.73,0-7.23-0.38-10.48-1.14c-3.25-0.76-6.02-1.8-8.3-3.11c-1.94-1.31-2.91-2.84-2.91-4.57
				C339.16,235.14,340.65,232.16,343.62,228.22z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="none"
          d="M431.86,35.18V10.52c0-0.41-0.22-0.78-0.57-0.98L409.94-2.79c-0.35-0.2-0.78-0.2-1.13,0L387.46,9.54
		c-0.35,0.2-0.57,0.58-0.57,0.98v24.66c0,0.41,0.22,0.78,0.57,0.98l21.35,12.33c0.35,0.2,0.78,0.2,1.13,0l21.35-12.33
		C431.65,35.96,431.86,35.58,431.86,35.18z"
        />
        <g>
          <path
            fill={colors.brand}
            d="M427.78,32.89c0-0.37-0.2-0.7-0.5-0.87l0,0l-16.89-9.75v-2.69l14.98,8.65c0.23,0.13,0.5,0.21,0.76,0.21
			c0.54,0,1.05-0.29,1.33-0.76l0.25-0.42l-17.32-10v-2.69l14.98,8.65c0.23,0.13,0.5,0.21,0.76,0.21c0.54,0,1.05-0.29,1.33-0.77
			l0.25-0.42l-17.32-10V9.53l14.98,8.65c0.23,0.13,0.5,0.21,0.76,0.21c0.55,0,1.05-0.29,1.33-0.77l0.25-0.42l-17.32-10V4.51
			l14.98,8.65c0.23,0.13,0.5,0.21,0.76,0.21c0.54,0,1.05-0.29,1.33-0.76l0.25-0.42L409.88,1.89l0,0c-0.15-0.09-0.32-0.14-0.5-0.14
			c-0.56,0-1.01,0.45-1.01,1.01v40.17l0,0c0,0.56,0.45,1.01,1.01,1.01c0.18,0,0.35-0.05,0.49-0.13l0,0c0,0,0.01,0,0.01-0.01
			l17.4-10.04c0,0,0,0,0,0C427.58,33.59,427.78,33.27,427.78,32.89z M410.39,24.6l2.13,1.23l-2.13,1.23V24.6z M410.39,29.39
			l4.15-2.4l2.06,1.19l-6.22,3.58V29.39z M410.39,34.1l8.24-4.75l2.07,1.2l-10.31,5.96V34.1z M410.39,41.19v-2.35l12.33-7.12
			l2.04,1.18L410.39,41.19z"
          />
          <path
            fill={colors.brand}
            d="M390.97,13.85v19.53h2.02V12.32h-0.49C391.66,12.32,390.97,13.01,390.97,13.85z"
          />
          <path
            fill={colors.brand}
            d="M404.02,5.85v35.11h2.02V4.32h-0.49C404.7,4.32,404.02,5.01,404.02,5.85z"
          />
          <path
            fill={colors.brand}
            d="M399.67,8.49v29.92h2.02V6.96h-0.49C400.36,6.96,399.67,7.65,399.67,8.49z"
          />
          <path
            fill={colors.brand}
            d="M395.32,11.18v24.66h2.02V9.65h-0.49C396.01,9.65,395.32,10.34,395.32,11.18z"
          />
        </g>
      </g>
    </Svg>
  )
}

export default HomestudiosLogo
