var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseFloor1 = function HouseFloor1(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseFloor1_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseFloor1_title"
  }, "Eerste Verdieping"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseFloor1_g"
  }, __jsx("g", {
    transform: "translate(-110.000000, -767.000000)",
    fill: color,
    "data-id": "icons_HouseFloor1_g_3"
  }, __jsx("g", {
    transform: "translate(40.000000, 761.000000)",
    "data-id": "icons_HouseFloor1_g_4"
  }, __jsx("g", {
    transform: "translate(70.000000, 6.000000)",
    "data-id": "icons_HouseFloor1_g_5"
  }, __jsx("g", {
    transform: "translate(7.500000, 8.125000)",
    fillRule: "nonzero",
    "data-id": "icons_HouseFloor1_g_6"
  }, __jsx("path", {
    d: "M2.09375,8.125 L2.09375,1.9375 L0.53125,2.75 C0.21875,2.3125 0.03125,1.96875 0.03125,1.75 C0.03125,1.53125 0.15625,1.34375 0.4375,1.1875 L2.1875,0.28125 C2.34375,0.1875 2.5,0.125 2.6875,0.09375 C2.84375,0.03125 3,0 3.15625,0 C3.40625,0 3.65625,0.09375 3.84375,0.1875 L3.84375,8.125 L2.09375,8.125 Z",
    "data-id": "icons_HouseFloor1_path"
  })), __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseFloor1_path_8"
  }))))));
};
HouseFloor1.defaultProps = defaultProps;
export default HouseFloor1;