import { FC } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import radii from '@/styles/radii'
import { CounterIconProps } from '@/types/svgTypes'

type OnClick = () => void

type Props = {
  icon: FC<CounterIconProps>
  onClick: OnClick
  disabled?: boolean
}

type CounterBtnProps = Omit<Props, 'icon'>

const CounterBtn = styled.button.attrs<CounterBtnProps>(({ disabled, onClick }) => ({
  disabled,
  onClick,
}))<CounterBtnProps>`
  ${radii.circle}
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? colors.gray20 : colors.brand)};
  ${({ disabled }) => (disabled ? `cursor: default` : `cursor: pointer`)};
`

const CounterButton: FC<Props> = ({ icon, ...props }) => {
  const Icon = icon
  return (
    <CounterBtn {...props}>
      <Icon width={8} />
    </CounterBtn>
  )
}

export default CounterButton
