import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { GridWrapper, GridItem } from '../../../styles/gridElements';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
import { fromBreakpoint, toBreakpoint } from '../../../styles/breakpoints';
import RichTextBlock from '../../RichTextBlock';
import withMatch from '@/hocs/withMatch';
var LayoutFooterPage = function LayoutFooterPage(_ref) {
  var match = _ref.match,
    title = _ref.title,
    footerPage = _ref.footerPage;
  var footerPageSlug = match.params.footerPageSlug;
  return __jsx(GridWrapper, {
    "data-id": "layout_LayoutFooterPage_GridWrapper"
  }, __jsx(CopyCell, {
    "data-id": "layout_LayoutFooterPage_CopyCell"
  }, footerPageSlug && __jsx(Title, {
    "data-id": "layout_LayoutFooterPage_Title"
  }, title)), __jsx(BlockCell, {
    "data-id": "layout_LayoutFooterPage_BlockCell"
  }, footerPage && __jsx(RichTextBlock, {
    bbcode: footerPage,
    "data-id": "layout_LayoutFooterPage_RichTextBlock"
  })));
};
var CopyCell = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 820rem;\n  margin-left: auto;\n  margin-right: auto;\n"])));
var BlockCell = styled(GridItem)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-top: 20rem;\n  padding-bottom: 20rem;\n  margin-right: 10rem;\n  margin-left: 10rem;\n  padding-right: 10rem;\n  padding-left: 10rem;\n"])));
var Title = styled.h1(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  ", ";\n\n  ", ";\n"])), textStyles.pageTitle, colorPalette.headerGrey, toBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-top: 40rem;\n  "]))), fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-top: 60rem;\n  "]))));
export default withMatch(LayoutFooterPage);