import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette, shadows, textStyles } from '../styles/styleGuide';
import { toBreakpoint } from '../styles/breakpoints';
import withMatch from '@/hocs/withMatch';
import withApiData from '../services/withApiData';
import PricelistBlock from './PricelistBlock';
import ContentLoader from './shared/ui/ContentLoader';
var connectApiData = withApiData({
  blocks: 'getBlock'
}, function (ownProps) {
  return {
    blocks: {
      projectSlug: ownProps.match.params.projectSlug,
      env: 'closed',
      blockId: ownProps.priceListBlockId
    }
  };
});
var PricelistModal = function PricelistModal(_ref) {
  var blocks = _ref.blocks,
    renderWidth = _ref.renderWidth;
  return __jsx(Container, {
    "data-id": "components_PricelistModal_Container"
  }, __jsx(Title, {
    "data-id": "components_PricelistModal_Title"
  }, "Maak een nieuw woonontwerp"), __jsx(Intro, {
    "data-id": "components_PricelistModal_Intro"
  }, "Kies hieronder een beschikbare woning en start een nieuw woonontwerp om te ontdekken hoe je van dit huis een thuis kunt maken."), !blocks.data && __jsx(ContentLoader, {
    "data-id": "components_PricelistModal_ContentLoader"
  }), blocks.data && __jsx(PricelistBlock, _extends({
    modal: true,
    renderWidth: renderWidth
  }, blocks.data, {
    "data-id": "components_PricelistModal_PricelistBlock"
  })));
};
export default withMatch(connectApiData(PricelistModal));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  align-self: center;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  padding: 80rem 100rem 80rem 100rem;\n\n  ", ";\n"])), shadows.boxPrimary, toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 40rem 10rem 40rem 10rem;\n  "]))));
var Intro = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  max-width: 760rem;\n"])), textStyles.introText, colorPalette.textGrey);
var Title = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n\n  ", ";\n"])), textStyles.sectionTitle, colorPalette.headerGrey, toBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-bottom: 12rem;\n  "]))));