import React, { ChangeEvent, Component, MouseEvent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import withMatch, { WithMatchProps } from '@/hocs/withMatch'
import withInvalidateWishListRequest, {
  WithInvalidateWishListRequestProps,
} from '@/hocs/withInvalidateWishListRequest'
import Link from '@/components/atoms/Link'
import { State as AppState } from '@/redux/index'
import {
  performApiRequest,
  getApiDataRequest,
  invalidateApiDataRequest,
  getApiDataRequestsForProject,
  ApiDataRequest,
} from '@/redux/apiData'
import { openModal } from '@/redux/modal'
import formatPrice from '@/services/formatPrice'
import Paragraph from '@/components/shared/richText/Paragraph'
import MultipleSelector from '@/components/shared/forms/fieldTypes/MultipleSelector'
import Textarea from '@/components/shared/forms/fieldTypes/Textarea'
import Checkbox from '@/components/shared/forms/fieldTypes/Checkbox'
import ButtonPrimary from '@/components/shared/buttons/ButtonPrimary'
import ButtonUpload from '@/components/shared/buttons/ButtonUpload'
import { colorPalette, colorPaletteRGB, shadows, textStyles } from '@/styles/styleGuide'
import { fromBreakpoint } from '@/styles/breakpoints'
import { WishListOption } from '@/entities/types'
import { Dispatch } from 'redux'
import { RSAAAction } from 'redux-api-middleware'

const TypedButtonUpload = ButtonUpload as (props: {
  label: string
  clickHandler: (e: MouseEvent<HTMLButtonElement>) => void
}) => JSX.Element

const TypedButtonPrimary = ButtonPrimary as (props: {
  type: 'button' | 'submit' | 'externalLink' | 'internalLink'
  loadingLabel?: string
  loading?: boolean
  label: string
  clickHandler: (e: MouseEvent<HTMLButtonElement>) => void
}) => JSX.Element

export type ProductOptionRequestModalProps = {
  projectSlug: string
  modalId: number
  amount: number
  options: Array<WishListOption>
}

type RequestOptionDetails = {
  wishlistOption?: string
  comment?: string
  amount?: number
  option?: string
}

type RequestOptionFile = {
  file?: File
}

type OwnProps = {
  modalId: number
  amount: number
  options: Array<WishListOption>
  projectSlug: string
}

type HOCProps = WithMatchProps & WithInvalidateWishListRequestProps

type MapStateProps = {
  postRequestOptionRequest?: ApiDataRequest
  postRequestOptionFileRequests: { [requestKey: string]: ApiDataRequest }
}

type MapDispatchProps = {
  postRequestOption: (projectSlug: string, details: RequestOptionDetails) => void
  postRequestOptionFile: (projectSlug: string, orderId: number, details: RequestOptionFile) => void
  invalidateData: () => void
  openOrderRequestResultModal: (result: 'success' | 'failed') => void
  resetRequest: (projectSlug: string) => void
}

type Props = OwnProps & HOCProps & MapStateProps & MapDispatchProps

type State = {
  optionAmount: number
  comment: string
  uploadedFile: File | null
  uploadError: string | null
  termsValid: boolean
  termsChecked: boolean
  isSubmitting: boolean
}

const mapStateToProps = (state: AppState, { match }: OwnProps & HOCProps): MapStateProps => {
  return {
    postRequestOptionRequest: getApiDataRequest(state.apiData, 'postRequestOption', {
      projectSlug: String(match.params.projectSlug),
    }),
    postRequestOptionFileRequests: getApiDataRequestsForProject(
      state.apiData,
      'postRequestOptionFile',
      match.params.projectSlug
    ),
  }
}

type DispatchArg = RSAAAction<unknown, unknown, unknown>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps & HOCProps
): MapDispatchProps => {
  const { wishListId, projectSlug } = ownProps.match.params
  return {
    postRequestOption: (projectSlug: string, details: RequestOptionDetails) => {
      const { wishListId } = ownProps.match.params
      dispatch(
        performApiRequest('postRequestOption', { projectSlug }, details) as unknown as DispatchArg
      )
      if (wishListId) {
        dispatch(invalidateApiDataRequest('getWishList', { projectSlug, wishListId }))
      }
    },
    postRequestOptionFile: (projectSlug: string, orderId: number, details: RequestOptionFile) => {
      dispatch(
        performApiRequest(
          'postRequestOptionFile',
          { projectSlug, orderId },
          details
        ) as unknown as DispatchArg
      )
    },
    openOrderRequestResultModal: (result: 'success' | 'failed') => {
      dispatch(
        openModal(colorPalette.orange, {
          type: 'orderRequestResult',
          componentProps: { result },
        })
      )
    },
    invalidateData: () => {
      if (wishListId) {
        dispatch(invalidateApiDataRequest('getWishList', { projectSlug, wishListId }))
      }
      dispatch(invalidateApiDataRequest('postRequestOption', { projectSlug }))
    },
    resetRequest: (projectSlug: string) => {
      dispatch(invalidateApiDataRequest('postRequestOption', { projectSlug }))
    },
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: ${colorPalette.white};
  ${shadows.boxPrimary};
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding: 48rem 20rem;
  box-sizing: border-box;

  ${fromBreakpoint.md`
    padding: 100rem 20rem;
  `};
`

const Title = styled.h2`
  ${textStyles.pageTitle};
  color: ${colorPalette.headerGrey};
  margin: 0 0 32rem;
`

const RequestContainer = styled.div`
  margin-top: 40rem;
  padding-top: 32rem;
  border-top: 1rem dashed ${colorPalette.lightBeige};
`

const OptionTitle = styled.h3`
  margin: 0 0 12rem;

  ${textStyles.sectionTitle};
  color: ${colorPalette.headerGrey};
`

const OptionCode = styled.span`
  ${textStyles.caption};
  color: ${colorPalette.beige};
  font-size: 14rem;
`

const RequestDetails = styled.section`
  padding: 32rem 100rem 60rem 40rem;
  margin: 40rem 0;
  background-color: rgba(${colorPaletteRGB.loadingBlue}, 0.25);
  color: ${colorPalette.headerGrey};

  ${textStyles.caption};
`

const RequestTitle = styled.h4`
  ${textStyles.title};
  margin: 0 0 12rem;
`

const RequestSubTitle = styled.h5`
  ${textStyles.subTitle};
  margin-bottom: 8rem;

  &:last-of-type {
    margin-bottom: 20rem;
  }
`

const RequestItemPrice = styled.span`
  ${textStyles.caption};
  margin-left: 8rem;
`

const RequestTotal = styled.div`
  ${textStyles.title};
  font-weight: 400;
`

const MultipleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 32rem;

  > ${RequestSubTitle} {
    flex: 1 0 100%;
    margin: 0 0 8rem;
  }
`

const TextAreaContainer = styled.div`
  margin: 0 0 22rem;
`

const UploadContainer = styled.div``

const FileInput = styled.input`
  display: none;
`

const FileName = styled.div`
  margin: 5rem 0;
  font-style: italic;
`

const ErrorMessage = styled.div`
  color: ${colorPalette.red};
`

const CheckboxContainer = styled.div`
  margin-bottom: 32rem;
`

class ProductOptionRequestModal extends Component<Props, State> {
  fileInput: HTMLElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {
      optionAmount: 1,
      comment: '',
      uploadedFile: null,
      uploadError: null,
      termsValid: true,
      termsChecked: false,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    const { amount } = this.props
    const { optionAmount } = this.state
    if (amount !== optionAmount) {
      this.setState({ optionAmount: amount })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { uploadedFile } = this.state
    const {
      postRequestOptionRequest,
      postRequestOptionFile,
      postRequestOptionFileRequests,
      openOrderRequestResultModal,
      match,
      resetRequest,
    } = this.props

    const postRequestOptionStatus =
      postRequestOptionRequest && postRequestOptionRequest.networkStatus

    if (postRequestOptionStatus === 'success') {
      if (uploadedFile) {
        const fileData = new FormData()
        if (uploadedFile) {
          fileData.append('file', uploadedFile)
        }
        if (postRequestOptionRequest) {
          postRequestOptionFile(
            String(match.params.projectSlug),
            Number(postRequestOptionRequest.result),
            fileData as RequestOptionFile
          )
        }
      } else {
        this.invalidateDataAfterRequests()
        openOrderRequestResultModal('success')
        resetRequest(String(match.params.projectSlug))
      }
    } else if (postRequestOptionStatus === 'failed') {
      this.invalidateDataAfterRequests()
      openOrderRequestResultModal('failed')
      resetRequest(String(match.params.projectSlug))
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(postRequestOptionFileRequests)) {
      if (
        prevProps.postRequestOptionFileRequests[key]?.networkStatus === 'loading' &&
        (value.networkStatus === 'success' || value.networkStatus === 'failed')
      ) {
        this.invalidateDataAfterRequests()
        openOrderRequestResultModal(value.networkStatus)
        break
      }
    }
  }

  invalidateDataAfterRequests = () => {
    const { invalidateData, invalidateWishListRequest } = this.props
    invalidateData()
    invalidateWishListRequest()
  }

  handleChangeAmount = (value: number) => {
    this.setState({ optionAmount: value })
  }

  handleTextInput = () => {
    const textAreaEl = document && document.querySelector('#requestInfo')
    if (textAreaEl && textAreaEl instanceof HTMLTextAreaElement) {
      this.setState({ comment: textAreaEl.value })
    }
  }

  handleUploadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (this.fileInput) {
      this.fileInput.click()
    }
  }

  handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const file = e.currentTarget?.files?.[0]
      if (!file) {
        this.setState({ uploadError: 'Het uploaden van dit bestand is niet gelukt.' })
        return
      }
      if (file.size >= 2000000) {
        this.setState({
          uploadError:
            'De gekozen afbeelding is te groot. Kies een afbeelding die kleiner is dan 2mb.',
        })
        return
      }
      this.setState({ uploadedFile: file, uploadError: null })
    }
  }

  handleCheckClick = () => {
    const { termsChecked } = this.state
    this.setState({ termsValid: !termsChecked, termsChecked: !termsChecked })
  }

  submitRequest = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const { postRequestOption, projectSlug, modalId, options } = this.props
    const { optionAmount, comment, termsChecked } = this.state
    const productOptionData = options.find((option) => option.id === modalId)
    if (termsChecked) {
      this.setState({ isSubmitting: true })
      const formData = new FormData()
      if (productOptionData && productOptionData.wishlistOptionId) {
        formData.append('wishlistOption', String(productOptionData.wishlistOptionId))
      }
      formData.append('comment', comment)
      formData.append('amount', String(optionAmount))
      formData.append('option', String(modalId))

      postRequestOption(projectSlug, formData as RequestOptionDetails)
    } else {
      this.setState({ termsValid: false })
    }
  }

  render() {
    const { projectSlug, modalId, options } = this.props
    const { optionAmount, comment, uploadedFile, uploadError, termsValid, isSubmitting } =
      this.state

    const productOptionData = options.find((option) => option.id === modalId)

    if (!productOptionData) {
      return null
    }

    return (
      <Container>
        <Content>
          <Title>Optie aanvragen</Title>
          <Paragraph>
            Op deze plaats kun je een aanvraag voor deze optie starten. Nadat je deze optie hebt
            aangevraagd, zullen wij allereerst beoordelen of de aangevraagde optie (bijvoorbeeld in
            combinatie met andere opties) kan worden gerealiseerd. Vervolgens verwerken we de optie
            en krijg je deze (eventueel voorzien van extra documentatie en een koperstekening waarop
            de optie is verwerkt) ter bevestiging voorgelegd. Hulp nodig? Neem contact op met je
            woonadviseur.
          </Paragraph>
          <RequestContainer>
            <OptionTitle>{productOptionData.title}</OptionTitle>
            {productOptionData.optionCode && (
              <OptionCode>Optiecode: {productOptionData.optionCode}</OptionCode>
            )}
            <RequestDetails>
              <RequestTitle>Controleer en vul aan</RequestTitle>
              <p>
                Controleer hier of het aantal (wanneer van toepassing) en de totaalprijs van de
                optie-aanvraag correct zijn. Wanneer je extra informatie wilt geven over de aanvraag
                (zoals de positie waarop een optie dient te worden gerealiseerd), kun je dat doen
                met behulp van aanvullende informatie en één of meerdere bijlagen.
              </p>
              {productOptionData.multiple && (
                <MultipleContainer>
                  <RequestSubTitle>Optie aantal</RequestSubTitle>
                  <MultipleSelector
                    amount={optionAmount}
                    changeHandler={this.handleChangeAmount}
                  />
                  <RequestItemPrice>
                    a{' '}
                    {formatPrice(productOptionData.price, {
                      showDecimal: true,
                      showEuroSign: true,
                    })}{' '}
                    per stuk
                  </RequestItemPrice>
                </MultipleContainer>
              )}
              <RequestSubTitle>Totaal bedrag</RequestSubTitle>
              <RequestTotal>
                {formatPrice(productOptionData.price * optionAmount, {
                  showDecimal: true,
                  showEuroSign: true,
                })}
              </RequestTotal>
              <TextAreaContainer>
                <RequestSubTitle>Aanvullende informatie (optioneel)</RequestSubTitle>
                <Textarea
                  id="requestInfo"
                  name="requestInfo"
                  title="Toelichting (optioneel)"
                  defaultValue={comment}
                  className={comment ? 'filled' : ''}
                  onChange={this.handleTextInput}
                  required={false}
                />
              </TextAreaContainer>

              <UploadContainer>
                <FileInput
                  type="file"
                  accept=".jpg, .png, .pdf"
                  ref={(el) => {
                    this.fileInput = el
                  }}
                  onChange={this.handleFileUpload}
                />
                <TypedButtonUpload
                  label="Bijlage toevoegen (optioneel)"
                  clickHandler={this.handleUploadClick}
                />
                {uploadedFile && <FileName>{uploadedFile.name}</FileName>}
                {!!uploadError && <ErrorMessage>{uploadError}</ErrorMessage>}
              </UploadContainer>
            </RequestDetails>
            <CheckboxContainer>
              <Checkbox
                id="requestTerms"
                name="requestTerms"
                isValid={termsValid}
                validationMessage="U dient akkoord te gaan met de algemene voorwaarden"
                onChange={this.handleCheckClick}
              >
                Ik ga akkoord met de{' '}
                <Link
                  href={`/${String(projectSlug)}/open/sectionpage/disclaimer`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  algemene voorwaarden
                </Link>
              </Checkbox>
            </CheckboxContainer>
            <TypedButtonPrimary
              type="submit"
              label="Aanvraag verzenden"
              clickHandler={this.submitRequest}
              loading={isSubmitting}
              loadingLabel={isSubmitting ? 'Uw aanvraag wordt verzonden' : undefined}
            />
          </RequestContainer>
        </Content>
      </Container>
    )
  }
}

export default withMatch(
  withInvalidateWishListRequest(
    connect(mapStateToProps, mapDispatchToProps)(ProductOptionRequestModal)
  )
)
