import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Link from '@/components/atoms/Link';
import withMatch from '@/hocs/withMatch';
import { textStyles, colorPalette, shadows } from '../styles/styleGuide';
import PriceTag from './shared/designElements/PriceTag';
import ImageLoader from './shared/ui/ImageLoader';
import { fromBreakpoint } from '../styles/breakpoints';
var HouseCard = function HouseCard(_ref) {
  var match = _ref.match,
    slug = _ref.slug,
    media = _ref.media,
    variantName = _ref.variantName,
    variantCodes = _ref.variantCodes,
    totalCount = _ref.totalCount,
    isSold = _ref.isSold,
    optionCount = _ref.optionCount,
    availableCount = _ref.availableCount,
    livingSpace = _ref.livingSpace,
    bedrooms = _ref.bedrooms,
    energyLabel = _ref.energyLabel,
    price = _ref.price;
  return __jsx(Card, {
    "data-id": "components_HouseCard_Card"
  }, __jsx(LinkElement, {
    href: "/".concat(String(match.params.projectSlug), "/").concat(String(match.params.env), "/housetype/").concat(slug),
    "data-id": "components_HouseCard_LinkElement"
  }, __jsx(ImageContainer, {
    "data-id": "components_HouseCard_ImageContainer"
  }, media.length > 0 && __jsx(ImageLoader, {
    src: media[0].image.src,
    width: 380,
    mode: "bg",
    position: "absolute",
    scaleTransition: true,
    "data-id": "components_HouseCard_ImageLoader"
  })), __jsx(TextContainer, {
    "data-id": "components_HouseCard_TextContainer"
  }, __jsx(Title, {
    "data-id": "components_HouseCard_Title"
  }, variantName, ' ', variantCodes && variantCodes.length > 0 ? "- ".concat(variantCodes.join(', ')) : ""), __jsx(Availability, {
    "data-id": "components_HouseCard_Availability"
  }, __jsx(Quantity, {
    "data-id": "components_HouseCard_Quantity"
  }, totalCount === 1 ? "".concat(totalCount, " woning") : "".concat(totalCount, " woningen")), !isSold && availableCount > 0 && __jsx(Seperation, {
    "data-id": "components_HouseCard_Seperation"
  }, "|"), !isSold && availableCount > 0 && __jsx(Available, {
    "data-id": "components_HouseCard_Available"
  }, availableCount, " te koop"), optionCount > 0 && !isSold && __jsx(Seperation, {
    "data-id": "components_HouseCard_Seperation"
  }, " | "), optionCount > 0 && !isSold && __jsx(Option, {
    "data-id": "components_HouseCard_Option"
  }, optionCount, " onder optie")), __jsx(PriceContainer, {
    "data-id": "components_HouseCard_PriceContainer"
  }, !isSold && __jsx(StartingPrice, {
    "data-id": "components_HouseCard_StartingPrice"
  }, "Vanaf"), __jsx(PriceTag, {
    price: price,
    isSold: isSold,
    "data-id": "components_HouseCard_PriceTag"
  }), !isSold && __jsx(FreeOnName, {
    "data-id": "components_HouseCard_FreeOnName"
  }, "v.o.n.")), __jsx(List, {
    "data-id": "components_HouseCard_List"
  }, __jsx(ListItem, {
    "data-id": "components_HouseCard_ListItem"
  }, "Woonoppervlak: ", __jsx(Strong, {
    "data-id": "components_HouseCard_Strong"
  }, livingSpace, " m\xB2")), __jsx(ListItem, {
    "data-id": "components_HouseCard_ListItem"
  }, "Slaapkamers: ", __jsx(Strong, {
    "data-id": "components_HouseCard_Strong"
  }, bedrooms)), energyLabel && __jsx(ListItem, {
    "data-id": "components_HouseCard_ListItem"
  }, "Energielabel: ", __jsx(Strong, {
    "data-id": "components_HouseCard_Strong"
  }, energyLabel))))));
};
export default withMatch(HouseCard);
var Card = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n"])));
var ImageContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  top: 0;\n  left: 0;\n  height: 160rem;\n  width: 100%;\n  position: absolute;\n  overflow: hidden;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    height: 215rem;\n  "]))));
var LinkElement = styled(Link)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-decoration: none;\n  background-color: #ffffff;\n  height: 100%;\n  width: 100%;\n  position: relative;\n  display: block;\n  box-sizing: border-box;\n  padding-top: 160rem;\n  padding-bottom: 15rem;\n  ", ";\n  transition: box-shadow 0.25s ease;\n\n  ", " > div:first-of-type {\n    backface-visibility: visible;\n  }\n\n  &:hover {\n    ", ";\n\n    ", " > div:first-of-type {\n      transform: scale(1.03) translateZ(0);\n    }\n  }\n\n  ", ";\n"])), shadows.boxPrimary, ImageContainer, shadows.boxPrimaryHover, ImageContainer, fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    padding-top: 215rem;\n  "]))));
var TextContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: 0 20rem;\n"])));
var Title = styled.h3(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 25rem;\n  margin-bottom: 10rem;\n  min-height: 60rem;\n"])), textStyles.title, colorPalette.headerGrey);
var Availability = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 14rem;\n"])));
var Quantity = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 16rem;\n"])), textStyles.bodyText);
var Available = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 16rem;\n  color: ", ";\n"])), textStyles.bodyText, colorPalette.green);
var Option = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 16rem;\n  color: ", ";\n"])), textStyles.bodyText, colorPalette.signalOrange);
var Seperation = styled.span(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 16rem;\n  color: ", ";\n  margin-left: 5rem;\n  margin-right: 5rem;\n"])), textStyles.bodyText, colorPalette.lightGrey);
var PriceContainer = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 20rem;\n"])), textStyles.caption, colorPalette.textGrey);
var List = styled.ul(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  padding: 0;\n  margin-top: 0;\n  list-style: none;\n"])), textStyles.caption, colorPalette.textGrey);
var ListItem = styled.li(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  margin-bottom: 4rem;\n"])));
var StartingPrice = styled.span(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: inline-block;\n  margin-right: 12rem;\n"])));
var FreeOnName = styled.span(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  display: inline-block;\n  margin-left: 10rem;\n"])));
var Strong = styled.span(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  font-weight: bold;\n"])));