import { getScrollbarWidth } from '@/processes/modalHelpers'
import { useRef } from 'react'

export const useScrollbarWidth = () => {
  const didCompute = useRef(false)
  const widthRef = useRef(0)

  if (didCompute.current) return widthRef.current

  const scrollbarWidth = getScrollbarWidth()
  widthRef.current = scrollbarWidth
  didCompute.current = true

  return scrollbarWidth
}
