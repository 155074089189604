import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;
var __jsx = React.createElement;
import React from 'react';
import { GridWrapper, GridItem } from '../../../styles/gridElements';
import { fromBreakpoint } from '../../../styles/breakpoints';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../../../styles/styleGuide';
import PictureCollage from './../../PictureCollage';
import PricelistBlock from '../../PricelistBlock';
import RichTextBlockExpand from '../../RichTextBlockExpand';
import Block from './../../Block';
import isLastItem from '../../../services/isLastItem';
import formatPrice from '../../../services/formatPrice';
var LayoutHouseType = function LayoutHouseType(_ref) {
  var data = _ref.data,
    renderWidth = _ref.renderWidth;
  var variantName = data.variantName,
    variantCodes = data.variantCodes,
    totalCount = data.totalCount,
    isSold = data.isSold,
    availableCount = data.availableCount,
    optionCount = data.optionCount,
    energyLabel = data.energyLabel,
    price = data.price,
    media = data.media,
    description = data.description,
    blocks = data.blocks,
    livingSpace = data.livingSpace,
    bedrooms = data.bedrooms;
  return __jsx(HouseTypeGridWrapper, {
    "data-id": "layout_LayoutHouseType_HouseTypeGridWrapper"
  }, __jsx(PageTitle, {
    "data-id": "layout_LayoutHouseType_PageTitle"
  }, "".concat(variantName, " ").concat(variantCodes && variantCodes.length > 0 ? "- ".concat(variantCodes.join(', ')) : "")), __jsx(AvailabilityList, {
    "data-id": "layout_LayoutHouseType_AvailabilityList"
  }, __jsx(AvailabilityItem, {
    "data-id": "layout_LayoutHouseType_AvailabilityItem"
  }, totalCount, " ", totalCount === 1 ? 'woning' : 'woningen', !isSold && __jsx(AvailabilityDivider, {
    "data-id": "layout_LayoutHouseType_AvailabilityDivider"
  })), availableCount !== 0 && __jsx(AvailabilityItem, {
    color: colorPalette.green,
    "data-id": "layout_LayoutHouseType_AvailabilityItem"
  }, availableCount, " te koop", optionCount !== 0 && __jsx(AvailabilityDivider, {
    "data-id": "layout_LayoutHouseType_AvailabilityDivider"
  })), optionCount !== 0 && __jsx(AvailabilityItem, {
    color: colorPalette.signalOrange,
    "data-id": "layout_LayoutHouseType_AvailabilityItem"
  }, optionCount, " onder optie")), __jsx(PropertyInfoList, {
    "data-id": "layout_LayoutHouseType_PropertyInfoList"
  }, __jsx(PropertyInfoItem, {
    line: energyLabel,
    "data-id": "layout_LayoutHouseType_PropertyInfoItem"
  }, __jsx(PropertyInfoLabel, {
    "data-id": "layout_LayoutHouseType_PropertyInfoLabel"
  }, "Woonoppervlak: "), __jsx(PropertyInfoValue, {
    "data-id": "layout_LayoutHouseType_PropertyInfoValue"
  }, __jsx("span", {
    "data-id": "layout_LayoutHouseType_span"
  }, livingSpace, "m\xB2"))), __jsx(PropertyInfoItem, {
    line: energyLabel,
    "data-id": "layout_LayoutHouseType_PropertyInfoItem"
  }, __jsx(PropertyInfoLabel, {
    "data-id": "layout_LayoutHouseType_PropertyInfoLabel"
  }, "Slaapkamers: "), __jsx(PropertyInfoValue, {
    "data-id": "layout_LayoutHouseType_PropertyInfoValue"
  }, __jsx("span", {
    "data-id": "layout_LayoutHouseType_span"
  }, bedrooms))), energyLabel && __jsx(PropertyInfoItem, {
    line: energyLabel,
    "data-id": "layout_LayoutHouseType_PropertyInfoItem"
  }, __jsx(PropertyInfoLabel, {
    "data-id": "layout_LayoutHouseType_PropertyInfoLabel"
  }, "Energielabel: "), __jsx(PropertyInfoValue, {
    "data-id": "layout_LayoutHouseType_PropertyInfoValue"
  }, __jsx("span", {
    "data-id": "layout_LayoutHouseType_span"
  }, energyLabel))), __jsx(PropertyInfoItem, {
    line: energyLabel,
    "data-id": "layout_LayoutHouseType_PropertyInfoItem"
  }, __jsx(PropertyInfoLabel, {
    "data-id": "layout_LayoutHouseType_PropertyInfoLabel"
  }, "Prijs: "), isSold ? __jsx(PropertyInfoValue, {
    "data-id": "layout_LayoutHouseType_PropertyInfoValue"
  }, __jsx("span", {
    "data-id": "layout_LayoutHouseType_span"
  }, "Verkocht")) : __jsx(PropertyInfoValue, {
    "data-id": "layout_LayoutHouseType_PropertyInfoValue"
  }, "vanaf ", __jsx("span", {
    "data-id": "layout_LayoutHouseType_span"
  }, formatPrice(price, {
    showDecimal: true
  })), " v.o.n."))), __jsx(PictureCollage, {
    media: media,
    renderWidth: renderWidth,
    "data-id": "layout_LayoutHouseType_PictureCollage"
  }), __jsx(ContentWrapper, {
    "data-id": "layout_LayoutHouseType_ContentWrapper"
  }, __jsx(RichTextBlockExpand, {
    richtext: description,
    "data-id": "layout_LayoutHouseType_RichTextBlockExpand"
  })), blocks.map(function (block, index) {
    switch (block.type) {
      case 'richtext':
        return __jsx(ContentWrapper, {
          "data-id": "layout_LayoutHouseType_ContentWrapper"
        }, __jsx(RichTextCell, {
          key: index,
          "data-id": "layout_LayoutHouseType_RichTextCell"
        }, __jsx(Block, {
          block: block,
          isLastItem: isLastItem(blocks, index),
          "data-id": "layout_LayoutHouseType_Block"
        })));
      case 'pricelist':
        return __jsx(PriceListCell, {
          key: index,
          "data-id": "layout_LayoutHouseType_PriceListCell"
        }, __jsx(PricelistBlock, _extends({
          layoutHouseType: true,
          renderWidth: renderWidth
        }, block, {
          isLastItem: isLastItem(blocks, index),
          "data-id": "layout_LayoutHouseType_PricelistBlock"
        })));
      case 'housetypeCarousel':
      default:
        return __jsx(CarouselCell, {
          key: index,
          "data-id": "layout_LayoutHouseType_CarouselCell"
        }, __jsx(TypeTitle, {
          "data-id": "layout_LayoutHouseType_TypeTitle"
        }, "Andere woningen bekijken"), __jsx(Block, {
          block: block,
          isLastItem: isLastItem(blocks, index),
          "data-id": "layout_LayoutHouseType_Block"
        }));
    }
  }));
};
export default LayoutHouseType;
var RichTextCell = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 20rem;\n  padding-bottom: 20rem;\n  margin-bottom: 10rem;\n"])));
var PriceListCell = styled(GridItem)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 0;\n  margin-bottom: 50rem;\n"])));
var CarouselCell = styled(GridItem)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 0;\n"])));
var ContentWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 780rem;\n  margin: 50rem auto 10rem auto;\n  position: relative;\n  max-height: ", "rem;\n  overflow: hidden;\n  transition: max-height 0.4s ease-in-out;\n  z-index: 1;\n"])), function (props) {
  return !props.showMore ? props.maxHeight : '300';
});
var HouseTypeGridWrapper = styled(GridWrapper)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  padding: 40rem 20rem 0 20rem;\n"])));
var PageTitle = styled.h1(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  width: 100%;\n  margin-top: 0rem;\n  margin-bottom: 5rem;\n\n  ", ";\n"])), textStyles.pageTitle, colorPalette.headerGrey, fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    margin-bottom: 20rem;\n  "]))));
var TypeTitle = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 40rem;\n  margin-bottom: 20rem;\n"])), textStyles.sectionTitle, colorPalette.textGrey);
var AvailabilityList = styled.ul(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", ";\n  list-style: none;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  margin-bottom: 20rem;\n\n  ", ";\n"])), textStyles.bodyText, fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    margin-bottom: 40rem;\n  "]))));
var AvailabilityItem = styled.li(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: inline-block;\n  position: relative;\n  margin-bottom: 5rem;\n  color: ", ";\n"])), function (props) {
  return props.color ? props.color : colorPalette.textGrey;
});
var AvailabilityDivider = styled.span(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 1rem;\n  height: 20rem;\n  background-color: ", ";\n  margin: 0 10rem;\n  position: relative;\n  top: 4rem;\n"])), colorPalette.lightGrey);
var PropertyInfoList = styled.ul(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  ", ";\n  list-style: none;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  margin-bottom: 20rem;\n\n  ", ";\n"])), textStyles.bodyText, fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    margin-bottom: 20rem;\n    display: flex;\n    justify-content: space-around;\n  "]))));
var PropertyInfoItem = styled.li(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: block;\n  width: 100%;\n  position: relative;\n  margin-bottom: 5rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    padding: 0 10rem;\n\n    ", "\n  "])), function (props) {
  return props.line ? "&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {\n        &:after {\n          position: absolute;\n          content: '';\n          width: 2rem;\n          height: 45rem;\n          background-color: ".concat(colorPalette.lightGrey, ";\n          right: 0;\n          top: 4rem;\n        }") : "&:nth-of-type(1), &:nth-of-type(2) {\n        &:after {\n          position: absolute;\n          content: '';\n          width: 2rem;\n          height: 45rem;\n          background-color: ".concat(colorPalette.lightGrey, ";\n          right: 0;\n          top: 4rem;\n        }");
}));
var PropertyInfoLabel = styled.span(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  ", ";\n"])), fromBreakpoint.md(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n      display: block;\n      text-align: center;\n  "]))));
var PropertyInfoValue = styled.span(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  > span {\n    font-weight: bold;\n  }\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n      display: block;\n      text-align: center;\n  "]))));