import React from 'react'
import { Storey } from '@/entities/types'
import MaxWidth from '@/atoms/MaxWidth'
import Button from '@/atoms/Button'
import Sticky from '@/atoms/Sticky'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import MapIcon from '@/svg/MapIcon'

type Props = {
  storeys?: Storey[]
  openModal: () => void
  disableFloorPlan?: boolean
}

const LayoutStickyButton = ({ storeys, openModal, disableFloorPlan = false }: Props) => {
  const {
    isLargerThan: { slg },
  } = useBreakpointInfo()

  // has a storeys array of which the first item has a floorPlan svg
  const hasStoreys = !!storeys?.[0]?.floorPlan

  if (!hasStoreys || slg || disableFloorPlan) {
    return null
  }

  return (
    <Sticky withPadding>
      <MaxWidth>
        <Button
          shadow
          text="Bekijk plattegrond"
          variant="secondary"
          onClick={openModal}
          icon={MapIcon}
        />
      </MaxWidth>
    </Sticky>
  )
}

export default LayoutStickyButton
