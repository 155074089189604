import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { shadows, colorPalette, textStyles } from '../../styles/styleGuide';
import { toBreakpoint, fromBreakpoint } from '../../styles/breakpoints';
import ImageLoader from './../shared/ui/ImageLoader';
import ButtonSecondary from './../shared/buttons/ButtonSecondary';
import PlaceholderUserCJ from './../shared/icons/PlaceholderUserCJ';
import Check from './../shared/icons/Check';
var NeighboursBlock = function NeighboursBlock(_ref) {
  var selfPerson = _ref.self,
    match = _ref.match,
    _ref$neighbours = _ref.neighbours,
    neighbours = _ref$neighbours === void 0 ? [] : _ref$neighbours;
  return __jsx(Container, {
    "data-e2e-block-neighbours": true,
    "data-id": "dashboard_NeighboursBlock_Container"
  }, selfPerson && __jsx(Self, {
    "data-id": "dashboard_NeighboursBlock_Self"
  }, __jsx(UserImage, {
    "data-id": "dashboard_NeighboursBlock_UserImage"
  }, selfPerson.customerImage && __jsx(ImageLoader, {
    src: selfPerson.customerImage,
    position: "relative",
    mode: "bg",
    width: 200,
    height: 200,
    alt: selfPerson.customerName,
    "data-id": "dashboard_NeighboursBlock_ImageLoader"
  }), (!selfPerson.publicProfile || !selfPerson.customerImage) && __jsx(PlaceholderUserCJ, {
    width: 80,
    height: 80,
    color: colorPalette.lightOrange,
    "data-id": "dashboard_NeighboursBlock_PlaceholderUserCJ"
  })), !selfPerson.publicProfile && __jsx(Hide, {
    "data-id": "dashboard_NeighboursBlock_Hide"
  }, __jsx(Check, {
    color: colorPalette.lightBeige,
    "data-id": "dashboard_NeighboursBlock_Check"
  }), "Huishouden anoniem voor buren"), __jsx(UserName, {
    "data-id": "dashboard_NeighboursBlock_UserName"
  }, selfPerson.customerName || 'Toekomstige bewoner(s)'), __jsx(BNR, {
    "data-id": "dashboard_NeighboursBlock_BNR"
  }, "Bouwnummer ", selfPerson.bnr), __jsx(Intro, {
    "data-id": "dashboard_NeighboursBlock_Intro"
  }, selfPerson.publicProfile ? selfPerson.bio : ''), __jsx(ButtonSecondary, {
    label: "Profielinformatie aanpassen",
    type: "externalLink",
    link: "/".concat(String(match.params.projectSlug), "/closed/profiel/"),
    "data-id": "dashboard_NeighboursBlock_ButtonSecondary"
  })), neighbours && __jsx(NeighboursContainer, {
    "data-id": "dashboard_NeighboursBlock_NeighboursContainer"
  }, neighbours.map(function (neighbour, index) {
    return __jsx(Block, {
      key: index,
      oneBeforeLast: neighbours.length % 2 === 0 && index === neighbours.length - 2,
      last: neighbours.length % 2 === 1 ? index === neighbours.length - 1 : index === neighbours.length - 1 || index === neighbours.length - 2,
      "data-id": "dashboard_NeighboursBlock_Block"
    }, __jsx(NeighbourImage, {
      "data-id": "dashboard_NeighboursBlock_NeighbourImage"
    }, neighbour.customerImage && __jsx(ImageLoader, {
      src: neighbour.customerImage,
      position: "relative",
      mode: "bg",
      width: 200,
      height: 200,
      alt: neighbour.customerName,
      "data-id": "dashboard_NeighboursBlock_ImageLoader"
    }), (!neighbour.publicProfile || !neighbour.customerImage) && __jsx(PlaceholderUserCJ, {
      width: 64,
      height: 64,
      color: colorPalette.lightOrange,
      "data-id": "dashboard_NeighboursBlock_PlaceholderUserCJ"
    })), __jsx("div", {
      "data-id": "dashboard_NeighboursBlock_div"
    }, __jsx(UserName, {
      "data-id": "dashboard_NeighboursBlock_UserName"
    }, neighbour.customerName || 'Toekomstige bewoner(s)'), __jsx(BNR, {
      "data-id": "dashboard_NeighboursBlock_BNR"
    }, "Bouwnummer ", neighbour.bnr), __jsx(Bio, {
      "data-id": "dashboard_NeighboursBlock_Bio"
    }, !neighbour.publicProfile ? '' : neighbour.bio === 'Stel jezelf of jouw gezin hier voor aan je toekomstige buren.' ? '' : neighbour.bio)));
  })));
};
export default withMatch(NeighboursBlock);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  background-color: white;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 60rem;\n"])), shadows.boxPrimary);
var NeighboursContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: wrap;\n  position: relative;\n\n  :before {\n    content: '';\n    height: 100%;\n    border: dashed 0.5rem #979797;\n    position: absolute;\n    top: 0;\n    right: 50%;\n    display: block;\n\n    ", ";\n  }\n\n  ", ";\n"])), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      display: none;\n    "]))), toBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]))));
var Block = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  border-bottom: ", ";\n  width: calc(50% - 40rem);\n  padding-right: 40rem;\n  padding-top: 40rem;\n  padding-bottom: 60rem;\n  ", " ", ";\n"])), function (props) {
  return props.last ? 'none' : 'dashed 1px #979797';
}, toBreakpoint.lg(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    padding-right: 10rem;\n    padding-bottom: 30rem;\n    padding-top: 24rem;\n    width: calc(50% - 10rem);\n  "]))), toBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    padding-right: 20rem;\n    width: calc(100% - 20rem);\n    border-bottom: ", ";\n  "])), function (props) {
  return !props.last ? 'dashed 1px #979797' : props.oneBeforeLast ? 'dashed 1px #979797' : 'none';
}));
var Bio = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: normal;\n  margin-top: 20rem;\n  ", ";\n"])), textStyles.subTitle, colorPalette.textGrey, toBreakpoint.lg(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    margin-left: -60rem;\n  "]))));
var Hide = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  display: flex;\n  align-items: center;\n  margin-top: 20rem;\n  > svg {\n    margin-right: 5rem;\n  }\n\n  ", ";\n"])), textStyles.caption, colorPalette.beige, fromBreakpoint.md(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 4rem;\n    right: 32rem;\n  "]))));
var Intro = styled.p(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  width: 100%;\n  ", ";\n  color: ", ";\n  font-style: italic;\n  font-weight: normal;\n  text-align: center;\n  margin-top: 20rem;\n"])), textStyles.subTitle, colorPalette.textGrey);
var BNR = styled.h3(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: normal;\n  margin-top: 0;\n  margin-bottom: 0;\n"])), textStyles.subTitle, colorPalette.headerGrey);
var UserName = styled.h2(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 5rem;\n  word-break: break-word;\n  ", ";\n"])), textStyles.title, colorPalette.headerGrey, toBreakpoint.lg(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    margin-top: 2rem;\n  "]))));
var Self = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-bottom: 60rem;\n  padding-right: 32rem;\n  padding-left: 32rem;\n  position: relative;\n  border-bottom: dashed 1px #979797;\n  ", ";\n\n  > a {\n    align-self: center;\n  }\n"])), toBreakpoint.lg(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    padding-bottom: 40rem;\n  "]))));
var UserImage = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 180rem;\n  width: 180rem;\n  overflow: hidden;\n  border-radius: 50%;\n  border: 4px solid white;\n  background-color: ", ";\n  margin-top: -60rem;\n\n  ", " ", "\n\n  > div > div {\n    border-radius: 50%;\n    width: 180rem;\n    height: 180rem;\n    ", " ", ";\n  }\n\n  > svg {\n    margin-top: -12rem;\n    ", " ", ";\n  }\n"])), colorPalette.loadingOrange, toBreakpoint.lg(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    width: 120rem;\n    height: 120rem;\n    margin-top: -40rem;\n  "]))), toBreakpoint.md(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n    width: 80rem;\n    height: 80rem;\n    margin-top: -20rem;\n  "]))), toBreakpoint.lg(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n      width: 120rem;\n      height: 120rem;\n    "]))), toBreakpoint.md(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n      width: 80rem;\n      height: 80rem;\n    "]))), toBreakpoint.lg(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n      margin-top: -8rem;\n      width: 53px !important;\n      height: 53px !important;\n    "]))), toBreakpoint.md(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n      margin-top: -4rem;\n      width: 35px !important;\n      height: 35px !important;\n    "]))));
var NeighbourImage = styled.div(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 140rem;\n  width: 140rem;\n  min-width: 140rem;\n  overflow: hidden;\n  margin-right: 20rem;\n  margin-left: 40rem;\n  border-radius: 50%;\n  border: 4px solid white;\n  background-color: ", ";\n\n  ", ";\n\n  > div > div {\n    border-radius: 50%;\n    width: 140rem;\n    height: 140rem;\n    ", ";\n  }\n\n  > svg {\n    margin-top: -8rem;\n    ", ";\n  }\n"])), colorPalette.loadingOrange, toBreakpoint.lg(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n    width: 60rem;\n    height: 60rem;\n    min-width: 60rem;\n    margin-right: 10rem;\n    margin-left: 10rem;\n  "]))), toBreakpoint.lg(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n      width: 60rem;\n      height: 60rem;\n    "]))), toBreakpoint.lg(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n      margin-top: -4rem;\n      width: 30px !important;\n      height: 30px !important;\n    "]))));