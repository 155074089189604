import React, { FC } from 'react'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import { ICON_COLORS } from '@/constants/colors'
import Svg from './Svg'

const MinusIcon: FC<IconProps> = ({ width = 21, height, variant = 'white' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 8 2"
    >
      <path
        d="M7.9965 0.679625C7.961 0.2975 7.641 0 7.251 0H0V0.99875L0.004125 1.32838C0.04325 1.70688 0.36175 2 0.749 2H8V0.96475L7.9965 0.679625Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default MinusIcon
