import styled, { css } from 'styled-components'
import Image from 'next/image'
import { colors } from '@/styles/colors'
import { PaddingProp, CoveredImageProps, SrcProp } from './CoveredImage.props'

const ContainerOuter = styled.figure`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  position: relative;
`

const getOffsets = ({ withPadding }: PaddingProp) => {
  const offset = withPadding ? 32 : 0
  return css`
    top: ${offset}rem;
    right: ${offset}rem;
    bottom: ${offset}rem;
    left: ${offset}rem;
  `
}

const ContainerInner = styled.div<PaddingProp>`
  ${getOffsets}
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    max-height: 100%;
  }
`

const Blur = styled.div<SrcProp>`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.5;
  ${({ src }) => `background: url(${src}) center center no-repeat;`}
  background-size: cover;
  filter: blur(8rem);
`

const StyledImage = styled(Image)`
  object-fit: contain;
`

const CoveredImage = ({ src, width, height, withPadding = false, ...rest }: CoveredImageProps) => (
  <ContainerOuter>
    <Blur src={src} />
    <ContainerInner withPadding={withPadding}>
      <StyledImage
        alt=""
        src={src}
        layout={!width || !height ? 'fill' : undefined}
        width={width}
        height={height}
        {...rest}
      />
    </ContainerInner>
  </ContainerOuter>
)

export default CoveredImage
