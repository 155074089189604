import {
  IWishList,
  OrderPlace,
  Place,
  Status,
  WishList,
  WishListOptionOrder,
} from '@/entities/wishList'
import { slugify } from '@/processes/houseDesignSlugs'
import { isOptionSelected } from '@/processes/houseDesignStatuses'

const PLACE_TO_STATUS_MAP: Record<Place, Status> = {
  blocked: 'blocked',
  requested: 'requested',
  process: 'processing',
  pending_bim_submit: 'processing',
  bim_submitted: 'processing',
  controle: 'processing',
  processed: 'processed',
  confirmed: 'confirmed',
  presale: 'processing',
  pending_presale_bim_submit: 'processing',
  presale_bim_submitted: 'processing',
  include: 'processing',
  pending_include_bim_submit: 'processing',
  include_bim_submitted: 'processing',
  signed: 'signed',
  presold: 'presold',
  included: 'included',
  irrevocable: 'signed',
}

const PLACE_IGNORE_LIST: OrderPlace[] = ['blank', 'prognosis', 'cancelled', 'cancelled-postsale']

/**
 * After fetching a wish list, wo do some post processing to:
 * 1. filter out the "places" that we don't handle in the front end and
 * 2. map the "places" to "statuses" that we will actually use to implement front end logic
 */
export const wishListPostProcess = (wishList: IWishList): WishList => ({
  ...wishList,
  categories: wishList.categories.map((category) => ({
    ...category,
    subcategories: category.subcategories.map((subcategory) => ({
      ...subcategory,
      options: subcategory.options.map((option) => {
        const orders: WishListOptionOrder[] = []
        option.orders.forEach((order) => {
          const { place } = order
          if (PLACE_IGNORE_LIST.includes(place)) {
            return
          }
          orders.push({
            ...order,
            status: PLACE_TO_STATUS_MAP[place as Place],
          } as WishListOptionOrder)
        })
        return {
          ...option,
          orders,
        }
      }),
    })),
  })),
})

export const getLayoutImages = ({ categories }: WishList): Record<string, string | undefined> => {
  const result: Record<string, string | undefined> = {}

  categories.forEach(({ title, subcategories }) => {
    subcategories.forEach(({ options }) => {
      const imageSrc = options.find((option) => !!option.layoutImage && isOptionSelected(option))
        ?.layoutImage?.src
      if (imageSrc) {
        result[slugify(title)] = imageSrc
      }
    })
  })

  return result
}
