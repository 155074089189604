export const addThousandDots = (n: string): string => {
  return n.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const getPriceString = (n: number): string => {
  const formattedPrice = addThousandDots(n.toFixed(2).replace('.', ','))
  const [price, decimals] = formattedPrice.split(',')

  return `€\u00A0${price},${decimals === '00' ? '–' : decimals}`
}
