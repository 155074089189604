import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Plus from '../icons/Plus';
import { fonts, colorPalette } from './../../../styles/styleGuide';
import { darken } from '../../../styles/utils';
var defaultProps = {
  color: colorPalette.blue
};
var ButtonAdd = function ButtonAdd(_ref) {
  var id = _ref.id,
    clickHandler = _ref.clickHandler,
    color = _ref.color,
    label = _ref.label;
  return __jsx(Button, {
    id: id,
    onClick: clickHandler,
    "data-e2e-button-add": true,
    "data-id": "buttons_ButtonAdd_Button"
  }, __jsx(IconContainer, {
    color: color,
    "data-id": "buttons_ButtonAdd_IconContainer"
  }, __jsx(Plus, {
    "data-id": "buttons_ButtonAdd_Plus"
  })), __jsx(Label, {
    color: color,
    "data-id": "buttons_ButtonAdd_Label"
  }, label));
};
ButtonAdd.defaultProps = defaultProps;
export default ButtonAdd;
var Button = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  font-family: ", ";\n  padding: 10rem 0;\n\n  &:hover {\n    > span {\n      color: ", ";\n    }\n    > div {\n      background-color: ", ";\n    }\n  }\n"])), fonts.primary, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
}, function (props) {
  return props.color ? darken(props.color, 15) : colorPalette.darkBlue;
});
var IconContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  border-radius: 100%;\n  width: 26rem;\n  height: 26rem;\n  background-color: ", ";\n  align-items: center;\n  justify-content: center;\n  margin-right: 10rem;\n  transition: background 0.2s ease-in-out;\n"])), function (props) {
  return props.color;
});
var Label = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: bold;\n  font-size: 18rem;\n  transition: color 0.2s ease-in-out;\n"])), function (props) {
  return props.color;
});