var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Duplicate = function Duplicate(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "18px",
    height: "19px",
    viewBox: "0 0 18 19",
    version: "1.1",
    "data-id": "icons_Copy_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Copy_g"
  }, __jsx("g", {
    transform: "translate(-1275.000000, -486.000000)",
    fill: color,
    "data-id": "icons_Copy_g_2"
  }, __jsx("path", {
    d: "M1286.4,490.75 L1292.1,490.75 L1292.1,505 L1280.7,505 L1280.7,500.25 L1275,500.25 L1275,486 L1286.4,486 L1286.4,490.75 Z M1284.5,490.75 L1284.5,487.9 L1276.9,487.9 L1276.9,498.35 L1280.7,498.35 L1280.7,490.75 L1284.5,490.75 Z M1282.5,492.5 L1282.5,503 L1290.5,503 L1290.5,492.5 L1282.5,492.5 Z",
    id: "Combined-Shape",
    "data-id": "icons_Copy_path"
  }))));
};
Duplicate.defaultProps = defaultProps;
export default Duplicate;