var __jsx = React.createElement;
import React from 'react';
var defaultProps = {
  color: '#ffffff'
};
var Expand = function Expand(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "12px",
    height: "12px",
    viewBox: "0 0 12 12",
    "data-id": "icons_Expand_svg"
  }, __jsx("title", {
    "data-id": "icons_Expand_title"
  }, "Expand"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Expand_g"
  }, __jsx("g", {
    transform: "translate(-3.000000, -3.000000)",
    fill: color,
    "data-id": "icons_Expand_g_3"
  }, __jsx("g", {
    "data-id": "icons_Expand_g_4"
  }, __jsx("g", {
    "data-id": "icons_Expand_g_5"
  }, __jsx("path", {
    d: "M15.3923812,5.70854651 L13.166766,7.93416176 C12.9260866,8.17484112 12.6119519,8.28896071 12.3014653,8.27900953 L12.3014653,8.27900953 C11.9909786,8.28896071 11.6768439,8.17484112 11.4361646,7.93416176 L9.19758792,5.69558513 L7.80344434,4.30157521 L8.61400043,3.49101913 L8.61448728,3.49150598 C8.62039345,3.48534391 8.62638002,3.47922874 8.63244702,3.47316174 C9.09009294,3.01551582 9.826149,3.00958124 10.2897097,3.47314196 L12.3014653,5.48489752 L14.3132208,3.47314196 C14.7680826,3.01828016 15.4853087,3.01545842 15.9444293,3.44787526 L15.9470829,3.44522164 L16.8034443,4.30158311 L15.3944311,5.71059636 L15.3923812,5.70854651 Z",
    transform: "translate(12.303444, 5.703652) scale(-1, 1) rotate(-225.000000) translate(-12.303444, -5.703652) ",
    "data-id": "icons_Expand_path"
  }), __jsx("path", {
    d: "M8.89238125,12.3081559 L6.666766,14.5337711 C6.42608664,14.7744505 6.11195192,14.8885701 5.80146528,14.8786189 L5.80146528,14.8786189 C5.49097864,14.8885701 5.17684392,14.7744505 4.93616456,14.5337711 L2.69758792,12.2951945 L1.30344434,10.9011846 L2.11400043,10.0906285 L2.11448728,10.0911154 C2.12039345,10.0849533 2.12638002,10.0788381 2.13244702,10.0727711 C2.59009294,9.6151252 3.326149,9.60919061 3.78970973,10.0727513 L5.80146528,12.0845069 L7.81322083,10.0727513 C8.26808263,9.61788954 8.98530872,9.61506779 9.44442926,10.0474846 L9.44708287,10.044831 L10.3034443,10.9011925 L8.8944311,12.3102057 L8.89238125,12.3081559 Z",
    transform: "translate(5.803444, 12.303261) scale(1, -1) rotate(-225.000000) translate(-5.803444, -12.303261) ",
    "data-id": "icons_Expand_path_7"
  }), __jsx("circle", {
    cx: "9",
    cy: "9",
    r: "1",
    "data-id": "icons_Expand_circle"
  }))))));
};
Expand.defaultProps = defaultProps;
export default Expand;