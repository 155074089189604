import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colorPalette, shadows, textStyles } from '../styles/styleGuide';
import { fromBreakpoint } from '../styles/breakpoints';
import ButtonPrimary from './shared/buttons/ButtonPrimary';
import { updateModalOpenState, clearModalState } from '../redux/modal';
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onClose: function onClose() {
      dispatch(updateModalOpenState(false));
      dispatch(clearModalState());
    }
  };
};
var FirstWelcomeModal = function FirstWelcomeModal(_ref) {
  var onClose = _ref.onClose,
    type = _ref.type;
  var content;
  if (type === 'firstWelcomeCJ1') {
    content = {
      title: 'Welkom thuis!',
      intro: 'Welkom op het Mijn Homestudios dashboard, jouw uitvalsbasis op de weg naar je nieuwe thuis.',
      paragraphs: ['Het dashboard toont je altijd de meest actuele informatie; je vindt hier een overzicht van je favoriete of laatst bewerkte woonontwerpen, de volgende stappen op de projectplanning, actuele projectupdates en andere artikelen van Homestudios specialisten.', 'Vanuit de diverse onderdelen duik je verder de diepte in; via de planning heb je toegang tot de volledige projectplanning en via de meest recente artikelen ben je met één klik bij het overzicht van alle artikelen en projectupdates. Via het woonontwerpenoverzicht kun je jouw woonontwerpen in detail bekijken. Heb je nog geen woonontwerp gemaakt? Gebruik het blok dan om je eerste woonontwerp te starten en te ontdekken welke woning het beste bij je past.', 'Veel plezier!'],
      button: 'Aan de slag'
    };
  } else if (type === 'firstWelcomeCJ3') {
    content = {
      title: 'Welkom op Mijn Homestudios voor bewoners!',
      intro: 'Waarschijnlijk weet je inmiddels de weg op Mijn Homestudios, maar er is het één en ander veranderd op deze online omgeving voor bewoners.',
      paragraphs: ['De grootste verandering is de toevoeging van de pagina Bewonersinformatie. Hier vind je nuttige informatie over onderhoud, tips voor wanneer je nog gaat klussen en informatie over het melden van storingen en gebreken. En als bewoner van een woning van BAM Wonen houd je natuurlijk exclusief toegang tot de Homestudios webshop; handig voor wanneer je nieuwe interieur nog wat aanvulling kan gebruiken.', 'Veel woonplezier in je nieuwe woning gewenst!'],
      button: 'Aan de slag'
    };
  }
  return __jsx(Container, {
    "data-id": "components_FirstWelcomeModal_Container"
  }, __jsx(Title, {
    "data-id": "components_FirstWelcomeModal_Title"
  }, content && content.title), __jsx(Fragment, null, __jsx(IntroText, {
    "data-id": "components_FirstWelcomeModal_IntroText"
  }, content && content.intro), (content && content.paragraphs || []).map(function (paragraph, index) {
    return __jsx(Text, {
      key: index,
      "data-id": "components_FirstWelcomeModal_Text"
    }, paragraph);
  })), __jsx(ButtonPrimary, {
    label: content && content.button,
    type: "button",
    clickHandler: onClose,
    "data-e2e-firsttimewelcome-modal-submit": true,
    "data-id": "components_FirstWelcomeModal_ButtonPrimary"
  }));
};
export default connect(null, mapDispatchToProps)(FirstWelcomeModal);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  align-self: center;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  padding: 20rem 20rem 40rem 20rem;\n\n  ", ";\n\n  ", ";\n\n  > button {\n    margin-top: 20rem;\n  }\n"])), shadows.boxPrimary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 60rem 70rem 60rem 70rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 80rem 200rem 80rem 200rem;\n  "]))));
var Title = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 20rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var IntroText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 20rem;\n\n  > span {\n    font-style: italic;\n  }\n"])), textStyles.introText, colorPalette.textGrey);
var Text = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n"])), textStyles.bodyText, colorPalette.textGrey);