import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { BreakpointSize, fromBreakpoint } from '@/styles/breakpoints'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children?: ReactNode
  applyUpToBreakpoint?: BreakpointSize
}

const MaxWidthOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`

const MaxWidthInner = styled.div`
  max-width: 520rem;
  width: 100%;
  margin: 0 auto;

  ${fromBreakpoint.slg`
    max-width: 680rem;
  `}
`

const MaxWidth = ({ children, applyUpToBreakpoint }: Props) => {
  const { isLargerThan } = useBreakpointInfo()
  if (!!applyUpToBreakpoint && isLargerThan[applyUpToBreakpoint]) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
  return (
    <MaxWidthOuter>
      <MaxWidthInner>{children}</MaxWidthInner>
    </MaxWidthOuter>
  )
}

export default MaxWidth
