var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var PlanningStep = function PlanningStep(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "15px",
    height: "20px",
    viewBox: "0 0 15 20",
    version: "1.1",
    "data-id": "icons_PlanningStep_svg"
  }, __jsx("title", {
    "data-id": "icons_PlanningStep_title"
  }, "Planning Step"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_PlanningStep_g"
  }, __jsx("g", {
    transform: "translate(-441.000000, -362.000000)",
    fill: color,
    "data-id": "icons_PlanningStep_g_3"
  }, __jsx("g", {
    transform: "translate(389.000000, 354.000000)",
    "data-id": "icons_PlanningStep_g_4"
  }, __jsx("path", {
    d: "M54.2255865,19.3744444 C54.7519272,26.0184317 61.0345507,23.3799764 57.6255185,18.3481827 C56.5475916,18.7186467 55.5947182,18.9531957 54.2255865,19.3744444 M57.7849179,16.7486119 C58.0796452,15.5984708 58.2252475,13.6801013 57.9751403,12.3130246 C57.520133,9.82721691 56.54994,8.38557764 55.2380512,8.04270762 C52.4390224,7.6334946 52.0882264,10.2502269 52.0171866,12.2455073 C51.8598421,13.3613027 52.8047895,17.6691944 53.9537564,18.4811623 C55.0845229,18.1955351 56.1944472,17.915779 57.3169942,17.6624427 C57.6073182,17.5089144 57.5154361,17.559112 57.7849179,16.7486119 M66.4218372,16.9009659 C66.3519715,14.9068597 65.9997078,12.2901274 63.200679,12.699634 C61.8887902,13.042504 60.920065,14.4826755 60.4635899,16.9687767 C60.2134827,18.3355599 60.3593785,20.2551037 60.6538123,21.4052447 C60.9244683,22.2148641 60.8328797,22.1655472 61.1217359,22.3190755 C62.244283,22.5727054 63.3545008,22.8524615 64.486148,23.1363273 C65.6351148,22.3261208 66.5788881,18.0179355 66.4218372,16.9009659 M60.8132116,23.0048155 C61.8911386,23.3741053 62.844012,23.6104156 64.2131436,24.029903 C63.6865094,30.6738902 57.4041795,28.035435 60.8132116,23.0048155",
    "data-id": "icons_PlanningStep_path"
  })))));
};
PlanningStep.defaultProps = defaultProps;
export default PlanningStep;