var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightGrey,
  width: 90,
  height: 140
};
var Document = function Document(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 90 140",
    "data-id": "icons_Document_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-115.000000, -622.000000)",
    "data-id": "icons_Document_g"
  }, __jsx("g", {
    transform: "translate(10.000000, 470.000000)",
    fill: color,
    "data-id": "icons_Document_g_2"
  }, __jsx("g", {
    transform: "translate(105.000000, 152.000000)",
    "data-id": "icons_Document_g_3"
  }, __jsx("path", {
    d: "M7.9992,131.31824 L80.6672,131.31824 C80.8552,131.31824 80.9824,131.20944 81.0304,131.08144 L81.0304,8.06304 L7.9992,8.00544 L7.9992,131.31824 Z M80.6672,139.31824 L0,139.31824 L0,-0.00016 L89.0296,0.06864 L89.0088,131.81264 C88.5688,136.09184 84.9824,139.31824 80.6672,139.31824 Z",
    "data-id": "icons_Document_path"
  }), __jsx("path", {
    d: "M66.11872,41.4104 L23.54352,41.4104 C21.33472,41.4104 19.54352,39.62 19.54352,37.4104 C19.54352,35.2008 21.33472,33.4104 23.54352,33.4104 L66.11872,33.4104 C68.32832,33.4104 70.11872,35.2008 70.11872,37.4104 C70.11872,39.62 68.32832,41.4104 66.11872,41.4104",
    "data-id": "icons_Document_path_5"
  }), __jsx("path", {
    d: "M66.11872,62.83464 L23.54352,62.83464 C21.33472,62.83464 19.54352,61.04424 19.54352,58.83464 C19.54352,56.62504 21.33472,54.83464 23.54352,54.83464 L66.11872,54.83464 C68.32832,54.83464 70.11872,56.62504 70.11872,58.83464 C70.11872,61.04424 68.32832,62.83464 66.11872,62.83464",
    "data-id": "icons_Document_path_6"
  }), __jsx("path", {
    d: "M66.11872,84.2596 L23.54352,84.2596 C21.33472,84.2596 19.54352,82.4692 19.54352,80.2596 C19.54352,78.05 21.33472,76.2596 23.54352,76.2596 L66.11872,76.2596 C68.32832,76.2596 70.11872,78.05 70.11872,80.2596 C70.11872,82.4692 68.32832,84.2596 66.11872,84.2596",
    "data-id": "icons_Document_path_7"
  }), __jsx("path", {
    d: "M35.6828,105.68384 L23.5436,105.68384 C21.334,105.68384 19.5436,103.89344 19.5436,101.68384 C19.5436,99.47424 21.334,97.68384 23.5436,97.68384 L35.6828,97.68384 C37.8924,97.68384 39.6828,99.47424 39.6828,101.68384 C39.6828,103.89344 37.8924,105.68384 35.6828,105.68384",
    "data-id": "icons_Document_path_8"
  })))));
};
Document.defaultProps = defaultProps;
export default Document;