import styled from 'styled-components'
import { shadows, colorPalette } from '@/styles/styleGuide'
import ButtonPrimary from '@/components/shared/buttons/ButtonPrimary'
import { ReactNode } from 'react'

const Button = ButtonPrimary as (props: {
  type: 'button' | 'submit' | 'externalLink' | 'internalLink'
  label?: string
  clickHandler?: () => void
}) => JSX.Element

export type GenericModalProps = {
  onDismiss?: () => void
  content: ReactNode
  showDismissButton?: boolean
  dismissButtonText?: string
}

const Wrapper = styled.div`
  margin-right: 20rem;
  margin-left: 20rem;
`

const Container = styled.div`
  ${shadows.boxPrimary};
  align-self: center;
  background-color: ${colorPalette.white};
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding: 60rem 0;
  display: flex;
  flex-direction: column;
  gap: 30rem;
`

const GenericModal = ({
  content,
  dismissButtonText = 'OK',
  showDismissButton = false,
  onDismiss,
}: GenericModalProps) => (
  <Container>
    <ContentWrapper>
      <Wrapper>{content}</Wrapper>
      {!!onDismiss && showDismissButton && (
        <Wrapper>
          <Button
            type="button"
            label={dismissButtonText}
            clickHandler={onDismiss}
          />
        </Wrapper>
      )}
    </ContentWrapper>
  </Container>
)

export default GenericModal
