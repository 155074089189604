import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import Box from '@/atoms/Box'
import useSections from '@/hooks/useSections'
import Button from '@/atoms/Button'
import RightArrowIcon from '@/atoms/svg/RightArrowIcon'
import LeftArrowIcon from '@/atoms/svg/LeftArrowIcon'
import styled from 'styled-components'

type Props = {
  isInitial?: boolean
}

const NavigationButtons = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16rem;
  flex-wrap: wrap;
`

const NavigationButton = styled.div<{ alignRight?: boolean }>`
  max-width: 240rem;

  ${({ alignRight = false }) =>
    alignRight &&
    `
    margin-left: auto
  `}
`

const NavButtons = ({ isInitial = false }: Props) => {
  const {
    isLargerThan: { sm },
  } = useBreakpointInfo()
  const { nextSection, previousSection } = useSections()

  if (!sm || (!nextSection && !previousSection)) {
    return null
  }

  if (isInitial) {
    return nextSection ? (
      <Button
        href={nextSection.url}
        text={nextSection.title}
        icon={RightArrowIcon}
      />
    ) : null
  }

  return (
    <NavigationButtons marginTop="xxl">
      <NavigationButton>
        {!!previousSection && (
          <Button
            variant="outlined"
            href={previousSection.url}
            text={previousSection.title}
            iconBefore={LeftArrowIcon}
          />
        )}
      </NavigationButton>
      <NavigationButton alignRight>
        {!!nextSection && (
          <Button
            variant="outlined"
            href={nextSection.url}
            text={nextSection.title}
            icon={RightArrowIcon}
          />
        )}
      </NavigationButton>
    </NavigationButtons>
  )
}

export default NavButtons
