var __jsx = React.createElement;
import React from 'react';
import Event from '../icons/PlanningEvent';
import Update from '../icons/PlanningUpdate';
import Step from '../icons/PlanningStep';
import Action from '../icons/PlanningAction';
var ICONMAPPING = {
  event: Event,
  update: Update,
  step: Step,
  action: Action
};
var PlanningIconsMap = function PlanningIconsMap(_ref) {
  var icon = _ref.icon,
    color = _ref.color;
  var Icon = ICONMAPPING[icon] || ICONMAPPING['action'];
  return __jsx(Icon, {
    color: color,
    "data-id": "designElements_PlanningIconsMap_Icon"
  });
};
export default PlanningIconsMap;