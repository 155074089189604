import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card, CardTitle, CardLink, CardTextContainer } from './shared/designElements/CardElements';
import { openModal as _openModal } from '../redux/modal';
import { textStyles, colorPalette } from '../styles/styleGuide';
import ImageLoader from './shared/ui/ImageLoader';
import ThemeLabel from './shared/designElements/ThemeLabel';
import truncateText from '../services/truncateText';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state) {
  return {
    currentlyOpenedModal: get(state, 'modal.modalConfig.componentProps.projectSlug')
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(_openModal(colorPalette.lightOrange, {
        type: 'project',
        componentProps: {
          projectSlug: ownProps.slug
        }
      }));
    }
  };
};
var ProjectCard = function ProjectCard(_ref) {
  var openModal = _ref.openModal,
    slug = _ref.slug,
    image = _ref.image,
    isNewBuild = _ref.isNewBuild,
    title = _ref.title,
    description = _ref.description;
  return __jsx(CardContainer, {
    hoverColor: colorPalette.darkOrange,
    "data-id": "components_ProjectCard_CardContainer"
  }, __jsx(CardLink, {
    href: "/".concat(String(slug), "/open"),
    onClick: openModal,
    "data-id": "components_ProjectCard_CardLink"
  }, __jsx(CardImageContainer, {
    "data-id": "components_ProjectCard_CardImageContainer"
  }, __jsx(ImageLoader, {
    mode: "bg",
    src: image,
    width: 380,
    "data-id": "components_ProjectCard_ImageLoader"
  }), __jsx(ThemeLabelContainer, {
    "data-id": "components_ProjectCard_ThemeLabelContainer"
  }, __jsx(ThemeLabel, {
    label: isNewBuild ? 'Nieuwbouw' : 'Bestaande bouw',
    theme: "projectUpdate",
    "data-id": "components_ProjectCard_ThemeLabel"
  }))), __jsx(TextContainer, {
    "data-id": "components_ProjectCard_TextContainer"
  }, __jsx(CardTitle, {
    color: colorPalette.orange,
    isNewBuild: isNewBuild,
    "data-id": "components_ProjectCard_CardTitle"
  }, title), __jsx(Text, {
    "data-id": "components_ProjectCard_Text"
  }, truncateText(description, 155), __jsx(SeeMoreLink, {
    "data-id": "components_ProjectCard_SeeMoreLink"
  }, "Bekijk dit project")))));
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard);
var CardContainer = styled(Card)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: unset;\n  min-height: 516rem;\n  > ", " {\n    color: ", ";\n    margin: 0;\n  }\n"])), CardTitle, colorPalette.orange);
var TextContainer = styled(CardTextContainer)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: 100%;\n  position: relative;\n  padding: 24rem 24rem 48rem;\n"])));
var Text = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  ", ";\n  color: ", ";\n  margin-top: 15rem;\n  margin-bottom: 0;\n"])), textStyles.bodyText, colorPalette.textGrey);
var CardImageContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: 238rem;\n  flex-shrink: 0;\n"])));
var SeeMoreLink = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: bold;\n  position: absolute;\n  bottom: 20px;\n  left: 24px;\n  width: 100%;\n"])), textStyles.bodyText, colorPalette.blue);
var ThemeLabelContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 2;\n  margin-top: -40rem;\n"])));