import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const OneLayerIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-40.000000, -212.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <g transform="translate(40.000000, 212.000000)">
            <g>
              <path d="M10,0 L0,6 L0,20 L8.40625,20 L8.40625,13.125 L11.53125,13.125 L11.53125,20 L18.09375,20 C19.125,20 19.96875,19.15625 19.96875,18.125 L20,6 L10,0 Z M18.40625,17.5 C18.40625,18.03125 18,18.4375 17.46875,18.4375 L12.78125,18.4375 L12.78125,11.875 L7.15625,11.875 L7.15625,18.375 L7.15625,18.4375 L1.59375,18.4375 L1.5625,6.875 L10,1.84375 L18.40625,6.875 L18.40625,17.5 Z" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default OneLayerIcon
