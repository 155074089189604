import Text from '@/atoms/Text'
import { IWishlistOptionIncludedInPackage } from '@/entities/wishList'

type Props = {
  included?: IWishlistOptionIncludedInPackage[]
}

const OptionIncludedMessage = ({ included }: Props) => {
  if (!included || included.length === 0) {
    return null
  }
  const [totalAmount, titles] = included.reduce<[number, string[]]>(
    ([_totalAmount, _titles], { amount, title }) => [_totalAmount + amount, [..._titles, title]],
    [0, []]
  )
  return (
    <Text
      bold
      text={`Deze optie is al ${totalAmount}× inbegrepen bij je gekozen ${
        titles.length === 1 ? `pakket (${titles[0]})` : `pakketten (${titles.join(', ')})`
      }`}
    />
  )
}
export default OptionIncludedMessage
