import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import { colorPalette, textStyles } from '../../styles/styleGuide';
import { toBreakpoint } from '../../styles/breakpoints';
import ForumIcon from '../shared/icons/Forum';
import ButtonSecondary from '../shared/buttons/ButtonSecondary';
import truncateText from '../../services/truncateText';
import { openModal as _openModal } from '../../redux/modal';
import { getResultData } from '../../redux/apiData';
import WidgetHeader from '../shared/designElements/WidgetHeader';
import { WidgetFooter } from '../shared/designElements/WidgetFooter';
import { WidgetScroller } from '../shared/designElements/WidgetScroller';
import getLinkFromPageObject from '../../services/getLinkFromPageObject';
import calculateDays from '../../services/calculateDays';
import get from 'lodash.get';
import { useRouter } from 'next/router';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    phase: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'phase', {})
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: function openModal() {
      if (ownProps.dummyContent) {
        dispatch(_openModal(colorPalette.lightOrange, {
          type: 'profile',
          componentProps: {
            type: 'topics',
            content: ownProps.dummyContent.modal
          }
        }));
      }
    }
  };
};
var ForumWidget = function ForumWidget(_ref) {
  var link = _ref.link,
    phase = _ref.phase,
    openModal = _ref.openModal,
    _ref$topics = _ref.topics,
    topics = _ref$topics === void 0 ? [] : _ref$topics,
    renderWidth = _ref.renderWidth;
  var router = useRouter();
  return __jsx(Container, {
    "data-id": "dashboard_ForumWidget_Container"
  }, __jsx(WidgetHeader, {
    title: "Onderwerp van gesprek op het forum",
    subtitle: phase === 'cj1' ? 'Vanaf deze plaats heb je straks toegang tot het forum, waar je vragen en ervaringen met de nieuwe buurtbewoners kunt delen.' : 'Bekijk hier de laatste actieve topics op het forum.',
    "data-id": "dashboard_ForumWidget_WidgetHeader"
  }), __jsx(WidgetScroller, {
    "data-id": "dashboard_ForumWidget_WidgetScroller"
  }, phase === 'cj1' && __jsx(IconWrapper, {
    onClick: openModal,
    "data-id": "dashboard_ForumWidget_IconWrapper"
  }, __jsx(ForumIcon, {
    "data-id": "dashboard_ForumWidget_ForumIcon"
  })), topics && topics.length > 0 && link && topics.map(function (topic, index) {
    return __jsx(Topic, {
      key: index,
      border: index !== 0,
      last: index === (topics && topics.length - 1),
      onClick: function onClick() {
        return router === null || router === void 0 ? void 0 : router.push('/' + getLinkFromPageObject(link));
      },
      "data-id": "dashboard_ForumWidget_Topic"
    }, __jsx(TopicTitle, {
      "data-id": "dashboard_ForumWidget_TopicTitle"
    }, truncateText(topic.title, renderWidth > 1097 ? 68 : 38)), __jsx(Last, {
      "data-id": "dashboard_ForumWidget_Last"
    }, __jsx("span", {
      "data-id": "dashboard_ForumWidget_span"
    }, "Laatste reactie: "), __jsx("span", {
      "data-id": "dashboard_ForumWidget_span_9"
    }, topic.lastreaction), __jsx(Divider, {
      "data-id": "dashboard_ForumWidget_Divider"
    }, "|"), __jsx("span", {
      "data-id": "dashboard_ForumWidget_span"
    }, calculateDays(topic.date)), __jsx(Divider, {
      "data-id": "dashboard_ForumWidget_Divider"
    }, "|"), __jsx("span", {
      "data-id": "dashboard_ForumWidget_span"
    }, topic.replies, " ", topic.replies === 1 ? 'reactie' : 'reacties')));
  })), __jsx(WidgetFooter, {
    "data-id": "dashboard_ForumWidget_WidgetFooter"
  }, phase === 'cj1' && __jsx(ButtonSecondary, {
    label: "Lees meer",
    type: "button",
    clickHandler: openModal,
    "data-id": "dashboard_ForumWidget_ButtonSecondary"
  }), (phase === 'cj2' || phase === 'cj3') && link && __jsx(ButtonSecondary, {
    label: "Bekijk alle topics",
    type: "internalLink",
    route: '/' + getLinkFromPageObject(link),
    "data-id": "dashboard_ForumWidget_ButtonSecondary_16"
  })));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(ForumWidget));
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  min-height: 480rem;\n  background-color: white;\n  position: relative;\n  width: 100%;\n  padding: 0 0 0 24rem;\n\n  ", ";\n\n  > svg {\n    position: absolute;\n    bottom: -20rem;\n    right: -20rem;\n    width: 310rem;\n    height: 310rem;\n    z-index: -1;\n    ", ";\n  }\n"])), toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 24rem 0 0 0;\n  "]))), toBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      display: none;\n    "]))));
var Divider = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: block;\n  margin-right: 4rem;\n  ", ";\n"])), toBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    display: none;\n  "]))));
var Last = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  ", ";\n  color: ", ";\n  font-size: 12rem;\n\n  > span {\n    margin-right: 4rem;\n  }\n\n  ", ";\n"])), textStyles.caption, colorPalette.textGrey, toBreakpoint.lg(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]))));
var TopicTitle = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-weight: bold;\n"])), textStyles.caption, colorPalette.textGrey);
var Topic = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  border-top: ", ";\n  border-bottom: 1px dashed #979797;\n  padding: 14rem 0;\n  margin-bottom: ", ";\n  cursor: pointer;\n"])), function (props) {
  return props.border ? 'none' : '1px dashed #979797';
}, function (props) {
  return props.last ? '48rem' : 0;
});
var IconWrapper = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  &:hover {\n    cursor: pointer;\n  }\n\n  > svg {\n    transform: scale(1.6);\n    position: absolute;\n    top: 42%;\n    ", ";\n  }\n"])), toBreakpoint.md(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n      top: 50%;\n    "]))));