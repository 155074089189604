import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colorPalette, shadows, textStyles } from '../styles/styleGuide';
import { fromBreakpoint } from '../styles/breakpoints';
import ButtonPrimary from './shared/buttons/ButtonPrimary';
import { updateModalOpenState, clearModalState } from '../redux/modal';
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onClose: function onClose() {
      dispatch(updateModalOpenState(false));
      dispatch(clearModalState());
    }
  };
};
var FirstWelcomeWishlistModal = function FirstWelcomeWishlistModal(_ref) {
  var onClose = _ref.onClose;
  return __jsx(Container, {
    "data-id": "components_FirstWelcomeWishlistModal_Container"
  }, __jsx(Title, {
    "data-id": "components_FirstWelcomeWishlistModal_Title"
  }, "Jouw wensen in een woonontwerp"), __jsx(Fragment, null, __jsx(IntroText, {
    "data-id": "components_FirstWelcomeWishlistModal_IntroText"
  }, "Doe alsof je thuis bent en begin direct met het maken van je eerste woonontwerp."), __jsx(Text, {
    "data-id": "components_FirstWelcomeWishlistModal_Text"
  }, "Met woonontwerpen maak je keuzes uit alle mogelijkheden van je favoriete woning(en). Bij alle keuzes zie je direct wat de gevolgen zijn voor de ruimte, het aantal slaapkamers (wanneer van toepassing) en de totale investering voor de woning."), __jsx(Text, {
    "data-id": "components_FirstWelcomeWishlistModal_Text_4"
  }, __jsx("em", {
    "data-id": "components_FirstWelcomeWishlistModal_em"
  }, "Tip:"), " maak woonontwerpen voor meerdere bouwnummers en verschillende woontypes, deel ze met familie en vrienden. Of neem ze mee naar de makelaar of hypotheekadviseur. Gebruik ze om voorbij de vrij-op-naam-prijs en vierkante meters te kijken, zodat je een bewuste keuze kunt maken voor de woning die het beste bij je past!")), __jsx(ButtonPrimary, {
    label: "Start met ontwerpen",
    type: "button",
    clickHandler: onClose,
    "data-id": "components_FirstWelcomeWishlistModal_ButtonPrimary"
  }));
};
export default connect(null, mapDispatchToProps)(FirstWelcomeWishlistModal);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  align-self: center;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  padding: 20rem 20rem 40rem 20rem;\n\n  ", ";\n\n  ", ";\n\n  > button {\n    margin-top: 20rem;\n  }\n"])), shadows.boxPrimary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 60rem 70rem 60rem 70rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 80rem 200rem 80rem 200rem;\n  "]))));
var Title = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 20rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var IntroText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 20rem;\n\n  > span {\n    font-style: italic;\n  }\n"])), textStyles.introText, colorPalette.textGrey);
var Text = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n"])), textStyles.bodyText, colorPalette.textGrey);