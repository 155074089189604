// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'
import { Dispatch } from 'redux'
import { updateModalOpenState, clearModalState } from '@/redux/modal'
import { shadows } from '@/styles/styleGuide'
import TextBox from '@/atoms/TextBox'
import { resetHtmlStyleForModal } from '@/processes/modalHelpers'
import { colors } from '@/styles/colors'

export type ProductOptionRequestResultModalProps = {
  result: 'success' | 'failed'
}

type DispatchProps = {
  onClose: () => void
}

type Props = ProductOptionRequestResultModalProps & DispatchProps

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onClose: () => {
    dispatch(updateModalOpenState(false))
    dispatch(clearModalState())
  },
})

const Container = styled.div`
  ${shadows.boxPrimary};
  align-self: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding: 60rem 20rem;
`

const getContent = (result: 'success' | 'failed') =>
  result === 'success'
    ? [
        'De optie-aanvraag is verzonden',
        'Bedankt voor je aanvraag. We nemen deze in behandeling en brengen je op de hoogte wanneer de aanvraag is goedgekeurd en wordt verwerkt. Je vind deze aanvraag samen met andere optie-aanvragen vanaf nu op de pagina mijn woning, inclusief de actuele orderstatus.',
      ]
    : [
        'Er is iets misgegaan',
        'Het is niet gelukt je aanvraag te verzenden. Probeer het nogmaals en neem anders contact op met de woonadviseur.',
      ]

class ProductOptionRequestResultModal extends Component<Props> {
  closeModal = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClose()
    resetHtmlStyleForModal()
  }

  render() {
    const { result } = this.props

    const [title, text] = getContent(result)

    return (
      <Container>
        <Content>
          <TextBox marginBottom="xl">
            <Title text={title} />
            <Text text={text} />
          </TextBox>
          <Button
            text="Terug naar het woonontwerp"
            onClick={this.closeModal}
            fullWidth={false}
          />
        </Content>
      </Container>
    )
  }
}

export default connect(null, mapDispatchToProps)(ProductOptionRequestResultModal)
