import { FC } from 'react'
import styled from 'styled-components'
import Dot from '@/atoms/Dot'

type Props = {
  title: string
  hasNew?: boolean
}

const TextWrapper = styled.div`
  display: flex;
  gap: 8rem;
  align-items: center;
`

const LayerName: FC<Props> = ({ title, hasNew = false }) => {
  return (
    <TextWrapper>
      {hasNew && <Dot />}
      {title}
    </TextWrapper>
  )
}

export default LayerName
