import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const Floor3Icon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 20"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-173.000000, -767.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <g transform="translate(173.000000, 767.000000)">
            <g>
              <path d="M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z" />
            </g>
            <path d="M12.5625,13.78125 C12.5625,14.5625 12.3125,15.15625 11.8125,15.59375 C11.34375,16.03125 10.625,16.25 9.71875,16.25 C8.875,16.25 8.125,16.0625 7.53125,15.71875 C7.40625,15.65625 7.34375,15.5625 7.28125,15.46875 C7.21875,15.375 7.1875,15.28125 7.1875,15.1875 C7.1875,14.96875 7.34375,14.625 7.65625,14.125 C7.9375,14.40625 8.21875,14.59375 8.5625,14.75 C8.90625,14.90625 9.25,14.96875 9.59375,14.96875 C10,14.96875 10.3125,14.84375 10.5625,14.625 C10.8125,14.40625 10.90625,14.09375 10.90625,13.71875 C10.90625,13.34375 10.78125,13.0625 10.53125,12.84375 C10.28125,12.625 9.96875,12.53125 9.53125,12.53125 L8.9375,12.53125 L8.9375,11.34375 L9.53125,11.34375 C9.75,11.34375 9.9375,11.3125 10.125,11.21875 C10.3125,11.125 10.4375,11 10.53125,10.84375 C10.625,10.6875 10.6875,10.5 10.6875,10.28125 C10.6875,9.6875 10.3125,9.375 9.5625,9.375 C9.28125,9.375 9,9.4375 8.71875,9.53125 C8.4375,9.625 8.125,9.84375 7.75,10.15625 C7.59375,10.03125 7.46875,9.875 7.375,9.6875 C7.28125,9.5 7.21875,9.375 7.21875,9.25 C7.21875,9.125 7.25,9.0625 7.3125,8.96875 C7.375,8.90625 7.4375,8.8125 7.5,8.75 C7.75,8.53125 8.09375,8.375 8.5,8.25 C8.90625,8.125 9.34375,8.0625 9.8125,8.0625 C10.625,8.0625 11.25,8.25 11.6875,8.59375 C12.125,8.9375 12.34375,9.4375 12.34375,10.0625 C12.34375,10.46875 12.25,10.84375 12.03125,11.15625 C11.8125,11.46875 11.53125,11.71875 11.15625,11.875 C11.625,12.0625 11.96875,12.28125 12.1875,12.59375 C12.46875,12.9375 12.5625,13.3125 12.5625,13.78125 Z" />
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default Floor3Icon
