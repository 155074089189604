var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white,
  width: 12,
  height: 9
};
var List = function List(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 12 9",
    "data-id": "icons_List_svg"
  }, __jsx("path", {
    d: "M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V2C12 2.55228 11.5523 3 11 3H1C0.447715 3 0 2.55228 0 2V1Z",
    fill: color,
    "data-id": "icons_List_path"
  }), __jsx("path", {
    d: "M0 7C0 6.44772 0.447715 6 1 6H7C7.55228 6 8 6.44772 8 7V8C8 8.55228 7.55228 9 7 9H1C0.447715 9 0 8.55228 0 8V7Z",
    fill: color,
    "data-id": "icons_List_path_2"
  }));
};
List.defaultProps = defaultProps;
export default List;