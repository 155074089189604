import { colors } from '@/styles/colors'
import styled from 'styled-components'
import CheckmarkIcon from '@/svg/CheckmarkIcon'

export type InputControlProps = {
  onClick: (checked: boolean) => void
  checked: boolean
  disabled?: boolean
  final?: boolean
  pending?: boolean
  excluded?: boolean
  actionRequired?: boolean
  isRadio?: boolean
}

type StyleProps = Omit<InputControlProps, 'onClick'>

const OuterCheckbox = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  width: 24rem;
  height: 24rem;
  border-radius: ${({ isRadio }) => (isRadio ? '100%' : '4rem')};
  box-sizing: border-box;
  border: 1rem solid ${colors.gray40};
  padding: 1rem;

  ${({ disabled }) => disabled && `opacity: .5;`}

  ${({ disabled, final, pending, actionRequired }) =>
    !disabled && !final && !pending && !actionRequired && `cursor: pointer;`}

  ${({ checked, final, pending }) => {
    if (final) {
      return `
        border-color: ${colors.green};
      `
    }
    if (pending) {
      return `
        border-color: ${colors.blue};
      `
    }
    if (checked) {
      return `
        border-color: ${colors.brand};
      `
    }
    return ``
  }}
`

const InnerCheckbox = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem 1rem;
  border-radius: ${({ isRadio }) => (isRadio ? '100%' : '2rem')};
  box-sizing: border-box;

  ${({ checked, final, excluded, pending }) => {
    if (final) {
      return `
        background-color: ${colors.green};
      `
    }
    if (pending) {
      return `
        background-color: ${colors.blue};
      `
    }
    if (checked) {
      if (excluded) {
        return `
          background-color: ${colors.brand};
          width: 12rem;
          height: 12rem;
        `
      }
      return `
        background-color: ${colors.brand};
      `
    }
    return ``
  }}
`

const InputControl = ({ onClick, ...rest }: InputControlProps) => {
  const {
    checked,
    disabled,
    final = false,
    pending = false,
    actionRequired = false,
    excluded = false,
  } = rest
  const showAsChecked = checked || final || pending || actionRequired

  return (
    <OuterCheckbox
      onClick={() => {
        if (!disabled && !final && !pending && !actionRequired) {
          onClick(!checked)
        }
      }}
      {...rest}
      checked={showAsChecked}
    >
      <InnerCheckbox
        {...rest}
        checked={showAsChecked}
      >
        {showAsChecked && !excluded && <CheckmarkIcon variant="white" />}
      </InnerCheckbox>
    </OuterCheckbox>
  )
}

export default InputControl
