import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import shadows from '@/styles/shadows'
import Box from '@/atoms/Box'
import Container from '@/atoms/Container'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import { AnimatePresence, motion } from 'framer-motion'
import ToggleButton from '@/atoms/ToggleButton'
import { colors } from '@/styles/colors'

type Props = {
  children: ReactNode
  flyoutContent?: ReactNode | ReactNode[]
  buttonText?: string
}

const Wrapper = styled(Box)`
  ${shadows.default}
  border-radius: 8rem 0;
`

const TopContainer = styled(Container)`
  border-radius: 8rem 0 0;
`

const BottomContainer = styled(Container)`
  border-radius: 0 0 8rem;
  background-color: ${colors.gray05};
  border-top: 1rem solid ${colors.gray15};
`

const SlideInOut = styled(motion.div)`
  overflow: hidden;
`

const FlyoutContainer = ({ children, flyoutContent, buttonText = 'Weergeven' }: Props) => {
  const horizontalSpacing = useResponsiveSpacing()
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  if (!flyoutContent || (Array.isArray(flyoutContent) && flyoutContent.length === 0)) {
    return (
      <Container
        paddingTop="xl"
        paddingHorizontal={horizontalSpacing}
        paddingBottom="xs"
        marginBottom="xl"
        shadow
      >
        {children}
      </Container>
    )
  }

  return (
    <Wrapper marginBottom="xl">
      <TopContainer
        paddingTop="xl"
        paddingHorizontal={horizontalSpacing}
        paddingBottom="xs"
      >
        {children}
      </TopContainer>
      <BottomContainer>
        <Box
          paddingHorizontal={horizontalSpacing}
          paddingTop="xl"
          paddingBottom="xl"
        >
          <ToggleButton
            text={buttonText}
            onClick={() => setFlyoutOpen((v) => !v)}
            open={flyoutOpen}
          />
        </Box>
        <AnimatePresence>
          {flyoutOpen && (
            <SlideInOut
              animate={{ height: 'auto', opacity: 1 }}
              initial={{ height: 0, opacity: 0.2 }}
              exit={{ height: 0, opacity: 0.2 }}
              transition={{ duration: 0.8 }}
            >
              <Box
                paddingHorizontal={horizontalSpacing}
                paddingBottom="sm"
              >
                {flyoutContent}
              </Box>
            </SlideInOut>
          )}
        </AnimatePresence>
      </BottomContainer>
    </Wrapper>
  )
}

export default FlyoutContainer
