var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  direction: 'right',
  color: colorPalette.textGrey,
  width: 60,
  height: 60
};
var ROTATE = {
  up: 270,
  down: 90,
  left: 180,
  right: 0
};
var CircleArrow = function CircleArrow(_ref) {
  var width = _ref.width,
    height = _ref.height,
    direction = _ref.direction,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px"),
      transform: "rotateZ(".concat(ROTATE[direction], "deg)"),
      overflow: "visible"
    },
    viewBox: "0 0 60 60",
    version: "1.1",
    "data-id": "icons_CircleArrow_svg"
  }, __jsx("title", {
    "data-id": "icons_CircleArrow_title"
  }, "Circle Arrow"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_CircleArrow_g"
  }, __jsx("g", {
    transform: "translate(-1229.000000, -480.000000)",
    fill: color,
    "data-id": "icons_CircleArrow_g_3"
  }, __jsx("g", {
    transform: "translate(130.000000, 175.000000)",
    "data-id": "icons_CircleArrow_g_4"
  }, __jsx("g", {
    transform: "translate(20.000000, 305.000000)",
    "data-id": "icons_CircleArrow_g_5"
  }, __jsx("path", {
    d: "M1109,60 C1092.43146,60 1079,46.5685425 1079,30 C1079,13.4314575 1092.43146,0 1109,0 C1125.56854,0 1139,13.4314575 1139,30 C1139,46.5685425 1125.56854,60 1109,60 Z M1098.87213,29.9757829 L1098.87213,29.9757829 C1098.84316,30.8797773 1099.17542,31.7943932 1099.87617,32.4951408 L1108.03936,40.658335 C1109.37178,41.9907577 1111.5228,42.0000216 1112.86455,40.6582774 C1114.197,39.3258229 1114.21428,37.182766 1112.86461,35.8330903 L1107.0073,29.9757829 L1112.86461,24.1184756 C1114.21428,22.7687998 1114.197,20.625743 1112.86455,19.2932884 C1111.5228,17.9515443 1109.37178,17.9608081 1108.03936,19.2932309 L1099.87617,27.456425 C1099.17542,28.1571727 1098.84316,29.0717885 1098.87213,29.9757829 Z",
    id: "icon/modelSlider/arrow/right/hover",
    transform: "translate(1109.000000, 30.000000) scale(-1, 1) translate(-1109.000000, -30.000000) ",
    "data-id": "icons_CircleArrow_path"
  }))))));
};
CircleArrow.defaultProps = defaultProps;
export default CircleArrow;