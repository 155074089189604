var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var CalendarDate = function CalendarDate(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    style: {
      width: '50px',
      height: '56px'
    },
    viewBox: "0 0 50 56",
    version: "1.1",
    "data-id": "icons_CalendarDate_svg"
  }, __jsx("title", {
    "data-id": "icons_CalendarDate_title"
  }, "Datum icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_CalendarDate_g"
  }, __jsx("g", {
    transform: "translate(-250.000000, -351.000000)",
    fill: color,
    "data-id": "icons_CalendarDate_g_3"
  }, __jsx("g", {
    transform: "translate(210.000000, 312.000000)",
    "data-id": "icons_CalendarDate_g_4"
  }, __jsx("g", {
    transform: "translate(39.000000, 35.000000)",
    "data-id": "icons_CalendarDate_g_5"
  }, __jsx("path", {
    d: "M45,5 L51,5 L51,60 L1,60 L1,5 L8,5 L8,4 L11,4 L11,5 L42,5 L42,4 L45,4 L45,5 Z M8.5,10 C7.67157288,10 7,10.6715729 7,11.5 C7,12.3284271 7.67157288,13 8.5,13 L43.5,13 C44.3284271,13 45,12.3284271 45,11.5 C45,10.6715729 44.3284271,10 43.5,10 L8.5,10 Z",
    "data-id": "icons_CalendarDate_path"
  }))))));
};
CalendarDate.defaultProps = defaultProps;
export default CalendarDate;