import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import parseTimestamp from '../services/parseTimestamp';
import staticData from '../constants/staticData';
import HeroArticle from './HeroArticle';
import SocialShare from './SocialShare';
import DateIcon from './shared/designElements/DateIcon';
import BBCode from './shared/richText/BBCode';
import ArticleCard from './ArticleCard';
import { defaultSharingImage } from '../constants/sharing';
import UserAvatar from './UserAvatar';
import hasEventPassed from '../services/hasEventPassed';
import { colorPalette, themes, textStyles, shadows } from '../styles/styleGuide';
import { fromBreakpoint, toBreakpoint, breakpointSizes } from '../styles/breakpoints';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var ArticleContent = function ArticleContent(_ref) {
  var planningObj = _ref.planningObj,
    article = _ref.article,
    renderWidth = _ref.renderWidth,
    modal = _ref.modal;
  var isPlanningObj = planningObj;
  var disableShare = article.env === 'closed';
  var eventDate = article.eventDate,
    type = article.type,
    title = article.title,
    author = article.author,
    image = article.image,
    theme = article.theme,
    media = article.media,
    youtubeId = article.youtubeId,
    leader = article.leader,
    text = article.text,
    credits = article.credits,
    relatedArticles = article.relatedArticles,
    publishedDate = article.publishedDate,
    projectSlug = article.projectSlug,
    env = article.env,
    articleSlug = article.articleSlug;
  var _parseTimestamp = parseTimestamp(publishedDate),
    day = _parseTimestamp.day,
    month = _parseTimestamp.month,
    year = _parseTimestamp.year;
  var eventHasPassed = hasEventPassed(eventDate);
  var smallView = renderWidth < 1068;
  var primaryColor = isPlanningObj ? colorPalette.orange : themes[theme].primary;
  var activeColor = isPlanningObj ? colorPalette.orange : themes[theme].active;
  var themeColorPrimary = eventHasPassed && type === 'event' ? colorPalette.headerGrey : primaryColor;
  var themeColorPrimaryLight = eventHasPassed && type === 'event' ? colorPalette.lightBeige : primaryColor;
  var themeColorActive = eventHasPassed && type === 'event' ? colorPalette.textGrey : activeColor;
  var socialShareProps = {
    colorPrimary: themeColorPrimary,
    colorSecondary: themeColorActive,
    shareLink: "".concat(window.location.origin, "/").concat(String(projectSlug), "/").concat(String(env), "/article/").concat(String(articleSlug), "/"),
    image: image ? image.src : defaultSharingImage,
    buttons: [{
      type: 'facebook'
    }, {
      type: 'twitter'
    }, {
      type: 'pinterest'
    }, {
      type: 'clipboard'
    }, {
      type: 'whatsapp'
    }, {
      type: 'mail'
    }]
  };
  return __jsx(Container, {
    "data-id": "components_ArticleContent_Container"
  }, __jsx(TopContainer, {
    modal: modal,
    "data-id": "components_ArticleContent_TopContainer"
  }, image && type !== 'event' && __jsx(HeroArticle, {
    type: type,
    title: title,
    author: author,
    image: image,
    theme: theme,
    media: media,
    youtubeId: youtubeId,
    credits: credits,
    renderWidth: renderWidth,
    "data-id": "components_ArticleContent_HeroArticle"
  }), __jsx(Content, {
    modal: modal,
    "data-id": "components_ArticleContent_Content"
  }, __jsx(TitleContainer, {
    type: type,
    "data-id": "components_ArticleContent_TitleContainer"
  }, type === 'event' && eventDate && __jsx(DateIconWrapper, {
    iconSmall: smallView,
    "data-id": "components_ArticleContent_DateIconWrapper"
  }, __jsx(DateIcon, {
    textColor: "#FFFFFF",
    bgColor: themeColorPrimaryLight,
    size: smallView ? 'big' : 'huge',
    date: eventDate,
    "data-id": "components_ArticleContent_DateIcon"
  })), __jsx(Title, {
    color: themeColorPrimary,
    "data-id": "components_ArticleContent_Title"
  }, title), __jsx(PublishedDate, {
    "data-id": "components_ArticleContent_PublishedDate"
  }, day, " ", get(month, 'shortName', '').toLowerCase(), " ", year, author && author.name && __jsx("span", {
    "data-id": "components_ArticleContent_span"
  }, "| Door ", author.name))), !eventHasPassed && !disableShare && __jsx(SocialShare, _extends({}, socialShareProps, {
    "data-id": "components_ArticleContent_SocialShare"
  })), __jsx(IntroText, {
    "data-id": "components_ArticleContent_IntroText"
  }, leader), __jsx(BBCode, {
    content: text,
    "data-id": "components_ArticleContent_BBCode"
  }), eventHasPassed && __jsx(PassedEventNotification, {
    "data-id": "components_ArticleContent_PassedEventNotification"
  }, staticData.passedEventNotification), author && __jsx(AuthorInfo, {
    "data-id": "components_ArticleContent_AuthorInfo"
  }, __jsx(UserAvatar, {
    mode: "imageLoader",
    size: renderWidth > breakpointSizes.md ? 'large' : 'normal',
    imageUrl: author.image,
    name: author.name,
    "data-id": "components_ArticleContent_UserAvatar"
  }), __jsx(AuthorText, {
    "data-id": "components_ArticleContent_AuthorText"
  }, __jsx(AuthorName, {
    "data-id": "components_ArticleContent_AuthorName"
  }, author.name), __jsx(AuthorDescription, {
    "data-id": "components_ArticleContent_AuthorDescription"
  }, __jsx(BBCode, {
    content: author.description,
    "data-id": "components_ArticleContent_BBCode"
  })))))), !isPlanningObj && __jsx(ArticleRow, {
    "data-id": "components_ArticleContent_ArticleRow"
  }, (relatedArticles || []).map(function (article, index) {
    return __jsx(ArticleCard, {
      key: index,
      id: index,
      isBig: false,
      type: article.type,
      leader: article.leader,
      image: article.image,
      title: article.title,
      theme: article.theme,
      label: article.label,
      author: article.author,
      eventDate: article.eventDate,
      renderWidth: renderWidth,
      slug: article.slug,
      "data-id": "components_ArticleContent_ArticleCard"
    });
  })));
};
export default withMatch(ArticleContent);
var Content = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n"])), function (props) {
  return props.modal ? '0' : '40rem';
}, function (props) {
  return props.modal ? '20rem' : '0';
}, function (props) {
  return props.modal ? '20rem' : '0';
});
var TopContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background: #fff;\n  padding-bottom: 60rem;\n  overflow: hidden;\n  position: relative;\n\n  ", ";\n  margin-bottom: ", ";\n  ", ";\n"])), function (props) {
  return props.modal ? shadows.boxPrimary : '';
}, function (props) {
  return props.modal ? '40rem' : '0';
}, fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      padding-bottom: 80rem;\n    "]))));
var TitleContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  @media only screen and (max-width: 1120px) {\n    padding-right: ", ";\n  }\n"])), function (props) {
  return props.type === 'event' ? '160rem' : '0';
});
var DateIconWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n"])), function (props) {
  return props.iconSmall ? '40rem' : '80rem';
}, function (props) {
  return props.iconSmall ? '35rem' : '80rem';
});
var AuthorInfo = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-top: 60rem;\n  margin-bottom: 50rem;\n  overflow: hidden;\n  width: 100%;\n  padding-top: 20rem;\n  border-top: 1rem dashed ", ";\n  border-bottom: 1rem dashed ", ";\n\n  > div:first-of-type {\n    margin-bottom: 20rem;\n  }\n"])), colorPalette.beige, colorPalette.beige);
var AuthorText = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: auto;\n  margin-left: 20rem;\n"])));
var PassedEventNotification = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", ";\n  font-weight: bold;\n  color: ", ";\n  margin-top: 30rem;\n"])), textStyles.bodyText, colorPalette.textGrey);
var AuthorName = styled.h4(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin-top: 0;\n  margin-bottom: 10rem;\n  ", ";\n  font-weight: bold;\n  color: ", ";\n"])), textStyles.bodyText, colorPalette.textGrey);
var AuthorDescription = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 20rem;\n"])), textStyles.bodyText, colorPalette.textGrey);
var ArticleRow = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-left: -10rem;\n  margin-right: -10rem;\n  justify-content: space-between;\n  padding: 0 10rem;\n\n  > div {\n    ", ";\n\n    ", ";\n  }\n"])), fromBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n      width: calc( ((1/2) * 100%) - 10rem );\n    "]))), fromBreakpoint.lg(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n      width: calc( ((1/3) * 100%) - 13.333rem );\n    "]))));
var Container = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
var Title = styled.h1(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  ", ";\n\n  ", ";\n"])), textStyles.pageTitle, function (props) {
  return props.color;
}, toBreakpoint.md(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    margin-top: 40rem;\n  "]))), fromBreakpoint.md(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    margin-top: 60rem;\n  "]))));
var PublishedDate = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 10rem;\n  margin-bottom: 45rem;\n"])), textStyles.bodyText, colorPalette.textGrey);
var IntroText = styled.p(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 40rem;\n"])), textStyles.introText, colorPalette.textGrey);