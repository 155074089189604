import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const SummaryIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50742)">
        <path
          d="M18.375 21H0V7.31719L10.4672 0L21 7.84219V18.375C21 19.8188 19.8188 21 18.375 21ZM1.64062 19.3594H18.375C18.9328 19.3594 19.3594 18.9328 19.3594 18.375V8.6625L10.4344 2.03437L1.64062 8.17031V19.3594Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4128 17.0058C10.796 17.0058 10.2595 16.8865 9.80304 16.648C9.34663 16.4095 8.97656 16.0683 8.69285 15.6242C8.40913 15.1801 8.22204 14.6538 8.13158 14.0452H7.21875V13.2681H8.05757C8.04934 13.1776 8.04523 13.0419 8.04523 12.861C8.04523 12.6883 8.04934 12.5362 8.05757 12.4046H7.21875V11.6398H8.13158C8.28783 10.6776 8.662 9.919 9.25411 9.3639C9.84622 8.8088 10.5905 8.53125 11.4868 8.53125C12.2188 8.53125 12.8314 8.69983 13.3248 9.03701C13.6291 9.2426 13.7812 9.46464 13.7812 9.70312C13.7812 9.82648 13.7257 9.97451 13.6147 10.1472C13.5037 10.3199 13.366 10.4844 13.2015 10.6406C12.9794 10.4186 12.7924 10.2603 12.6402 10.1657C12.4881 10.0711 12.3236 9.99918 12.1468 9.94984C11.97 9.90049 11.7788 9.87582 11.5732 9.87582C11.0798 9.87582 10.685 10.0197 10.389 10.3076C10.0929 10.5954 9.88734 11.0395 9.7722 11.6398H12.3133V12.4046H9.68586C9.67763 12.4951 9.67352 12.6184 9.67352 12.7747V13.0029C9.67352 13.0892 9.68174 13.1776 9.69819 13.2681H12.3133V14.0452H9.79688C9.90378 14.5962 10.1011 15.0074 10.389 15.2788C10.6768 15.5502 11.0633 15.6859 11.5485 15.6859C11.7459 15.6859 11.935 15.6612 12.116 15.6118C12.2969 15.5625 12.4675 15.4885 12.6279 15.3898C12.7882 15.2911 12.9589 15.139 13.1398 14.9334C13.1974 15.0074 13.2714 15.0958 13.3618 15.1986C13.4523 15.3014 13.5325 15.4145 13.6024 15.5378C13.6723 15.6612 13.7072 15.7804 13.7072 15.8956C13.7072 16.0929 13.588 16.2862 13.3495 16.4753C13.111 16.6562 12.8355 16.7899 12.523 16.8762C12.2105 16.9626 11.8405 17.0058 11.4128 17.0058Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50742">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default SummaryIcon
