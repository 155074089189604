var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Pinterest = function Pinterest(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "19px",
    height: "25px",
    viewBox: "0 0 19 25",
    version: "1.1",
    "data-id": "icons_Pinterest_svg"
  }, __jsx("title", {
    "data-id": "icons_Pinterest_title"
  }, "Pintrest"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Pinterest_g"
  }, __jsx("g", {
    transform: "translate(-442.000000, -1182.000000)",
    fill: color,
    "data-id": "icons_Pinterest_g_3"
  }, __jsx("g", {
    transform: "translate(331.000000, 1173.000000)",
    "data-id": "icons_Pinterest_g_4"
  }, __jsx("g", {
    transform: "translate(100.000000, 0.000000)",
    "data-id": "icons_Pinterest_g_5"
  }, __jsx("g", {
    transform: "translate(11.000000, 9.000000)",
    "data-id": "icons_Pinterest_g_6"
  }, __jsx("path", {
    d: "M7.83774783,15.8415037 C7.2684559,18.3342801 6.7108472,20.7378391 5.49366708,22.6337087 C5.12299006,23.2125596 4.74700248,23.9432925 4.11079752,24.2566155 C3.16870435,19.416572 5.13467329,15.3423112 5.91426335,11.2733609 C4.92118882,9.25322422 5.53827578,5.83003789 7.65825093,5.44236708 C10.7320025,4.87944783 9.92161118,8.58728012 9.40011429,10.3110876 C9.11440621,11.2563671 8.59928199,12.1591621 8.67894037,13.1968453 C8.84887826,15.3826714 11.5986857,15.5228702 13.0059839,14.3991559 C15.0250584,12.7889944 15.6070957,9.67913106 15.4116671,7.12581429 C15.1110894,3.23636087 10.7054497,1.30225528 6.81705839,2.85718696 C4.81285342,3.65908137 2.99239379,5.50078323 2.72792795,8.2070441 C2.58985342,9.63239814 2.88830683,10.770982 3.45016398,11.5728764 C3.53513292,11.6960814 3.82721366,11.910628 3.86969814,12.2356342 C3.95785342,12.8952056 3.56699627,13.6057584 3.2090646,14.0975161 C1.21654286,13.5229137 0.188418634,11.7343174 0.0227291925,9.47095714 C-0.351134161,4.3186528 3.87713292,0.398398137 8.85737516,0.033031677 C14.1711205,-0.356763354 18.3866422,2.71167764 18.7753752,7.18635466 C19.0663938,10.5118267 17.8874497,13.8596031 16.0128224,15.6609447 C14.6034,17.0140752 11.6082447,18.3385286 9.16007702,17.0438143 C8.61627578,16.755982 8.37623851,16.4054851 7.83774783,15.8415037",
    "data-id": "icons_Pinterest_path"
  })))))));
};
Pinterest.defaultProps = defaultProps;
export default Pinterest;