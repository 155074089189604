import React, { FC } from 'react'
import WithSettings from '@/components/WithSettings'
import { Match } from '@/types/match'
import { ISettings } from '@/entities/types'
import Article from '../Article'

type Props = {
  settings: ISettings
  match: Match
  renderWidth: number
}

const ArticlePage: FC<Props> = ({ match: { params }, ...rest }) => {
  return (
    <Article
      // @ts-ignore
      articleSlug={params.articleSlug}
      env={params.env}
      projectSlug={params.projectSlug}
      {...rest}
    />
  )
}

export default WithSettings(ArticlePage)
