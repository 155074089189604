export const listFormat = (strings?: string[]) => {
  if (!strings || strings.length === 0) {
    return ''
  }

  if (strings.length <= 2) {
    return strings.join(' en ')
  }

  const stringsCopy = [...strings]
  const last = stringsCopy.pop()
  return `${stringsCopy.join(', ')} en ${last}`
}
