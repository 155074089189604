import { Endpoint, EndpointConfigItem, Method } from '@/types/endpoints'

export const endpointConfig: Record<Endpoint, EndpointConfigItem> = {
  getSettings: {
    path: '/settings/:projectSlug/:env/',
    method: Method.get,
  },
  deleteUserWish: {
    path: '/userwish/:projectSlug/closed/:userWishId/',
    method: Method.delete,
  },
  patchUserWish: {
    path: '/userwish/:projectSlug/closed/:userWishId/',
    method: Method.patch,
    apiVersion: '2.0',
  },
  postUserWish: {
    path: '/userwish/:projectSlug/closed/',
    method: Method.post,
    apiVersion: '2.0',
  },
  getWishList: {
    path: '/wishlist/:projectSlug/closed/:wishListId/',
    method: Method.get,
    apiVersion: '2.0',
  },
  duplicateWishList: {
    path: '/wishlist/:projectSlug/closed/:wishListId/duplicate/',
    method: Method.post,
    apiVersion: '2.0',
  },
  getWishLists: {
    path: '/wishlist/:projectSlug/closed/',
    method: Method.get,
    apiVersion: '2.0',
  },
  patchWishList: {
    path: '/wishlist/:projectSlug/closed/:wishListId/',
    method: Method.patch,
    apiVersion: '2.0',
  },
  postWishList: {
    path: '/wishlist/:projectSlug/closed/',
    method: Method.post,
    apiVersion: '2.0',
  },
}
