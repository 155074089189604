import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Alert from '../icons/Alert';
import { colorPalette, fonts, shadows, CUBIC_TIMING, rollOutLeft, fadeIn } from '../../../styles/styleGuide';
var isCMSPreviewModeEnabled = function isCMSPreviewModeEnabled() {
  return window.location.search && window.location.search.includes('phase') && (window.location.search.includes('preview') || window.location.search.includes('concept'));
};
var CMSPreviewMessage = function CMSPreviewMessage() {
  return isCMSPreviewModeEnabled() ? __jsx(Bar, {
    "data-id": "ui_CMSPreviewMessage_Bar"
  }, __jsx(Content, {
    "data-id": "ui_CMSPreviewMessage_Content"
  }, __jsx(Alert, {
    color: "#ffffff",
    "data-id": "ui_CMSPreviewMessage_Alert"
  }), __jsx(Message, {
    "data-id": "ui_CMSPreviewMessage_Message"
  }, "U zit in CMS Preview mode."))) : null;
};
export default CMSPreviewMessage;
var Bar = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  z-index: 1;\n  top: 50%;\n  left: 0;\n  width: 100rem;\n  padding: 15rem;\n  background: ", ";\n  opacity: 0.8;\n  ", ";\n  transform-origin: 0 0;\n  animation: ", " 0.6s ", ";\n  animation-fill-mode: forwards;\n"])), colorPalette.blue, shadows.boxPrimary, rollOutLeft, CUBIC_TIMING);
var Content = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n  opacity: 0;\n  animation: ", " 0.6s ", ";\n  animation-delay: 0.6s;\n  animation-fill-mode: forwards;\n"])), fadeIn, CUBIC_TIMING);
var Message = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  font-family: ", ";\n  color: #fff;\n  font-weight: bold;\n  margin-top: 10rem;\n  text-align: center;\n  line-height: 18rem;\n"])), fonts.primary);