import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../styles/styleGuide';
var NotificationsNumber = function NotificationsNumber(_ref) {
  var number = _ref.number,
    className = _ref.className;
  return number > 0 ? __jsx(Container, {
    className: className,
    "data-id": "components_NotificationNumber_Container"
  }, number) : null;
};
export default NotificationsNumber;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  right: -8rem;\n  bottom: -1rem;\n  border-radius: 50%;\n  background-color: ", ";\n  border: 1px solid white;\n  width: 16rem;\n  height: 16rem;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 12rem;\n"])), colorPalette.orange);