var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 63,
  height: 64
};
var LogOut = function LogOut(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 63 64",
    version: "1.1",
    "data-id": "icons_Logout_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Logout_g"
  }, __jsx("g", {
    transform: "translate(-481.000000, -481.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_Logout_g_2"
  }, __jsx("g", {
    transform: "translate(512.500000, 513.000000) rotate(-270.000000) translate(-512.500000, -513.000000) translate(480.500000, 481.500000)",
    "data-id": "icons_Logout_g_3"
  }, __jsx("path", {
    d: "M31.8031846,0.0340043966 C33.1876996,-0.133675891 34.6301975,0.318208276 35.7092424,1.40244607 L50.4446406,16.2087577 C52.2633193,18.0361885 52.2937249,20.9641991 50.5217153,22.8427867 L50.5252508,22.8463392 L47.0837621,26.3043899 L36.5853477,15.7611365 L36.5556492,42.7704521 L26.8548513,42.7697416 L26.9163696,16.1917054 L20.0687475,23.0722813 C18.1800653,24.9700527 15.20456,24.9643686 13.3646681,23.1156224 C13.3597184,23.1106488 13.35689,23.1049647 13.3526473,23.1007016 L10.0363165,19.7684112 L28.2577512,1.45928685 C29.2484078,0.463862763 30.5360492,-0.00862618523 31.8031846,0.0340043966 Z M9.68826351,48 L9.68826351,53.0717469 L54.3117365,53.0717469 L54.3117365,48 L54.2999878,48 L54.2999878,36 C54.2999878,32.6862915 56.9862793,30 60.2999878,30 L63.9999876,30 L63.9999876,48 L54.3117365,48 L54.3117365,44.9133759 C54.3117365,41.5837204 56.998028,38.8845012 60.3117365,38.8845012 L64,38.8845012 L64,56.9711253 C64,60.3007809 61.3137085,63 58,63 L6,63 C2.6862915,63 1.29399067e-15,60.3007809 8.8817842e-16,56.9711253 L0,39.3079052 L0,30 L3.69999981,30 C7.01370831,30 9.69999981,32.6862915 9.69999981,36 L9.69999981,48 L9.68826351,48 Z",
    "data-id": "icons_Logout_path"
  })))));
};
LogOut.defaultProps = defaultProps;
export default LogOut;