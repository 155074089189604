import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import { getResultData } from '../../redux/apiData';
import WidgetContainer from '../shared/designElements/WidgetContainer';
import DocumentsWidget from './DocumentsWidget';
import HouseWidget from './HouseWidget';
import { fromBreakpoint } from '../../styles/breakpoints';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    phase: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'phase', undefined)
  };
};
var MyHouseAndDocsBlock = function MyHouseAndDocsBlock(_ref) {
  var phase = _ref.phase,
    house = _ref.house,
    document = _ref.document;
  return __jsx(WidgetContainer, {
    label: "De Woning",
    size: "normal",
    icon: "house",
    "data-e2e-block-pricelistblock": true,
    "data-id": "dashboard_MyHouseAndDocsBlock_WidgetContainer"
  }, __jsx(BlocksContainer, {
    "data-id": "dashboard_MyHouseAndDocsBlock_BlocksContainer"
  }, __jsx(House, {
    "data-id": "dashboard_MyHouseAndDocsBlock_House"
  }, phase && __jsx(HouseWidget, {
    phase: phase,
    house: house,
    "data-id": "dashboard_MyHouseAndDocsBlock_HouseWidget"
  })), __jsx(Documents, {
    "data-id": "dashboard_MyHouseAndDocsBlock_Documents"
  }, phase && __jsx(DocumentsWidget, {
    phase: phase,
    document: document,
    "data-id": "dashboard_MyHouseAndDocsBlock_DocumentsWidget"
  }))));
};
export default withMatch(connect(mapStateToProps)(MyHouseAndDocsBlock));
var BlocksContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: row;\n    max-height: 520rem;\n  "]))));
var House = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: auto;\n  position: relative;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 50%;\n  "]))), fromBreakpoint.lg(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    width: calc(1/3 * 100%);\n  "]))));
var Documents = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: auto;\n  position: relative;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    width: 50%;\n    margin-left: 60rem;\n  "]))), fromBreakpoint.lg(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: calc(2/3 * 100%);\n  "]))));