var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var Brush = function Brush(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    version: "1.1",
    "data-id": "icons_Brush_svg"
  }, __jsx("title", {
    "data-id": "icons_Brush_title"
  }, "Brush Icon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-69.000000, -110.000000)",
    "data-id": "icons_Brush_g"
  }, __jsx("g", {
    transform: "translate(42.000000, 100.000000)",
    fill: color,
    "data-id": "icons_Brush_g_3"
  }, __jsx("g", {
    transform: "translate(27.000000, 10.000000)",
    "data-id": "icons_Brush_g_4"
  }, __jsx("path", {
    d: "M11.5583849,13 L8,9.42306451 C8,9.42306451 11.0907532,8.17234374 12.1752829,7.08320938 C13.2587688,5.99407502 18.6772423,0.546304682 18.6772423,0.546304682 C18.6772423,0.546304682 19.7596843,-0.541780418 20.6490196,0.353240402 C21.5393987,1.24721196 20.454869,2.33529706 20.454869,2.33529706 C20.454869,2.33529706 15.0374394,7.78201813 13.9549973,8.87010323 C12.8704675,9.96133612 11.5583849,13 11.5583849,13",
    "data-id": "icons_Brush_path"
  }), __jsx("path", {
    d: "M0,19.6984307 C2.59440423,18.9903499 2.34200743,16.6589963 3.8828018,12.5731637 C4.63999217,10.5649466 6.66405791,10 6.66405791,10 L10,13.6965508 C10,13.6965508 10,21.6242372 0,19.6984307",
    "data-id": "icons_Brush_path_6"
  })))));
};
Brush.defaultProps = defaultProps;
export default Brush;