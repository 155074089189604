import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const LeftArrowIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 10 16"
    >
      <path
        d="M8.84385 1.52234L8.8391 1.52735C9.60765 2.34347 9.60265 3.61873 8.79403 4.42734L5.21787 8.0035L8.79428 11.5799C9.61842 12.4043 9.6079 13.7129 8.79428 14.5265C8.78352 14.5372 8.77275 14.5478 8.76174 14.5583L8.76249 14.559L7.32151 16L0.86413 9.54187C0.436292 9.11403 0.233012 8.55576 0.251037 8.0035C0.233763 7.4515 0.436292 6.89298 0.86413 6.46514L7.32176 0L8.84385 1.52234Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default LeftArrowIcon
