import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fromBreakpoint } from '../../styles/breakpoints';
import { colorPalette, textStyles } from '../../styles/styleGuide';
import ImageLoader from '../shared/ui/ImageLoader';
import get from 'lodash.get';
var HeroDashboard = function HeroDashboard(_ref) {
  var renderWidth = _ref.renderWidth,
    hero = _ref.hero;
  return __jsx(Container, {
    "data-id": "dashboard_HeroDashboard_Container"
  }, __jsx(ImageLoader, {
    src: renderWidth > 380 ? get(hero, 'desktopImage') : get(hero, 'mobileImage'),
    mode: "bg",
    position: "absolute",
    width: renderWidth,
    "data-id": "dashboard_HeroDashboard_ImageLoader"
  }), __jsx(TextContainer, {
    "data-id": "dashboard_HeroDashboard_TextContainer"
  }, hero && hero.buttonLabel && __jsx(Caption, {
    "data-id": "dashboard_HeroDashboard_Caption"
  }, hero.buttonLabel)));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n  min-height: 300rem;\n  box-sizing: border-box;\n\n  > div:after {\n    position: absolute;\n    content: ' ';\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 2;\n    background: linear-gradient( rgba(0,0,0,0.1), rgba(0,0,0,0.2) );\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"])), colorPalette.lightGrey, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    min-height: 440rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    height: 552rem;\n  "]))), fromBreakpoint.xl(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    min-height: 621rem;\n  "]))), fromBreakpoint.xxl(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    min-height: 831rem;\n  "]))));
var TextContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  color: white;\n  z-index: 2;\n  padding: 20rem 15rem 30rem 15rem;\n  box-sizing: border-box;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    padding: 20rem 30rem;\n  "]))), fromBreakpoint.lg(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    padding: 40rem 50rem;\n  "]))), fromBreakpoint.xl(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    padding: 40rem 130rem;\n  "]))));
var Caption = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  max-width: 200rem;\n  bottom: 80px;\n  position: absolute;\n\n  ", ";\n\n  ", ";\n"])), textStyles.caption, fromBreakpoint.lg(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    margin-top: 436rem;\n    bottom: 60px;\n    max-width: 320rem;\n  "]))), fromBreakpoint.xl(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    margin-top: 500rem;\n  "]))));
export default HeroDashboard;