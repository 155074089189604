import { Sizes } from '@/styles/spacing'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'

/**
 * Get the spacing size to use for the current breakpoint: spacing xl if larger than breakpoint md, otherwise spacing lg
 */
const useResponsiveSpacing = (): Sizes => {
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()
  return md ? 'xl' : 'lg'
}

export default useResponsiveSpacing
