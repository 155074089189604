import styled from 'styled-components'
import { IUserWishOption } from '@/entities/wishList'
import UserWishItem from '@/userwish/UserWishItem'
import useParams from '@/hooks/useParams'
import { useState } from 'react'

type Props = {
  userWishes: IUserWishOption[]
}

const WishList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16rem;
`

const UserWishList = ({ userWishes }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wishListItem, ...params } = useParams()

  const [currentlyEditing, setCurrentlyEditing] = useState<number | undefined>()

  return (
    <WishList>
      {userWishes?.map((userWish) => (
        <UserWishItem
          key={userWish.id}
          userWish={userWish}
          editing={currentlyEditing === userWish.id}
          onEdit={(id) => setCurrentlyEditing(id)}
        />
      ))}
    </WishList>
  )
}

export default UserWishList
