import useMatch from '@/hooks/useMatch'
import { RequestError } from '@/types/endpoints'
import { useRouter } from 'next/router'

/**
 * Redirect to login screen if any endpoint returns a 401
 */
const useHandleLogout = () => {
  const {
    match: { params },
  } = useMatch()
  const router = useRouter()
  return (error?: RequestError | null) => {
    if (error?.statusCode !== 401) {
      return
    }
    const { projectSlug = 'portal', env = 'closed' } = params
    const path = `/${projectSlug}/${env}`
    if (path === window.location.pathname) {
      return
    }
    router?.replace(path)
  }
}

export default useHandleLogout
