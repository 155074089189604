import { QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'

export enum Method {
  delete = 'DELETE',
  get = 'GET',
  patch = 'PATCH',
  post = 'POST',
}

export enum WishListRequestKey {
  wishListLoading = 'wishListLoading',
  wishListUpdating = 'wishListUpdating',
  wishListFavorite = 'wishListFavorite',
  wishListTitle = 'wishListTitle',
  wishListUserWish = 'wishListUserWish',
}

/** All GET endpoints that return a IWishList response */
export type WishListQueryEndpoint = 'getWishList'

/** All mutation endpoints that return a IWishList response */
export type WishListMutationEndpoint =
  | 'postUserWish'
  | 'patchWishList'
  | 'patchUserWish'
  | 'postWishList'
  | 'duplicateWishList'

export type QueryEndpoint = 'getSettings' | 'getWishLists' | WishListQueryEndpoint

export type MutationEndpoint = 'deleteUserWish' | WishListMutationEndpoint

export type Endpoint = QueryEndpoint | MutationEndpoint

export type EndpointConfigItem = {
  path: string
  method: Method
  apiVersion?: string
}

export type RequestError = {
  message: string
  statusCode?: number
  data?: unknown
  humanReadableMessage?: string
}

export type RequestParams = Record<string, string>

export type WishListRequestParams = {
  projectSlug?: string
  env?: string
  userWishId?: string
  wishListId?: string
}

export type RequestBody = Record<string, unknown>

export type Variables = {
  params?: RequestParams
  body?: RequestBody
}

export type RequestOptions<QueryReturnType> = Omit<
  UseQueryOptions<QueryReturnType, RequestError, QueryReturnType, QueryKey>,
  'queryKey' | 'queryFn'
>

export type MutationOptions<QueryReturnType> = Omit<
  UseMutationOptions<QueryReturnType, RequestError, Variables>,
  'mutationKey' | 'mutationFn'
>
