import React, { useCallback } from 'react'
import { WishList } from '@/entities/types'
import Category from '@/components/houseDesign/Category'
import House from '@/components/houseDesign/HouseLayout'
import Summary from '@/components/houseDesign/Summary'
import InlineError from '@/molecules/InlineError'
import { getCategoryBySlug } from '@/processes/houseDesignSlugs'
import FadeAnimationWrapper from '@/atoms/FadeAnimationWrapper'
import styled from 'styled-components'
import useLayoutDimensions, { LayoutDimensions } from '@/hooks/useLayoutDimensions'
import { fromBreakpoint } from '@/styles/breakpoints'
import UserWishes from '@/components/houseDesign/userWish/UserWishes'
import { openModal } from '@/redux/houseDesign'
import { useDispatch } from 'react-redux'
import LayoutSidebar from './LayoutSidebar'
import LayoutStickyButton from './LayoutStickyButton'

type Props = {
  wishList: WishList
  wishListItem?: string
  disableFloorPlan?: boolean
}

type LayoutContentProps = {
  wishList: WishList
  wishListItem: string
}

const Container = styled.div<{ disableFloorPlan: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 56rem;
  padding-bottom: ${({ disableFloorPlan }) => (disableFloorPlan ? 0 : 80)}rem;

  ${fromBreakpoint.md`
    padding-top: 0;
  `}
  ${fromBreakpoint.slg`
    display: block;
    padding-bottom: 0;
  `}
`

const Main = styled.div<LayoutDimensions>`
  ${({ sideBarWidth }) =>
    fromBreakpoint.slg`
      padding-right: ${sideBarWidth}px;
    `}
  padding-bottom: 32rem;
`

const LayoutContent = ({ wishList, wishListItem }: LayoutContentProps) => {
  if (wishListItem === 'samenvatting') {
    return <Summary wishList={wishList} />
  }
  if (wishListItem === 'eigen-woonwensen') {
    return <UserWishes wishList={wishList} />
  }

  const category = getCategoryBySlug(wishList, wishListItem)

  if (!category) {
    return <InlineError text="Excuses, we hebben deze pagina niet kunnen vinden." />
  }

  return (
    <Category
      category={category}
      wishList={wishList}
    />
  )
}

const Layout = ({ wishListItem, wishList, disableFloorPlan = false }: Props) => {
  const layoutDimensions = useLayoutDimensions()
  const storeys = wishList.houseType?.storeys
  const dispatch = useDispatch()

  const openFloorplanModal = useCallback(() => {
    if (storeys) {
      dispatch(
        openModal({
          type: 'FloorPlan',
          props: { storeys, wishList },
        })
      )
    }
  }, [dispatch, storeys, wishList])

  return (
    <FadeAnimationWrapper animationKey={wishListItem ? 1 : 0}>
      {wishListItem ? (
        <Container disableFloorPlan={disableFloorPlan}>
          <Main {...layoutDimensions}>
            <FadeAnimationWrapper animationKey={wishListItem}>
              <LayoutContent
                wishListItem={wishListItem}
                wishList={wishList}
              />
            </FadeAnimationWrapper>
          </Main>
          <LayoutSidebar
            disableFloorPlan={disableFloorPlan}
            wishList={wishList}
            wishListItem={wishListItem}
            storeys={storeys}
            openModal={openFloorplanModal}
          />
          <LayoutStickyButton
            disableFloorPlan={disableFloorPlan}
            storeys={storeys}
            openModal={openFloorplanModal}
          />
        </Container>
      ) : (
        <House wishList={wishList} />
      )}
    </FadeAnimationWrapper>
  )
}

export default Layout
