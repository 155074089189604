import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const FloorIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <path
        d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0813 21 18.9984 21H0V6.277L10.5 0Z"
        fill={fillColor}
      />
    </Svg>
  )
}
export default FloorIcon
