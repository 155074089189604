import { colors } from '@/styles/colors'
import shadows from '@/styles/shadows'
import React, { ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ErrorBoundary from '@/atoms/ErrorBoundaryWithSentry'
import { z } from '@/styles/zIndexes'
import useLayoutDimensions, { LayoutDimensions } from '@/hooks/useLayoutDimensions'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import Header from '@/molecules/Header'
import { useSelector } from '@/redux/index'
import { useDispatch } from 'react-redux'
import { toggleMenu } from '@/redux/houseDesign'
import { useRouter } from 'next/router'

type Props = {
  menu: ReactNode
  children: ReactNode
  backLink?: string
}

const Container = styled.div`
  min-height: 100vh;
  background: ${colors.gray10};
`

const MobileNav = styled.div<{ open: boolean }>`
  padding-top: 56rem;
  background: ${colors.white};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${z.mobileNav};
  transform: translateX(-110%);
  transition: transform 400ms ease-in-out;
  ${shadows.lg}

  ${({ open }) =>
    open &&
    css`
      transform: translateX(0);
    `}
`

const Menu = styled.div<LayoutDimensions>`
  background: ${colors.white};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  ${shadows.default}
  z-index: ${z.menu};
  ${({ sideBarWidth, spacingInPx }) =>
    css`
      padding-left: ${spacingInPx}px;
      width: ${spacingInPx + sideBarWidth}px;
    `}
`

const Content = styled.div<LayoutDimensions>`
  margin: 0 auto;
  ${({ maxContentWidthInPx, sideBarWidth }) =>
    css`
      padding-left: ${sideBarWidth}px;
      max-width: ${maxContentWidthInPx}px;
    `}
`

const Chrome = ({ menu, backLink, children }: Props) => {
  const layoutDimensions = useLayoutDimensions()
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()
  const menuOpen = useSelector((state) => state.houseDesign.menuOpen)
  const dispatch = useDispatch()
  const router = useRouter()
  useEffect(() => {
    if (!md) {
      dispatch(toggleMenu(true))
    }
  }, [md, dispatch])

  if (!md) {
    return (
      <Container>
        <Header
          backButtonText="Overzicht"
          onClick={() => dispatch(toggleMenu(true))}
        />
        <ErrorBoundary>{children}</ErrorBoundary>
        <MobileNav open={menuOpen}>
          <Header
            backButtonText="Mijn woonontwerpen"
            onClick={() => {
              if (backLink) {
                router?.push(backLink)
              }
            }}
          />
          <ErrorBoundary>{menu as JSX.Element}</ErrorBoundary>
        </MobileNav>
      </Container>
    )
  }

  return (
    <Container>
      <Menu {...layoutDimensions}>
        <ErrorBoundary>{menu as JSX.Element}</ErrorBoundary>
      </Menu>
      <Content {...layoutDimensions}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Content>
    </Container>
  )
}

export default Chrome
