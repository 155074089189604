var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseAdditionalOptions = function HouseAdditionalOptions(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseAdditionalOptions_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseAdditionalOptions_title"
  }, "Huis aanvullende opties"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseAdditionalOptions_g"
  }, __jsx("g", {
    transform: "translate(-302.000000, -767.000000)",
    fill: color,
    "data-id": "icons_HouseAdditionalOptions_g_3"
  }, __jsx("g", {
    transform: "translate(302.000000, 767.000000)",
    "data-id": "icons_HouseAdditionalOptions_g_4"
  }, __jsx("g", {
    "data-id": "icons_HouseAdditionalOptions_g_5"
  }, __jsx("path", {
    d: "M10.03125,1.84375 L1.59375,6.875 L1.625,18.40625 L17.5,18.4375 C18.03125,18.4375 18.4375,18.03125 18.4375,17.5 L18.4375,6.90625 L10.03125,1.84375 Z M10.03125,0.03125 L20.03125,6.03125 L20,18.125 C20,19.15625 19.15625,20 18.125,20 L0.03125,20 L0.03125,6 L10.03125,0.03125 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseAdditionalOptions_path"
  })), __jsx("g", {
    transform: "translate(5.937500, 8.125000)",
    "data-id": "icons_HouseAdditionalOptions_g"
  }, __jsx("path", {
    d: "M2.68749996,1.62499998 C2.90624996,1.49999998 3.15624995,1.40624998 3.40624995,1.34374998 L3.40624995,0 L4.31249994,0 C4.65624993,0 4.93749993,0.281249996 4.93749993,0.624999991 L4.93749993,1.40624998 C5.12499993,1.46874998 5.31249992,1.56249998 5.49999992,1.65624998 L6.53124991,0.624999991 L7.1562499,1.24999998 C7.40624989,1.49999998 7.40624989,1.87499997 7.1562499,2.12499997 L6.53124991,2.74999996 C6.6249999,2.90624996 6.6874999,3.09374996 6.7499999,3.28124995 L8.24999988,3.28124995 L8.24999988,4.18749994 C8.24999988,4.53124993 7.96874988,4.81249993 7.62499989,4.81249993 L6.7499999,4.81249993 C6.6874999,4.99999993 6.6249999,5.15624993 6.53124991,5.34374992 L7.46874989,6.28124991 L6.8437499,6.9062499 C6.5937499,7.1562499 6.21874991,7.1562499 5.96874991,6.9062499 L5.49999992,6.43749991 C5.31249992,6.53124991 5.15624993,6.6249999 4.93749993,6.6874999 L4.93749993,8.12499988 L4.06249994,8.12499988 C3.71874995,8.12499988 3.43749995,7.84374989 3.43749995,7.49999989 L3.43749995,6.7812499 C3.21874995,6.7187499 2.99999996,6.6562499 2.78124996,6.53124991 L1.78124997,7.53124989 L1.15624998,6.9062499 C0.906249987,6.6562499 0.906249987,6.28124991 1.15624998,6.03124991 L1.68749998,5.49999992 C1.56249998,5.28124992 1.46874998,5.06249993 1.40624998,4.81249993 L0,4.81249993 L0,3.90624994 C0,3.56249995 0.281249996,3.28124995 0.624999991,3.28124995 L1.37499998,3.28124995 C1.43749998,3.06249996 1.53124998,2.87499996 1.62499998,2.68749996 L0.749999989,1.81249997 L1.37499998,1.18749998 C1.62499998,0.937499986 1.99999997,0.937499986 2.24999997,1.18749998 L2.68749996,1.62499998 Z M4.06249994,5.34374992 C4.74999993,5.34374992 5.34374992,4.78124993 5.34374992,4.06249994 C5.34374992,3.34374995 4.78124993,2.78124996 4.06249994,2.78124996 C3.34374995,2.78124996 2.78124996,3.34374995 2.78124996,4.06249994 C2.78124996,4.78124993 3.37499995,5.34374992 4.06249994,5.34374992 Z",
    fillRule: "nonzero",
    "data-id": "icons_HouseAdditionalOptions_path"
  }))))));
};
HouseAdditionalOptions.defaultProps = defaultProps;
export default HouseAdditionalOptions;