var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 59,
  height: 64
};
var Notification = function Notification(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 59 64",
    version: "1.1",
    "data-id": "icons_Notification_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Notification_g"
  }, __jsx("g", {
    transform: "translate(-3.000000, 0.000000)",
    fill: color,
    "data-id": "icons_Notification_g_2"
  }, __jsx("path", {
    d: "M37.0595743,5.62218931 C45.7711472,7.82001327 52.2190871,15.7081412 52.2190871,25.1022661 C52.2190871,25.3236198 52.2155072,25.5441372 52.2084019,25.7637638 L52.2190871,25.7637638 L52.2190871,47.1204997 C52.7455964,49.3569639 54.1963032,50.6921309 56.5712075,51.1260006 L57.4099998,51.1260006 C59.6191388,51.1260006 61.4099998,52.9168616 61.4099998,55.1260006 L61.4099998,55.7373164 L3,55.7373164 L3,55.1260006 C3,53.0676108 4.5547875,51.3723611 6.55403176,51.1505821 C6.52712485,51.1425843 6.56604775,51.1333272 6.67080047,51.1228108 C7.77679105,51.0117778 12.0351186,50.1818217 12.0351186,45.0718904 C12.0351186,44.9078203 12.0368259,44.7563577 12.0402405,44.6175026 L12.0402405,25.7637638 L12.0509257,25.7637638 C12.0438205,25.5441372 12.0402405,25.3236198 12.0402405,25.1022661 C12.0402405,15.726793 18.4626013,7.85134262 27.1478902,5.63534777 C27.1211125,5.42587423 27.107308,5.21234526 27.107308,4.99559209 C27.107308,2.23660276 29.3439108,4.72863212e-17 32.1029001,4.72863212e-17 C34.8618894,4.72863212e-17 37.0984922,2.23660276 37.0984922,4.99559209 C37.0984922,5.20780852 37.0852595,5.41693429 37.0595743,5.62218931 Z M26.1028368,58.8967732 L37.8784459,58.8967732 C37.8784459,61.7151869 35.5936702,63.9999627 32.7752565,63.9999627 L31.2060263,63.9999627 C28.3876126,63.9999627 26.1028368,61.7151869 26.1028368,58.8967732 Z M32.2780216,6.32354255 C32.9147114,6.32354255 33.4308505,5.80740345 33.4308505,5.17071361 C33.4308505,4.53402376 32.9147114,4.01788466 32.2780216,4.01788466 C31.6413318,4.01788466 31.1251927,4.53402376 31.1251927,5.17071361 C31.1251927,5.80740345 31.6413318,6.32354255 32.2780216,6.32354255 Z",
    "data-id": "icons_Notification_path"
  }))));
};
Notification.defaultProps = defaultProps;
export default Notification;