import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const SaveIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 512 512"
    >
      <path
        d="M432,96h-48V32c0-17.672-14.328-32-32-32H160c-17.672,0-32,14.328-32,32v64H80c-17.672,0-32,14.328-32,32v32h416v-32
		C464,110.328,449.672,96,432,96z M192,96V64h128v32H192z"
        fill={ICON_COLORS[variant]}
      />
      <path
        d="M80,480.004C80,497.676,94.324,512,111.996,512h288.012C417.676,512,432,497.676,432,480.008v-0.004V192H80V480.004z
		 M320,272c0-8.836,7.164-16,16-16s16,7.164,16,16v160c0,8.836-7.164,16-16,16s-16-7.164-16-16V272z M240,272
		c0-8.836,7.164-16,16-16s16,7.164,16,16v160c0,8.836-7.164,16-16,16s-16-7.164-16-16V272z M160,272c0-8.836,7.164-16,16-16
		s16,7.164,16,16v160c0,8.836-7.164,16-16,16s-16-7.164-16-16V272z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default SaveIcon
