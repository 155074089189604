import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fromBreakpoint } from '../styles/breakpoints';
import { textStyles } from '../styles/styleGuide';
import Form from './Form';
var FormContainer = function FormContainer(props) {
  return __jsx(Container, {
    "data-id": "components_FormContainer_Container"
  }, __jsx(Form, _extends({}, props, {
    "data-id": "components_FormContainer_Form"
  })));
};
export default FormContainer;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", " padding: 40rem 20rem;\n  background: #fff;\n  width: 100%;\n  box-sizing: border-box;\n\n  > div {\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n  ", ";\n\n  ", ";\n"])), textStyles.bodyText, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 80rem 60rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 100rem 100rem;\n  "]))));