import { ISettings } from '@/entities/types'
import { useQueryRequest } from '@/hooks/requestHooks'
import useMatch from '@/hooks/useMatch'
import { StaticPageSlug } from '@/types/pageType'
import { NextPage } from 'next'
import { StaticPageComponent } from '@/components/contentTypes/types'
import AppPage from '@/components/organisms/AppPage'

const SLUG_COMPONENT_MAP: Record<StaticPageSlug, StaticPageComponent> = {
  [StaticPageSlug.app]: AppPage,
}

/**
 * We can use this component to render pages that do not serve content from the back end.
 */
const StaticPage: NextPage = () => {
  const { slugs } = useMatch()
  const { data, isError, isLoading } = useQueryRequest<ISettings>('getSettings', undefined, {
    projectSlug: 'portal',
    env: 'open',
  })

  if (isLoading || isError) {
    return null
  }

  const StaticComponent = slugs ? SLUG_COMPONENT_MAP[slugs[0] as StaticPageSlug] : () => null

  return <StaticComponent settings={data} />
}

export default StaticPage
