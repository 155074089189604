import Container, { ContainerProps } from '@/atoms/Container'
import { IconProps } from '@/types/svgTypes'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children?: ReactNode
  icon?: FC<IconProps>
} & ContainerProps

const Banner = styled(Container)`
  display: flex;
  gap: 8rem;
  align-items: flex-start;
`

const IconWrapper = styled.div`
  padding-top: 3rem;
  height: 22rem;
`

const ContentWrapper = styled.div``

const SimpleBanner = ({ children, icon: Icon, ...props }: Props) => {
  return (
    <Banner
      variant="gray10"
      paddingVertical="sm"
      paddingHorizontal="md"
      {...props}
    >
      {!!Icon && (
        <IconWrapper>
          <Icon
            variant="gray30"
            height={16}
          />
        </IconWrapper>
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </Banner>
  )
}

export default SimpleBanner
