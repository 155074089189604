import Button from '@/atoms/Button'
import LayoutBase from '@/components/shared/layout/LayoutBase'
import { ISettings } from '@/entities/types'
import useRenderWidth from '@/hooks/useRenderWidth'
import { fromBreakpoint, toBreakpoint } from '@/styles/breakpoints'
import { GridWrapper } from '@/styles/gridElements'
import { colorPalette, textStyles } from '@/styles/styleGuide'
import { title } from 'process'
import { useEffect } from 'react'
import styled from 'styled-components'

const ArticleGridWrapper = styled(GridWrapper)`
  box-sizing: border-box;
  padding: 40rem 20rem 100rem;
`

const Article = styled.div`
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40rem;
  padding-left: 0;
  padding-right: 0;
`

const Title = styled.h1`
  ${textStyles.pageTitle};
  color: ${colorPalette.orange};
  ${toBreakpoint.md`
    margin-top: 40rem;
  `};

  ${fromBreakpoint.md`
    margin-top: 60rem;
  `};
`

const IntroParagraph = styled.p`
  ${textStyles.introText};
  margin-top: 0;
  margin-bottom: 20rem;
`

const Paragraph = styled.p`
  ${textStyles.bodyText};
  margin-top: 0;
  margin-bottom: 20rem;
`

const ButtonsWrapper = styled.div`
  ${fromBreakpoint.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20rem;`}
`

const ButtonWrapper = styled.p`
  margin-bottom: 20rem;
`

type ContentProps = {
  settings: ISettings
}

const TITLE = 'De Homestudios app'
const INTRO = `Met de Homestudios app kun je gebruikmaken van verschillende functionaliteiten in het Homestudios experience center om je bezoek optimaal te benutten en te personaliseren. Daarnaast biedt de app toegang tot je persoonlijke Homestudios account; zo heb je alle informatie over je nieuwe woning altijd binnen handbereik.`
const APPLE_TEXT = `Download de app voor je iPhone of iPad:`
const ANDROID_TEXT = `Download de app voor je Android device:`
const APP_STORE_LINK = 'https://apps.apple.com/nl/app/homestudios/id6504245890'
const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=nl.homestudios.customers&utm_source=download_page'

const LAYOUT_SETTINGS = {
  hasHeader: true,
  hasFooter: true,
  hasMenu: true,
}
const META = {
  metaTitle: TITLE,
  metaKeywords: 'app',
  metaDescription: '',
}

const getPlatform = () => {
  if (navigator) {
    // @ts-ignore
    const userAgentData = navigator.userAgentData as undefined | { platform?: string }
    if (userAgentData?.platform) {
      return userAgentData.platform.toUpperCase()
    }
    if (navigator.platform) {
      return navigator.platform.toUpperCase()
    }
  }

  return undefined
}

const isDebug = () => {
  const params = new URLSearchParams(document.location.search)

  return params.get('debug')
}

const navigateToStore = () => {
  const currentPlatform = getPlatform()

  if (isDebug() || !currentPlatform) {
    return
  }

  if (
    currentPlatform.includes('MAC') ||
    currentPlatform.includes('IPHONE') ||
    currentPlatform.includes('IPAD')
  ) {
    window.location.replace(APP_STORE_LINK)
    return
  }

  if (currentPlatform.includes('ANDROID')) {
    window.location.replace(PLAY_STORE_LINK)
  }
}

const AppPage = ({ settings }: ContentProps) => {
  const renderWidth = useRenderWidth()

  useEffect(() => {
    navigateToStore()
  }, [])

  return (
    // @ts-ignore
    <LayoutBase
      settings={settings}
      layoutSettings={LAYOUT_SETTINGS}
      renderWidth={renderWidth}
      title={title}
      pageTitle={TITLE}
      meta={META}
    >
      <ArticleGridWrapper>
        <Article>
          <Title>{TITLE}</Title>
          <IntroParagraph>{INTRO}</IntroParagraph>
          <ButtonsWrapper>
            <ButtonWrapper>
              <Paragraph>{APPLE_TEXT}</Paragraph>
              <Button
                href={APP_STORE_LINK}
                text="App Store"
                centerText
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Paragraph>{ANDROID_TEXT}</Paragraph>
              <Button
                href={PLAY_STORE_LINK}
                text="Google Play"
                centerText
              />
            </ButtonWrapper>
          </ButtonsWrapper>
        </Article>
      </ArticleGridWrapper>
    </LayoutBase>
  )
}

export default AppPage
