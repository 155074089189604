import parseTimestamp from './parseTimestamp';
export default (function (timestamp) {
  var isLongName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!timestamp) return '';
  var dateObject = parseTimestamp(timestamp);
  if (dateObject) {
    var _dateObjectType = isLongName ? dateObject.month && dateObject.month.longName : dateObject.month && dateObject.month.shortName;
    return "".concat(String(dateObject.day), " ").concat((_dateObjectType || '').toLowerCase(), " ").concat(String(dateObject.year));
  }
  return '';
});