export default (function (string) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  if (!string) return '';
  var ending = '...';
  if (string.length > length) {
    // Remove last space from string
    var _rtrim = function _rtrim(string) {
      return string.replace(/((\s*\S+)*)\s*/, '$1');
    };
    return _rtrim(string.substring(0, length)) + ending;
  } else {
    return string;
  }
});