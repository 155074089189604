import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import Link from '@/components/atoms/Link';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import styled from 'styled-components';
import { textStyles, colorPalette } from '../styles/styleGuide';
import { darken } from '../styles/utils';
import LoginIcon from './shared/icons/Login';
import get from 'lodash.get';
import MobileLoginBarProfile from './MobileLoginBarProfile';
import { updateMobileMenuState } from '../redux/menu';
import { getResultData } from '../redux/apiData';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onNavigate: function onNavigate() {
      dispatch(updateMobileMenuState(false));
    }
  };
};
var MobileLoginBar = function MobileLoginBar(_ref) {
  var settings = _ref.settings,
    match = _ref.match,
    styling = _ref.styling,
    onNavigate = _ref.onNavigate;
  var _match$params = match.params,
    projectSlug = _match$params.projectSlug,
    env = _match$params.env;
  var loggedIn = settings && settings.user;
  var showLogin = settings && settings.showLogin;
  var activeMenuColor = styling.navActiveLabel && env === 'open' ? styling.navActiveLabel : colorPalette.blue;
  return __jsx(Login, {
    loggedIn: env === 'closed',
    "data-id": "components_MobileLoginBar_Login"
  }, showLogin && (env === 'open' || env === 'closed' && !loggedIn) && __jsx(LoginItemlink, {
    href: "/".concat(String(projectSlug || ''), "/closed"),
    color: activeMenuColor,
    onClick: onNavigate,
    "data-id": "components_MobileLoginBar_LoginItemlink"
  }, __jsx(LoginIconWrapper, {
    "data-id": "components_MobileLoginBar_LoginIconWrapper"
  }, __jsx(LoginIcon, {
    color: "white",
    "data-id": "components_MobileLoginBar_LoginIcon"
  })), "Naar Mijn Homestudios"), env === 'closed' && loggedIn && __jsx(MobileLoginBarProfile, {
    settings: settings,
    "data-id": "components_MobileLoginBar_MobileLoginBarProfile"
  }), env === 'open' && loggedIn && __jsx(Itemlink, {
    href: 'https://www2.homestudios.nl/',
    color: activeMenuColor,
    target: "_blank",
    rel: "noopener noreferrer",
    "data-id": "components_MobileLoginBar_Itemlink"
  }, "Over Homestudios"));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(MobileLoginBar));
var Login = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-left: 40rem;\n  ", ";\n"])), textStyles.standardText);
var LoginItemlink = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding-left: 30rem;\n  color: ", ";\n  text-decoration: none;\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), colorPalette.white, colorPalette.headerGrey, darken(colorPalette.headerGrey, 15));
var LoginIconWrapper = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-right: 8rem;\n"])));
var Itemlink = styled.a(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding-left: 30rem;\n  color: ", ";\n  text-decoration: none;\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), colorPalette.white, colorPalette.headerGrey, darken(colorPalette.headerGrey, 15));