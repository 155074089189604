import Box from '@/atoms/Box'
import Price from '@/atoms/Price'
import CheckmarkIcon from '@/atoms/svg/CheckmarkIcon'
import ResponsiveTable from '@/atoms/ResponsiveTable'
import Title from '@/atoms/Title'
import { WishListOption } from '@/entities/wishList'
import { formatShortDate } from '@/processes/dateTime'
import {
  filterOutIrrelevantBlockedAndCancelledOrders,
  isOrderFinal,
  sortOrdersByDateDesc,
} from '@/processes/houseDesignStatuses'
import { fromBreakpoint } from '@/styles/breakpoints'
import { colors } from '@/styles/colors'
import { fontStyles } from '@/styles/fonts'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  option: WishListOption
  disabled?: boolean
}

type StyleProps = {
  disabled: boolean
}

const sharedStyles = ({ disabled }: StyleProps) => css`
  ${fontStyles.body.sm}
  ${disabled ? `color: ${colors.gray20};` : ''}
`

const Date = styled(Box)<StyleProps>`
  ${sharedStyles}
  min-width: 96rem;
`

const Amount = styled.div<StyleProps>`
  ${sharedStyles}
  min-width: 60rem;
  flex-grow: 1;
`

const Description = styled.div<StyleProps>`
  ${sharedStyles}
  flex-grow: 10;
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  align-items: flex-start;
`

const PriceBox = styled.div<StyleProps>`
  ${sharedStyles}
  min-width: 96rem;
  ${fromBreakpoint.lg`
    text-align: right;`}
`

const Icon = styled.div`
  margin-top: 0.1em;
`

const Orders = ({ option: { multiple, orders }, disabled = false }: Props) => {
  const tableConfig = useMemo(() => {
    const filteredOrders = filterOutIrrelevantBlockedAndCancelledOrders(orders)
    if (!filteredOrders || filteredOrders.length === 0) {
      return undefined
    }
    filteredOrders.sort(sortOrdersByDateDesc)
    return {
      headers: ['Datum', ...(multiple ? ['Aantal'] : []), 'Status', 'Prijs'],
      content: filteredOrders.map((order) => {
        const { amount, id, lastUpdate, placeDescription, salesPrice } = order
        const isFinal = isOrderFinal(order)
        return [
          {
            key: `Datum-${id}`,
            item: lastUpdate ? (
              <Date disabled={disabled}>{formatShortDate(lastUpdate)}</Date>
            ) : null,
          },
          ...(multiple
            ? [
                {
                  key: `Aantal-${id}`,
                  item: multiple ? (
                    <Amount disabled={disabled}>
                      {amount} stuk{amount > 1 ? 's' : ''}
                    </Amount>
                  ) : null,
                },
              ]
            : []),
          {
            key: `Status-${id}`,
            item: (
              <Description disabled={disabled}>
                {isFinal && !disabled && (
                  <Icon>
                    <CheckmarkIcon
                      variant="green"
                      width={14}
                    />
                  </Icon>
                )}
                <span>{placeDescription}</span>
              </Description>
            ),
          },
          {
            key: `Prijs-${id}`,
            item: (
              <PriceBox disabled={disabled}>
                <Price
                  price={salesPrice}
                  variant={disabled ? 'gray20' : undefined}
                  displayZero
                />
              </PriceBox>
            ),
          },
        ]
      }),
    }
  }, [disabled, multiple, orders])

  if (!tableConfig) {
    return null
  }

  return (
    <Box marginTop="md">
      <Title
        size="xs"
        text={`Mijn keuze${multiple && orders.length > 1 ? 's' : ''}`}
        variant={disabled ? 'gray25' : undefined}
      />
      <Box marginTop="xs">
        <ResponsiveTable
          disabled={disabled}
          headers={tableConfig.headers}
          content={tableConfig.content}
        />
      </Box>
    </Box>
  )
}

export default Orders
