import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const FavoriteIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4683 4.78996C12.2831 6.90296 10.4434 9.32983 6.99958 12.0176C3.55478 9.33087 1.71801 6.90296 1.52988 4.78996V4.08737C1.68039 2.4057 2.66561 1.69791 3.29931 1.63347C3.4716 1.61476 3.63399 1.60645 3.7845 1.60645C4.69347 1.60645 5.07469 1.89954 5.82722 2.84015L6.99958 4.30667L8.17293 2.84015C8.92546 1.89954 9.30469 1.60645 10.2156 1.60645C10.3642 1.60645 10.5266 1.61476 10.7008 1.63347C11.3335 1.69791 12.3198 2.4057 12.4683 4.08737V4.78996ZM10.8493 0.0342984C10.6235 0.0114328 10.4126 0 10.2156 0C8.62932 0 7.86887 0.718188 6.9995 1.80742C6.12915 0.718188 5.3687 0 3.78443 0C3.5854 0 3.3745 0.0114328 3.15072 0.0342984C1.77735 0.17461 0.192093 1.4925 0 4.01915V4.85895C0.180211 7.27751 1.91895 10.2594 6.9995 14C12.0811 10.2594 13.8198 7.27751 14 4.85895V4.01915C13.8069 1.4925 12.2207 0.17461 10.8493 0.0342984Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default FavoriteIcon
