import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fromBreakpoint } from '../../../styles/breakpoints';
import { colorPalette, fonts } from '../../../styles/styleGuide';
import Spinner from '../ui/Spinner';
import formatPrice from '../../../services/formatPrice';
var defaultProps = {
  isSold: false
};
var PriceTag = function PriceTag(_ref) {
  var isSold = _ref.isSold,
    small = _ref.small,
    mini = _ref.mini,
    notAvailable = _ref.notAvailable,
    loading = _ref.loading,
    price = _ref.price;
  return __jsx(PriceSummary, {
    isSold: isSold,
    small: small,
    mini: mini,
    notAvailable: notAvailable,
    loading: loading,
    "data-id": "designElements_PriceTag_PriceSummary"
  }, isSold ? 'Verkocht' : loading ? __jsx(Loading, {
    "data-id": "designElements_PriceTag_Loading"
  }, __jsx(Spinner, {
    monochrome: true,
    color: "#fff",
    size: "small",
    "data-id": "designElements_PriceTag_Spinner"
  })) : __jsx("div", {
    "data-id": "designElements_PriceTag_div"
  }, __jsx(EuroSignContainer, {
    "data-id": "designElements_PriceTag_EuroSignContainer"
  }, "\u20AC"), __jsx(PriceContainer, {
    "data-id": "designElements_PriceTag_PriceContainer"
  }, __jsx("span", {
    "data-e2e-pricetag": true,
    "data-id": "designElements_PriceTag_span"
  }, formatPrice(Number(price), {
    showDecimal: true,
    showEuroSign: false
  })))));
};
PriceTag.defaultProps = defaultProps;
export default PriceTag;
var PriceSummary = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  justify-content: center;\n  align-items: center;\n  height: ", "rem;\n  min-width: ", "rem;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  box-sizing: border-box;\n  background-color: ", ";\n  color: #fff;\n  text-transform: uppercase;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: ", ";\n  transition: background 0.25s ease-in-out;\n\n  > div {\n    display: flex;\n    flex-direction: row;\n  }\n\n  ", ";\n"])), function (props) {
  return props.mini ? 24 : 28;
}, function (props) {
  return props.mini ? 72 : 96;
}, function (props) {
  return props.loading ? colorPalette.darkGreen : props.isSold || props.notAvailable ? colorPalette.lightBeige : colorPalette.lightGreen;
}, fonts.primary, function (props) {
  return props.mini ? '12rem' : '14rem';
}, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    height: ", "rem;\n    min-width: ", "rem;\n    font-size: ", ";\n  "])), function (props) {
  return props.mini ? 24 : 32;
}, function (props) {
  return props.mini ? 72 : 115;
}, function (props) {
  return props.small ? '16rem' : props.mini ? '12rem' : '18rem';
}));
var EuroSignContainer = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-right: 5rem;\n"])));
var PriceContainer = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
var Loading = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n\n  > div {\n    width: 22rem;\n    min-height: 22rem;\n    margin-right: 10rem;\n\n    ", ";\n  }\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      min-height: 25rem;\n    "]))));