import React, { useMemo } from 'react'
import Container from '@/atoms/Container'
import { WishList } from '@/entities/wishList'
import SquareMeters from '@/atoms/SquareMeters'
import styled, { css } from 'styled-components'
import { fontWeight } from '@/styles/fonts'
import { colors } from '@/styles/colors'
import Button from '@/atoms/Button'
import DownloadIcon from '@/svg/DownloadIcon'
import Box from '@/atoms/Box'
import Text from '@/atoms/Text'
import TextBox from '@/atoms/TextBox'
import NavButtons from '@/components/houseDesign/NavButtons'
import CategoryIntro from '@/components/houseDesign/CategoryIntro'
import useSections from '@/hooks/useSections'
import Price from '@/atoms/Price'
import { ICON_MAP } from '@/constants/icons'
import { getSelectedOptions, OptionSummary, Section } from '@/processes/houseDesignSections'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { Slug } from '@/constants/slugs'
import ApproximateIcon from '@/atoms/svg/ApproximateIcon'
import SimpleBanner from '@/atoms/SimpleBanner'
import formatPrice from '@/services/formatPrice'

type Props = {
  wishList: WishList
}

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`

type ThTdStyleProps = {
  isCategory?: boolean
  alignRight?: boolean
  isLastOfCategory?: boolean
  isSummary?: boolean
}

const sharedThTdStyles = ({
  isCategory = false,
  alignRight = false,
  isLastOfCategory = false,
  isSummary = false,
}: ThTdStyleProps) => css`
  padding: 4rem;
  ${alignRight ? `text-align: right;` : ``}
  ${isCategory ? `padding-top: 16rem;` : ``}
  ${isLastOfCategory ? `padding-bottom: 16rem;` : ``}
  ${isSummary ? `border-top: 1rem solid ${colors.gray20};` : ``}
`

const StyledTh = styled.th<ThTdStyleProps>`
  font-weight: ${fontWeight.medium};
  ${({ isCategory = false }) => isCategory && `font-weight: ${fontWeight.bold};`}

  ${sharedThTdStyles}
`

const StyledTd = styled.td<ThTdStyleProps>`
  ${sharedThTdStyles}
`

const StyledTr = styled.tr<{ isCategory?: boolean }>`
  ${StyledTd}, ${StyledTh} {
    ${({ isCategory = false }) => isCategory && `border-top: 1rem solid ${colors.gray10};`}
  }
`

const IconContainer = styled.div`
  padding-top: 4rem;
`

const getPriceVariant = (isSummary: boolean, isCategory: boolean) => {
  if (isSummary) {
    return 'brand'
  }
  if (isCategory) {
    return 'transparent'
  }
  return 'gray30'
}

const Row = ({
  iconSlug,
  price,
  squareMeters,
  title,
  isBase = false,
  isCategory = false,
  isSummary = false,
  isLastOfCategory = false,
  isReference = false,
}: (Section | OptionSummary) & {
  isBase?: boolean
  isCategory?: boolean
  isLastOfCategory?: boolean
}) => {
  const {
    isLargerThan: { lg, md },
  } = useBreakpointInfo()
  const Icon = ICON_MAP[iconSlug ?? 'houseEmpty']

  const showIconCell = !md || lg

  return (
    <StyledTr isCategory={isCategory}>
      {showIconCell && (
        <StyledTd
          isCategory={isCategory}
          isLastOfCategory={isLastOfCategory}
        >
          {isCategory && (
            <IconContainer>
              <Icon
                width={22}
                variant="brand"
              />
            </IconContainer>
          )}
        </StyledTd>
      )}
      <StyledTh
        isCategory={isCategory}
        isLastOfCategory={isLastOfCategory}
      >
        {isReference && (
          <>
            <ApproximateIcon
              height={12}
              variant="gray30"
            />{' '}
          </>
        )}
        <Text
          inline
          text={title}
          variant={isCategory ? 'gray40' : 'gray30'}
        />
      </StyledTh>
      <StyledTd
        isCategory={isCategory}
        isLastOfCategory={isLastOfCategory}
        alignRight
      >
        <SquareMeters
          squareMeters={squareMeters}
          withPlus={!isBase && !isSummary}
          variant={isCategory ? 'gray40' : 'gray30'}
        />
      </StyledTd>
      <StyledTd
        isCategory={isCategory}
        isLastOfCategory={isLastOfCategory}
        alignRight
      >
        <Price
          price={price}
          displayZero
          variant={getPriceVariant(isSummary, isCategory)}
        />
      </StyledTd>
    </StyledTr>
  )
}

const CategoryRow = ({ wishList, section }: { wishList: WishList; section: Section }) => {
  const { id, slug } = section
  const options = useMemo(() => getSelectedOptions(wishList, id), [id, wishList])

  return (
    <>
      <Row
        {...section}
        isBase={slug === Slug.base}
        isCategory
        isLastOfCategory={options.length === 0}
      />
      {options.map((option, i) => (
        <Row
          key={option.id}
          {...option}
          isLastOfCategory={i === options.length - 1}
        />
      ))}
    </>
  )
}

const Summary = ({ wishList }: Props) => {
  const { sections } = useSections()
  const { exportUrl } = wishList

  const downloadButton = exportUrl ? (
    <Button
      href={exportUrl}
      text="Download samenvatting"
      icon={DownloadIcon}
    />
  ) : null

  const sectionsWithoutSummary = [...sections]
  const summarySection = sectionsWithoutSummary.pop() as Section

  const referenceOptionsTotalPrice = useMemo(
    () => Math.round(sections.reduce((total, { referencePrice = 0 }) => total + referencePrice, 0)),
    [sections]
  )

  return (
    <CategoryIntro
      title="Samenvatting"
      iconSlug="houseEuro"
    >
      <Container
        padding="xl"
        marginBottom="xl"
        shadow
      >
        <TextBox marginBottom="xl">
          <Text
            text={`Download een samenvatting van dit woonontwerp en deel deze met vrienden of familie. Of neem 'm mee naar de makelaar of hypotheekadviseur. Zo kom je goed beslagen ten ijs.`}
          />
          {downloadButton}
        </TextBox>
        <StyledTable>
          <tbody>
            {sectionsWithoutSummary.map((section) => (
              <CategoryRow
                section={section}
                wishList={wishList}
                key={section.slug}
              />
            ))}
          </tbody>
          <tfoot>
            <Row
              {...summarySection}
              title="Totaal"
              isCategory
              isLastOfCategory
            />
          </tfoot>
        </StyledTable>

        {referenceOptionsTotalPrice > 0 && (
          <SimpleBanner icon={ApproximateIcon}>
            <Text
              variant="gray30"
              text={`In dit totaalbedrag zijn voorbeeldopties meegerekend ter waarde van ${formatPrice(
                referenceOptionsTotalPrice,
                { showDecimal: false, showEuroSign: true }
              )}. Deze kosten zijn een schatting.`}
            />
          </SimpleBanner>
        )}
        <Box marginTop="lg">{downloadButton}</Box>
      </Container>
      <NavButtons />
    </CategoryIntro>
  )
}

export default Summary
