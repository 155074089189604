import { FC } from 'react'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { getPriceString } from '@/processes/priceFormatter'
import AnimatedBox from './AnimatedBox'

type Variant = 'brand' | 'transparent' | 'gray30' | 'gray25' | 'gray20'

type Props = {
  price?: number
  sign?: 'minus' | 'plus' | 'none'
  variant?: Variant
  displayZero?: boolean
  animation?: boolean
  strikeThrough?: boolean
}

const signs = {
  minus: '− ',
  plus: '+ ',
  none: '',
}

const BACKGROUND_COLOR_MAP: Record<Variant, string> = {
  brand: colors.brand,
  transparent: 'transparent',
  gray30: 'transparent',
  gray25: 'transparent',
  gray20: 'transparent',
}

const TEXT_COLOR_MAP: Record<Variant, string> = {
  brand: colors.white,
  transparent: colors.gray40,
  gray30: colors.gray30,
  gray25: colors.gray25,
  gray20: colors.gray20,
}

const PriceText = styled.span<{ variant: Variant; strikeThrough: boolean }>`
  ${({ variant }) => variant === 'brand' && 'font-weight: 700;'}
  color: ${({ variant }) => TEXT_COLOR_MAP[variant]};
  background-color: ${({ variant }) => BACKGROUND_COLOR_MAP[variant]};
  ${({ strikeThrough }) => strikeThrough && 'text-decoration: line-through;'};
`

const Content = styled.span`
  padding: 0 2rem;
  min-width: max-content;
  white-space: nowrap;
`

const Price: FC<Props> = ({
  price,
  sign = 'none',
  variant = 'transparent',
  displayZero = false,
  animation = false,
  strikeThrough = false,
}) => {
  if (price === undefined || (price === 0 && !displayZero)) {
    return null
  }

  const content = (
    <Content>
      {signs[sign]}
      {getPriceString(price)}
    </Content>
  )

  return (
    <PriceText
      variant={variant}
      strikeThrough={strikeThrough}
    >
      {animation ? (
        <AnimatedBox
          animationKey={price}
          fromTextColor={TEXT_COLOR_MAP[variant]}
          fromBackgroundColor={variant === 'brand' ? colors.brand : undefined}
          toBackgroundColor={variant === 'brand' ? colors.lightOrange : undefined}
        >
          {content}
        </AnimatedBox>
      ) : (
        content
      )}
    </PriceText>
  )
}

export default Price
