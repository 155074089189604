import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Triangle from './shared/designElements/Triangle';
import { textStyles, colorPalette, CUBIC_TIMING, fadeIn, rollOutTop, moveUpLeft } from '../styles/styleGuide';
import { hex2rgb } from '../styles/utils';
import { fromBreakpoint, toBreakpoint, breakpointSizes } from '../styles/breakpoints';
import { GridItem } from '../styles/gridElements';
var PageTextHero = function PageTextHero(_ref) {
  var heroText = _ref.heroText,
    env = _ref.env,
    renderWidth = _ref.renderWidth;
  var title = heroText.title,
    subtitle = heroText.subtitle,
    introText = heroText.introText;
  return env === 'open' ? __jsx(Container, {
    "data-id": "components_PageTextHero_Container"
  }, __jsx(Title, {
    "data-id": "components_PageTextHero_Title"
  }, title), subtitle && __jsx(Subtitle, {
    "data-id": "components_PageTextHero_Subtitle"
  }, subtitle), introText && __jsx(IntroText, {
    "data-id": "components_PageTextHero_IntroText"
  }, introText)) : __jsx(ContainerFlex, {
    "data-id": "components_PageTextHero_ContainerFlex"
  }, renderWidth && __jsx(TriangleContainer, {
    "data-id": "components_PageTextHero_TriangleContainer"
  }, __jsx(Triangle, {
    direction: "topleft",
    color: hex2rgb(colorPalette.orange, 0.25),
    width: renderWidth > breakpointSizes.lg ? 350 : 300,
    height: renderWidth > breakpointSizes.lg ? 350 : 300,
    "data-id": "components_PageTextHero_Triangle"
  })), __jsx(TextFlex, {
    "data-id": "components_PageTextHero_TextFlex"
  }, __jsx(Title, {
    "data-id": "components_PageTextHero_Title"
  }, title), subtitle && __jsx(Subtitle, {
    "data-id": "components_PageTextHero_Subtitle"
  }, subtitle), introText && __jsx(IntroText, {
    "data-id": "components_PageTextHero_IntroText"
  }, introText)));
};
export default PageTextHero;
var Container = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-top: 40rem;\n  max-width: 760rem;\n\n  ", ";\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-top: 60rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    margin-top: 80rem;\n    margin-bottom: 10rem;\n  "]))));
var Title = styled.h1(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 8rem;\n"])), textStyles.pageTitle, colorPalette.headerGrey);
var Subtitle = styled.h2(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 10rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var IntroText = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-top: 20rem;\n  margin-bottom: 0;\n\n  ", ";\n"])), textStyles.introText, colorPalette.textGrey, fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    margin-top: 30rem;\n    margin-bottom: 0;\n  "]))));
var TextFlex = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  z-index: 2;\n  margin-top: 0;\n  padding-bottom: 10rem;\n  color: #fff;\n  max-width: 760rem;\n  opacity: 0;\n  animation: ", " 0.75s ", ";\n  animation-fill-mode: forwards;\n  animation-delay: 0.7s;\n\n  > h1 {\n    color: #fff;\n    z-index: 2;\n  }\n\n  > h2 {\n    color: #fff;\n    z-index: 2;\n    margin-top: 0;\n  }\n\n  > p {\n    color: #fff;\n    z-index: 2;\n    margin-top: 0;\n    margin-bottom: 0;\n    padding: 0;\n  }\n"])), fadeIn, CUBIC_TIMING);
var ContainerFlex = styled(GridItem)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 39rem 30rem 30rem;\n  margin: 30rem 10rem;\n  background-color: ", ";\n  opacity: 0;\n  min-height: 270rem;\n  transform-origin: 0 0;\n  animation: ", " 0.4s ", ";\n  animation-fill-mode: forwards;\n\n  ", ";\n\n  @media screen and (min-width: 1001px) {\n    margin: 100rem 20rem 40rem 20rem;\n  }\n"])), colorPalette.lightOrange, rollOutTop, CUBIC_TIMING, fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    margin: 30rem 20rem;\n    padding: 70rem 100rem;\n  "]))));
var TriangleContainer = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 0;\n  top: -10rem;\n  left: -10rem;\n  height: 100%;\n  opacity: 0;\n  animation: ", " 0.5s ", ";\n  animation-delay: 0.4s;\n  animation-fill-mode: forwards;\n\n  > svg {\n    height: 100%;\n  }\n\n  ", ";\n\n  ", ";\n"])), moveUpLeft, CUBIC_TIMING, toBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    height: 60%;\n  "]))), fromBreakpoint.lg(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    top: -20rem;\n    left: -20rem;\n  "]))));