import { ISettings } from '@/entities/types'
import { setPhase } from '@/redux/houseDesign'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryRequest } from '@/hooks/requestHooks'
import useMatch from '@/hooks/useMatch'

/**
 * Request the settings endpoint, then set the user's phase (e.g. customer journey 1) in the redux state
 */
export const useSetPhase = () => {
  const dispatch = useDispatch()
  const {
    match: {
      params: { projectSlug },
    },
  } = useMatch()

  const { data, error, isError, isLoading, isRefetching } = useQueryRequest<ISettings>(
    'getSettings',
    undefined,
    {
      projectSlug,
      env: 'closed',
    }
  )

  useEffect(() => {
    if (!data) {
      return
    }

    dispatch(setPhase(data.phase))
  }, [data, dispatch])

  return {
    error,
    isError,
    isLoading,
    isRefetching,
  }
}
