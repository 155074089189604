import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import { BreakpointSize, toBreakpoint } from '@/styles/breakpoints'
import { colors } from '@/styles/colors'
import { fontStyles } from '@/styles/fonts'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  headers?: string[]
  content: { key: string | number; item?: ReactNode | JSX.Element }[][]
  breakpointSize?: BreakpointSize
  disabled?: boolean
}

type BreakpointStyleProps = { breakpoint: BreakpointSize }

const StyledTable = styled.table<BreakpointStyleProps & { disabled: boolean }>`
  ${({ breakpoint }) => toBreakpoint[breakpoint]`display:block;`}
  border-spacing: 2rem;
  width: calc(100% + 4rem);
  margin: 0 -2rem;
  text-align: left;
  ${fontStyles.body.sm}
  ${({ disabled }) => disabled && `color: ${colors.gray20};`}
`
const StyledThead = styled.thead<BreakpointStyleProps>`
  ${({ breakpoint }) => toBreakpoint[breakpoint]`display:block;`}
`
const StyledTbody = styled.tbody<BreakpointStyleProps>`
  ${({ breakpoint }) => toBreakpoint[breakpoint]`display:block;`}
`
const StyledTr = styled.tr<BreakpointStyleProps>`
  background-color: ${colors.gray10};
  border-bottom: 1rem solid ${colors.white};
  ${({ breakpoint }) => toBreakpoint[breakpoint]`display: block;`}
`
const StyledTh = styled.th<BreakpointStyleProps>`
  padding: 2rem 4rem 1rem;
  ${({ breakpoint }) => toBreakpoint[breakpoint]`display:block;`}
`
const StyledTd = styled.td<BreakpointStyleProps & { first?: boolean; last?: boolean }>`
  padding: 1rem 4rem;
  ${({ first }) => first && `padding-top: 2rem;`}
  ${({ last }) => last && `padding-bottom: 2rem;`}
  ${({ breakpoint }) => toBreakpoint[breakpoint]`
      display: block;
      position: relative;
      padding-left: calc(30% + 8rem);
      white-space: pre-wrap;
      overflow-wrap: break-word;
    `}
`
const TdBefore = styled.div<BreakpointStyleProps & { first?: boolean; last?: boolean }>`
  ${({ breakpoint, first }) => toBreakpoint[breakpoint]`
    position: absolute;
    display: block;
    top: ${first ? `2rem` : `1rem`};
    left: 4rem;
    width: calc(30% - 16rem);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-weight: 700;
  `}
`

const ResponsiveTable = ({
  headers,
  content,
  breakpointSize = BreakpointSize.lg,
  disabled = false,
}: Props) => {
  const { isLargerThan } = useBreakpointInfo()

  if (content.length === 0) {
    return null
  }

  const breakpoint = isLargerThan[breakpointSize]

  return (
    <StyledTable
      breakpoint={breakpointSize}
      disabled={disabled}
    >
      {breakpoint && !!headers && (
        <StyledThead breakpoint={breakpointSize}>
          <StyledTr breakpoint={breakpointSize}>
            {headers.map((header) => (
              <StyledTh
                breakpoint={breakpointSize}
                key={header}
              >
                {header}
              </StyledTh>
            ))}
          </StyledTr>
        </StyledThead>
      )}
      <StyledTbody breakpoint={breakpointSize}>
        {content.map((items, rowIndex) => {
          const trKey = headers?.[rowIndex] ?? rowIndex
          return (
            <StyledTr
              key={trKey}
              breakpoint={breakpointSize}
            >
              {items.map(({ key, item }, columnIndex) => {
                if (!item && !breakpoint) {
                  return null
                }
                const first = columnIndex === 0 && !breakpoint
                const last = rowIndex === content.length - 1
                return (
                  <StyledTd
                    breakpoint={breakpointSize}
                    key={key}
                    first={first}
                    last={last}
                  >
                    {!breakpoint && !!headers?.[columnIndex] && (
                      <TdBefore
                        breakpoint={breakpointSize}
                        first={first}
                        last={last}
                      >
                        {headers?.[columnIndex]}:
                      </TdBefore>
                    )}
                    {item}
                  </StyledTd>
                )
              })}
            </StyledTr>
          )
        })}
      </StyledTbody>
    </StyledTable>
  )
}

export default ResponsiveTable
