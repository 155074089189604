import { triggerLabels } from '../TriggerLabel';
var getOptionLabelType = function getOptionLabelType(flag, place) {
  var labelType;
  if (Object.keys(triggerLabels).includes(place)) {
    labelType = place;
  }
  if (flag) {
    labelType = flag;
  }
  return {
    type: labelType,
    hasLabel: !!labelType
  };
};
var getOptionStyleType = function getOptionStyleType(place) {
  var label = getOptionLabelType(place);
  var styleType = '';
  if (label.type) {
    styleType = label.type;
  } else if (place === 'signed') {
    styleType = 'signed';
  } else if (place === 'presold') {
    styleType = 'presold';
  }
  return {
    type: styleType,
    hasOptionStyle: !!label.hasLabel || place === 'signed' || place === 'presold'
  };
};
var getOptionUpdateDate = function getOptionUpdateDate(date) {
  var parts = date.split(' ')[0].split('-');
  return "".concat(parts[2], "-").concat(parts[1], "-").concat(parts[0]);
};
export { getOptionLabelType, getOptionStyleType, getOptionUpdateDate };