import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../styles/styleGuide';
import ImageLoader from './shared/ui/ImageLoader';
var ImageContainer = function ImageContainer(_ref) {
  var image = _ref.image,
    renderWidth = _ref.renderWidth;
  return __jsx(Container, {
    "data-id": "components_ImageContainer_Container"
  }, __jsx(ImageLoader, {
    color: colorPalette.lightBlue,
    mode: "img",
    src: image,
    position: "relative",
    width: renderWidth,
    "data-id": "components_ImageContainer_ImageLoader"
  }));
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n\n  > div {\n    width: 100%;\n    > img {\n      margin-left: auto;\n      margin-right: auto;\n    }\n  }\n"])));
export default ImageContainer;