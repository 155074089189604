import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { GridWrapper, GridItem } from '../../styles/gridElements';
import ProfileWidget from './ProfileWidget';
import ProfileForm from './ProfileForm';
var ProfileOverviewBlock = function ProfileOverviewBlock(_ref) {
  var contactUrl = _ref.contactUrl;
  return __jsx(Wrapper, {
    "data-e2e-page-layout": "profile",
    "data-id": "dashboard_ProfileOverviewBlock_Wrapper"
  }, __jsx(CopyCell, {
    "data-id": "dashboard_ProfileOverviewBlock_CopyCell"
  }, __jsx(Block, {
    "data-id": "dashboard_ProfileOverviewBlock_Block"
  }, __jsx(ProfileWidget, {
    isSmall: true,
    "data-id": "dashboard_ProfileOverviewBlock_ProfileWidget"
  }), __jsx(ProfileForm, {
    contactUrl: contactUrl,
    "data-id": "dashboard_ProfileOverviewBlock_ProfileForm"
  }))));
};
export default ProfileOverviewBlock;
var Wrapper = styled(GridWrapper)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: 1;\n"])));
var CopyCell = styled(GridItem)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-top: 100rem;\n  padding-bottom: 20rem;\n  margin-right: 10rem;\n  margin-left: 10rem;\n  padding-right: 0rem;\n  padding-left: 0rem;\n"])));
var Block = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: calc(33.33% - 10rem) calc(66.66% - 10rem);\n  grid-template-rows: 1fr;\n  grid-gap: 20px;\n\n  @media all and (max-width: 969px) {\n    grid-gap: 24px;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto auto;\n  }\n"])));