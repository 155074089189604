var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBlue,
  colorBrush: colorPalette.blue
};
var Actions = function Actions(_ref) {
  var color = _ref.color,
    colorBrush = _ref.colorBrush;
  return __jsx("svg", {
    width: "133px",
    height: "133px",
    viewBox: "0 0 133 133",
    version: "1.1",
    "data-id": "icons_MakeWishList_svg"
  }, __jsx("title", {
    "data-id": "icons_MakeWishList_title"
  }, "Make Wishlist"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-511.000000, -290.000000)",
    "data-id": "icons_MakeWishList_g"
  }, __jsx("g", {
    transform: "translate(168.000000, 111.000000)",
    "data-id": "icons_MakeWishList_g_3"
  }, __jsx("g", {
    transform: "translate(277.000000, 179.000000)",
    "data-id": "icons_MakeWishList_g_4"
  }, __jsx("g", {
    transform: "translate(66.000000, 0.000000)",
    "data-id": "icons_MakeWishList_g_5"
  }, __jsx("path", {
    d: "M66.5,0 L133,39.9624413 L132.792188,120.511737 C132.792188,127.380282 127.18125,133 120.323438,133 L0,133 L0,39.7543036 L66.5,0 Z M66.5,12.0719875 L10.390625,45.5821596 L10.5984375,122.384977 L116.167188,122.593114 C119.7,122.593114 122.401563,119.887324 122.401563,116.348983 L122.401563,45.7902973 L66.5,12.0719875 Z",
    fill: color,
    "data-id": "icons_MakeWishList_path"
  }), __jsx("g", {
    transform: "translate(38.000000, 53.000000)",
    fill: colorBrush,
    "data-id": "icons_MakeWishList_g"
  }, __jsx("path", {
    d: "M27.2116574,31 L19,22.4703846 C19,22.4703846 26.1325073,19.4878966 28.6352683,16.8907301 C31.1356204,14.2935635 43.6397898,1.30272655 43.6397898,1.30272655 C43.6397898,1.30272655 46.1377331,-1.29193792 48.1900453,0.842342496 C50.2447662,2.97412082 47.7420053,5.56878529 47.7420053,5.56878529 C47.7420053,5.56878529 35.2402447,18.5571202 32.7423014,21.1517846 C30.2395405,23.7539554 27.2116574,31 27.2116574,31",
    "data-id": "icons_MakeWishList_path"
  }), __jsx("path", {
    d: "M0,47.3063907 C6.48601057,45.6778047 5.85501859,40.3156916 9.7070045,30.9182765 C11.5999804,26.2993772 16.6601448,25 16.6601448,25 L25,33.5020668 C25,33.5020668 25,51.7357456 0,47.3063907",
    "data-id": "icons_MakeWishList_path_9"
  })))))));
};
Actions.defaultProps = defaultProps;
export default Actions;