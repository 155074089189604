import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { CardCollectionContainer } from './shared/designElements/CardElements';
import getLinkFromPageObject from '../services/getLinkFromPageObject';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import ArticleCard from './ArticleCard';
import withMatch from '@/hocs/withMatch';
var ArticleCardCollection = function ArticleCardCollection(_ref) {
  var articles = _ref.articles,
    renderWidth = _ref.renderWidth,
    label = _ref.label,
    link = _ref.link;
  return __jsx(ArticleBlock, {
    "data-e2e-block-articlecardcollection": true,
    "data-id": "components_ArticleCardCollection_ArticleBlock"
  }, __jsx(ArticleContainer, {
    "data-id": "components_ArticleCardCollection_ArticleContainer"
  }, (articles || []).map(function (article, index) {
    return __jsx(ArticleCard, {
      key: index,
      id: index,
      isBig: false,
      type: article.type,
      leader: article.leader,
      image: article.image,
      title: article.title,
      theme: article.theme,
      label: article.label,
      author: article.author,
      eventDate: article.eventDate,
      slug: article.slug,
      renderWidth: renderWidth,
      "data-id": "components_ArticleCardCollection_ArticleCard"
    });
  })), label && link && __jsx(ButtonWrapper, {
    "data-id": "components_ArticleCardCollection_ButtonWrapper"
  }, __jsx(ButtonSecondary, {
    label: label,
    route: '/' + getLinkFromPageObject(link),
    type: "internalLink",
    "data-id": "components_ArticleCardCollection_ButtonSecondary"
  })));
};
export default withMatch(ArticleCardCollection);
var ArticleBlock = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 40rem;\n  position: relative;\n\n  > svg {\n    position: absolute;\n    bottom: 0;\n    max-width: 800rem;\n    width: 100%;\n  }\n"])));
var ArticleContainer = styled(CardCollectionContainer)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  text-align: center;\n"])));