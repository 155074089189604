import Box from '@/atoms/Box'
import useLayoutDimensions, { LayoutDimensions } from '@/hooks/useLayoutDimensions'
import useResponsiveSpacing from '@/hooks/useResponsiveSpacing'
import { z } from '@/styles/zIndexes'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  children: ReactNode
  withPadding?: boolean
  withOffset?: boolean
}

const StyledSticky = styled.div<LayoutDimensions & { withOffset: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${z.stickyButton};
  ${({ sideBarWidth, currentContentWidthInPx, withOffset }) =>
    withOffset &&
    css`
      padding-left: ${sideBarWidth}px;
      width: ${currentContentWidthInPx}px;
    `}
`

const Sticky = ({ children, withPadding = false, withOffset = true }: Props) => {
  const layoutDimensions = useLayoutDimensions()
  const spacing = useResponsiveSpacing()

  return (
    <StyledSticky
      withOffset={withOffset}
      {...layoutDimensions}
    >
      {withPadding ? (
        <Box
          padding={spacing}
          paddingTop="none"
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </StyledSticky>
  )
}

export default Sticky
