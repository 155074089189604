import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import get from 'lodash.get'
import useMatch from '@/hooks/useMatch'
import { useRouter } from 'next/router'
import { useSelector } from '../redux/index'
import type { FormValuesObject } from './shared/forms/FormGenerator'
import { GridItem } from '../styles/gridElements'
import { textStyles, colorPalette, shadows, fonts } from '../styles/styleGuide'
import { fromBreakpoint } from '../styles/breakpoints'
import FormGenerator from './shared/forms/FormGenerator'
import { performApiRequest, getApiDataRequest } from '../redux/apiData'
import ButtonPrimary from './shared/buttons/ButtonPrimary'

const FormContainer = styled(GridItem)`
  z-index: 1;
  max-width: 1180rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #ffffff;
  ${shadows.boxPrimary};

  ${fromBreakpoint.md`
    margin-top: -210rem;
  `};

  > div {
    position: relative;
    min-width: 250rem;
    width: 100%;
    padding: 60rem 0 100rem;

    ${fromBreakpoint.md`
      width: 80%;
      padding: 60rem 80rem 100rem 80rem;
    `};
  }
`

const Title = styled.h2`
  ${textStyles.sectionTitle}
  color: ${colorPalette.headerGrey};
  margin-bottom: 10rem;
`

const FormError = styled.div`
  display: flex;
  padding: 20rem;
  justify-content: center;
  border: solid 1rem #eeeeee;
  border-radius: 5rem;
  background: ${colorPalette.lightGrey};
  font-family: ${fonts.primary};
  color: ${colorPalette.red};
`

const ForgotPasswordBlock = () => {
  const router = useRouter()
  const {
    match: {
      params: { projectSlug, token },
    },
  } = useMatch()
  const dispatch = useDispatch()
  const request = useSelector((state) =>
    getApiDataRequest(state.apiData, 'resetPassword', {
      projectSlug: String(projectSlug),
    })
  )

  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (!request) return

    if (request.networkStatus === 'success') {
      setSuccess(true)
      return
    }

    if (loading && get(request, 'errorBody.message')) {
      setError(get(request, 'errorBody.message'))
      setLoading(false)
    }
  }, [loading, request])

  const formConfig = useMemo(
    () => [
      {
        options: [
          {
            id: 'password',
            name: 'password',
            label: 'Vul hier je nieuwe wachtwoord in',
            required: true,
            title: 'Vul hier je nieuwe wachtwoord in',
            type: passwordIsVisible ? 'text' : 'password',
            toggle: true,
            help: 'Gebruik acht of meer tekens met een combinatie van letters, cijfers en symbolen',
          },
        ],
      },
    ],
    [passwordIsVisible]
  )

  const onPressSuccesButton = useCallback(() => {
    router?.push(`/${String(projectSlug)}/closed`)
  }, [projectSlug, router])

  if (success) {
    return (
      <FormContainer>
        <div>
          <Title>Gelukt!</Title>
          <p>Je wachtwoord is aangepast.</p>
          <ButtonPrimary
            // @ts-ignore
            id="form_submit"
            // @ts-ignore
            type="button"
            // @ts-ignore
            label="Naar startpagina"
            // @ts-ignore
            clickHandler={onPressSuccesButton}
          />
        </div>
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <div>
        <Title>Wachtwoord opnieuw instellen</Title>
        {error && <FormError>{error}</FormError>}
        <FormGenerator
          fields={formConfig}
          submitLabel="Nieuw wachtwoord instellen"
          loadingLabel="wachtwoord wordt ingesteld"
          submitFn={({ password }: FormValuesObject) => {
            setLoading(true)
            if (typeof password === 'string') {
              dispatch(
                // @ts-ignore
                performApiRequest(
                  'resetPassword',
                  { projectSlug: String(projectSlug) },
                  { password, token: String(token) }
                )
              )
            }
          }}
          togglePassword={() => setPasswordIsVisible((v) => !v)}
        />
      </div>
    </FormContainer>
  )
}

export default ForgotPasswordBlock
