import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import { shadows } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
var ModalInnerWrapper = function ModalInnerWrapper(_ref) {
  var children = _ref.children;
  return __jsx(Container, {
    "data-id": "designElements_ModalInnerWrapper_Container"
  }, children);
};
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  align-self: center;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  padding: 40rem 20rem 40rem 20rem;\n\n  ", ";\n\n  ", ";\n"])), shadows.boxPrimary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 80rem 70rem 60rem 70rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 80rem 200rem 80rem 200rem;\n  "]))));
export default ModalInnerWrapper;