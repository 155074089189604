var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseLivingReady = function HouseLivingReady(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseLivingReady_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseLivingReady_title"
  }, "Huis Woonklaar"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseLivingReady_g"
  }, __jsx("g", {
    transform: "translate(-238.000000, -767.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_HouseLivingReady_g_3"
  }, __jsx("g", {
    transform: "translate(238.000000, 767.000000)",
    "data-id": "icons_HouseLivingReady_g_4"
  }, __jsx("path", {
    d: "M7.34375,12.28125 L6.15625,11.09375 C5.90625,10.84375 5.53125,10.84375 5.28125,11.09375 L4.34375,12 L7.3125,14.96875 L12.40625,9.84375 L11.5,8.9375 C11.25,8.6875 10.875,8.6875 10.625,8.9375 L7.34375,12.28125 Z",
    "data-id": "icons_HouseLivingReady_path"
  }), __jsx("path", {
    d: "M15.90625,14.09375 L15.90625,13.9375 C15.875,13.71875 15.71875,13.5625 15.5,13.5625 L14.34375,13.5625 L14.34375,11.84375 L13.8125,11.84375 L13.625,11.84375 C13.40625,11.875 13.25,12.03125 13.25,12.25 L13.25,13.5625 L11.53125,13.5625 L11.53125,14.09375 L11.53125,14.28125 C11.5625,14.5 11.71875,14.65625 11.9375,14.65625 L13.25,14.65625 L13.25,16.21875 L13.8125,16.21875 L13.96875,16.21875 C14.1875,16.1875 14.34375,16.03125 14.34375,15.8125 L14.34375,14.65625 L15.90625,14.65625 L15.90625,14.09375 Z",
    "data-id": "icons_HouseLivingReady_path_6"
  }), __jsx("path", {
    d: "M10,1.8125 L1.5625,6.84375 L1.59375,18.375 L17.46875,18.40625 C18,18.40625 18.40625,18 18.40625,17.46875 L18.40625,6.875 L10,1.8125 Z M10,0 L20,6 L19.96875,18.09375 C19.96875,19.125 19.125,19.96875 18.09375,19.96875 L0,19.96875 L0,5.96875 L10,0 Z",
    "data-id": "icons_HouseLivingReady_path_7"
  })))));
};
HouseLivingReady.defaultProps = defaultProps;
export default HouseLivingReady;