import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { themes } from '../../../styles/styleGuide';
import Check from '../icons/Check';
var ProgressCircle = function ProgressCircle(_ref) {
  var theme = _ref.theme,
    progress = _ref.progress;
  return __jsx(BackgroundCircle, {
    "data-id": "designElements_ProgressCircle_BackgroundCircle"
  }, __jsx(InnerCircle, {
    color: themes[theme].primary,
    "data-id": "designElements_ProgressCircle_InnerCircle"
  }, __jsx(FirstHalf, {
    color: themes[theme].primary,
    lightColor: themes[theme].tertiary,
    "data-id": "designElements_ProgressCircle_FirstHalf"
  }, __jsx(SecondHalfWrap, {
    progress: progress,
    "data-id": "designElements_ProgressCircle_SecondHalfWrap"
  }, __jsx(SecondHalf, {
    color: themes[theme].primary,
    progress: progress,
    lightColor: themes[theme].tertiary,
    "data-id": "designElements_ProgressCircle_SecondHalf"
  })))), progress === 1 && __jsx(Check, {
    color: "white",
    "data-id": "designElements_ProgressCircle_Check"
  }));
};
export default ProgressCircle;
var BackgroundCircle = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 36rem;\n  height: 36rem;\n  border-radius: 18px;\n  background-color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n\n  > svg {\n    position: absolute;\n  }\n"])));
var InnerCircle = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 32rem;\n  height: 32rem;\n  border-radius: 16px;\n  background-color: ", ";\n  position: relative;\n"])), function (props) {
  return props.color;
});
var FirstHalf = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 16rem;\n  height: 32rem;\n  border-radius: 16px;\n  background-color: ", ";\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n  z-index: 1;\n"])), function (props) {
  return props.lightColor;
});
var SecondHalfWrap = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 32rem;\n  height: 32rem;\n  position: absolute;\n  top: 0;\n  left: 0;\n  transform: rotate(\n    -", "deg\n  );\n"])), function (props) {
  return props.progress === 1 ? 0 : (0.5 - props.progress % 0.5) * 360;
});
var SecondHalf = styled(FirstHalf)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background-color: ", ";\n"])), function (props) {
  return props.progress >= 0.5 ? props.color : props.lightColor;
});