import { useMutationRequest, useQueryRequest } from '@/hooks/requestHooks'
import { IWishList, WishList } from '@/entities/wishList'
import { addWishListData } from '@/redux/houseDesign'
import {
  Variables,
  RequestError,
  WishListMutationEndpoint,
  WishListQueryEndpoint,
  WishListRequestParams,
  RequestOptions,
  MutationOptions,
} from '@/types/endpoints'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { getLayoutImages, wishListPostProcess } from '@/processes/wishListPostProcess'
import useWishList from '@/hooks/useWishList'

/*
 * We use these hooks to consolidate a number of requests that all return the Wish List data: getWishList,
 * postUserWish and patchWishList. We take the last response that we receive from any of these endpoints
 * and store it in the redux state, so the redux state becomes the single source of truth for the Wish
 * List data.
 */

/**
 * Determine whether the new WishList data is more recent than the current WishList data, by comparing the updatedAt timestamps.
 */
const isOutdated = (newWishListData: IWishList, currentWishlistData?: WishList) => {
  if (!currentWishlistData) {
    return false
  }
  return newWishListData.updatedAt < currentWishlistData.updatedAt
}

/**
 * Hook for GET requests that return an WishList response.
 */
export const useWishListQuery = (
  endpoint: WishListQueryEndpoint,
  queryKey?: unknown[],
  params: WishListRequestParams = {},
  options: RequestOptions<WishList> = {}
) => {
  const dispatch = useDispatch()

  const currentWishlistData = useWishList()

  const result = useQueryRequest<WishList>(endpoint, queryKey, params, {
    ...options,
    onSuccess: (newWishListData) => {
      const wishListId = params?.wishListId
      if (wishListId && !isOutdated(newWishListData, currentWishlistData)) {
        const data = wishListPostProcess(newWishListData)
        dispatch(addWishListData(wishListId, data, getLayoutImages(data)))
      }
      options?.onSuccess?.(newWishListData)
    },
  })

  return { ...result, data: currentWishlistData } as UseQueryResult<
    WishList | undefined,
    RequestError
  > & {
    keys: unknown[]
  }
}

/**
 * Hook for mutation requests that return an WishList response.
 */
export const useWishListMutation = (
  endpoint: WishListMutationEndpoint,
  mutationKey: unknown[],
  params?: WishListRequestParams,
  options?: MutationOptions<WishList>
) => {
  const dispatch = useDispatch()

  const currentWishlistData = useWishList()

  const result = useMutationRequest<WishList>(endpoint, mutationKey, {
    ...options,
    onSuccess: (newWishListData, ...rest) => {
      const wishListId = params?.wishListId
      if (wishListId && !isOutdated(newWishListData, currentWishlistData)) {
        dispatch(addWishListData(wishListId, wishListPostProcess(newWishListData)))
      }
      options?.onSuccess?.(newWishListData, ...rest)
    },
  })

  return { ...result, data: currentWishlistData } as UseMutationResult<
    WishList,
    RequestError,
    Variables
  >
}
