import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import ImageLoader from './shared/ui/ImageLoader';
import { colorPalette, fonts } from '../styles/styleGuide';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import { fromBreakpoint } from '../styles/breakpoints';
import { LinkStyles } from './shared/richText/PageLinkInternal';
var getProjectBioFields = function getProjectBioFields(_ref) {
  var type = _ref.type,
    features = _ref.features,
    partners = _ref.partners,
    energyLabel = _ref.energyLabel,
    location = _ref.location,
    latitude = _ref.latitude,
    longitude = _ref.longitude;
  return [{
    id: 'type',
    label: 'Type',
    value: type,
    type: 'text'
  }, {
    id: 'features',
    label: 'Kenmerken',
    value: features,
    type: 'text'
  }, {
    id: 'energylabel',
    label: 'Energielabel',
    value: energyLabel,
    type: 'text'
  }, {
    id: 'partners',
    label: 'Partners',
    value: partners,
    type: 'text'
  }, {
    id: 'city',
    label: 'Plaats',
    value: location,
    type: 'text'
  }, {
    id: 'location',
    label: 'Locatie',
    value: "https://www.google.com/maps/search/?api=1&query=".concat(latitude, ",").concat(longitude),
    type: 'link'
  }];
};
var ProjectBio = function ProjectBio(_ref2) {
  var projectSlug = _ref2.projectSlug,
    title = _ref2.title,
    image = _ref2.image,
    type = _ref2.type,
    features = _ref2.features,
    energyLabel = _ref2.energyLabel,
    location = _ref2.location,
    latitude = _ref2.latitude,
    longitude = _ref2.longitude,
    partners = _ref2.partners;
  return __jsx(Container, {
    "data-id": "components_ProjectBio_Container"
  }, __jsx(ProjectLogo, {
    href: "/".concat(projectSlug, "/open"),
    target: "_blank",
    "data-id": "components_ProjectBio_ProjectLogo"
  }, __jsx(ImageLoader, {
    src: image,
    width: 260,
    height: 0,
    mode: "img",
    alt: title,
    "data-id": "components_ProjectBio_ImageLoader"
  })), __jsx(ProjectDetails, {
    "data-id": "components_ProjectBio_ProjectDetails"
  }, __jsx(ProjectDetailsHeader, {
    "data-id": "components_ProjectBio_ProjectDetailsHeader"
  }, "Projectinformatie"), __jsx(ProjectTitle, {
    "data-id": "components_ProjectBio_ProjectTitle"
  }, title), __jsx(List, {
    "data-id": "components_ProjectBio_List"
  }, getProjectBioFields({
    type: type,
    features: features,
    partners: partners,
    energyLabel: energyLabel,
    location: location,
    latitude: latitude,
    longitude: longitude
  }).map(function (field) {
    return field.value ? __jsx(ListItem, {
      key: field.id,
      "data-id": "components_ProjectBio_ListItem"
    }, field.label, ":", ' ', field.type === 'text' ? field.value : __jsx(SmallLink, {
      href: field.value,
      target: "_blank",
      rel: "noopener noreferrer",
      "data-id": "components_ProjectBio_SmallLink"
    }, "Bekijk op kaart")) : null;
  })), __jsx(ButtonSecondary, {
    color: colorPalette.blue,
    type: "externalLink",
    label: "Naar de projectwebsite",
    link: "/".concat(projectSlug, "/open"),
    target: "_blank",
    "data-id": "components_ProjectBio_ButtonSecondary"
  })));
};
export default ProjectBio;
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 20rem 0;\n  border-top: dashed 1rem ", ";\n  border-bottom: dashed 1rem ", ";\n  font-family: ", ";\n  margin-bottom: 30rem;\n  ", ";\n"])), colorPalette.lightBeige, colorPalette.lightBeige, fonts.primary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: row;\n  "]))));
var ProjectLogo = styled.a(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  align-self: flex-start;\n  flex-shrink: 0;\n  max-width: 280rem;\n  width: 100%;\n  height: 262rem;\n  padding: 20rem;\n  box-sizing: border-box;\n  border: solid 1rem #e3e3e3;\n  margin-bottom: 20rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 280rem;\n    margin-bottom: 0;\n    margin-right: 20rem;\n  "]))));
var ProjectDetails = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  > a {\n    margin-top: auto;\n    margin-bottom: 3rem;\n  }\n"])));
var ProjectTitle = styled.h3(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-size: 18rem;\n  font-weight: bold;\n  margin: 0 0 10rem 0;\n  color: ", ";\n"])), colorPalette.textGrey);
var ProjectDetailsHeader = styled.h4(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 18rem;\n  font-weight: normal;\n  margin: 0 0 5rem 0;\n  padding: 0;\n  color: ", ";\n"])), colorPalette.textGrey);
var List = styled.ul(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  margin-bottom: 5rem;\n  color: ", ";\n"])), colorPalette.textGrey);
var ListItem = styled.li(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  list-style: none;\n  margin-bottom: 5rem;\n  font-size: 14rem;\n"])));
var SmallLink = styled.a(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: inherit;\n"])), LinkStyles);