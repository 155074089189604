import { colorPalette } from '../styles/styleGuide';
var getOrderStatusColor = function getOrderStatusColor(status) {
  switch (status) {
    case 'processing':
    case 'processed':
    case 'confirmed':
    case 'signed':
    case 'signed-revised':
    case 'presale':
    case 'presold':
    case 'presold-revised':
    case 'include':
    case 'included':
    case 'included-revised':
      return colorPalette.green;
    case 'blocked':
      return colorPalette.textGrey;
    case 'requested':
    case 'requested-revised':
    default:
      return colorPalette.blue;
  }
};
export { getOrderStatusColor };
export default {
  requested: {
    color: colorPalette.blue
  },
  processing: {
    color: colorPalette.green
  },
  processed: {
    color: colorPalette.green
  },
  confirmed: {
    color: colorPalette.green
  },
  signed: {
    color: colorPalette.green
  },
  'signed-revised': {
    color: colorPalette.green
  },
  'requested-revised': {
    color: colorPalette.blue
  },
  presale: {
    color: colorPalette.green
  },
  presold: {
    color: colorPalette.green
  },
  'presold-revised': {
    color: colorPalette.green
  },
  include: {
    color: colorPalette.green
  },
  included: {
    color: colorPalette.green
  },
  'included-revised': {
    color: colorPalette.green
  },
  blocked: {
    color: colorPalette.textGrey
  }
};