/* eslint-disable react/style-prop-object */
import Box from '@/atoms/Box'
import { useState } from 'react'
import styled from 'styled-components'
// there is no typing for react-svgmt, but since the code is simple, we can ignore it
// @ts-ignore
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { WishList } from '@/entities/wishList'
import { getTotalAmountForOption } from '@/processes/houseDesignStatuses'
import UtilityButton from '@/atoms/UtilityButton'
import Floor0Icon from '@/svg/Floor0Icon'
import Floor1Icon from '@/svg/Floor1Icon'
import Floor2Icon from '@/svg/Floor2Icon'
import Floor3Icon from '@/svg/Floor3Icon'
import Floor4Icon from '@/svg/Floor4Icon'
import FloorIcon from '@/svg/FloorIcon'
import FadeAnimationWrapper from '@/atoms/FadeAnimationWrapper'
import { getOptionByUuid, getAllOptionUuids } from '@/processes/optionByUuid'
import { FloorPlanProps } from './FloorPlan.props'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  gap: 16rem;
`

const FloorPlanContainer = styled(FadeAnimationWrapper)`
  flex-grow: 10;
  overflow: hidden;
`

const Buttons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 12rem;
`

const StyledSvgLoader = styled(SvgLoader)`
  display: block;
  width: 100%;
  height: 100%;
`

const animationStyle = 'transition:opacity 700ms;'

const getGetStyle = (wishList: WishList) => (uuid: string) => {
  const option = getOptionByUuid(uuid, wishList)
  if (!option) {
    return 'opacity:0'
  }
  return getTotalAmountForOption(option, wishList) > 0
    ? `opacity:1;${animationStyle}`
    : `opacity:0;${animationStyle}`
}

const ICON_MAP = [Floor0Icon, Floor1Icon, Floor2Icon, Floor3Icon, Floor4Icon]

const FloorPlan = ({ storeys, wishList }: FloorPlanProps) => {
  const [currentStoreyIndex, setCurrentStoreyIndex] = useState(0)
  const getStyle = getGetStyle(wishList)

  return (
    <Container>
      {storeys.length > 1 && (
        <Buttons>
          {storeys.map(({ name }, i) => (
            <UtilityButton
              key={name}
              onClick={() => {
                setCurrentStoreyIndex(i)
              }}
              icon={ICON_MAP[i] ?? FloorIcon}
              iconSize={22}
              variant={i === currentStoreyIndex ? 'white' : 'gray40'}
            />
          ))}
        </Buttons>
      )}
      <FloorPlanContainer
        animationKey={currentStoreyIndex}
        style={{
          flexGrow: 10,
          overflow: 'hidden',
        }}
      >
        <StyledSvgLoader path={storeys[currentStoreyIndex].floorPlan}>
          <SvgProxy
            selector={`[data-is-option="true"]`}
            style="opacity:0"
          />
          {/* {
            <SvgProxy
              selector="text"
              style="display:none"
            />
          } */}
          {getAllOptionUuids(wishList).map((uuid) => (
            <SvgProxy
              key={uuid}
              selector={`#uuid-${uuid}`}
              style={getStyle(uuid)}
            />
          ))}
        </StyledSvgLoader>
      </FloorPlanContainer>
    </Container>
  )
}

export default FloorPlan
