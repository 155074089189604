var __jsx = React.createElement;
import React from 'react';
var Hamburger = function Hamburger(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    style: {
      width: '20px',
      height: '17px'
    },
    viewBox: "0 0 20 17",
    "data-id": "icons_Hamburger_svg"
  }, __jsx("title", {
    "data-id": "icons_Hamburger_title"
  }, "Hamburger of Menu icoon"), __jsx("g", {
    fill: color,
    "data-id": "icons_Hamburger_g"
  }, __jsx("path", {
    d: "M0,0 L18.4967494,0 C19.3269718,0 20,0.665797234 20,1.5 L20,3 L0,3 L0,0 Z",
    "data-id": "icons_Hamburger_path"
  }), __jsx("rect", {
    x: "0",
    y: "7",
    width: "20",
    height: "3",
    "data-id": "icons_Hamburger_rect"
  }), __jsx("path", {
    d: "M0,14 L20,14 L20,17 L1.5032506,17 C0.673028218,17 0,16.3342028 0,15.5 L0,14 Z",
    "data-id": "icons_Hamburger_path"
  })));
};
export default Hamburger;