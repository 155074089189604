var __jsx = React.createElement;
import React from 'react';
var DottedHorizontalLine = function DottedHorizontalLine(_ref) {
  var width = _ref.width;
  return __jsx("svg", {
    width: "".concat(width),
    height: "5",
    viewBox: "0 0 ".concat(width, " 3"),
    version: "1.1",
    "data-id": "designElements_DottedHorizontalLine_svg"
  }, __jsx("line", {
    strokeDasharray: "5, 4",
    y1: "0",
    x1: "0",
    y2: "0",
    x2: "".concat(width),
    style: {
      stroke: 'white',
      strokeWidth: 2
    },
    "data-id": "designElements_DottedHorizontalLine_line"
  }));
};
export default DottedHorizontalLine;