export const setHtmlStyleForModal = (open: boolean, scrollbarWidth: number) => {
  const html = document.getElementsByTagName('html')[0]
  html.style.overflowY = open ? 'hidden' : 'scroll'
  html.style.marginRight = open ? `${scrollbarWidth}px` : '0'
}

export const resetHtmlStyleForModal = () => {
  const html = document.getElementsByTagName('html')[0]
  html.style.overflowY = ''
  html.style.marginRight = ''
}

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}
