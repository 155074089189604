import { ReactElement, ReactNode } from 'react'
import Spinner from '@/components/shared/ui/Spinner'
import { colors, RGBAColors } from '@/styles/colors'
import styled from 'styled-components'
import InlineError from '@/molecules/InlineError'

type Props = {
  children?: ReactNode
  error?: string
  isLoading?: boolean
  isError?: boolean
  statusCode?: number
  transparent?: boolean
  alignRight?: boolean
}

const SpinnerOuter = styled.div`
  min-height: 24rem;
  min-width: 24rem;
  position: relative;
`

type SpinnerInnerProps = Pick<Props, 'transparent' | 'alignRight'>

const SpinnerInner = styled.div<SpinnerInnerProps>`
  display: flex;
  justify-content: ${({ alignRight }) => (alignRight ? 'flex-end' : 'center')};
  align-items: center;
  ${(transparent) => transparent && `background-color: ${RGBAColors.white50};`}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const getErrorText = (error?: string, statusCode?: number) => {
  let result = ''
  if (error) {
    result += `: ${error}`
  }
  if (statusCode) {
    result += ` (code ${statusCode})`
  }
  return result
}

const InlineRequestHandler = ({
  children,
  error,
  isLoading = false,
  isError = false,
  statusCode,
  transparent = false,
  alignRight = false,
}: Props) => {
  if (isError) {
    return (
      <InlineError
        text={`Onze excuses. Er is iets misgegaan bij het ophalen van de informatie${getErrorText(
          error,
          statusCode
        )}. Herlaad de pagina a.u.b.`}
      />
    )
  }

  if (isLoading) {
    return (
      <SpinnerOuter>
        {transparent && children}
        <SpinnerInner
          transparent={transparent}
          alignRight={alignRight}
        >
          <Spinner
            monochrome
            size="small"
            color={colors.brand}
          />
        </SpinnerInner>
      </SpinnerOuter>
    )
  }

  return children as ReactElement
}

export default InlineRequestHandler
