import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import withMatch from '@/hocs/withMatch';
import { clearModalState, updateModalOpenState } from '../redux/modal';
import { getResultData } from '../redux/apiData';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import Pricelist from './Pricelist';
import { colorPalette, textStyles } from '../styles/styleGuide';
import get from 'lodash.get';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  var _ownProps$match$param = ownProps.match.params,
    projectSlug = _ownProps$match$param.projectSlug,
    env = _ownProps$match$param.env;
  return {
    showLogin: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(projectSlug),
      env: String(env)
    }), 'showLogin', false)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleClick: function handleClick(slug) {
      var _ownProps$router;
      dispatch(updateModalOpenState(false));
      dispatch(clearModalState());
      (_ownProps$router = ownProps.router) === null || _ownProps$router === void 0 || _ownProps$router.push("/".concat(String(ownProps.match.params.projectSlug), "/open/housetype/").concat(slug));
    }
  };
};
var PricelistBlock = function PricelistBlock(_ref) {
  var match = _ref.match,
    priceListTable = _ref.priceListTable,
    _ref$houseTypes = _ref.houseTypes,
    houseTypes = _ref$houseTypes === void 0 ? [] : _ref$houseTypes,
    layoutHouseType = _ref.layoutHouseType,
    handleClick = _ref.handleClick,
    renderWidth = _ref.renderWidth,
    modal = _ref.modal,
    showLogin = _ref.showLogin,
    onBoarding = _ref.onBoarding;
  var projectSlug = match.params.projectSlug;
  var extraField = 0;
  if (priceListTable.groundLease) extraField++;
  if (priceListTable.priceLivingReady) extraField++;
  if (priceListTable.gardenLocation) extraField++;
  if (priceListTable.parkingSpots) extraField++;
  if (priceListTable.nearWater) extraField++;
  return __jsx(Fragment, null, houseTypes.map(function (houseType, index1) {
    return __jsx("div", {
      key: index1,
      "data-e2e-block-pricelistblock": true,
      "data-id": "components_PricelistBlock_div"
    }, __jsx(TypeTitle, {
      "data-id": "components_PricelistBlock_TypeTitle"
    }, !layoutHouseType ? "".concat(houseType.typeName, " ").concat(houseType.typeUsp ? '- ' + houseType.typeUsp : '') : 'Heb je interesse in deze woning?'), layoutHouseType && __jsx(PriceListInfo, {
      "data-id": "components_PricelistBlock_PriceListInfo"
    }, __jsx(HouseDetails, {
      "data-id": "components_PricelistBlock_HouseDetails"
    }, "In de prijslijst hieronder zie je alle beschikbare woningen binnen deze woningvariant, inclusief informatie over de kaveloppervlakte, v.o.n.-prijs en verkoopstatus per bouwnummer. Kies jouw favoriete woning en ontdek op Mijn Homestudios alle mogelijkheden om van dit huis een thuis te maken."), __jsx(ButtonWrapper, {
      "data-id": "components_PricelistBlock_ButtonWrapper"
    }, __jsx(ButtonSecondary, {
      label: "Meer over Homestudios",
      type: "internalLink",
      route: "/".concat(String(projectSlug), "/closed"),
      "data-id": "components_PricelistBlock_ButtonSecondary"
    }))), houseType.houseVariants.map(function (houseVariant, index2) {
      return __jsx("div", {
        key: index2,
        "data-id": "components_PricelistBlock_div"
      }, !layoutHouseType && __jsx(PriceListInfo, {
        "data-id": "components_PricelistBlock_PriceListInfo"
      }, houseVariant.variantName && __jsx(HouseTitle, {
        "data-id": "components_PricelistBlock_HouseTitle"
      }, "".concat(houseVariant.variantName, " ").concat(houseVariant.variantCodes && houseVariant.variantCodes.length > 0 ? "- ".concat(houseVariant.variantCodes.join(', ')) : "")), __jsx(HouseDetails, {
        "data-id": "components_PricelistBlock_HouseDetails"
      }, "Woonoppervlak:", ' ', __jsx("strong", {
        "data-id": "components_PricelistBlock_strong"
      }, houseVariant.livingSpace ? houseVariant.livingSpace + ' m²' : '-')), __jsx(HouseDetails, {
        "data-id": "components_PricelistBlock_HouseDetails"
      }, "Slaapkamers:", ' ', __jsx("strong", {
        "data-id": "components_PricelistBlock_strong"
      }, houseVariant.bedrooms ? houseVariant.bedrooms : '-')), __jsx(HouseDetails, {
        "data-id": "components_PricelistBlock_HouseDetails"
      }, "Energielabel:", ' ', __jsx("strong", {
        "data-id": "components_PricelistBlock_strong"
      }, houseVariant.energyLabel ? houseVariant.energyLabel : '-')), houseType.showPage && __jsx(ButtonWrapper, {
        "data-id": "components_PricelistBlock_ButtonWrapper"
      }, houseType.externalUrl ? __jsx(ButtonSecondary, {
        label: "Meer over deze woningvariant",
        type: "externalLink",
        link: houseType.externalUrl,
        target: "_blank",
        "data-id": "components_PricelistBlock_ButtonSecondary"
      }) : __jsx(ButtonSecondary, {
        label: "Meer over deze woningvariant",
        type: "button",
        clickHandler: function clickHandler() {
          return handleClick(houseVariant.slug);
        },
        "data-id": "components_PricelistBlock_ButtonSecondary_17"
      }))), extraField < 3 && renderWidth > 1067 && __jsx(PricelistContainer, {
        modal: modal,
        "data-id": "components_PricelistBlock_PricelistContainer"
      }, __jsx(Pricetable, {
        "data-id": "components_PricelistBlock_Pricetable"
      }, __jsx(TitleContainer, {
        "data-id": "components_PricelistBlock_TitleContainer"
      }, __jsx(Titles, {
        showLogin: showLogin,
        "data-id": "components_PricelistBlock_Titles"
      }, __jsx(PropertyTitle, {
        width: 75,
        "data-id": "components_PricelistBlock_PropertyTitle"
      }, "Bouwnr."), __jsx(PropertyTitle, {
        width: 85,
        "data-id": "components_PricelistBlock_PropertyTitle_23"
      }, "Kavelopp."), __jsx(PropertyTitle, {
        width: 134,
        "data-id": "components_PricelistBlock_PropertyTitle_24"
      }, "Prijs"), priceListTable.groundLease && __jsx(PropertyTitle, {
        width: 90,
        "data-id": "components_PricelistBlock_PropertyTitle_25"
      }, "Erfpacht"), priceListTable.priceLivingReady && __jsx(PropertyTitle, {
        width: 120,
        "data-id": "components_PricelistBlock_PropertyTitle_26"
      }, "Woonklaar v.a."), priceListTable.gardenLocation && __jsx(PropertyTitle, {
        width: 95,
        "data-id": "components_PricelistBlock_PropertyTitle_27"
      }, "Tuinligging"), priceListTable.parkingSpots && __jsx(PropertyTitle, {
        width: 132,
        "data-id": "components_PricelistBlock_PropertyTitle_28"
      }, "Parkeerplaats(en)"), priceListTable.nearWater && __jsx(PropertyTitle, {
        width: 110,
        "data-id": "components_PricelistBlock_PropertyTitle_29"
      }, "Aan het water"), __jsx(PropertyTitle, {
        width: 90,
        "data-id": "components_PricelistBlock_PropertyTitle_30"
      }, "Status"))))), houseVariant.houses.map(function (block, index3) {
        return __jsx(Pricelist, {
          key: index3,
          open: index2,
          index: index3,
          id: block.id,
          modal: modal,
          width: renderWidth,
          houseVariant: houseVariant,
          priceListTable: priceListTable,
          extraField: extraField,
          onBoarding: onBoarding,
          bnr: block.bnr,
          hasCustomer: block.hasCustomer,
          status: block.status,
          auction: block.auction,
          surface: block.surface,
          price: block.price,
          variantCode: block.variantCode,
          groundLease: block.groundLease,
          priceLivingReady: block.priceLivingReady,
          gardenLocation: block.gardenLocation,
          parkingSpots: block.parkingSpots,
          nearWater: block.nearWater,
          renderWidth: renderWidth,
          "data-id": "components_PricelistBlock_Pricelist"
        });
      }));
    }));
  }));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(PricelistBlock));
var ButtonWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-top: 20rem;\n  > button {\n    letter-spacing: 0;\n    align-self: auto;\n  }\n  > a {\n    letter-spacing: 0;\n    align-self: auto;\n  }\n"])));
var PriceListInfo = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-bottom: 20rem;\n"])));
var TypeTitle = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 40rem;\n  margin-bottom: 20rem;\n"])), textStyles.sectionTitle, colorPalette.headerGrey);
var HouseTitle = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 32rem;\n  margin-bottom: 12rem;\n"])), textStyles.title, colorPalette.headerGrey);
var HouseDetails = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  max-width: 780rem;\n"])), textStyles.bodyText, colorPalette.textGrey);
var Titles = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: ", "%;\n  display: flex;\n  justify-content: space-between;\n  margin-left: 10rem;\n"])), function (props) {
  return props.showLogin ? 78 : 100;
});
var Pricetable = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: auto;\n  position: relative;\n  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.15);\n"])));
var PricelistContainer = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin-left: ", "rem;\n"])), function (props) {
  return props.modal ? 0 : 100;
});
var TitleContainer = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  height: 50rem;\n  background-color: ", ";\n"])), colorPalette.lightBeige);
var PropertyTitle = styled.p(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", ";\n  color: #fff;\n  width: ", "rem;\n"])), textStyles.bodyText, function (props) {
  return props.width;
});