var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white,
  width: 27,
  height: 31
};
var Like = function Like(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 27 31",
    "data-id": "icons_Like_svg"
  }, __jsx("title", {
    "data-id": "icons_Like_title"
  }, "Like Thumb"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(-111.000000, -39.000000)",
    "data-id": "icons_Like_g"
  }, __jsx("g", {
    transform: "translate(95.000000, 26.000000)",
    fill: color,
    "data-id": "icons_Like_g_3"
  }, __jsx("path", {
    d: "M16.0121585,30.9076071 C16.1401432,29.6512248 17.2621416,28.7340017 18.520657,28.8619864 L21.9293138,29.2096779 L21.9421123,29.1094233 L19.2395039,28.8342564 C18.1388362,28.7233364 17.3495977,27.6120033 17.4775824,26.3534879 C17.6077001,25.0949726 18.6017139,24.1670841 19.7023816,24.2801372 L28.7508937,25.2016264 L30.035006,25.2208241 C30.035006,25.2208241 28.177096,24.3782587 29.6041245,17.6590665 C31.0290198,10.9420074 34.7917675,13.4633043 34.7917675,13.4633043 C34.7917675,13.4633043 34.8408282,19.2034142 35.0434705,20.0182496 C35.2482459,20.8309519 39.661582,29.4208525 39.661582,29.4208525 C39.661582,29.8751979 42.3961866,30.3295433 42.3940535,30.7860217 C42.3620573,34.7364801 42.3940535,38.1152739 42.3940535,42.2065154 C39.4610728,41.4386077 37.8932613,43.7423307 31.6262814,43.7423307 C29.5806606,43.7423307 27.0209684,43.4074377 24.8665607,43.0512138 L19.2267054,42.4774161 C18.2476231,42.3771615 17.5607724,41.2764938 17.6866239,40.0179785 C17.7868785,39.0474285 18.3500108,38.2795208 19.0517931,38.0214185 L18.3414785,37.9488939 C17.2237462,37.8337077 16.4195762,36.7245078 16.5475608,35.4659924 C16.6606139,34.3866555 17.4285216,33.595284 18.3457447,33.4481017 L18.0577793,33.4203717 C16.7992639,33.290254 15.8841739,32.1661225 16.0121585,30.9076071",
    "data-id": "icons_Like_path"
  }))));
};
Like.defaultProps = defaultProps;
export default Like;