export const z = {
  categoryIntro: 1,
  sidebarContent: 10,
  slider: 10,
  sliderContent: 11,
  sliderNav: 12,
  stickyButton: 20,
  mobileHeader: 20,
  menu: 30,
  sideBar: 30,
  mobileNav: 40,
  modal: 50,
  modalContent: 51,
  modalButton: 52,
}
