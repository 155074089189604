import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const InformationIcon: FC<IconProps> = ({ width = 2, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 2 9"
    >
      <path
        d="M1.80011 8.42601C1.74797 8.45581 1.5692 8.4707 1.2638 8.4707C0.913706 8.4707 0.660447 8.39621 0.504023 8.24724C0.355047 8.09826 0.280559 7.86363 0.280559 7.54333V3.07406C0.302905 3.06661 0.369944 3.05916 0.481676 3.05171C0.593408 3.03681 0.697691 3.02936 0.794525 3.02936C1.46492 3.02936 1.80011 3.32359 1.80011 3.91204V8.42601ZM1.81128 1.28635C1.81128 1.51726 1.73307 1.71093 1.57665 1.86735C1.42022 2.01633 1.22656 2.09081 0.995643 2.09081C0.757281 2.09081 0.559888 2.01633 0.403464 1.86735C0.254488 1.71093 0.18 1.51726 0.18 1.28635C0.18 1.05543 0.254488 0.861764 0.403464 0.70534C0.559888 0.548915 0.757281 0.470703 0.995643 0.470703C1.22656 0.470703 1.42022 0.548915 1.57665 0.70534C1.73307 0.861764 1.81128 1.05543 1.81128 1.28635Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}

export default InformationIcon
