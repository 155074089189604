import React, { FC } from 'react'
import { ICON_COLORS } from '@/constants/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const EditIcon: FC<IconProps> = ({ width = 14, height, variant = 'gray40' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.11741 8.78938L5.21229 11.8825L11.3749 5.71987L8.28179 2.625L2.11741 8.78938Z"
        fill={ICON_COLORS[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7436 2.11052L11.8877 0.254641C11.5456 -0.0874844 10.9917 -0.0874844 10.6505 0.254641L9.4115 1.49189L12.5064 4.58502L13.7436 3.34777C14.0857 3.00564 14.0857 2.45264 13.7436 2.11052Z"
        fill={ICON_COLORS[variant]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14L4.0215 13.0716L0.926625 9.97852L0 14Z"
        fill={ICON_COLORS[variant]}
      />
    </Svg>
  )
}
export default EditIcon
