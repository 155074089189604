import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import CloseCross from '../icons/CloseCross';
import { fonts, colorPalette } from './../../../styles/styleGuide';
import { fromBreakpoint, breakpointSizes } from '../../../styles/breakpoints';
import { darken } from '../../../styles/utils';
var defaultProps = {
  label: 'sluit',
  colorText: false
};
var ButtonClose = function ButtonClose(_ref) {
  var label = _ref.label,
    color = _ref.color,
    colorText = _ref.colorText,
    renderWidth = _ref.renderWidth,
    clickHandler = _ref.clickHandler;
  return __jsx(Button, {
    onClick: clickHandler,
    "data-e2e-button-close": true,
    "data-id": "buttons_ButtonClose_Button"
  }, __jsx(Label, {
    colorText: colorText,
    color: color,
    "data-id": "buttons_ButtonClose_Label"
  }, label), __jsx(CloseCrossContainer, {
    color: color,
    "data-id": "buttons_ButtonClose_CloseCrossContainer"
  }, __jsx(CloseCross, {
    width: renderWidth < breakpointSizes.md ? 12 : 16,
    height: renderWidth < breakpointSizes.md ? 12 : 16,
    color: "#fff",
    "data-id": "buttons_ButtonClose_CloseCross"
  })));
};
ButtonClose.defaultProps = defaultProps;
export default ButtonClose;
var Button = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  background: none;\n  border: none;\n"])));
var Label = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-self: center;\n  margin-right: 10rem;\n  font-family: ", ";\n  font-size: 16rem;\n  font-weight: bold;\n  color: ", ";\n  text-transform: uppercase;\n\n  ", ";\n"])), fonts.primary, function (props) {
  return props.colorText ? props.color : '#ffffff';
}, fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    font-size: 20rem;"]))));
var CloseCrossContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30rem;\n  width: 30rem;\n  background: ", ";\n  border-radius: 4rem 0 4rem 0;\n\n  ", ";\n"])), function (props) {
  return props.color ? "".concat(props.color) : "".concat(darken(colorPalette.beige, 5));
}, fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    height: 40rem;\n    width: 40rem;\n  "]))));