import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Expand from '../icons/Expand';
import { colorPalette } from '../../../styles/styleGuide';
var ButtonExpand = function ButtonExpand(_ref) {
  var clickHandler = _ref.clickHandler,
    label = _ref.label;
  return __jsx(SeeMoreButton, {
    onClick: clickHandler,
    "data-id": "buttons_ButtonExpand_SeeMoreButton"
  }, label, __jsx(ExpandContainer, {
    "data-id": "buttons_ButtonExpand_ExpandContainer"
  }, __jsx(Expand, {
    "data-id": "buttons_ButtonExpand_Expand"
  })));
};
export default ButtonExpand;
var SeeMoreButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  border: none;\n  color: ", ";\n  background: none;\n  text-align: left;\n  align-self: flex-start;\n  transition: color 0.25s ease-in-out;\n  font-weight: bold;\n\n  &:hover {\n    color: ", ";\n  }\n"])), colorPalette.blue, colorPalette.darkBlue);
var ExpandContainer = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background: ", ";\n  width: 16rem;\n  height: 16rem;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4rem 0 4rem 0;\n  margin-left: 5rem;\n"])), colorPalette.blue);