import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withApiData from '../../services/withApiData';
import Like from './../shared/icons/Like';
import { fromBreakpoint } from '../../styles/breakpoints';
import { shadows, textStyles, colorPalette } from '../../styles/styleGuide';
var connectApiData = withApiData({
  hotspotOptions: 'getHotspotOptions'
}, function (ownProps) {
  var hotspotId = ownProps.hotspotId;
  return {
    hotspotOptions: {
      hotspotId: hotspotId
    },
    options: {
      cache: 'disabled'
    }
  };
});
var ECWInterestModal = function ECWInterestModal(_ref) {
  var hotspotId = _ref.hotspotId,
    color = _ref.color,
    hotspotOptions = _ref.hotspotOptions;
  var optionData = hotspotOptions && hotspotOptions.data;
  return optionData ? __jsx(Container, {
    "data-id": "dashboard_ECWInterestModal_Container"
  }, __jsx(Title, {
    color: color,
    "data-id": "dashboard_ECWInterestModal_Title"
  }, optionData.title), __jsx(SubTitle, {
    "data-id": "dashboard_ECWInterestModal_SubTitle"
  }, "Bekijk hieronder de voorkeuren die je in het Homestudios experience center over dit onderwerp hebt bewaard. Ben je nog niet in het experience center geweest? Dan kun je hier alvast bekijken over welke onderwerpen je straks meer kunt ontdekken, ervaren en kunt leren in de Kennisstudio."), __jsx(Preferences, {
    "data-id": "dashboard_ECWInterestModal_Preferences"
  }, __jsx(PreferenceTitles, {
    color: color,
    "data-id": "dashboard_ECWInterestModal_PreferenceTitles"
  }, __jsx("div", {
    "data-id": "dashboard_ECWInterestModal_div"
  }, __jsx(Like, {
    color: color,
    width: 16,
    height: 19,
    "data-id": "dashboard_ECWInterestModal_Like"
  }), "Niets voor mij"), __jsx("div", {
    "data-id": "dashboard_ECWInterestModal_div"
  }, __jsx(Like, {
    color: color,
    width: 16,
    height: 19,
    "data-id": "dashboard_ECWInterestModal_Like"
  }), "Iets voor mij")), (optionData.optionGroups || []).map(function (optionGroup, index) {
    return (optionGroup.options || []).map(function (option, index) {
      return __jsx(OptionContainer, {
        key: index,
        rating: option.customerRating,
        "data-id": "dashboard_ECWInterestModal_OptionContainer"
      }, option.title);
    });
  }))) : null;
};
export default connectApiData(ECWInterestModal);
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n  padding: 20rem 20rem 40rem 20rem;\n  ", ";\n  ", ";\n\n  ", ";\n"])), shadows.boxPrimary, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 60rem 70rem 60rem 70rem;\n  "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 80rem 200rem 80rem 200rem;\n  "]))));
var Title = styled.h3(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  margin-bottom: 18rem;\n  margin-top: 0;\n  ", ";\n"])), textStyles.pageTitle, function (props) {
  return props.color;
}, fromBreakpoint.md(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-bottom: 28rem;\n  "]))));
var SubTitle = styled.h4(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n  width: 100%;\n  color: ", ";\n  font-weight: normal;\n  font-size: 16rem;\n  line-height: 24rem;\n  margin-top: 0;\n  text-align: center;\n  ", ";\n"])), textStyles.title, colorPalette.headerGrey, fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    font-size: 22rem;\n    line-height: 30rem;\n  "]))));
var Preferences = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 600rem;\n  min-height: 200rem;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 74rem;\n  box-sizing: border-box;\n  ", " &:after {\n    content: '';\n    width: 1rem;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 50%;\n    background-color: ", ";\n  }\n\n  &:before {\n    content: '';\n    height: 1rem;\n    position: absolute;\n    top: 40rem;\n    left: 0;\n    right: 0;\n    background-color: ", ";\n    ", ";\n  }\n"])), fromBreakpoint.md(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    padding-bottom: 114rem;\n  "]))), colorPalette.lightBeige, colorPalette.lightBeige, fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n      top: 50rem;\n    "]))));
var PreferenceTitles = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", ";\n  font-size: 14rem;\n  line-height: 20rem;\n  color: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 40rem;\n  margin-bottom: 25rem;\n  ", ";\n\n  > div {\n    display: flex;\n    align-items: center;\n    &:nth-of-type(1) {\n      svg {\n        transform: scale(1) rotate(180deg);\n      }\n    }\n    > svg {\n      margin-right: 4rem;\n      ", ";\n    }\n  }\n"])), textStyles.title, function (props) {
  return props.color;
}, fromBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    font-size: 22rem;\n    line-height: 30rem;\n    height: 50rem;\n    margin-bottom: 40rem;\n  "]))), fromBreakpoint.md(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n        margin-right: 12rem;\n      "]))));
var OptionContainer = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 30rem;\n  width: 142rem;\n  margin-bottom: 16rem;\n  border-radius: 20px;\n  z-index: 1;\n  box-sizing: border-box;\n  padding: 15rem;\n  align-self: ", ";\n  ", ";\n  font-size: 12rem;\n  line-height: 18rem;\n  background-color: ", ";\n  color: white;\n  font-weight: bold;\n  text-align: center;\n\n  ", ";\n"])), function (props) {
  return props.rating === 1 || props.rating === null ? 'center' : props.rating === 0 ? 'flex-start' : 'flex-end';
}, textStyles.standardText, colorPalette.blue, fromBreakpoint.md(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    width: 188rem;\n    min-height: 40rem;\n    font-size: 16rem;\n    line-height: 24rem;\n  "]))));