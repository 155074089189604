import React from 'react'
import styled from 'styled-components'
import Layer from '@/molecules/Layer'
import { WishList } from '@/entities/types'
import Box from '@/atoms/Box'
import EditableTitle from '@/molecules/EditableTitle'
import FavoriteButton from '@/molecules/FavoriteButton'
import { useRouter } from 'next/router'
import useParams from '@/hooks/useParams'
import { colors } from '@/styles/colors'
import Text from '@/atoms/Text'
import TextButton from '@/atoms/TextButton'
import { formatDateWithFullMonth } from '@/processes/dateTime'
import LeftArrowIcon from '@/svg/LeftArrowIcon'
import { toggleMenu } from '@/redux/houseDesign'
import { useDispatch } from 'react-redux'
import useBreakpointInfo from '@/hooks/useBreakpointInfo'
import CustomScrollbars from '@/atoms/CustomScrollbars'
import { BreakpointSize, fromBreakpoint } from '@/styles/breakpoints'
import HomestudiosLogo from '@/svg/HomestudiosLogo'
import useSections from '@/hooks/useSections'
import MaxWidth from '@/atoms/MaxWidth'
import { Slug } from '@/constants/slugs'
import useSectionLoading from '@/hooks/useSectionLoading'

type Props = {
  wishList: WishList
  backLink: string
}

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  height: 100%;

  ${fromBreakpoint.md`
    height: 100vh;
  `}
`

const TitleContainer = styled.div`
  padding-right: 40rem;
  position: relative;
  min-height: 24rem;
`

const EditableTitleWrapper = styled.div`
  margin-bottom: 8rem;
`

const FavoriteButtonWrapper = styled.div`
  position: absolute;
  width: 24rem;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Menu = ({ wishList, backLink }: Props) => {
  const router = useRouter()
  const { projectSlug, wishListId } = useParams()
  const dispatch = useDispatch()
  const {
    isLargerThan: { md },
  } = useBreakpointInfo()

  const { sections, currentSectionSlug } = useSections()
  const { isSectionLoading, isAnySectionLoading } = useSectionLoading()

  if (!wishList || !projectSlug || !wishListId) {
    return null
  }

  const onClick = (path: string) => {
    dispatch(toggleMenu(false))
    router?.push(path)
  }

  const { createdAt, house, isFavorite, title } = wishList

  return (
    <Container>
      <CustomScrollbars
        autoHeight
        autoHeightMax={md ? '100vh' : '100%'}
      >
        <Box
          padding="xl"
          paddingTop="lg"
        >
          <MaxWidth applyUpToBreakpoint={BreakpointSize.md}>
            {md && (
              <>
                <Box marginBottom="md">
                  <TextButton
                    variant="brand"
                    iconBefore={LeftArrowIcon}
                    text="Mijn woonontwerpen"
                    onClick={() => {
                      router?.push(backLink)
                    }}
                  />
                </Box>
                <Box marginBottom="md">
                  <HomestudiosLogo width={100} />
                </Box>
              </>
            )}
            <Box marginBottom="lg">
              <TitleContainer>
                <EditableTitleWrapper>
                  <EditableTitle title={title} />
                </EditableTitleWrapper>
                <FavoriteButtonWrapper>
                  <FavoriteButton isFavorite={isFavorite} />
                </FavoriteButtonWrapper>
              </TitleContainer>
              <Text text={`Ontwerp voor bouwnummer ${house.bnr}`} />
              <Text
                text={`Aangemaakt op ${formatDateWithFullMonth(createdAt)}`}
                variant="gray30"
              />
            </Box>
            {sections.map(
              (
                {
                  slug,
                  title,
                  price,
                  squareMeters,
                  url,
                  iconSlug,
                  extraSquareMeters,
                  hasNew,
                  isSummary,
                },
                i
              ) => (
                <Layer
                  active={
                    currentSectionSlug === slug || (slug === Slug.base && !currentSectionSlug)
                  }
                  key={slug}
                  title={title}
                  price={price}
                  onClick={() => onClick(url)}
                  squareMeters={squareMeters}
                  iconSlug={iconSlug}
                  extraSquareMeters={extraSquareMeters}
                  hasNew={hasNew}
                  isSummary={isSummary}
                  isFirst={i === 0}
                  loading={slug === Slug.summary ? isAnySectionLoading : isSectionLoading(slug)}
                />
              )
            )}
          </MaxWidth>
        </Box>
      </CustomScrollbars>
    </Container>
  )
}

export default Menu
