var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 46,
  height: 64
};
var Documents = function Documents(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 46 64",
    version: "1.1",
    "data-id": "icons_Documents_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Documents_g"
  }, __jsx("g", {
    transform: "translate(-9.000000, 0.000000)",
    fill: color,
    "data-id": "icons_Documents_g_2"
  }, __jsx("g", {
    transform: "translate(9.000000, 0.000000)",
    "data-id": "icons_Documents_g_3"
  }, __jsx("path", {
    d: "M40.7462,55.7117 C40.7462,57.4117 39.4462,58.7117 37.7462,58.7117 L5.0002,58.7117 L5.0002,5.0577 L40.7462,5.0577 L40.7462,55.7117 Z M40.7462,-0.0003 L0.0002,-0.0003 L0.0002,5.0577 L0.0002,63.7117 L37.1942,63.7117 L39.4802,63.7117 L39.7462,63.7117 C42.4982,63.7117 44.8272,61.8317 45.5262,59.2947 C45.6542,58.8887 45.7472,58.4667 45.7922,58.0277 L45.8042,35.9307 L45.8042,5.0577 L45.8042,-0.0003 L40.7462,-0.0003 Z",
    "data-id": "icons_Documents_path"
  }), __jsx("polygon", {
    points: "11.335 19.524 34.469 19.524 34.469 14.763 11.335 14.763",
    "data-id": "icons_Documents_polygon"
  }), __jsx("polygon", {
    points: "11.335 29.318 34.469 29.318 34.469 24.557 11.335 24.557",
    "data-id": "icons_Documents_polygon_6"
  }), __jsx("polygon", {
    points: "11.335 39.112 34.469 39.112 34.469 34.351 11.335 34.351",
    "data-id": "icons_Documents_polygon_7"
  }), __jsx("polygon", {
    points: "11.335 48.906 20.982 48.906 20.982 44.145 11.335 44.145",
    "data-id": "icons_Documents_polygon_8"
  })))));
};
Documents.defaultProps = defaultProps;
export default Documents;