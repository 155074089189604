import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { textStyles, colorPalette } from '../styles/styleGuide';
import { hex2rgb } from '../styles/utils';
import { fromBreakpoint, toBreakpoint } from '../styles/breakpoints';
import DateIcon from './shared/designElements/DateIcon';
import { hideModalLoader, openModalWithLoader, updateModalOpenState } from '../redux/modal';
import { getResultData } from '../redux/apiData';
import get from 'lodash.get';
import withMatch from '@/hocs/withMatch';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', {})
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    handleClick: function handleClick(evt, endpoint) {
      evt.preventDefault();
      dispatch(hideModalLoader());
      dispatch(openModalWithLoader(colorPalette.beige, endpoint));
      dispatch(updateModalOpenState(true));
    }
  };
};
var CTAPrimary = function CTAPrimary(_ref) {
  var title = _ref.title,
    content = _ref.content,
    date = _ref.date,
    match = _ref.match,
    formId = _ref.formId,
    styling = _ref.styling,
    handleClick = _ref.handleClick;
  var projectSlug = match.params.projectSlug;
  var _styling$formPrimaryT = styling.formPrimaryTriangle,
    formPrimaryTriangle = _styling$formPrimaryT === void 0 ? '#F8EA1B' : _styling$formPrimaryT,
    _styling$formPrimaryB = styling.formPrimaryBackground,
    formPrimaryBackground = _styling$formPrimaryB === void 0 ? colorPalette.lightGreen : _styling$formPrimaryB,
    _styling$formPrimaryD = styling.formPrimaryDate,
    formPrimaryDate = _styling$formPrimaryD === void 0 ? colorPalette.green : _styling$formPrimaryD,
    _styling$formPrimaryT2 = styling.formPrimaryText,
    formPrimaryText = _styling$formPrimaryT2 === void 0 ? colorPalette.textGrey : _styling$formPrimaryT2;
  return __jsx(CTAWrapper, {
    onClick: function onClick(evt) {
      return handleClick(evt, {
        type: 'form',
        componentProps: {
          projectSlug: String(projectSlug),
          formId: formId
        }
      });
    },
    "data-e2e-block-ctaprimary": true,
    "data-id": "components_CTAPrimary_CTAWrapper"
  }, __jsx(CTAWrapperInner, {
    bgColor: formPrimaryBackground,
    triangleColor: formPrimaryTriangle,
    textColor: formPrimaryText,
    "data-id": "components_CTAPrimary_CTAWrapperInner"
  }, __jsx(CTAContent, {
    "data-id": "components_CTAPrimary_CTAContent"
  }, __jsx(CTAContentHeader, {
    "data-id": "components_CTAPrimary_CTAContentHeader"
  }, __jsx(CTADateIconContainer, {
    "data-id": "components_CTAPrimary_CTADateIconContainer"
  }, __jsx(DateIcon, {
    date: date,
    textColor: formPrimaryDate,
    "data-id": "components_CTAPrimary_DateIcon"
  })), __jsx(Header, {
    "data-id": "components_CTAPrimary_Header"
  }, title)), __jsx(Content, {
    textColor: formPrimaryText,
    "data-id": "components_CTAPrimary_Content"
  }, content))));
};
export default withMatch(connect(mapStateToProps, mapDispatchToProps)(CTAPrimary));
var CTAWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  max-width: 1020rem;\n  width: 100%;\n  margin: 30rem auto;\n  padding-left: 0;\n  padding-right: 0;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-left: 20rem;\n    padding-right: 20rem;\n    margin-bottom: 60rem;\n  "]))));
var CTAWrapperInner = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  max-width: 980rem;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin-left: auto;\n  margin-right: auto;\n  background: ", ";\n  border-radius: 10rem 0 10rem 0;\n  font-size: 20rem;\n  color: ", ";\n  box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.16);\n\n  &:hover {\n    cursor: pointer;\n    box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.32);\n    transition: box-shadow 0.15s ease-in-out;\n\n    > div {\n      transition: background 0.15s ease-in-out;\n      background: rgba(0, 0, 0, 0.2);\n    }\n  }\n\n  &:before {\n    content: '';\n    display: block;\n    width: 0;\n    height: 0;\n    position: absolute;\n    top: -10rem;\n    left: -10rem;\n    border-style: solid;\n    border-width: 142rem 142rem 0 0;\n    border-color: ", " transparent transparent\n      transparent;\n\n    ", ";\n  }\n"])), function (props) {
  return props.bgColor;
}, function (props) {
  return props.textColor;
}, function (props) {
  return hex2rgb(props.triangleColor, 0.65);
}, fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      top: -20rem;\n      left: -20rem;\n      border-width: 166rem 166rem 0 0;\n    "]))));
var CTAContent = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  padding: 10rem 10rem 15rem 10rem;\n  border-radius: 10rem 0 10rem 0;\n  width: 100%;\n  text-align: left;\n  min-height: 110rem;\n  text-align: center;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    min-height: 70rem;\n    padding: 30rem 100rem;\n  "]))));
var CTAContentHeader = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
var CTADateIconContainer = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  position: relative;\n  margin-right: 15rem;\n  margin-bottom: 15rem;\n  min-width: 50rem;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    position: absolute;\n    left: 20rem;\n    top: 15rem;\n    margin-right: 0;\n    margin-bottom: 0;\n  "]))));
var Header = styled.h3(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", " margin-top: 0;\n  margin-bottom: 15rem;\n  flex-basis: 20rem;\n  flex-grow: 2;\n"])), textStyles.title);
var Content = styled.p(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  margin-top: 0;\n  margin-bottom: 0;\n  padding-bottom: 10rem;\n\n  ", ";\n"])), textStyles.bodyText, function (props) {
  return props.textColor;
}, toBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    margin-left: 65rem;\n  "]))));