import { FC } from 'react'
import styled from 'styled-components'
import { IconProps, IconVariant } from '@/types/svgTypes'
import { colors } from '@/styles/colors'

type Variant = 'brand' | 'gray40' | 'white'

type Props = {
  icon: FC<IconProps>
  onClick: () => void
  variant?: Variant
  iconSize?: number
}

const VARIANT_ICON_VARIANT_MAP: Record<Variant, IconVariant> = {
  brand: 'white',
  gray40: 'white',
  white: 'brand',
}

const VARIANT_HOVER_COLOR_MAP: Record<Variant, string> = {
  brand: colors.gray10,
  gray40: colors.white,
  white: colors.white,
}

const Wrapper = styled.div<{ variant: Variant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48rem;
  height: 48rem;
  border-radius: 8rem 0;
  background-color: ${({ variant }) => colors[variant]};
  transition: background-color 200ms ease-in-out;
  cursor: pointer;

  path {
    transition: fill 200ms ease-in-out;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ variant }) => VARIANT_HOVER_COLOR_MAP[variant]};

    path {
      fill: ${colors.brand};
    }
  }
`

const UtilityButton = ({ icon: Icon, variant = 'brand', iconSize = 16, onClick }: Props) => (
  <Wrapper
    onClick={onClick}
    variant={variant}
  >
    <Icon
      height={iconSize}
      variant={VARIANT_ICON_VARIANT_MAP[variant]}
    />
  </Wrapper>
)

export default UtilityButton
