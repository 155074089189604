import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { textStyles, fonts, colorPalette } from '../../../styles/styleGuide';
import { fromBreakpoint } from '../../../styles/breakpoints';
var WidgetHeader = function WidgetHeader(_ref) {
  var title = _ref.title,
    subtitle = _ref.subtitle;
  return __jsx(Fragment, null, __jsx(Title, {
    "data-id": "designElements_WidgetHeader_Title"
  }, title), subtitle && __jsx(SubTitle, {
    "data-id": "designElements_WidgetHeader_SubTitle"
  }, subtitle));
};
export default WidgetHeader;
var Title = styled.h3(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 22rem;\n  line-height: 28rem;\n  color: ", ";\n  margin-top: 5rem;\n  margin-bottom: 10rem;\n\n  ", ";\n"])), fonts.primary, colorPalette.headerGrey, fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-bottom: 15rem;\n  "]))));
var SubTitle = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  color: ", ";\n  font-style: italic;\n  margin-top: -10rem;\n  margin-bottom: 20rem;\n"])), textStyles.standardText, colorPalette.textGrey);