var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.lightBeige,
  width: 64,
  height: 46
};
var Message = function Message(_ref) {
  var width = _ref.width,
    height = _ref.height,
    color = _ref.color;
  return __jsx("svg", {
    style: {
      width: "".concat(String(width), "px"),
      height: "".concat(String(height), "px")
    },
    viewBox: "0 0 64 46",
    "data-id": "icons_Message_svg"
  }, __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_Message_g"
  }, __jsx("g", {
    transform: "translate(0.000000, -9.000000)",
    "data-id": "icons_Message_g_2"
  }, __jsx("g", {
    transform: "translate(0.000000, 9.000000)",
    "data-id": "icons_Message_g_3"
  }, __jsx("g", {
    "data-id": "icons_Message_g_4"
  }, __jsx("path", {
    d: "M57.207,5.831 C57.006,5.757 56.787,5.716 56.559,5.716 L7.435,5.716 C7.272,5.716 7.115,5.737 6.964,5.777 L33.047,24.877 L57.207,5.831 Z",
    "data-id": "icons_Message_path"
  }), __jsx("path", {
    d: "M63.994,0.145 L7.435,0.145 C3.328,0.145 0,3.478 0,7.59 L0,45.854 L54.021,45.854 L56.559,45.854 C60.666,45.854 63.995,42.521 63.995,38.408 L64,0.145 L63.994,0.145 Z M7.435,5.715 L56.559,5.715 C56.788,5.715 57.006,5.756 57.207,5.83 L33.047,24.875 L6.964,5.775 C7.114,5.736 7.272,5.715 7.435,5.715 Z M58.423,38.408 C58.423,39.38 57.688,40.166 56.75,40.263 L56.75,40.283 L56.559,40.283 L5.571,40.283 L5.571,11.661 L31.464,30.621 C32.474,31.36 33.852,31.336 34.834,30.561 L58.423,11.966 L58.423,38.408 L58.423,38.408 Z",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_Message_path_6"
  }))))));
};
Message.defaultProps = defaultProps;
export default Message;