import {
  Status,
  WishList,
  WishListOption,
  WishListOptionOrder,
  WishListSubcategory,
} from '@/entities/wishList'

export enum OptionStatus {
  disabled = 'disabled',
  excluded = 'excluded',
  final = 'final',
  new = 'new',
  pending = 'pending',
  actionRequired = 'actionRequired',
}

export type OptionStatusInfo = {
  isDisabled: boolean
  isExcluded: boolean
  isFinal: boolean
  isNew: boolean
  isPending: boolean
  isActionRequired: boolean
  isPostDeadlineSelected: boolean
  isPostDeadlineNotSelected: boolean
  optionStatus?: OptionStatus
}

export const ORDER_STATUS_OPTION_STATUS_MAP: Record<Status, OptionStatus | undefined> = {
  blocked: OptionStatus.disabled,
  confirmed: OptionStatus.pending,
  included: OptionStatus.final,
  presold: OptionStatus.final,
  processing: OptionStatus.pending,
  processed: OptionStatus.actionRequired,
  requested: OptionStatus.pending,
  signed: OptionStatus.final,
}

// -------------
// Order helpers

export const sortOrdersByDateDesc = (
  { lastUpdate: a }: WishListOptionOrder,
  { lastUpdate: b }: WishListOptionOrder
) => {
  if (a < b) return 1
  if (a > b) return -1
  return 0
}

/**
 * Filter out orders with a status that does not have to be displayed in the front end.
 * We filter out blocked and cancelled orders, unless there are only blocked and cancelled orders, in that case we display only the most recent one
 */
export const filterOutIrrelevantBlockedAndCancelledOrders = (orders: WishListOptionOrder[]) => {
  if (orders.length <= 1) {
    return orders
  }
  const blockedAndCancelledOrders: WishListOptionOrder[] = []
  const nonBlockedAndCancelledOrders: WishListOptionOrder[] = []
  orders.forEach((order) => {
    if (order.status === 'blocked') {
      blockedAndCancelledOrders.push(order)
      return
    }
    nonBlockedAndCancelledOrders.push(order)
  })
  if (nonBlockedAndCancelledOrders.length === 0) {
    if (blockedAndCancelledOrders.length === 0) {
      return []
    }
    let mostRecentBlockedOrCancelledOrder = blockedAndCancelledOrders.splice(0, 1)[0]
    blockedAndCancelledOrders.forEach((order) => {
      if (order.lastUpdate > mostRecentBlockedOrCancelledOrder.lastUpdate) {
        mostRecentBlockedOrCancelledOrder = order
      }
    })
    return [mostRecentBlockedOrCancelledOrder]
  }
  return nonBlockedAndCancelledOrders
}

/**
 * Get the status of an option. This status is to determine how to display the option
 */
export const getOptionStatus = ({
  isDisabled,
  isExcluded,
  isFinal,
  isNew,
  isPending,
  isActionRequired,
}: Omit<OptionStatusInfo, 'optionStatus'>) => {
  if (isDisabled) {
    return OptionStatus.disabled
  }
  if (isFinal) {
    return OptionStatus.final
  }
  if (isExcluded) {
    return OptionStatus.excluded
  }
  if (isActionRequired) {
    return OptionStatus.actionRequired
  }
  if (isPending) {
    return OptionStatus.pending
  }
  if (isNew) {
    return OptionStatus.new
  }
  return undefined
}

/**
 * Determine if an order is "final"
 */
export const isOrderFinal = ({ status }: WishListOptionOrder & { status: Status }) =>
  ORDER_STATUS_OPTION_STATUS_MAP[status] === OptionStatus.final

/**
 * Determine if an order is "pending"
 */
export const isOrderPending = ({ status }: WishListOptionOrder & { status: Status }) =>
  ORDER_STATUS_OPTION_STATUS_MAP[status] === OptionStatus.pending

/**
 * Determine if an order is "actionRequired"
 */
export const isOrderActionRequired = ({ status }: WishListOptionOrder & { status: Status }) =>
  ORDER_STATUS_OPTION_STATUS_MAP[status] === OptionStatus.actionRequired

// -----------------
// Includes/excludes

/**
 * Is the option selected, where "selected" means either selected in the UI or having a pending/final order
 */
export const isOptionSelected = ({ amount, orders }: WishListOption) => {
  if (amount >= 1) {
    return true
  }
  for (let i = 0; i < orders.length; i += 1) {
    if (isOrderFinal(orders[i]) || isOrderPending(orders[i])) {
      return true
    }
  }
  return false
}

/**
 * Get set of the ids for excluded options
 */
export const getExcludedOptionIds = ({ categories }: WishList) => {
  let excludeIds: string[] = []
  categories.forEach(({ subcategories }) => {
    subcategories.forEach(({ options }) => {
      options.forEach((option) => {
        const selected = isOptionSelected(option)
        if (!!option.excludes && selected) {
          excludeIds = [...excludeIds, ...option.excludes]
        }
        if (!!option.includes && !selected) {
          excludeIds = [...excludeIds, ...option.includes]
        }
      })
    })
  })

  return new Set(excludeIds)
}

// --------------
// Option helpers

/**
 * Determine if an option should be disabled ("vervallen")
 */
export const isOptionDisabled = ({ orders }: WishListOption) =>
  orders.some(({ status }) => status === 'blocked')

/**
 * Determine if an option can be displayed as "final", i.e. selected and no longer editable
 * Note that an option with multiple: true is still editable if it includes "final" orders
 */
export const isOptionFinal = ({ orders, multiple }: WishListOption) =>
  orders.some(isOrderFinal) && multiple === false

/**
 * Determine if a "new" label should be displayed for an option
 */
export const isOptionNew = ({ flag }: WishListOption) => flag === 'new'

/**
 * Determine if an option should be pending ("in behandeling")
 */
export const isOptionPending = ({ orders, multiple }: WishListOption) =>
  orders.some(isOrderPending) && multiple === false

/**
 * Determine if an option should be actionRequired ("actie vereist")
 */
export const isOptionActionRequired = ({ orders }: WishListOption) =>
  orders.some(isOrderActionRequired)

/**
 * Determine if an option is excluded: this can be due to being in the excludes of a selected option, or due to not being in the includes of a not selected option
 */
export const isOptionExcluded = (option: WishListOption, wishList?: WishList) => {
  if (!wishList) {
    return false
  }

  if (isOptionFinal(option) || isOptionPending(option)) {
    return false
  }

  const { uuid } = option
  return getExcludedOptionIds(wishList).has(uuid)
}

/**
 * Determines whether the amount of an option should be included in the cumulatives for price and living space, on top of the orders
 */
export const shouldIncludeOptionForTotals = (option: WishListOption, wishList?: WishList) => {
  return (
    !isOptionFinal(option) &&
    !isOptionDisabled(option) &&
    !isOptionExcluded(option, wishList) &&
    !isOptionPending(option)
  )
}

/**
 * Get the total amount for an option, based on the amount and the orders, taking various statuses into account
 */
export const getTotalAmountForOption = (option: WishListOption, wishList?: WishList) => {
  const { amount, orders } = option
  if (isOptionDisabled(option)) {
    return 0
  }
  const totalAmountForOrders = orders.reduce((ordersTotal, { amount, status }) => {
    if (status === 'blocked') {
      return ordersTotal
    }
    return ordersTotal + amount
  }, 0)
  if (shouldIncludeOptionForTotals(option, wishList)) {
    return totalAmountForOrders + amount
  }
  return totalAmountForOrders
}

/**
 * Determine whether an option is final; to be used after the deadline for the subcategory has passed
 */
export const isOptionFinalPostDeadline = (option: WishListOption, wishList?: WishList) =>
  getTotalAmountForOption(option, wishList) > 0

// ----------------
// Category and subategory helpers

/**
 * Return a list of final options and a list of not selected options for a subcategory; to be used after the deadline for the subcategory has passed
 */
export const getPostDeadlineOptions = ({ options }: WishListSubcategory) => {
  const result: { selected: WishListOption[]; notSelected: WishListOption[] } = {
    selected: [],
    notSelected: [],
  }
  options.forEach((option) => {
    if (isOptionFinal(option) || getTotalAmountForOption(option) > 0) {
      result.selected.push(option)
      return
    }
    result.notSelected.push(option)
  })
  return result
}

/**
 * Determine if a category has options labeled as new
 */
export const hasNewOptions = (subcategories: WishListSubcategory[]) =>
  subcategories.some(({ options }) => options.some(isOptionNew))

/**
 * Get list of all new options in a category
 */
export const getAllNewOptions = (subcategories: WishListSubcategory[]) =>
  subcategories.reduce(
    (acc, { options }) => [...acc, ...options.filter(isOptionNew)],
    [] as WishListOption[]
  )
