import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Link from '@/components/atoms/Link';
import { GridWrapper } from '../styles/gridElements';
import { colorPalette, fonts, textStyles } from '../styles/styleGuide';
import { fromBreakpoint } from '../styles/breakpoints';
import { hex2rgb } from '../styles/utils';
import getLinkFromPageObject from '../services/getLinkFromPageObject';
import withMatch from '@/hocs/withMatch';
import ButtonSecondary from './shared/buttons/ButtonSecondary';
import BBCode from './shared/richText/BBCode';
import isElectron from 'is-electron';
var Footer = function Footer(_ref) {
  var match = _ref.match,
    footer = _ref.footer,
    siteName = _ref.siteName;
  var projectSlug = match.params.projectSlug;
  return footer ? __jsx(FooterContainer, {
    "data-id": "components_Footer_FooterContainer"
  }, __jsx(TopFooter, {
    "data-id": "components_Footer_TopFooter"
  }, __jsx(GridWrapper, {
    "data-id": "components_Footer_GridWrapper"
  }, __jsx(TopFooterContent, {
    "data-id": "components_Footer_TopFooterContent"
  }, __jsx(FooterContentBlock, {
    "data-id": "components_Footer_FooterContentBlock"
  }, footer.status && footer.status.title && __jsx("h3", {
    "data-id": "components_Footer_h3"
  }, footer.status.title), footer.status && footer.status.content && __jsx(BBCode, {
    content: footer.status.content,
    "data-id": "components_Footer_BBCode"
  }), footer.status && footer.status.contact && footer.status.contact.linkType === 'external' && __jsx(ButtonSecondary, {
    label: footer.status.contact.label,
    link: footer.status.contact.url,
    type: "externalLink",
    target: "_blank",
    rel: "noopener noreferrer",
    color: "#fff",
    "data-id": "components_Footer_ButtonSecondary"
  }), footer.status && footer.status.contact && (footer.status.contact.linkType === 'internal' || footer.status.contact.linkType === 'menuitem') && footer.status.contact.url && __jsx(ButtonSecondary, {
    label: footer.status.contact.label,
    route: '/' + getLinkFromPageObject(footer.status.contact.url),
    type: "internalLink",
    color: "#fff",
    "data-id": "components_Footer_ButtonSecondary_8"
  })), __jsx(FooterContentBlock, {
    "data-id": "components_Footer_FooterContentBlock"
  })))), __jsx(BottomFooter, {
    "data-id": "components_Footer_BottomFooter"
  }, __jsx(GridWrapper, {
    "data-id": "components_Footer_GridWrapper"
  }, __jsx(BottomFooterContent, {
    "data-id": "components_Footer_BottomFooterContent"
  }, __jsx(FooterContentBottomBlockLeft, {
    "data-id": "components_Footer_FooterContentBottomBlockLeft"
  }, __jsx(FooterMeta, {
    "data-id": "components_Footer_FooterMeta"
  }, __jsx("li", {
    "data-id": "components_Footer_li"
  }, "\xA9 ", siteName, ", ", footer.project.copyrightText, isElectron() ? '.' : null), __jsx("li", {
    "data-id": "components_Footer_li_16"
  }, __jsx(Link, {
    href: "/".concat(String(projectSlug), "/open/sectionpage/disclaimer"),
    rel: "noopener noreferrer",
    "data-id": "components_Footer_Link"
  }, "Disclaimer")), __jsx("li", {
    "data-id": "components_Footer_li"
  }, __jsx(Link, {
    href: "/".concat(String(projectSlug), "/open/sectionpage/privacy-statement"),
    rel: "noopener noreferrer",
    "data-id": "components_Footer_Link"
  }, "Privacy Statement")), __jsx("li", {
    "data-id": "components_Footer_li"
  }, __jsx(Link, {
    href: "/".concat(String(projectSlug), "/open/sectionpage/about"),
    rel: "noopener noreferrer",
    "data-id": "components_Footer_Link"
  }, "Over Homestudios.nl")))), __jsx(FooterContentBottomBlockRight, {
    "data-id": "components_Footer_FooterContentBottomBlockRight"
  }, __jsx(FooterLogos, {
    "data-id": "components_Footer_FooterLogos"
  }, (footer.partners || []).map(function (item) {
    return __jsx("li", {
      key: item.id,
      "data-id": "components_Footer_li"
    }, __jsx("a", {
      href: item.href,
      target: "_blank",
      rel: "noopener noreferrer",
      "data-id": "components_Footer_a"
    }, __jsx("img", {
      src: item.src,
      alt: item.name,
      "data-id": "components_Footer_img"
    })));
  }))))))) : null;
};
var FooterContainer = styled.footer(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  font-family: ", ";\n"])), fonts.primary);
var TopFooter = styled.section(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  background: ", ";\n  width: 100%;\n  height: ", ";\n\n  ", "\n\n  &:before{\n    content: '';\n    display: block;\n    width: 0;\n    height: 0;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    border-style: solid;\n    border-width: 320rem 0 0 320rem;\n    border-color: transparent transparent transparent ", ";\n\n    ", "\n  }\n }\n"])), colorPalette.beige, function (props) {
  return props.twoBlocks ? '530rem' : '360rem';
}, fromBreakpoint.md(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    height: 360rem;\n    "]))), hex2rgb('#6F6259', 0.2), fromBreakpoint.md(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      border-width: 360rem 0 0 360rem;\n      "]))));
var TopFooterContent = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  flex-direction: column;\n\n  ", ";\n\n  > div {\n    color: #fff;\n\n    h3 {\n      ", " margin-bottom: 25rem;\n      margin-top: 0;\n      padding-top: 0;\n      line-height: 26rem;\n\n      ", ";\n    }\n\n    p {\n      font-size: 16rem;\n      line-height: 24rem;\n\n      ", ";\n    }\n\n    a {\n      margin-top: 40rem;\n    }\n  }\n"])), fromBreakpoint.md(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    flex-direction: row;\n    padding: 0;\n    "]))), textStyles.title, fromBreakpoint.md(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n        font-size: 22rem;\n        line-height: 20rem;\n        "]))), fromBreakpoint.md(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n        font-size: 18rem;\n        line-height: 26rem;\n        "]))));
var FooterContentBlock = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  width: 100%;\n  height: auto;\n  padding: 45rem 15rem 15rem 15rem;\n  color: #fff;\n\n  ", ";\n\n  > div > * {\n    color: #fff;\n  }\n\n  > div > * > * {\n    color: #fff;\n    &:hover {\n      color: #fff;\n      border-bottom: 1rem solid #fff;\n    }\n  }\n"])), fromBreakpoint.md(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    height: 360rem;\n    padding: 65rem 20rem 20rem 20rem;\n    margin-bottom: 0;\n  "]))));
var FooterContentBottomBlockLeft = styled(FooterContentBlock)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 0;\n  width: 100%;\n  height: auto;\n  padding: 0;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    flex-grow: 2;\n    width: auto;\n    height: auto;\n    margin-bottom: 0;\n    padding: 0;\n    "]))));
var FooterContentBottomBlockRight = styled(FooterContentBlock)(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 0;\n  width: 100%;\n  height: auto;\n  padding-top: 0;\n  padding-bottom: 0;\n\n  ", ";\n"])), fromBreakpoint.md(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    flex-direction: row;\n    width: auto;\n    height: auto;\n    padding: 0 20rem;\n    "]))));
var BottomFooter = styled.section(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: middle;\n  padding: 0;\n  background: #fff;\n"])));
var BottomFooterContent = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  flex-wrap: wrap;\n"])));
var FooterMeta = styled.ul(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding-left: 5rem;\n  padding-right: 5rem;\n  margin: 0;\n  list-style: none;\n  font-size: 14rem;\n\n  ", " li {\n    display: flex;\n    margin: 12rem 15rem;\n\n    ", " &:first-child {\n      flex-basis: 100%;\n      justify-content: center;\n      color: #585858;\n\n      ", ";\n    }\n\n    a {\n      color: ", ";\n      text-decoration: none;\n      transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;\n      border-bottom: 1rem solid #fff;\n\n      &:hover {\n        color: ", ";\n        border-bottom: 1rem solid ", ";\n      }\n    }\n  }\n"])), fromBreakpoint.md(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    padding-left: 15rem;\n    padding-right: 15rem;\n    margin: 15rem -10rem;\n    "]))), fromBreakpoint.md(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n      margin: 0 15rem;\n      "]))), fromBreakpoint.md(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n        flex-basis: auto;\n        margin-right: 15rem;\n        "]))), colorPalette.blue, colorPalette.darkBlue, colorPalette.darkBlue);
var FooterLogos = styled.ul(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 0;\n\n  ", " li {\n    display: flex;\n    height: 100%;\n    text-align: center;\n    margin: 0 20rem;\n    max-width: 250rem;\n\n    a {\n      display: flex;\n      align-items: center;\n    }\n\n    img {\n      display: flex;\n      width: 100%;\n    }\n\n    &:first-child {\n      margin-right: 0;\n    }\n\n    &:last-child {\n      margin-left: 0;\n    }\n  }\n"])), fromBreakpoint.md(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n    justify-content: right;\n    margin-left: auto;\n    "]))));
export default withMatch(Footer);