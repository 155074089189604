import { colors } from '@/styles/colors'
import { motion } from 'framer-motion'
import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
  children?: ReactNode
  animationKey: string | number
  fromTextColor?: string
  fromBackgroundColor?: string
  toTextColor?: string
  toBackgroundColor?: string
}

const MotionDiv = styled(motion.div)`
  padding: 2rem;
`
const AnimatedBox = ({
  animationKey,
  children,
  fromTextColor = colors.gray40,
  toTextColor = colors.white,
  fromBackgroundColor = 'rgba(238, 134, 62, 0)',
  toBackgroundColor = 'rgba(238, 134, 62, 1)',
}: Props) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [key] = useState(animationKey)
  useEffect(() => {
    // Start animating on key change, when the key changes for the first time. This prevents animation on mount.
    if (animationKey !== key) {
      setShouldAnimate(true)
    }
  }, [animationKey, key])

  return (
    <MotionDiv
      initial={
        shouldAnimate
          ? {
              backgroundColor: fromBackgroundColor,
              color: fromTextColor,
            }
          : false
      }
      animate={
        shouldAnimate
          ? {
              backgroundColor: [
                fromBackgroundColor,
                toBackgroundColor,
                toBackgroundColor,
                fromBackgroundColor,
              ],
              color: [fromTextColor, toTextColor, toTextColor, fromTextColor],
            }
          : undefined
      }
      key={animationKey}
      transition={{ duration: 2, times: [0, 0.2, 0.6, 1] }}
    >
      {children}
    </MotionDiv>
  )
}

export default AnimatedBox
