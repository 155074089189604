var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from './../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.white
};
var CalendarDateTime = function CalendarDateTime(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    style: {
      width: '50px',
      height: '78px'
    },
    viewBox: "0 0 50 78",
    "data-id": "icons_CalendarDateTime_svg"
  }, __jsx("title", {
    "data-id": "icons_CalendarDateTime_title"
  }, "Datum en tijd icoon"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_CalendarDateTime_g"
  }, __jsx("g", {
    transform: "translate(-250.000000, -99.000000)",
    fill: color,
    "data-id": "icons_CalendarDateTime_g_3"
  }, __jsx("g", {
    transform: "translate(210.000000, 60.000000)",
    "data-id": "icons_CalendarDateTime_g_4"
  }, __jsx("g", {
    transform: "translate(36.000000, 34.000000)",
    "data-id": "icons_CalendarDateTime_g_5"
  }, __jsx("path", {
    d: "M48,6.05405405 L54,6.05405405 L54,83 L4,83 L4,6.05405405 L11,6.05405405 L11,5 L14,5 L14,6.05405405 L45,6.05405405 L45,5 L48,5 L48,6.05405405 Z M11.5810811,11.3243243 C10.7078741,11.3243243 10,12.0321984 10,12.9054054 C10,13.7786124 10.7078741,14.4864865 11.5810811,14.4864865 L11.5810811,14.4864865 L46.4189189,14.4864865 C47.2921259,14.4864865 48,13.7786124 48,12.9054054 L48,12.9054054 C48,12.0321984 47.2921259,11.3243243 46.4189189,11.3243243 L46.4189189,11.3243243 L11.5810811,11.3243243 Z",
    "data-id": "icons_CalendarDateTime_path"
  }))))));
};
CalendarDateTime.defaultProps = defaultProps;
export default CalendarDateTime;