import React, { FC } from 'react'
import { colors } from '@/styles/colors'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import Svg from './Svg'

const SecondFloorIcon: FC<IconProps> = ({ width = 21, height, variant = 'gray30' }) => {
  const { h, w } = getWidthOrHeight(width, height)
  const fillColor = colors[variant]

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 21 21"
    >
      <g clipPath="url(#clip0_820_50673)">
        <path
          d="M10.0406 17.0563V10.5493L8.4 11.4038C8.07188 10.9437 7.875 10.5822 7.875 10.3521C7.875 10.1221 8.00625 9.92488 8.30156 9.76056L10.1391 8.80751C10.3031 8.70892 10.4672 8.64319 10.6641 8.61033C10.8281 8.5446 10.9922 8.51174 11.1563 8.51174C11.4188 8.51174 11.6813 8.61033 11.8781 8.70892V17.0563H10.0406Z"
          fill={fillColor}
        />
        <path
          d="M10.5 1.9061L1.64062 7.19718L1.67344 19.3239L18.3422 19.3568C18.9 19.3568 19.3266 18.9296 19.3266 18.3709V7.23005L10.5 1.9061ZM10.5 0L21 6.30986L20.9672 19.0282C20.9672 20.1127 20.0813 21 18.9984 21H0V6.277L10.5 0Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_820_50673">
          <rect
            width="21"
            height="21"
            fill={fillColor}
          />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default SecondFloorIcon
