import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import withMatch from '@/hocs/withMatch';
import { connect } from 'react-redux';
import { GridWrapper, GridItem } from '../../../styles/gridElements';
import { breakpointSizes, toBreakpoint, fromBreakpoint } from '../../../styles/breakpoints';
import { getResultData } from '../../../redux/apiData';
import HeroHome from '../../HeroHome';
import Block from './../../Block';
import get from 'lodash.get';
import isLastItem from '../../../services/isLastItem';
var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    styling: get(getResultData(state.apiData, 'getSettings', {
      projectSlug: String(ownProps.match.params.projectSlug),
      env: String(ownProps.match.params.env)
    }), 'styling', undefined)
  };
};
var LayoutHome = function LayoutHome(_ref) {
  var page = _ref.page,
    styling = _ref.styling,
    renderWidth = _ref.renderWidth;
  return __jsx("div", {
    "data-id": "layout_LayoutHome_div"
  }, page.templateProps && page.templateProps.hero && styling && styling.button && __jsx(HeroHome, {
    hero: page.templateProps.hero,
    heroTriangleSmallColor: styling.heroTriangleSmall,
    heroTriangleLargeColor: styling.heroTriangleLarge,
    buttonColor: styling.button,
    "data-id": "layout_LayoutHome_HeroHome"
  }), __jsx(ContentWrapper, {
    "data-e2e-page-layout": "home",
    "data-id": "layout_LayoutHome_ContentWrapper"
  }, (page.blocks || []).map(function (block, index) {
    return __jsx(BlockCell, {
      key: index,
      noGutterUntilBreakpoint: block.type === 'storyslider' ? breakpointSizes.md : 0,
      "data-id": "layout_LayoutHome_BlockCell"
    }, __jsx(Block, {
      block: block,
      isLastItem: isLastItem(page.blocks, index),
      renderWidth: renderWidth,
      "data-id": "layout_LayoutHome_Block"
    }));
  })));
};
LayoutHome.defaultProperties = {
  hasHeader: true,
  hasFooter: true,
  hasMenu: true
};
export default withMatch(connect(mapStateToProps)(LayoutHome));
var BlockCell = styled(GridItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-bottom: 20rem;\n\n  &:last-child {\n    ", ";\n\n    ", ";\n  }\n"])), toBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      padding-bottom: 0;\n    "]))), fromBreakpoint.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      padding-bottom: 30rem;\n    "]))));
var ContentWrapper = styled(GridWrapper)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding-top: 60rem;\n"])));