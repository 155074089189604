import { IconSlug, WishList } from '@/entities/wishList'
import {
  getTotalLivingSurfaceForCategory,
  getTotalLivingSurface,
  getLivingSurfaceForOption,
} from '@/processes/houseDesignLivingSpace'
import {
  getTotalPriceForCategory,
  getTotalPrice,
  getTotalReferencePriceForCategory,
} from '@/processes/houseDesignPrices'
import {
  getTotalAmountForOption,
  hasNewOptions,
  isOptionDisabled,
  isOptionExcluded,
} from '@/processes/houseDesignStatuses'
import { slugify } from '@/processes/houseDesignSlugs'
import { Slug } from '@/constants/slugs'

export type Section = {
  id?: number
  slug: string
  title: string
  price: number
  squareMeters?: number
  url: string
  iconSlug?: IconSlug
  extraSquareMeters?: boolean
  hasNew?: boolean
  referencePrice?: number
  isSummary?: boolean
  isReference?: false
}

export type OptionSummary = Pick<Section, 'title' | 'squareMeters' | 'price'> & {
  iconSlug: undefined
  isSummary: false
  id: number
  isReference: boolean
}

/**
 * Get the config for the sections/pages of a wish list, to build navigation etc.
 */
export const getWishListSections = (baseUrl: string, wishList?: WishList): Section[] => {
  if (!wishList) {
    return []
  }
  const { categories, house, houseType, totals } = wishList
  return [
    {
      slug: Slug.base,
      title: 'De basis',
      price: house.price,
      squareMeters: houseType?.livingSpace,
      url: baseUrl,
    },
    ...categories.map(({ title, icon, subcategories, id }) => {
      const slug = slugify(title)
      return {
        id,
        slug,
        title,
        price: getTotalPriceForCategory(title, totals),
        url: `${baseUrl}/${slug}`,
        squareMeters: getTotalLivingSurfaceForCategory(subcategories, wishList),
        iconSlug: icon,
        extraSquareMeters: true,
        hasNew: hasNewOptions(subcategories),
        referencePrice: getTotalReferencePriceForCategory(subcategories, wishList),
      }
    }),
    {
      slug: Slug.userWishes,
      title: 'Eigen woonwensen',
      price: totals.userWishTotal,
      url: `${baseUrl}/eigen-woonwensen`,
      iconSlug: 'houseEllipsis',
    },
    {
      slug: Slug.summary,
      title: 'Samenvatting',
      price: getTotalPrice(totals, house.price),
      url: `${baseUrl}/samenvatting`,
      squareMeters: getTotalLivingSurface(wishList),
      iconSlug: 'houseEuro',
      isSummary: true,
    },
  ]
}

/**
 * Get the index of the current section in the list of all sections
 */
export const getCurrentSectionIndex = (
  sections: Section[],
  currentSectionSlug?: string
): number => {
  if (!currentSectionSlug) {
    return 0
  }

  return sections.findIndex(({ slug }) => slug === currentSectionSlug)
}

/**
 * Get the next section of the wish list, based on the current slug.
 */
export const getNextSection = (
  sections: Section[],
  currentSectionSlug?: string
): Section | undefined => {
  const currentSectionIndex = getCurrentSectionIndex(sections, currentSectionSlug)

  if (currentSectionIndex === -1) {
    return undefined
  }

  return sections[currentSectionIndex + 1]
}

/**
 * Get the previous section of the wish list, based on the current slug.
 */
export const getPreviousSection = (
  sections: Section[],
  currentSectionSlug?: string
): Section | undefined => {
  const currentSectionIndex = getCurrentSectionIndex(sections, currentSectionSlug)

  if (currentSectionIndex === -1) {
    return undefined
  }

  return sections[currentSectionIndex - 1]
}

export const getSelectedOptions = (wishList: WishList, categoryId?: number): OptionSummary[] => {
  if (!categoryId) {
    return []
  }
  const category = wishList.categories.find(({ id }) => id === categoryId)
  if (!category) {
    return []
  }
  const optionSummaries: OptionSummary[] = []
  category.subcategories.forEach(({ options }) => {
    options.forEach((option) => {
      const optionAmount = getTotalAmountForOption(option)
      if (optionAmount === 0 || isOptionExcluded(option, wishList) || isOptionDisabled(option)) {
        return
      }
      optionSummaries.push({
        id: option.id,
        title: option.title,
        price: optionAmount * option.price,
        squareMeters: getLivingSurfaceForOption(option),
        iconSlug: undefined,
        isSummary: false,
        isReference: option.optionType === 'reference',
      })
    })
  })

  return optionSummaries
}
