import React, { FC } from 'react'
import getWidthOrHeight from '@/processes/getWidthOrHeight'
import { IconProps } from '@/types/svgTypes'
import { ICON_COLORS } from '@/constants/colors'
import Svg from './Svg'

const ApproximateIcon: FC<IconProps> = ({ width = 16, height, variant = 'brand' }) => {
  const { h, w } = getWidthOrHeight(width, height)

  return (
    <Svg
      style={{ width: w, height: h }}
      viewBox="0 0 22 22"
    >
      <path
        fill={ICON_COLORS[variant]}
        fillRule="evenodd"
        d="M11,22A11,11,0,1,1,22,11,11,11,0,0,1,11,22ZM16.928,6.156a4.132,4.132,0,0,1-2.864,1.686,6.579,6.579,0,0,1-2.89-.91,7.383,7.383,0,0,0-3.292-.963A5.485,5.485,0,0,0,3.52,8.484L4.885,10.17A4.093,4.093,0,0,1,7.855,8.458a7.077,7.077,0,0,1,2.944.91,7.147,7.147,0,0,0,3.292.99,5.234,5.234,0,0,0,4.336-2.623Zm0.107,5.674a3.853,3.853,0,0,1-2.944,1.686,6.7,6.7,0,0,1-2.89-.91,7.384,7.384,0,0,0-3.292-.963,5.554,5.554,0,0,0-4.362,2.489l1.365,1.713a4.259,4.259,0,0,1,2.944-1.686,6.716,6.716,0,0,1,2.971.91,7.6,7.6,0,0,0,3.265.964,5.293,5.293,0,0,0,4.389-2.676Z"
      />
    </Svg>
  )
}

export default ApproximateIcon
