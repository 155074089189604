import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import BBCode from './shared/richText/BBCode';
var RichTextBlock = function RichTextBlock(_ref) {
  var bbcode = _ref.bbcode;
  return bbcode ? __jsx(RichTextStyling, {
    "data-e2e-block-richtextblock": true,
    "data-id": "components_RichTextBlock_RichTextStyling"
  }, __jsx(BBCode, {
    content: bbcode,
    "data-id": "components_RichTextBlock_BBCode"
  })) : null;
};
export default RichTextBlock;
var RichTextStyling = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 780rem;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 30rem 0rem;\n"])));