import { IconProps, IconVariant } from '@/types/svgTypes'
import { colors } from '@/styles/colors'
import radii from '@/styles/radii'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { transitions } from '@/styles/shared'

export type BoxIconVariant = 'brand' | 'transparent' | 'white' | 'gray30' | 'gray25' | 'red'
type Sizes = 'sm' | 'md' | 'lg'

type Props = {
  icon: FC<IconProps>
  onClick?: () => void
  variant?: BoxIconVariant
  size?: Sizes
}

const VARIANT_BACKGROUND_MAP: Record<BoxIconVariant, string> = {
  brand: colors.brand,
  transparent: 'transparent',
  white: colors.white,
  gray30: colors.gray30,
  gray25: colors.gray25,
  red: colors.red,
}

const VARIANT_ICON_MAP: Record<BoxIconVariant, IconVariant> = {
  brand: 'white',
  transparent: 'gray40',
  white: 'brand',
  gray30: 'white',
  gray25: 'white',
  red: 'white',
}

const SIZE_DIMENSIONS_MAP: Record<Sizes, number> = {
  sm: 12,
  md: 14,
  lg: 24,
}

const SIZE_ICON_HEIGHT_MAP: Record<Sizes, number> = {
  sm: 8,
  md: 10,
  lg: 18,
}

const IconButtonStyle = styled.span<{ variant: BoxIconVariant; size: Sizes; clickable: boolean }>`
  ${radii.container.sm}
  width: ${({ size }) => SIZE_DIMENSIONS_MAP[size]}rem;
  height: ${({ size }) => SIZE_DIMENSIONS_MAP[size]}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${transitions.hover}
  background-color: ${({ variant }) => VARIANT_BACKGROUND_MAP[variant]};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  ${({ variant, clickable }) =>
    variant === 'transparent' &&
    clickable &&
    css`
      &:hover,
      &:focus,
      &:active {
        path {
          fill: ${colors.brand};
        }
      }
    `}
`

const BoxIcon: FC<Props> = ({ variant = 'brand', size = 'md', icon, onClick }) => {
  const Icon = icon
  return (
    <IconButtonStyle
      variant={variant}
      size={size}
      onClick={onClick}
      clickable={!!onClick}
    >
      <Icon
        variant={VARIANT_ICON_MAP[variant]}
        height={SIZE_ICON_HEIGHT_MAP[size]}
      />
    </IconButtonStyle>
  )
}

export default BoxIcon
