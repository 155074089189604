import { WishList } from '@/entities/wishList'

export const getOptionByUuid = (uuid: string, { categories }: WishList) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    // eslint-disable-next-line no-restricted-syntax
    for (const subcategory of category.subcategories) {
      // eslint-disable-next-line no-restricted-syntax
      for (const option of subcategory.options) {
        if (option.uuid === uuid) {
          return option
        }
      }
    }
  }
  return undefined
}

export const getAllOptionUuids = ({ categories }: WishList) => {
  const uuids: string[] = []
  categories.forEach(({ subcategories }) => {
    subcategories.forEach(({ options }) => {
      options.forEach(({ uuid }) => {
        uuids.push(uuid)
      })
    })
  })
  return uuids
}
