import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { openModal } from '../redux/modal';
import { colorPalette, fonts } from '../styles/styleGuide';
import { hex2rgb } from '../styles/utils';
import Slider from './shared/ui/Slider';
import PlusIcon from './shared/icons/Plus';
import ImageLoader from './shared/ui/ImageLoader';
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onPress: function onPress(evt, images, currentIndex) {
      evt.preventDefault();
      evt.stopPropagation();
      dispatch(openModal('#ffffff', {
        type: 'slider',
        componentProps: {
          currentIndex: currentIndex,
          images: images
        }
      }));
    }
  };
};
var PictureCollage = function PictureCollage(_ref) {
  var renderWidth = _ref.renderWidth,
    media = _ref.media,
    onPress = _ref.onPress;
  var size = renderWidth > 689 ? 'medium' : 'small';
  var remainingPictureAvailable = media.length > 6;
  var remainingPictureCount = (media.length - 5).toString();
  return media && media.length ? __jsx(View, {
    "data-id": "components_PictureCollage_View"
  }, size === 'medium' ? __jsx(CollageWrapper, {
    "data-id": "components_PictureCollage_CollageWrapper"
  }, __jsx(Collage, {
    mediumView: size === 'medium',
    "data-id": "components_PictureCollage_Collage"
  }, __jsx(ColumnOne, {
    "data-id": "components_PictureCollage_ColumnOne"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 0);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[0].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  }))), media[1] && __jsx(ColumnOne, {
    "data-id": "components_PictureCollage_ColumnOne"
  }, __jsx(RowOne, {
    "data-id": "components_PictureCollage_RowOne"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 1);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[1].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  }))), media[2] && __jsx(RowOne, {
    "data-id": "components_PictureCollage_RowOne"
  }, __jsx(RowTwo, {
    "data-id": "components_PictureCollage_RowTwo"
  }, __jsx(ColumnTwo, {
    "data-id": "components_PictureCollage_ColumnTwo"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 2);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[2].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  }))), media[3] && __jsx(ColumnTwo, {
    "data-id": "components_PictureCollage_ColumnTwo"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 3);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[3].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  })))), media[4] && __jsx(RowTwo, {
    "data-id": "components_PictureCollage_RowTwo"
  }, __jsx(ColumnTwo, {
    "data-id": "components_PictureCollage_ColumnTwo"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 4);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[4].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  }))), media[5] && __jsx(ColumnTwo, {
    "data-id": "components_PictureCollage_ColumnTwo"
  }, __jsx(ShowBtn, {
    onClick: function onClick(evt) {
      return onPress(evt, media, 5);
    },
    "data-id": "components_PictureCollage_ShowBtn"
  }, __jsx(ImageLoader, {
    src: media[5].image.src,
    mode: "bg",
    "data-id": "components_PictureCollage_ImageLoader"
  }), remainingPictureAvailable && __jsx(Overlay, {
    "data-id": "components_PictureCollage_Overlay"
  }, __jsx(PictureCountText, {
    "data-id": "components_PictureCollage_PictureCountText"
  }, __jsx(PlusIconWrapper, {
    "data-id": "components_PictureCollage_PlusIconWrapper"
  }, __jsx(PlusIcon, {
    "data-id": "components_PictureCollage_PlusIcon"
  })), remainingPictureCount, " beelden"))))))))) : __jsx(SliderContainer, {
    "data-id": "components_PictureCollage_SliderContainer"
  }, __jsx(Slider, {
    heroes: media,
    showPrimaryArrows: false,
    heightRatio: 544 / 780,
    currentIndex: 0,
    "data-id": "components_PictureCollage_Slider"
  }))) : null;
};
export default connect(null, mapDispatchToProps)(PictureCollage);
var View = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
var SliderContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: calc(100% + 40rem);\n  position: relative;\n  left: -20rem;\n"])));
var ShowBtn = styled.button(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  box-shadow: 0rem 0rem 4rem 0rem rgba(0, 0, 0, 0.16);\n\n  > div:nth-of-type(1) > div {\n    transform: scale(1) translateZ(0);\n    transition: transform 1s ease;\n    will-change: transform;\n  }\n\n  &:hover > div {\n    > div:nth-of-type(1) {\n      transform: scale(1.03) translateZ(0);\n    }\n  }\n"])));
var Overlay = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  background-color: ", ";\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  color: #ffffff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), hex2rgb(colorPalette.beige, 0.75));
var PlusIconWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 13rem;\n  height: 13rem;\n  display: inline-block;\n  margin-right: 6rem;\n  position: relative;\n"])));
var PictureCountText = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: 20rem;\n  font-weight: bold;\n  line-height: 20rem;\n  display: block;\n"])), fonts.primary);
var ColumnTwo = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  height: 100%;\n  width: calc(50% - 10rem);\n  position: relative;\n  float: left;\n"])));
var RowTwo = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  float: left;\n  width: 100%;\n  height: calc(50% - 10rem);\n\n  > ", ":nth-of-type(1) {\n    margin-right: 10rem;\n  }\n\n  > ", ":nth-of-type(2) {\n    margin-left: 10rem;\n  }\n"])), ColumnTwo, ColumnTwo);
var RowOne = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  float: left;\n  width: 100%;\n  height: calc(50% - 10rem);\n\n  > ", ":nth-of-type(1) {\n    margin-bottom: 10rem;\n  }\n\n  > ", ":nth-of-type(2) {\n    margin-top: 10rem;\n  }\n"])), RowTwo, RowTwo);
var ColumnOne = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  height: 100%;\n  width: calc(50% - 20rem);\n\n  > ", ":nth-of-type(1) {\n    margin-bottom: 10rem;\n  }\n\n  > ", ":nth-of-type(2) {\n    margin-top: 10rem;\n  }\n"])), RowOne, RowOne);
var CollageWrapper = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  position: relative;\n  padding-top: calc((544 / 1311) * 100%);\n  width: 100%;\n  margin-top: 20rem;\n  margin-bottom: 20rem;\n\n  &:before {\n    position: absolute;\n    content: '';\n    top: -20rem;\n    height: 1rem;\n    width: 100%;\n    border-top: 1rem dashed ", ";\n  }\n\n  &:after {\n    position: absolute;\n    content: '';\n    bottom: -20rem;\n    height: 1rem;\n    width: 100%;\n    border-top: 1rem dashed ", ";\n  }\n"])), hex2rgb(colorPalette.lightGrey, 0.5), hex2rgb(colorPalette.lightGrey, 0.5));
var Collage = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  position: absolute;\n  justify-content: space-between;\n\n  ", ":nth-of-type(1) {\n    width: calc(((8 / 12) * 100%) - 10rem);\n  }\n\n  ", ":nth-of-type(2) {\n    width: calc(((4 / 12) * 100%) - 10rem);\n  }\n"])), ColumnOne, ColumnOne);