import { motion } from 'framer-motion'
import { CSSProperties, ReactNode } from 'react'

type Props = {
  animationKey: string | number
  children: ReactNode
  style?: CSSProperties
}

const FadeAnimationWrapper = ({ children, animationKey, style }: Props) => (
  <motion.div
    key={animationKey}
    initial="initial"
    animate="animate"
    transition={{
      duration: 1,
      ease: 'easeInOut',
    }}
    variants={{
      initial: {
        opacity: 0,
      },
      animate: {
        opacity: 1,
      },
    }}
    style={style}
  >
    {children}
  </motion.div>
)

export default FadeAnimationWrapper
