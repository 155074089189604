import React, { Component, ComponentType } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import withMatch, { WithMatchProps } from '@/hocs/withMatch'
import {
  IWishListOption,
  IProductOptionModal,
  IAuthor,
  IImage,
  ISlideNode,
  BBCodeModelPageResource,
} from '@/entities/types'
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import withApiData, { ApiDataBinding, GetParams } from '@/services/withApiData'
import { colorPalette, shadows, textStyles } from '@/styles/styleGuide'
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import HeroArticle from '@/components/HeroArticle'
import { toBreakpoint, fromBreakpoint } from '@/styles/breakpoints'
import formatPrice from '@/services/formatPrice'
import { performApiRequest } from '@/redux/apiData'
import BBCode from '@/components/shared/richText/BBCode'
import ButtonDownload from '@/components/shared/buttons/ButtonDownload'
import SummaryOptionsOption from '@/components/homestudio/SummaryOptionsOption'
import Pill from '@/atoms/Pill'
import ApproximateIcon from '@/svg/ApproximateIcon'
import { colors } from '@/styles/colors'
import { fontWeight } from '@/styles/fonts'
import Box from '@/atoms/Box'
import ExternalSeller from '../molecules/ExternalSeller'
// eslint-disable-next-line import/no-cycle
import { OptionSubtitleContainer } from '../organisms/Option'

const TypedHeroArticle = (props: {
  renderWidth: number
  showDownloadButton?: boolean
  type: 'slider' | 'image' | 'video'
  title?: string
  author?: IAuthor
  credits?: string
  image?: IImage
  theme: string
  media?: Array<ISlideNode>
  youtubeId?: string
}) => {
  // @ts-ignore
  return <HeroArticle {...props} />
}

const TypedButtonDownload = (props: {
  fileId?: number
  files?: BBCodeModelPageResource
  link?: string
  content: string
  image?: boolean
}) => {
  // @ts-ignore
  return <ButtonDownload {...props} />
}

export type ProductOptionDescriptionModalComponentProps = {
  projectSlug: string
  modalId: number
  options: Array<IWishListOption>
}

type DispatchProps = {
  getNewProductOption: (modalId: number, projectSlug: string) => void
}

type ApiDataProps = { wishListModalProductOption: ApiDataBinding<IProductOptionModal> }

type Props = WithMatchProps &
  ProductOptionDescriptionModalComponentProps &
  ApiDataProps &
  DispatchProps

const getApiDataParams = ({
  match: {
    params: { projectSlug },
  },
  modalId,
}: WithMatchProps & ProductOptionDescriptionModalComponentProps) => {
  return {
    wishListModalProductOption: {
      projectSlug,
      modalId,
    },
  }
}

type WithApiData = <P extends object>(
  WrappedComponent: ComponentType<P & ApiDataProps>
) => (props: P) => JSX.Element

const connectApiData = withApiData(
  {
    wishListModalProductOption: 'getWishListModalProductOption',
  },
  getApiDataParams as unknown as GetParams
) as WithApiData

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getNewProductOption: (modalId: number, projectSlug: string) => {
    dispatch(performApiRequest('getWishListModalProductOption', { projectSlug, modalId }))
  },
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: ${colors.white};
  ${shadows.boxPrimary};
`

const Wrapper = styled.div`
  margin-right: 20rem;
  margin-left: 20rem;
  margin-top: 10rem;
`

const Content = styled.div`
  width: 100%;
  max-width: 780rem;
  margin-left: auto;
  margin-right: auto;
  padding: 48rem 0;

  ${fromBreakpoint.md`
    padding: 100rem 0;
  `};
`

const Title = styled.h3`
  ${textStyles.sectionTitle};
  color: ${colorPalette.headerGrey};
  margin: 0 20rem;
  ${toBreakpoint.lg`
    font-size: 24rem;
  `};
`

const SubTitle = styled.div`
  margin: 0 20rem 20rem;
  ${textStyles.caption};
  color: ${colorPalette.beige};
  font-size: 14rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4rem;
`

const OptionSubTitle = styled(SubTitle)`
  margin: 0 20rem;
`

const OptionSubtitleBase = styled(OptionSubtitleContainer)`
  margin-bottom: 20rem;
`

const Price = styled.h3`
  ${textStyles.title};
  color: ${colorPalette.headerGrey};
  font-weight: ${fontWeight.medium};
  margin: 0 20rem 16rem;
`

const PriceContainer = styled.div`
  border-top: 2rem solid ${colorPalette.background};
  margin: 50rem 20rem;
`

const PriceTitle = styled.h5`
  ${textStyles.subTitle};
  color: ${colorPalette.headerGrey};
  margin: 20rem 0 10rem;
`

const PriceStructure = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`

const Structure = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 90%;
    color: ${colorPalette.headerGrey};
    ${textStyles.bodyText};

    ${toBreakpoint.md`
      width: 100%;
    `};

    p:first-of-type {
      position: relative;
      &::before {
        content: '●';
        color: ${colorPalette.lightOrange};
        display: inline-flex;
        width: 20rem;
      }
    }
  }

  &:last-of-type {
    > div {
      font-weight: ${fontWeight.bold};
      margin-top: 10rem;
    }

    p:first-of-type {
      text-align: end;
      padding-right: 14rem;

      &::before {
        display: none;
      }
    }

    p:last-of-type {
      position: relative;

      ${toBreakpoint.md`
        &:after {
          width: calc(100% + 28rem);
        }
      `};

      &::after {
        position: absolute;
        content: '';
        background-color: ${colorPalette.beige};
        height: 1rem;
        width: calc(100% + 35rem);
        right: 0;
        top: -9rem;

        ${toBreakpoint.md`
          width: calc(100% + 26rem);
        `};
      }
    }
  }
`

const DownloadContainer = styled.div`
  border-top: 2rem solid ${colorPalette.background};
  margin-left: 20rem;
  margin-right: 20rem;
`

const DownloadTitle = styled.h5`
  ${textStyles.subTitle};
  color: ${colorPalette.headerGrey};
  margin: 20rem 0 10rem;
`

class ProductOptionDescriptionModal extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { match, modalId, getNewProductOption } = this.props
    if (prevProps.modalId !== modalId) {
      getNewProductOption(modalId, String(match.params.projectSlug))
    }
  }

  render() {
    const renderWidth = 0
    const { wishListModalProductOption } = this.props
    const productOptionData = wishListModalProductOption.data
    if (!productOptionData) {
      return null
    }

    const heroArticleProps = {
      type: productOptionData.type as 'video' | 'image' | 'slider',
      renderWidth,
    }

    return (
      <Container>
        {productOptionData.type === 'slider' && (
          <TypedHeroArticle
            {...heroArticleProps}
            media={productOptionData.media}
            theme="algemeen"
            showDownloadButton
          />
        )}
        {productOptionData.type === 'image' && (
          <TypedHeroArticle
            {...heroArticleProps}
            image={productOptionData.image}
            theme="algemeen"
            showDownloadButton
          />
        )}
        {productOptionData.type === 'video' && (
          <TypedHeroArticle
            {...heroArticleProps}
            youtubeId={productOptionData.youtubeId}
            theme="algemeen"
          />
        )}
        <Content>
          <div>
            <Title>{productOptionData.title}</Title>
            <Price>
              {formatPrice(productOptionData.price, { showDecimal: true, showEuroSign: true })}
            </Price>
            {productOptionData.optionType === 'reference' && (
              <SubTitle>
                <Pill
                  icon={ApproximateIcon}
                  text="Voorbeeldoptie"
                />
                <span>
                  Let op: dit is geen offerte, maar een voorbeeldoptie. Deze is bedoeld om een beeld
                  te geven van de mogelijkheden en mogelijke kosten.
                </span>
              </SubTitle>
            )}
            <OptionSubtitleBase>
              {productOptionData.optionCode && (
                <OptionSubTitle>
                  <span>Optiecode: {productOptionData.optionCode}</span>
                </OptionSubTitle>
              )}
              {productOptionData.external_seller && (
                <Box marginLeft="sm">
                  {/* eslint-disable-next-line camelcase */}
                  <ExternalSeller text={productOptionData.external_seller} />
                </Box>
              )}
            </OptionSubtitleBase>
          </div>
          <Wrapper>
            {/* @ts-ignore */}
            <BBCode content={productOptionData.text} />
          </Wrapper>
          {productOptionData.priceStructure && productOptionData.priceStructure.length > 0 && (
            <PriceContainer>
              <PriceTitle>Prijsopbouw</PriceTitle>
              <PriceStructure>
                {(productOptionData.priceStructure || []).map((structure) => (
                  <Structure key={structure.title}>
                    <SummaryOptionsOption
                      title={
                        structure.multiple && structure.amount
                          ? `${structure.title} (${structure.amount} ${
                              structure.amount > 1 ? 'stuks' : 'stuk'
                            })`
                          : structure.title
                      }
                      price={structure.subtotal}
                      amount={1}
                      hidePriceDelta={false}
                    />
                  </Structure>
                ))}
                <Structure>
                  <SummaryOptionsOption
                    title="Totaal"
                    price={productOptionData.price}
                    amount={1}
                    hidePriceDelta={false}
                  />
                </Structure>
              </PriceStructure>
            </PriceContainer>
          )}
          {productOptionData.externalFiles && productOptionData.externalFiles.length > 0 && (
            <DownloadContainer>
              <DownloadTitle>Bijlagen</DownloadTitle>
              {productOptionData.externalFiles.map((file) => (
                <TypedButtonDownload
                  key={file.name}
                  link={file.url}
                  content={file.name}
                />
              ))}
            </DownloadContainer>
          )}
        </Content>
      </Container>
    )
  }
}

export default withMatch(
  connectApiData(connect(null, mapDispatchToProps)(ProductOptionDescriptionModal))
)
