import Option from '@/components/organisms/Option'
import { WishListOption, WishListSubcategory } from '@/entities/wishList'
import useParams from '@/hooks/useParams'
import { useWishListMutation } from '@/hooks/wishListRequestHooks'
import { WishListRequestKey } from '@/types/endpoints'
import { useCallback } from 'react'
import InputCheckbox from '@/atoms/inputs/InputCheckbox'
import useOptionStatus from '@/hooks/useOptionStatus'
import InputCounter from '@/atoms/inputs/InputCounter'
import Box from '@/atoms/Box'
import InputRadio from '@/atoms/inputs/InputRadio'
import useSectionLoading from '@/hooks/useSectionLoading'
import useLayoutImages from '@/hooks/useLayoutImages'
import { isOptionFinal, isOptionPending } from '@/processes/houseDesignStatuses'

type Props = {
  option: WishListOption
  subcategory: WishListSubcategory
}

const isOtherSingleChoiceOptionSelected = (
  currentOptionId: number,
  allOptionsInSubcategory: WishListOption[]
) =>
  allOptionsInSubcategory.some(
    (option) => option.id !== currentOptionId && (isOptionFinal(option) || isOptionPending(option))
  )

const OptionDefault = ({ option, subcategory }: Props) => {
  const optionStatus = useOptionStatus(option, subcategory)
  const {
    isDisabled,
    isExcluded,
    isFinal,
    isPending,
    isActionRequired,
    isPostDeadlineSelected,
    isPostDeadlineNotSelected,
  } = optionStatus

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wishListItem, ...params } = useParams()
  const { toggleSectionLoading } = useSectionLoading()
  const { setLayoutImage } = useLayoutImages()

  const { id, amount, multiple } = option
  const { isSingleChoice, options: allOptionsInSubcategory } = subcategory

  const { mutate, isLoading } = useWishListMutation(
    'patchWishList',
    [WishListRequestKey.wishListLoading],
    params,
    {
      onSettled: () => toggleSectionLoading(false, wishListItem),
    }
  )

  const layoutImageSrc = option.layoutImage?.src

  const onChange = useCallback(
    (n: number) => {
      toggleSectionLoading(true, wishListItem)
      const options = { [id]: { amount: n, productOption: id } }
      if (isSingleChoice) {
        allOptionsInSubcategory.forEach(({ id: optionId }) => {
          if (optionId !== id) {
            options[optionId] = { amount: 0, productOption: optionId }
          }
        })
      }
      if (layoutImageSrc) {
        setLayoutImage(n > 0 ? layoutImageSrc : undefined)
      }
      mutate({
        params,
        body: {
          options,
        },
      })
    },
    [
      allOptionsInSubcategory,
      id,
      isSingleChoice,
      layoutImageSrc,
      mutate,
      params,
      setLayoutImage,
      toggleSectionLoading,
      wishListItem,
    ]
  )

  const selected = amount > 0 || isPostDeadlineSelected

  const isDisabledBecauseOfSingleChoice =
    isSingleChoice && isOtherSingleChoiceOptionSelected(id, allOptionsInSubcategory)

  const disabled = isDisabled || isDisabledBecauseOfSingleChoice

  const Input = isSingleChoice ? InputRadio : InputCheckbox

  return (
    <Option
      {...optionStatus}
      isDisabled={disabled}
      option={option}
      selected={selected}
      input={
        isPostDeadlineNotSelected ? undefined : (
          <Input
            checked={selected}
            onClick={(checked: boolean) => onChange(checked ? 1 : 0)}
            disabled={(disabled || isExcluded) && !isFinal}
            final={isFinal || (isPostDeadlineSelected && !isPending)}
            pending={isPending}
            actionRequired={isActionRequired}
            excluded={isExcluded}
          />
        )
      }
      isLoading={isLoading}
      subcategory={subcategory}
    >
      {!isFinal && !isPostDeadlineSelected && !isPostDeadlineNotSelected && multiple && (
        <Box>
          <InputCounter
            value={amount}
            onChange={onChange}
            disabled={disabled || isExcluded}
          />
        </Box>
      )}
    </Option>
  )
}

export default OptionDefault
