var __jsx = React.createElement;
import React from 'react';
import { colorPalette } from '../../../styles/styleGuide';
var defaultProps = {
  color: colorPalette.orange
};
var HouseOneLayer = function HouseOneLayer(_ref) {
  var color = _ref.color;
  return __jsx("svg", {
    width: "21px",
    height: "20px",
    viewBox: "0 0 21 20",
    "data-id": "icons_HouseOneLayer_svg"
  }, __jsx("title", {
    "data-id": "icons_HouseOneLayer_title"
  }, "Appartement met een verdieping"), __jsx("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    "data-id": "icons_HouseOneLayer_g"
  }, __jsx("g", {
    transform: "translate(-40.000000, -212.000000)",
    fill: color,
    fillRule: "nonzero",
    "data-id": "icons_HouseOneLayer_g_3"
  }, __jsx("g", {
    transform: "translate(40.000000, 212.000000)",
    "data-id": "icons_HouseOneLayer_g_4"
  }, __jsx("g", {
    "data-id": "icons_HouseOneLayer_g_5"
  }, __jsx("path", {
    d: "M10,0 L0,6 L0,20 L8.40625,20 L8.40625,13.125 L11.53125,13.125 L11.53125,20 L18.09375,20 C19.125,20 19.96875,19.15625 19.96875,18.125 L20,6 L10,0 Z M18.40625,17.5 C18.40625,18.03125 18,18.4375 17.46875,18.4375 L12.78125,18.4375 L12.78125,11.875 L7.15625,11.875 L7.15625,18.375 L7.15625,18.4375 L1.59375,18.4375 L1.5625,6.875 L10,1.84375 L18.40625,6.875 L18.40625,17.5 Z",
    "data-id": "icons_HouseOneLayer_path"
  }))))));
};
HouseOneLayer.defaultProps = defaultProps;
export default HouseOneLayer;